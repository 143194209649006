import { franc } from "franc";

// ISO 639-3
export const rtlLangSupportList = ["heb", "ara", "fas"];

// Regular codes
export const rightToLeftLangCodes = [
  "he-IL",
  "ar-JO",
  "ar-AE",
  "ar-BH",
  "ar-DZ",
  "ar-IQ",
  "ar-KW",
  "ar-MA",
  "ar-SA",
  "ar-YE",
  "ar-TN",
  "ar-SY",
  "ar-OM",
  "ar-QA",
  "ar-LB",
  "ar-LY",
  "ar-EG",
  "fa-IR",
  "ps-AF",
];

export function checkRightToLeftText(sentence: string, primaryLanguage: string) {
  const isRegularRTL = rightToLeftLangCodes.includes(primaryLanguage);
  const isoLangCode = franc(sentence, { only: rtlLangSupportList });
  const isIsoRTL = rtlLangSupportList.includes(isoLangCode);

  // fran cannot detect Arabic consistently, so we check for "und" (undefined) to make sure it is not another language
  return isRegularRTL && (isIsoRTL || isoLangCode === "und");
}

export function checkRightToLeftLanguage(langCode: string) {
  return rightToLeftLangCodes.includes(langCode);
}
