import { Link } from "@chakra-ui/react";

interface Props {
  href: string;
  text: string;
  m?: string;
  fontSize?: string;
  color?: string;
  target?: string;
  rel?: string;
}

export function StdLink(props: Props) {
  return (
    <Link
      textDecoration={"underline"}
      color={props.color ?? "brand.secondary.main"}
      href={props.href}
      m={props.m}
      fontSize={props.fontSize}
      target={props.target || "_self"}
      rel={props.rel}
    >
      {props.text}
    </Link>
  );
}
