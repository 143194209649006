import { Flex, Menu, MenuButton, Button, MenuList, MenuItem, Box, Link } from "@chakra-ui/react";
import router from "next/router";
import { RiArrowDownSLine } from "react-icons/ri";
import { useUser } from "../../hooks/use-user";
import { LandingLocale } from "../../localization/landing";
import { signIn } from "next-auth/react";
import { authTarget } from "../../types/constants";

export function NavigationLanding(props: { l: LandingLocale; baseLangCode: string }) {
  const l = props.l;
  const user = useUser(false);

  const isDefaultEnglish = !props.baseLangCode || props.baseLangCode === "en";

  const onLogin = () => {
    user.sessionUser
      ? (window.location.href = "/app")
      : signIn(authTarget, { callbackUrl: "/app" });
  };

  return (
    <Flex flex="1" justifyContent={"flex-end"}>
      <Box id="top-nav-desktop" display={{ base: "none", md: "block" }}>
        <Link
          whiteSpace="nowrap"
          fontSize="xs"
          fontWeight={"bold"}
          color="brand.gray.800"
          href="/#pricing"
          mr="5"
          mt="9px"
        >
          {l["layout.nav.pricing"]}
        </Link>

        <Link
          whiteSpace="nowrap"
          fontSize="xs"
          fontWeight={"bold"}
          color="brand.gray.800"
          href="/#faq"
          mr="5"
          mt="9px"
        >
          {l["layout.nav.faq"]}
        </Link>

        {isDefaultEnglish && (
          <>
            <Link
              whiteSpace="nowrap"
              fontSize="xs"
              fontWeight={"bold"}
              color="brand.gray.800"
              mr="5"
              mt="9px"
              href="/languages"
            >
              {l["layout.nav.languages"]}
            </Link>

            <Link
              whiteSpace="nowrap"
              fontSize="xs"
              fontWeight={"bold"}
              color="brand.gray.800"
              mr="5"
              mt="9px"
              href="/blog"
            >
              {l["layout.nav.blog"]}
            </Link>
          </>
        )}
        <Link
          whiteSpace="nowrap"
          fontSize="xs"
          fontWeight={"bold"}
          color="brand.gray.800"
          mr="5"
          mt="9px"
          href="/partners/schools"
        >
          {l["layout.nav.schools"]}
        </Link>

        <Link
          whiteSpace="nowrap"
          fontSize="xs"
          fontWeight={"bold"}
          color="brand.gray.800"
          mr="5"
          mt="9px"
          href="/partners/business"
        >
          {l["layout.nav.teams"]}
        </Link>

        <Link
          whiteSpace="nowrap"
          id="go-to-app-header-link"
          fontSize="xs"
          fontWeight={"bold"}
          color="brand.gray.500"
          onClick={onLogin}
          mr="5"
          mt="9px"
        >
          {user.sessionUser ? l["layout.nav.goToApp"] : l["layout.nav.signIn"]}
        </Link>
      </Box>
      <Box display={{ md: "none" }}>
        <Menu id="main-navigation">
          <MenuButton as={Button} rightIcon={<RiArrowDownSLine />} variant="outline" size="sm">
            {l["layout.nav.menu"]}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => router.push("/#pricing")}>{l["layout.nav.pricing"]}</MenuItem>

            <MenuItem onClick={() => router.push("/#faq")}>{l["layout.nav.faq"]}</MenuItem>

            <MenuItem onClick={() => router.push("/partners/schools")}>
              {l["layout.nav.schools"]}
            </MenuItem>

            <MenuItem onClick={() => router.push("/partners/business")}>
              {l["layout.nav.teams"]}
            </MenuItem>

            <MenuItem onClick={() => router.push("/languages")}>
              {l["layout.nav.languages"]}
            </MenuItem>

            <MenuItem onClick={() => router.push("/blog")}>{l["layout.nav.blog"]}</MenuItem>

            <MenuItem onClick={() => router.push("/partners/affiliates")}>
              {l["layout.nav.affiliateProgram"]}
            </MenuItem>
            <MenuItem onClick={onLogin}>
              {user.sessionUser ? l["layout.nav.goToApp"] : l["layout.nav.signIn"]}
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
}
