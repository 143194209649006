import { AzRecognitionResult } from "../server/speech/azure/transcribe";

export function getAzureConfidence(results: AzRecognitionResult[]): number {
  let highestConfidence: number = 0;

  for (const result of results) {
    const reduced = result.NBest.reduce((acc, current) => {
      if (current.Confidence > acc) {
        acc = current.Confidence; // Update highest confidence
      }

      return acc; // Return the accumulator for the next iteration
    }, highestConfidence); // Initialize highestConfidence to 0

    highestConfidence = reduced;
  }

  let percentage = Math.round(highestConfidence * 100);

  return percentage;
}
