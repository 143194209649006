import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { fetchApi } from "../services/fetch-api";
import { StdLink } from "./StdLink";

export function NewsletterSignup() {
  const toast = useToast();
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (!inputText) {
      toast({
        title: "Please provide an email address.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    if (!termsAccepted) {
      toast({
        title: "Please accept the data privacy statement",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    fetchApi<{ ok: boolean }>(`/email/subscribe?email=${inputText}&list=11`)
      .then(() => {
        toast({
          title: "Thanks for subscribing!",
          status: "success",
          duration: 4000,
          isClosable: true,
        });

        setCompleted(true);
      })
      .catch(() => {
        toast({
          title: "Unable to subscribe to the newsletter. Please try again later.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box id="newsletter-signup" bgGradient="linear(to-b, gray.200 50%, brand.gray.700 50%, )">
      <Container py={4}>
        <Box
          bg="white"
          py={{ base: "10", md: "16" }}
          px={{ base: "6", md: "16" }}
          borderRadius="lg"
          boxShadow="sm"
        >
          <Stack
            spacing={{ base: "8", md: "8" }}
            direction={{ base: "column", lg: "row" }}
            justify="space-between"
          >
            <Stack spacing={{ base: "4", md: "5" }}>
              <Heading size={{ base: "sm", md: "md" }}>Subscribe to our newsletter</Heading>
              <Text fontSize={{ base: "lg", md: "xl" }} color="fg.muted">
                Subscribe to our newsletter for language learning tips, product updates, and
                exciting benefits!
              </Text>
            </Stack>

            {completed ? (
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing="4"
                width="full"
                maxW={{ base: "md", xl: "lg" }}
              >
                <Text
                  textAlign={"center"}
                  fontSize="2xl"
                  w="100%"
                  my={10}
                  color="brand.secondary.main"
                >
                  Thanks for subscribing!
                </Text>
              </Stack>
            ) : (
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing="4"
                width="full"
                maxW={{ base: "md", xl: "lg" }}
                as="form"
                onSubmit={onSubmit}
              >
                <FormControl flex="1">
                  <Input
                    type="email"
                    size="xl"
                    placeholder="Enter your email"
                    mb={3}
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                  />

                  <Checkbox
                    size="sm"
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                  >
                    I accept the{" "}
                    <StdLink
                      color="brand.gray.800"
                      href="https://www.brevo.com/legal/termsofuse/"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      text="data privacy statement"
                    ></StdLink>
                  </Checkbox>
                </FormControl>
                <Button
                  colorScheme={"brand.primary"}
                  color="brand.gray.700"
                  size="xl"
                  type="submit"
                  isLoading={loading}
                >
                  Subscribe
                </Button>
              </Stack>
            )}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
