import { useEffect, useState } from "react";

export function useIsInIframe(): boolean {
  const [isInIframeOrWebview, setIsInIframeOrWebview] = useState(false);

  useEffect(() => {
    const checkIframeOrWebview = () => {
      try {
        const isInIframe = window.self !== window.top;

        // Parse the query string from the current URL
        const searchParams = new URLSearchParams(window.location.search);
        const isInWebview = searchParams.get("webview") === "true";

        setIsInIframeOrWebview(isInIframe || isInWebview);
      } catch (e) {
        setIsInIframeOrWebview(false);
      }
    };
    checkIframeOrWebview();
  }, []);

  return isInIframeOrWebview;
}
