function embed() {
  return {
    __html: `
      <a href="https://www.producthunt.com/posts/talkio-ai?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-talkio&#0045;ai" target="_blank">
        <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=396157&theme=light" alt="Talkio&#0032;AI - AI&#0032;powered&#0032;language&#0032;training&#0032;app&#0032;for&#0032;the&#0032;browser | Product Hunt" style="width: 250px; height: 54px;" width="250" height="54" />
      </a>`,
  };
}

export function ProductHuntBadge() {
  return <div dangerouslySetInnerHTML={embed()} />;
}
