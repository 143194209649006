import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useSWR from "swr";
import { Settings, unavailableSettings } from "../types/settings";
import { AppError } from "../utils/app-error";
import { fetchApi } from "../services/fetch-api";
import { useRetries } from "./use-retries";
import { useToast } from "@chakra-ui/react";
import { logger } from "../utils/logger";
import { isScriptLanguage } from "../utils/is-script-language";
import { siteConfig } from "../configuration/config";

export const defaultMultiLingualId =
  "Microsoft Server Speech Text to Speech Voice (en-US, JennyMultilingualV2Neural)";

export const defaultTriggerWord = "Send";

const initialSettings: Settings = {
  id: undefined,
  practiceLanguage: siteConfig.practiceLanguageOptions?.[0] ?? "fr-FR",
  voicePreference:
    siteConfig.settingsVoicePreference ??
    "Microsoft Server Speech Text to Speech Voice (fr-FR, DeniseNeural)",
  readAloudSpeed: "3",
  uiLanguage: siteConfig.uiLanguage || "en-US",
  userLanguage: siteConfig.uiLanguage || "en-US",
  practiceLanguageProficiency: "elementary",
  autoSubmitTranscription: false,
  chatPreference: "text",
  firstVisit: siteConfig.hideIntroFlow ? false : true,
  showSubtitles: true,
  inputPreference: "voice",
  showSuggestions: siteConfig.settingsShowSuggestions ?? true,
  autoPunctuation: false,
  multiLingualSpeechRecognition: false,
  showChatTranslations: siteConfig.settingsShowChatTranslations ?? true,
  showChatRomanization: true,
  speechRecognitionProvider: "whisper",
  unavailableSettings: unavailableSettings,
  showLiveFeedback: siteConfig.settingsShowLiveFeedback ?? true,
  autoPlayTutorResponse: true,
  playbackUiAudio: siteConfig.settingsPlaybackUiAudio ?? true,
  submitWithTriggerWord: false,
  triggerWord: defaultTriggerWord,
};

export async function fetchSettings(): Promise<Settings> {
  try {
    const settings = await fetchApi<Settings>("/settings");

    return settings;
  } catch (err) {
    throw AppError.fromUnknownError(err);
  }
}

async function postSettings(settings: Settings): Promise<Settings> {
  try {
    return await fetchApi<Settings>("/settings", {
      method: "POST",
      body: JSON.stringify(settings),
    });
  } catch (err) {
    throw AppError.fromUnknownError(err);
  }
}

let saveOperationsCount = 0;

export function useSettings() {
  const [saveSettingsLoading, setSaveSettingsLoading] = useState(false);
  const [settingsSaveError, setSettingsSaveError] = useState<any>();
  const toast = useToast();
  const response = useSWR<Settings, AppError>(`use-settings`, fetchSettings);
  const loadingWithRetries = useRetries(response.isLoading, response.error);

  const settings = useMemo<Settings>(() => {
    if (!response.data?.id) {
      return initialSettings;
    }

    const isWhisper = response.data.speechRecognitionProvider === "whisper";

    return {
      ...response.data,
      unavailableSettings: {
        showChatRomanization: !isScriptLanguage(response.data.practiceLanguage),
        autoPunctuation: isWhisper,
      },
    };
  }, [response.data]);

  const settingsRef = useRef<Settings>(initialSettings);

  useEffect(() => {
    if (settings) {
      settingsRef.current = settings;
    }
  }, [settings]);

  // DEPRECATED
  const saveSettings = useCallback(
    (settingsToSave: Settings) => {
      setSaveSettingsLoading(true);

      // Pass defaults in case db is empty
      const settingsWithDefaults: Settings = {
        ...initialSettings,
        ...settingsToSave,
      };
      console.log("DEPRECATED SAVE");
      postSettings(settingsWithDefaults)
        .then((newSettings) => {
          response.mutate(newSettings);
          setSaveSettingsLoading(false);
        })
        .catch((err) => {
          setSettingsSaveError(err);
          setSaveSettingsLoading(false);
        });
    },
    [response],
  );

  const saveSettingsPartial = useCallback(
    (settingsToSave: Partial<Settings>) => {
      saveOperationsCount += 1;

      if (saveSettingsLoading) {
        logger.warn("Attempting to save settings while saving is in progress");
      }

      setSaveSettingsLoading(true);

      response.mutate((currentData) => {
        if (!currentData) {
          logger.error("Failed to save partial settings: no settings exist");
          return;
        }

        const updatedSettings = {
          ...currentData,
          ...settingsToSave,
        };

        postSettings(updatedSettings)
          .then((newSettings) => {
            saveOperationsCount -= 1;

            if (saveOperationsCount === 0) {
              response.mutate(newSettings);
              logger.info("Saved settings", newSettings);
            }
            setSaveSettingsLoading(false);
          })
          .catch((err) => {
            saveOperationsCount -= 1;
            setSettingsSaveError(err);
            setSaveSettingsLoading(false);
          });

        return updatedSettings;
      }, false);
    },
    [response, saveSettingsLoading],
  );

  const saveInitialSettings = () => {
    if (response.data?.id) {
      logger.error("Attempting to save initial settings when settings already exist");
    }

    logger.info("No settings for user: saving initial settings");
    saveSettings(initialSettings);
  };

  useEffect(() => {
    if (loadingWithRetries.failed) {
      const id = "use-settings";
      if (!toast.isActive(id)) {
        toast({
          id,
          title: "Unable to contact the  server",
          status: "warning",
          isClosable: true,
        });
      }

      logger.warn("Unable to contact the server to fetch settings", response.error);
    }
  }, [loadingWithRetries.failed, response.error, toast]);

  const userNotLoggedIn = response.error?.meta?.status === 401;

  return {
    ...response,
    mutateSettings: response.mutate,
    settings,
    settingsRef,
    settingsError: loadingWithRetries.failed,
    settingsAreLoading: loadingWithRetries.isLoading,
    settingsAreValidating: response.isValidating,
    /**
     * Use saveSettingsPartial instead
     * @deprecated
     */
    saveSettings,
    saveSettingsPartial,
    saveInitialSettings,
    settingsSaveError,
    saveSettingsLoading,
    userNotLoggedIn,
  };
}
