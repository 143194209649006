import { useEffect, useState } from "react";
import useStateRef from "react-usestateref";
import { staticCharacterListWithBios } from "../services/characters/static-character-list-with-bios";
import { BrowserVoiceInfo, getBrowserVoices } from "../services/speech/get-browser-voices";
import { logger } from "../utils/logger";
import { defaultMultiLingualId, useSettings } from "./use-settings";

export function useVoicePreference() {
  const { settings, settingsAreLoading } = useSettings();
  const [browserVoicesLoaded, setBrowserVoicesLoaded] = useState<boolean>(false);
  const [browserVoices, setBrowserVoices] = useState<BrowserVoiceInfo[]>();
  const [browserVoice, setBrowserVoice, browserVoiceRef] = useStateRef<BrowserVoiceInfo>();

  const [voicePreference, setVoicePreference] = useState<string>(defaultMultiLingualId);

  const shouldUseBrowserVoice = Boolean(browserVoice);

  useEffect(() => {
    getBrowserVoices()
      .then((browserVoices) => {
        setBrowserVoices(browserVoices);
      })
      .catch((err) => {
        logger.error("Failed to get browser voices");
      })
      .finally(() => {
        setBrowserVoicesLoaded(true);
      });
  }, []);

  useEffect(() => {
    if (settingsAreLoading || !settings.voicePreference || !browserVoicesLoaded) {
      return;
    }

    const character = staticCharacterListWithBios.find((c) => c.id === settings.voicePreference);

    const isBrowserVoice = Boolean(character?.browserVoice);

    if (!isBrowserVoice) {
      setVoicePreference(settings.voicePreference);
      return;
    }

    const browserVoice = browserVoices?.find(
      (voice) => voice.browserVoiceCharacter.id === settings.voicePreference,
    );

    if (
      !browserVoice?.browserVoiceCharacter.browserVoice ||
      !browserVoice.speechSynthesisVoice.voiceURI
    ) {
      if (!character?.browserVoice?.azureURI) {
        logger.error("Browser voice not found. This should not happen!");
        setVoicePreference(defaultMultiLingualId);
        return;
      }

      logger.error("Browser not supporting TTS. Using Azure voice.");
      setVoicePreference(character.browserVoice.azureURI);
      return;
    }

    logger.info("Using browser voice");
    setBrowserVoice(browserVoice);
    setVoicePreference(browserVoice.speechSynthesisVoice.voiceURI);
  }, [
    browserVoices,
    browserVoice,
    browserVoicesLoaded,
    settings.voicePreference,
    settingsAreLoading,
    setBrowserVoice,
  ]);

  return {
    shouldUseBrowserVoice,
    voicePreference,
    browserVoice,
    browserVoiceRef,
  };
}
