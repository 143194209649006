const l = {
  language: "English",

  "app.error": "Error",
  "app.close": "Close",
  "app.start": "Start",
  "app.noConversations": "No conversations yet",
  "app.conversation": "Conversation",
  "app.conversations": "Conversations",
  "app.loading": "Loading...",
  "app.tryAgain": "Please try again",
  "app.signOut": "Sign Out",
  "app.next": "Next",
  "app.previous": "Previous",
  "app.finish": "Finish",
  "app.cancel": "Cancel",
  "app.restart": "Restart",

  "layout.unableToConnect": "Unable to connect to server",
  "layout.home": "Home",
  "layout.chat": "Daily Session",
  "layout.wordbook": "Wordbook",
  "layout.pronunciation": "Pronunciation",
  "layout.progress": "Progress",
  "layout.programs": "Programs",
  "layout.settings": "Settings",
  "layout.account": "Account",
  "layout.manageAccount": "Manage Account",
  "layout.leaveReview": "Leave a Review",
  "layout.parentServiceLink": "Back to {name}",

  "dashboard.title": "Home",
  "dashboard.subtitle": "Select how you want to interact with your tutor.",
  "dashboard.learningStreaks.title": "Learning Streaks",
  "dashboard.learningStreaks.description":
    "Track your progress and maintain a consistent learning habit",
  "dashboard.learningStreaks.dayStreak": "Day streak",
  "dashboard.learningStreaks.longestStreak": "Longest streak",
  "dashboard.learningStreaks.conversations": "Conversations",

  "dashboard.learningStreaks.achievement1": "Starting the journey",
  "dashboard.learningStreaks.achievement3": "Three days of consistency",
  "dashboard.learningStreaks.achievement4": "Four days of consistency",
  "dashboard.learningStreaks.achievement5": "A solid five-day streak",
  "dashboard.learningStreaks.achievement6": "A solid six-day streak",
  "dashboard.learningStreaks.achievement7": "A week of learning",
  "dashboard.learningStreaks.achievement8": "More than a week of learning",
  "dashboard.learningStreaks.achievement10": "Double digits!",
  "dashboard.learningStreaks.achievement14": "Two weeks of knowledge",
  "dashboard.learningStreaks.achievement20": "Hitting the 20-day mark",
  "dashboard.learningStreaks.achievement25": "Quarter of a century!",
  "dashboard.learningStreaks.achievement30": "A month of dedication",
  "dashboard.learningStreaks.achievement40": "Forty days of wisdom",
  "dashboard.learningStreaks.achievement50": "Halfway to a hundred",
  "dashboard.learningStreaks.achievement60": "Two months of persistence",
  "dashboard.learningStreaks.achievement75": "Three-quarters of a century",
  "dashboard.learningStreaks.achievement90": "Three months of commitment",
  "dashboard.learningStreaks.achievement100": "Century of learning",
  "dashboard.learningStreaks.achievement120": "Four months, magic touch!",
  "dashboard.learningStreaks.achievement150": "150 days, a fortress of knowledge",
  "dashboard.learningStreaks.achievement180": "Half a year of enlightenment",
  "dashboard.learningStreaks.achievement200": "200 days, soaring high",
  "dashboard.learningStreaks.achievement250": "Quarter of a millennium!",
  "dashboard.learningStreaks.achievement300": "300 days, a shining example",

  "dashboard.learningStreaks.conversation1": "Getting started!",
  "dashboard.learningStreaks.conversation3": "Three chats already!",
  "dashboard.learningStreaks.conversation5": "Five conversations deep!",
  "dashboard.learningStreaks.conversation10": "Ten discussions, well done!",
  "dashboard.learningStreaks.conversation15": "15 conversations! Finding your rhythm!",
  "dashboard.learningStreaks.conversation16": "Finding your rhythm!",
  "dashboard.learningStreaks.conversation20": "20 deep dives into learning!",
  "dashboard.learningStreaks.conversation21": "Deep dives into learning!",
  "dashboard.learningStreaks.conversation30": "30 chats! The world is brighter!",
  "dashboard.learningStreaks.conversation31": "The world is brighter!",
  "dashboard.learningStreaks.conversation40": "40 conversations! Growing knowledge!",
  "dashboard.learningStreaks.conversation41": "Growing knowledge!",
  "dashboard.learningStreaks.conversation50": "Half a century of chats!",
  "dashboard.learningStreaks.conversation75": "75 enlightening talks!",
  "dashboard.learningStreaks.conversation100": "A hundred conversations! Truly committed!",
  "dashboard.learningStreaks.conversation150": "150 chats! A milestone of dedication!",
  "dashboard.learningStreaks.conversation200": "200 conversations! World-class communicator!",
  "dashboard.learningStreaks.conversation250": "250 chats! Master of dialogues!",
  "dashboard.learningStreaks.conversation300": "300 conversations! Future is bright!",
  "dashboard.learningStreaks.conversation400": "400 chats! Out of this world!",
  "dashboard.learningStreaks.conversation500": "500 conversations! Legendary!",
  "dashboard.learningStreaks.conversation750": "750 chats! Galaxy of knowledge!",
  "dashboard.learningStreaks.conversation1000": "1000 conversations! Kingdom of wisdom!",
  "dashboard.learningStreaks.conversation1500": "1500 discussions! Royalty of conversation!",
  "dashboard.learningStreaks.conversation2000": "2000 chats! Shooting star of dialogue!",

  "dashboard.aiTutors.title": "AI Tutors",
  "dashboard.aiTutors.description":
    " Your selected tutor will be waiting for you in the next chat session. Talk to you soon!",

  "dashboard.template.freeTalk.title": "New Session",
  "dashboard.template.freeTalk.description": "Talk about anything you want with your tutor",
  "dashboard.template.rolePlay.title": "Role play",
  "dashboard.template.rolePlay.description":
    "Put yourself in different roles and act out the scenarios",
  "dashboard.template.topics.title": "Topic",
  "dashboard.template.topics.description": "Let's talk about a topic of your choice",
  "dashboard.template.caseStudy.title": "Case Discussion",
  "dashboard.template.caseStudy.description": "Discuss a case and answer questions about it",
  "dashboard.template.groupDiscussion.title": "Group Discussion",
  "dashboard.template.groupDiscussion.description": "Practice leaderless group discussions",
  "dashboard.template.hkuProficiencyTest.title": "Proficiency Test",
  "dashboard.template.hkuProficiencyTest.description": "Prepare for the admission proficiency test",
  "dashboard.template.hkustAdmissionInterview.title": "Admission Interview",
  "dashboard.template.hkustAdmissionInterview.description": "Prepare for the admission interview",
  "dashboard.template.hku.title": "Master HKU's Interview Process",
  "dashboard.template.hku.description":
    "Tackle the University of Hong Kong's admissions confidently with focused preparation for the proficiency test, and develop your skills in the required group discussion discipline.",
  "dashboard.template.hkust.title": "Practice for HKUST Interview Success",
  "dashboard.template.hkust.description":
    "Practice with authentic interview questions from The Hong Kong University of Science and Technology. Develop your skills in self-introduction, motivation articulation, and handling the leaderless group discussions.",
  "dashboard.template.quiz.title": "Quiz",
  "dashboard.template.quiz.description": "Practice words and sentences with engaging quizzes ",

  "dashboard.template.game.title": "Games",
  "dashboard.template.game.description": "Practice vocabulary with fun word games",

  "dashboard.template.modal.curated": "Curated",
  "dashboard.template.modal.custom": "Custom",
  "dashboard.template.modal.inputContent": "Input your own content",

  "dashboard.template.modal.noContent": "No content available yet",

  "dashboard.conversations.description": "Your full list of conversations",

  "chat.index.failedConnection": "Unable to contact the server and send the message",
  "chat.index.audioFailedToSend": "Error sending audio message",
  "chat.index.usageCapMessageTrialTitle": "Wow, You're on a Roll!",
  "chat.index.usageCapMessageTrialText":
    "Looks like you've made the most out of your 7-day free trial - that's amazing! You've hit the character limit for this period. Eager to keep going right now? You can choose to upgrade your plan at any moment and pick up right where you left off.",
  "chat.index.usageCapMessageTrialFooterText":
    "We're here to support your journey, every step of the way.",
  "chat.index.usageCapMessagePlanTitle": "Heads Up!",
  "chat.index.usageCapMessagePlanText":
    "You have been enjoying all the features a lot this month, and we are thrilled! You have reached your usage limit for the current plan. To keep the experience seamless, consider taking a moment to review our plan options.",
  "chat.index.usageCapMessagePlanFooterText":
    "No rush though — your current plan will reset next month. Happy to have you with us!",
  "chat.index.visitAccount": "Visit Account",
  "chat.index.noMicTitle": "Microphone not allowed",
  "chat.index.noMicText": "Microphone access is blocked by your browser",

  "chat.asideMenu.voice": "Voice",
  "chat.asideMenu.text": "Text",
  "chat.asideMenu.newChat": "New Chat",
  "chat.asideMenu.pastConversations": "Past conversations",
  "chat.asideMenu.pastProgressConversations": "Conversation History & Progress", // eg. Progress Tracking and Conversation History
  "chat.asideMenu.noConversationsInLang": "No conversations in {language}",
  "chat.asideMenu.autoTranslate": "Auto Translate",
  "chat.asideMenu.showRomanization": "Show Romanization",
  "chat.asideMenu.multiLingualSpeech": "Multilingual Speech",

  "chat.suggestions.titleSm": "Suggestion on what to say",
  "chat.suggestions.titleXs": "Suggestion",
  "chat.suggestions.regenerate": "Regenerate",
  "chat.suggestions.generating": "Generating...",
  "chat.suggestions.failed": "Failed to get suggestion. Please try to regenerate.",

  "chat.conversation.tryAgain": "Go ahead and try again",
  "chat.conversation.cancel": "Cancel",

  "chat.speakInput.speak": "Speak",
  "chat.speakInput.recording": "Recording",

  "chat.textInput.sendMessage": "Send a message",

  "chat.autoSubmitSwitch.handsFree": "Hands-free",

  "chat.shortcuts.hold": "Hold",
  "chat.shortcuts.press": "Press",
  "chat.shortcuts.say": "Say",
  "chat.shortcuts.release": "Release",
  "chat.shortcuts.toRecord": "to record",
  "chat.shortcuts.toSend": "to send",
  "chat.shortcuts.toCancel": "to cancel",

  "chat.userMessageActions.speechPrecision": "Speech Precision",
  "chat.userMessageActions.speechPrecisionDescription":
    "Speech Precision indicates how confidently the tutor has interpreted your message. A higher percentage means greater accuracy in understanding what was said.",
  "chat.userMessageActions.speechPrecisionShowInfo": "Show Info",
  "chat.userMessageActions.speechPrecisionHideInfo": "Hide Info",
  "chat.userMessageActions.improveMyAnswer": "Improve My Answer",
  "chat.userMessageActions.retry": "Retry",

  "chat.feedbackMessageButton.improveMyAnswer": "Improve My Last Answer",
  "chat.feedbackMessageButton.feedbackOn": "Feedback on",
  "chat.feedbackMessageButton.speechPrecision": "Speech Precision",

  "chat.assessmentButton.title": "Conversation Assessment",
  "chat.assessmentButton.close": "Close",
  "chat.assessmentButton.analyzing": "Analyzing the entire conversation",
  "chat.assessmentButton.tooShort": "Conversation is too short to evaluate",

  "chat.languageMisMatchModal.title": "Language Mismatch",
  "chat.languageMisMatchModal.switchTo": "Yes, switch to {conversationPl}",
  "chat.languageMisMatchModal.keepCurrent": "No, keep my current setting",
  "chat.languageMisMatchModal.body":
    "Your practice language is set to {settingsPl}, but this conversation is in {conversationPl}. Would you like to switch your practice language to match the conversation?",
};

export default l;
