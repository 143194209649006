import { signOut } from "next-auth/react";
import { fetchApi } from "../services/fetch-api";

export async function logout(props: { callbackUrl?: string }) {
  try {
    const callbackUrl = props.callbackUrl || "/";

    await fetchApi("/auth/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    // After clearing cookies, call signOut to handle session and redirect
    await signOut({ callbackUrl });
  } catch (error) {
    console.error("Error logging out", error);
  }
}
