const l = {
  "guide.title": "Interactive Guides",
  "guide.subtitle": "Quiz-style guides to help you learn the language",
  "guide.disclaimerFirst":
    "The guide is partially generated with AI and we continuously quality check.",
  "guide.disclaimerLinkText": "Please let us know",
  "guide.disclaimerLast": "if you find any errors or omissions.",
  "guide.welcome.title": "Language Guide",
  "guide.welcome.errorMessage": "Hmm... looks like an error occurred",
  "guide.welcome.errorHelp": "Please refresh and try again in a few moments.",
  "guide.welcome.setupMessage": "Please check back a little later while we prepare the guide.",
  "guide.welcome.setupHelp":
    "Looks like we don't have this language combination yet. Come back a little later and we'll have it ready for you.",
  "guide.completion.title": "Well done!",
  "guide.completion.subtitle": "You completed the guide",
  "guide.completion.goToDashboard": "Go to Dashboard",
  "guide.completion.tryAgain": "Try again",
  "guide.step.examples": "Examples",
  "guide.retry": "Retry",

  "guide.beginnerIntro.title": "Beginner Introduction",
  "guide.beginnerIntro.shortText": "Begin your journey by mastering the basics of the language",
  "guide.beginnerIntro.longText":
    "Begin your journey by mastering the basics of the language. Our interactive quiz-style guide covers foundational elements to get you ready for your first conversation.",

  "guide.beginnerCounting.title": "Introduction to Numbers and Counting",
  "guide.beginnerCounting.shortText":
    "Dive into our three-step guide to mastering numbers in your new language",
  "guide.beginnerCounting.longText":
    "Dive into our three-step guide to mastering numbers in your new language. Begin with basic numbers 1-10, then practice counting objects, and finally, apply your skills in everyday scenarios like shopping and telling time. This interactive quiz reinforces your learning with practical exercises and multiple-choice questions, perfect for beginners aiming for fluency in everyday numerical conversations.",

  "guide.beginnerCommonVerbs.title": "Common Verbs",
  "guide.beginnerCommonVerbs.shortText": "A four-step quiz-style guide on common verbs",
  "guide.beginnerCommonVerbs.longText":
    "Start your journey to language mastery with our four-step beginner quiz on common verbs. This interactive guide begins by identifying and using essential verbs. Advance through forming simple sentences, applying verbs in daily actions, and crafting questions. Each step includes practical examples and exercises to solidify your understanding and usage of verbs in your new language.",

  "progress.title": "Progress",
  "progress.subtitle": "Track your learning journey for {languageName}",
  "progress.activityCalendar.feedbackNewUser":
    "Welcome! You've just started your language learning journey. Keep up the good work!",
  "progress.activityCalendar.feedbackGood":
    "Great job! You've completed {percentage}% of the days this month. Keep up the momentum!",
  "progress.activityCalendar.feedbackMediocre":
    "You're making progress! You've completed {percentage}% of the days this month. Keep going, every bit counts!",
  "progress.activityCalendar.feedbackBad":
    "Don't give up! You've completed {percentage}% of the days this month. It's never too late to turn things around and make the rest of the month count!",

  "progress.history.title": "History", // e.g. "Progress History"
  "progress.history.empty": "No activity",
  "progress.scores.timeSpent": "Time spent this month",
  "progress.scores.currentStreak": "Current streak",
  "progress.scores.longestStreak": "Longest streak",
  "progress.scores.wordsSaved": "Words saved",
  "progress.scores.pronunciationCount": "Pronunciations saved",

  "progress.weekly.title": "Weekly Assessment",
  "progress.weekly.welcomeTitle": "Results are processed weekly",
  "progress.weekly.welcomeDescription":
    "Start practicing this week! Each Monday, we’ll share a summary of your progress from the previous week with personalized feedback.",
  "progress.weekly.noPractice": "No activity for this period",
  "progress.weekly.error":
    "The tutor is currently unable to provide feedback. Please try again later.",
  "progress.weekly.noFeedback": "No feedback available",
  "progress.weekly.pronunciationLabel": "Pronunciation",
  "progress.weekly.conclusionLabel": "Conclusion",
  "progress.weekly.currentProficiency": "Current proficiency",
  "progress.weekly.suggestedProficiency": "Suggested proficiency",
  "progress.weekly.changeProficiency": "Change Proficiency",

  "chat.userMessage.perfectSentence1": "Great sentence! Keep it up!",
  "chat.userMessage.perfectSentence2": "Great job!",
  "chat.userMessage.perfectSentence3": "Well done!",

  "settings.liveFeedback": "Instant Feedback",
  "settings.liveFeedbackDescription":
    "Displays feedback on how to improve your answer after each message.",

  "dashboard.template.dailySession.title": "Daily Session",
  "dashboard.template.dailySession.with": "with", // e.g. "Daily Session with John" or "Daily Session with Jane"
  "dashboard.template.dailySession.description":
    "Join today's conversation to practice and enhance your language skills",
  "dashboard.template.dailySession.button": "Begin Daily Session",

  "feedbackCard.loader.analyzeGrammar": "Analyzing grammar...",
  "feedbackCard.loader.analyzeVocabulary": "Analyzing vocabulary...",
  "feedbackCard.loader.analyzeStructure": "Analyzing sentence structure...",
  "feedbackCard.error.message":
    "We are sorry, but we encountered an issue while processing your feedback. Please refresh to try again.",
  "feedbackCard.improvedAnswerLabel": "Improved Answer",
  "feedbackCard.grammarLabel": "Grammar",
  "feedbackCard.vocabularyLabel": "Vocabulary",
  "feedbackCard.structureLabel": "Structure", // e.g. sentence structure

  "chat.completionMessage.title": " Great job! You have successfully completed the scenario!",
  "chat.completionMessage.description":
    "Feel free to continue the conversation or explore another scenario.",
};

export default l;
