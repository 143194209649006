/**
 * Use like...
 *
 * const userPlan = {
 *  limit: 10000,
 *  estHours: 10
 * };
 * const translation = '{limit} characters are roughly equivalent to {estHours} hours of conversation.';
 * interpolateString(translation, userPlan);
 */
export function interpolateString(template: string, values: { [key: string]: any }): string {
  return template?.replace(/\{(\w+)\}/g, (_, key) => values[key] || "");
}
