import { Button, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

interface SidebarButtonProps {
  icon: ReactNode;
  href: string;
  label: string;
}

export default function SidebarButton({ icon, href, label }: SidebarButtonProps) {
  return (
    <Button
      leftIcon={icon as any}
      color="special.desktopSidebarText"
      _hover={{
        backgroundColor: "special.desktopSidebarTextHover",
      }}
      variant="ghost"
      size="sm"
      mb={"10px"}
      as="a"
      href={href}
    >
      <Text w={"120px"} textAlign="left" style={{ textWrap: "balance" }}>
        {label}
      </Text>
    </Button>
  );
}
