import { useRef, useState, useEffect, useCallback } from "react";
import useStateRef from "react-usestateref";
import { logger } from "../../../utils/logger";

export function useAudioMonitor(
  audioSource: HTMLAudioElement | MediaStream | undefined,
  isRecording: boolean
) {
  const interval = useRef<NodeJS.Timeout>();
  const audioCtxRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const sourceNodeRef = useRef<MediaElementAudioSourceNode | MediaStreamAudioSourceNode | null>(
    null
  );
  const [audioPulse, setAudioPulse] = useState<number>(0);
  const [averageVolume, setAverageVolume] = useState<number>(0);
  const [hasPulse, setHasPulse, hasPulseRef] = useStateRef(false);

  const monitorLevels = useCallback(() => {
    if (!analyserRef.current) return;

    const analyser = analyserRef.current;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    let totalVolume = 0;
    let count = 0;
    const volumeThreshold = 1;
    let monitorActive = true;

    const monitor = () => {
      if (!monitorActive) return;

      analyser.getByteFrequencyData(dataArray);
      const avgVolume = dataArray.reduce((acc, val) => acc + val, 0) / bufferLength;

      if (avgVolume > volumeThreshold) {
        totalVolume += avgVolume;
        count += 1;

        if (count % 10 === 0) {
          setAverageVolume(totalVolume / count);
        }
      }

      requestAnimationFrame(monitor);
    };

    monitor();

    return () => {
      monitorActive = false;
    };
  }, [setAverageVolume]);

  useEffect(() => {
    const threshold = averageVolume > 1 ? averageVolume / 2 : 15;

    if (isRecording && audioPulse > threshold) {
      setHasPulse(true);
    }

    if (!isRecording) {
      setHasPulse(false);
    }
  }, [isRecording, audioPulse, averageVolume, setHasPulse]);

  useEffect(() => {
    if (!audioSource) {
      return;
    }

    if (!audioCtxRef.current) {
      audioCtxRef.current = new AudioContext();
    }

    const audioCtx = audioCtxRef.current;

    if (!analyserRef.current) {
      analyserRef.current = audioCtx.createAnalyser();
      analyserRef.current.fftSize = 2048;
    }

    const analyser = analyserRef.current;

    if (!sourceNodeRef.current) {
      if (audioSource instanceof HTMLAudioElement) {
        sourceNodeRef.current = audioCtx.createMediaElementSource(audioSource);
      } else if (audioSource instanceof MediaStream) {
        sourceNodeRef.current = audioCtx.createMediaStreamSource(audioSource);
      } else {
        logger.error("Invalid audio source type");
        return;
      }

      sourceNodeRef.current.connect(analyser);
    }

    monitorLevels();

    interval.current = setInterval(() => {
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      analyser.getByteTimeDomainData(dataArray);

      let minVal = 255;
      let maxVal = 0;
      for (let i = 0; i < bufferLength; i++) {
        minVal = Math.min(minVal, dataArray[i]);
        maxVal = Math.max(maxVal, dataArray[i]);
      }

      const percentage = ((maxVal - minVal) / 128) * 100;
      setAudioPulse(percentage);
    }, 100);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }

      if (sourceNodeRef.current) {
        sourceNodeRef.current.disconnect(analyser);
      }

      if (audioCtxRef.current) {
        audioCtxRef.current.close();
        audioCtxRef.current = null;
      }

      analyserRef.current = null;
      sourceNodeRef.current = null;
    };
  }, [audioSource, monitorLevels]);

  return { audioPulse, averageVolume, hasPulse, hasPulseRef };
}
