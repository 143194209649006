import { Box } from "@chakra-ui/react";
import { curatedLangList } from "../services/speech/static-lang-list";
import { useEffect, useMemo, useState } from "react";
import ReactFlagsSelect from "../vendors/react-flag-select";
import {
  countryKeyToLocale,
  langCodeToLocale,
  localeToCountryKey,
  localeToBaseLangCode,
  localeToLangCode,
} from "../utils/lang-code-converters";
import { localizedPagePaths } from "../localization/landing";
import Cookies from "js-cookie";
import { useRouter } from "next/router";

interface Props {
  defaultBaseLangCode: string;
}

export default function LanguageSelectorLandingDynamic(props: Props) {
  const router = useRouter();
  const defaultLocale = useMemo(() => {
    const fallbackLocale = localizedPagePaths[0][1];
    const foundLocale = localizedPagePaths.find((p) => props.defaultBaseLangCode === p[1]);

    return foundLocale?.[1] ?? fallbackLocale;
  }, [props.defaultBaseLangCode]);

  const [selectedLocale, setSelectedLocale] = useState(defaultLocale);

  const langList = curatedLangList.filter(([_, langCode]) =>
    localizedPagePaths.some((p) => localeToLangCode(p[1]) === langCode),
  );

  const simpleCountries = useMemo(() => langList.map(([code]) => code), [langList]);
  const simpleLabels = useMemo(() => {
    const result: Record<string, { primary: string; secondary: string }> = {};

    langList.forEach((c) => {
      result[c[0]] = { primary: c[2], secondary: c[3] };
    });

    return result;
  }, [langList]);

  const saveNewLocaleAsBaseLangCode = (newLocale: string) => {
    const baseLangCode = localeToBaseLangCode(newLocale);

    router.push({
      pathname: router.pathname,
      query: { ...router.query, lang: baseLangCode },
    });

    // Save the preference in a cookie (valid for 1 year)
    Cookies.set("preferred-language", baseLangCode, { expires: 365 });
  };

  const onSelect = (countryKey: string, prevLocale: string) => {
    const newLocale = countryKeyToLocale(countryKey);

    if (newLocale === prevLocale) {
      return;
    }

    setSelectedLocale(newLocale);
    saveNewLocaleAsBaseLangCode(newLocale);
  };

  const countryKey = localeToCountryKey(selectedLocale);

  return (
    <Box my={6}>
      <ReactFlagsSelect
        selected={countryKey}
        onSelect={(c) => onSelect(c, selectedLocale)}
        placeholder={"All languages"}
        customLabels={simpleLabels}
        countries={simpleCountries}
        showSecondaryOptionLabel={false}
        showSecondarySelectedLabel={false}
        searchable
        selectButtonClassName="landing-flags"
        fullWidth={false}
        selectedSize={10}
        optionsSize={12}
      />
    </Box>
  );
}
