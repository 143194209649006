import { SiteConfig } from "../../types";

export const _config: SiteConfig = {
  isDefault: false,
  showTidio: false,
  serviceName: "Speak Up",
  pwaServiceName: "Speak Up",
  appLogoPath: "/partner/ili-edusky/logo.png",
  appLogoHeight: "60px",
  landingLogoPath: "/partner/ili-edusky/logo.png",
  landingLogoHeight: "60px",
  hubLogoPath: "/partner/ili-edusky/logo.png",
  mobileLogoPath: "/partner/ili-edusky/logo.png",
  favicon: "/partner/ili-edusky/logo.png",
  pwaIcons: [
    {
      src: "/partner/ili-edusky/logo.png",
      type: "image/png",
      sizes: "192x192",
    },
    {
      src: "/partner/ili-edusky/logo.png",
      type: "image/png",
      sizes: "256x256",
    },
    {
      src: "/partner/ili-edusky/logo.png",
      type: "image/png",
      sizes: "512x512",
    },
  ],
  overrideLanding: true,
  emailsSender: "info@lang-services.com",
  // adminEmail: "info@ili-edusky.pl",
  // userLanguageLock: "pl-PL",
  uiLanguage: "pl-PL",
  reviewLink: null,
  emailInvitationSubject: "You have been invited to Speak Up",
  emailInvitationText: `
  You have been invited to Speak Up 🎉
    
  We have created a user for you with the following details:
  Email: {email}
  Password: {password}

  Follow this link to accept the invitation:
  {link}
  `,
  copyright: "Speak Up",
  showLanguageGuides: false,
  bioPrompt: `
    You are a tutor at Speak Up, a language school in Poland.
    `,
  siteColors: {
    special: {
      desktopSidebar: "#111",
      desktopSidebarBorderColor: "#e2e8f0",
      desktopSidebarText: "#fff",
      desktopSidebarTextHover: "#555",
      chatAsideMenu: "#333",
      mobileNavBackgroundColor: "#111",
      mobileNavBackgroundContrastColor: "#ffffff",
      mobileNavBorderColor: "#111",
      speakInputIdle: "#333",
      speakInputRecording: "var(--chakra-colors-brand-primary-900)",
      //chatIconColor: "var(--chakra-colors-brand-primary-main)",
    },
  },
  colors: {
    primary: {
      main: "#d81c1c",
      mainContrast: "#fff",
      "50": "#fdf5f5",
      "100": "#f8d9d9",
      "200": "#f3b7b7",
      "300": "#eb8b8b",
      "400": "#e67070",
      "500": "#df4545",
      "600": "#d01b1b",
      "700": "#a81616",
      "800": "#8f1313",
      "900": "#690e0e",
    },
    secondary: {
      main: "#d81c1c",
      mainContrast: "#fff",
      "50": "#fdf5f5",
      "100": "#f8d9d9",
      "200": "#f3b7b7",
      "300": "#eb8b8b",
      "400": "#e67070",
      "500": "#df4545",
      "600": "#d01b1b",
      "700": "#a81616",
      "800": "#8f1313",
      "900": "#690e0e",
    },
    tertiary: {
      main: "#EB8113",
      mainContrast: "#fff",
      "50": "#fef6ee",
      "100": "#f9dbbc",
      "200": "#f4bb7f",
      "300": "#ed8f2e",
      "400": "#df7a12",
      "500": "#bc670f",
      "600": "#9f570d",
      "700": "#7f460a",
      "800": "#6c3b09",
      "900": "#4e2b06",
    },
    light: {
      main: "#f2f2f2",
      alt: "#f2f2f2",
    },
    // https://palette.saas-ui.dev/
    gray: {
      "50": "#fafafa",
      "100": "#f1f1f1",
      "200": "#e7e7e7",
      "300": "#d4d4d4",
      "400": "#adadad",
      "500": "#7f7f7f",
      "600": "#545454",
      "700": "#373737",
      "800": "#202020",
      "900": "#191919",
    },
    red: {
      "50": "#fdf5f5",
      "100": "#f8d9d9",
      "200": "#f3b7b7",
      "300": "#eb8b8b",
      "400": "#e67070",
      "500": "#df4545",
      "600": "#d01b1b",
      "700": "#a81616",
      "800": "#8f1313",
      "900": "#690e0e",
    },
    orange: {
      "50": "#fdfaf6",
      "100": "#f9ebdd",
      "200": "#f2d3b5",
      "300": "#e8b17a",
      "400": "#df9143",
      "500": "#cf751b",
      "600": "#af6317",
      "700": "#8b4f12",
      "800": "#6e3e0e",
      "900": "#5a330c",
    },
    yellow: {
      "50": "#fefefc",
      "100": "#fcf9ec",
      "200": "#f5eec6",
      "300": "#eee09a",
      "400": "#e3cc59",
      "500": "#c2a619",
      "600": "#9b8514",
      "700": "#796810",
      "800": "#5b4e0c",
      "900": "#4b400a",
    },
    green: {
      "50": "#f5fdf9",
      "100": "#c6f5de",
      "200": "#82eab6",
      "300": "#23d97e",
      "400": "#19be6c",
      "500": "#15a45c",
      "600": "#12874d",
      "700": "#0e693c",
      "800": "#0b5631",
      "900": "#094728",
    },
    teal: {
      "50": "#effcfc",
      "100": "#b8f3f3",
      "200": "#71e7e7",
      "300": "#1bd3d3",
      "400": "#17b3b3",
      "500": "#149898",
      "600": "#107c7c",
      "700": "#0c6060",
      "800": "#0a5050",
      "900": "#094242",
    },
    cyan: {
      "50": "#f3fbfd",
      "100": "#cceff6",
      "200": "#b5e8f2",
      "300": "#99e0ee",
      "400": "#40c4de",
      "500": "#1bb5d3",
      "600": "#19a3be",
      "700": "#14879e",
      "800": "#116f81",
      "900": "#0d5664",
    },
    blue: {
      "50": "#f1f7fd",
      "100": "#cae0f6",
      "200": "#a4caef",
      "300": "#79b0e8",
      "400": "#4e98e1",
      "500": "#2781da",
      "600": "#196bbd",
      "700": "#135291",
      "800": "#0f4377",
      "900": "#0d3761",
    },
    purple: {
      "50": "#f8f6fd",
      "100": "#e4daf9",
      "200": "#d1bff4",
      "300": "#b497ed",
      "400": "#9f7ae8",
      "500": "#8454e2",
      "600": "#6f37dd",
      "700": "#581bd1",
      "800": "#4916ad",
      "900": "#361181",
    },
    pink: {
      "50": "#fdf5f9",
      "100": "#f8d8e8",
      "200": "#f3b9d6",
      "300": "#eb8cbc",
      "400": "#e56aa8",
      "500": "#dc3589",
      "600": "#c71a70",
      "700": "#a3155c",
      "800": "#801148",
      "900": "#5f0c36",
    },
  },
  templates: [
    {
      type: "free-talk",
      titleKey: "dashboard.template.freeTalk.title",
      descriptionKey: "dashboard.template.freeTalk.description",
      backgroundColor: "brand.primary.main",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "role-play",
      titleKey: "dashboard.template.rolePlay.title",
      descriptionKey: "dashboard.template.rolePlay.description",
      backgroundColor: "brand.yellow.100",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "topic",
      titleKey: "dashboard.template.topics.title",
      descriptionKey: "dashboard.template.topics.description",
      backgroundColor: "brand.red.400",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "quiz",
      titleKey: "dashboard.template.quiz.title",
      descriptionKey: "dashboard.template.quiz.description",
      backgroundColor: "brand.green.300",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "case",
      titleKey: "dashboard.template.caseStudy.title",
      descriptionKey: "dashboard.template.caseStudy.description",
      backgroundColor: "brand.blue.400",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
  ],
};
