import { SpeechConfig } from "microsoft-cognitiveservices-speech-sdk";
import { logger } from "../../utils/logger";

interface Token {
  token: string;
  region: string;
}

export let savedSpeechConfig: SpeechConfig | undefined;
let timer: NodeJS.Timeout | undefined;
let useAltSpeech = false;

async function refreshSpeechConfig() {
  try {
    let altSpeechParams = "";

    if (useAltSpeech) {
      logger.info("Using alt speech key and region");
      altSpeechParams = "?use-alt-speech=true";
    }

    const result = await fetch("/api/speech-token" + altSpeechParams);
    const { token, region } = (await result.json()) as Token;
    logger.info("refresh speech config");
    savedSpeechConfig = SpeechConfig.fromAuthorizationToken(token, region);
    return savedSpeechConfig;
  } catch (e) {
    logger.error("error fetching speech token", e);

    setTimeout(() => {
      logger.warn("retry fetching speech token");
      refreshSpeechConfig();
    }, 3000);
  }
}

// This is used when the primary speech key is overloaded
export function fallbackToAltSpeech() {
  useAltSpeech = true;
  refreshSpeechConfig();
}

export function initTokenRefresher() {
  clearTimeout(timer);
  refreshSpeechConfig();

  timer = setTimeout(() => {
    refreshSpeechConfig();
  }, 2000 * 60); // refresh every 2 minutes, cap should be 10 minutes

  window.removeEventListener("focus", refreshSpeechConfig);
  window.addEventListener("focus", refreshSpeechConfig);
}

export async function getSpeechConfig() {
  if (savedSpeechConfig) {
    return savedSpeechConfig;
  }

  logger.info("Trying to fetch speech config before it's ready");
  return await refreshSpeechConfig();
}
