import en_US from "./master-landing";

// Generated locales
import da_DK from "./generated/da-DK-landing";
import pt_BR from "./generated/pt-BR-landing";
import ko_KR from "./generated/ko-KR-landing";
import pl_PL from "./generated/pl-PL-landing";
import zh_CN from "./generated/zh-CN-landing";

// Manual overrides
import o_da_DK from "./overrides/da-DK-landing";
import o_pt_BR from "./overrides/pt-BR-landing";
import o_pl_PL from "./overrides/pl-PL-landing";
import o_zh_CN from "./overrides/zh-CN-landing";

export type LandingLocale = typeof en_US;

export const landingLocales: Record<string, LandingLocale> = {
  en_US,
  pt_BR,
  ko_KR,
  // zh_CN,
  // pl_PL,
  da_DK,
};

export const landingOverrides: Record<string, Partial<LandingLocale>> = {
  pt_BR: o_pt_BR,
  da_DK: o_da_DK,
  // pl_PL: o_pl_PL,
  // zh_CN: o_zh_CN,
};

const isDevelopment = process.env.NODE_ENV === "development";

export const localizedPagePaths = [
  ["en", "en_US"],
  ["da", "da_DK"],
  ...(isDevelopment ? [["ko", "ko_KR"]] : []),
  // ["pt", "pt_BR"],
];
