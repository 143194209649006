// https://github.com/Azure-Samples/cognitive-services-speech-sdk/blob/master/quickstart/javascript/browser/text-to-speech/index.html
import {
  AudioConfig,
  AutoDetectSourceLanguageConfig,
  OutputFormat,
  ServicePropertyChannel,
  SpeechRecognizer,
} from "microsoft-cognitiveservices-speech-sdk";
import { Settings } from "../../types/settings";
import { logger } from "../../utils/logger";
import { getSpeechConfig } from "./speech-config";

export const monolingualSpeechRecognition = [
  "bs-BA",
  "sr-RS",
  "sq-AL",
  "my-MM",
  "az-AZ",
  "ka-GE",
  "kk-KZ",
  "mk-MK",
];

//Diagnostics.SetLoggingLevel(LogLevel.Debug);

// https://github.com/Azure-Samples/AzureSpeechReactSample
// https://learn.microsoft.com/en-us/azure/cognitive-services/speech-service/how-to-recognize-speech?pivots=programming-language-javascript
export async function azureSpeechToText(settings: Settings): Promise<SpeechRecognizer> {
  return new Promise(async (resolve, reject) => {
    const speechConfig = await getSpeechConfig();

    if (!speechConfig) {
      reject("Unable to contact speech recognition server");
      return;
    }

    // set fallback language
    const practiceLanguage = settings.practiceLanguage;
    speechConfig.speechRecognitionLanguage = practiceLanguage;

    speechConfig.outputFormat = OutputFormat.Detailed;

    if (!settings.autoPunctuation) {
      speechConfig.setServiceProperty(
        "punctuation",
        "explicit",
        ServicePropertyChannel.UriQueryParameter,
      );
    }

    const recognitionLanguages = settings.multiLingualSpeechRecognition
      ? [practiceLanguage, settings.userLanguage]
      : [practiceLanguage];

    const autoDetectSourceLanguageConfig =
      AutoDetectSourceLanguageConfig.fromLanguages(recognitionLanguages);

    const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
    let speechRecognizer: SpeechRecognizer;

    if (monolingualSpeechRecognition.includes(practiceLanguage)) {
      logger.warn(`Using monolingual speech recognition: ${practiceLanguage}`);
      speechRecognizer = new SpeechRecognizer(speechConfig, audioConfig);
    } else {
      logger.info(`Auto-detect speech recognition: ${recognitionLanguages.toString()}`);
      speechRecognizer = SpeechRecognizer.FromConfig(
        speechConfig,
        autoDetectSourceLanguageConfig,
        audioConfig,
      );
    }

    speechRecognizer.startContinuousRecognitionAsync(
      () => {
        resolve(speechRecognizer);
      },
      (err) => {
        reject(err);
      },
    );
  });
}
