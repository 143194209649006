import { SiteConfig } from "../../types";

export const _config: SiteConfig = {
  isDefault: false,
  showTidio: false,
  serviceName: "Sprache&Wissen",
  appLogoPath: "/partner/spracheundwissen/logo.svg",
  appLogoHeight: "40px",
  landingLogoPath: "/partner/spracheundwissen/logo.svg",
  landingLogoHeight: "40px",
  overrideLanding: true,
  useTokenAuth: false,
  uiLanguage: "pt-BR",
  reviewLink: null,
  showLanguageGuides: false,
  adminEmail: "contato@clubedealemao.com",
  emailsSender: "no-reply@talkio.ai",
  emailInvitationText: `
    Você foi convidado para fazer parte da Sprache&Wissen AI! 🎉 
    Siga este link para aceitar o convite: 

    {link}
  `,
  emailInvitationSubject: "Você foi convidado para fazer parte da Sprache&Wissen AI!",
  copyright: "Sprache&Wissen",
  practiceLanguageLock: "de-DE",
  subscriptionDefaults: {
    currency: "usd",
    price: 300,
    characterLimit: 1_050_000,
    productId: "spracheundwissen-1",
  },
  bioPrompt: `
    About Sprache&Wissen:
    Sprache&Wissen is an EdTech that began its activities in the German teaching segment .
    Since 2021, it has been the fastest growing German school in the Brazilian market, providing quality education to more than 6 thousand students .
    In 2023, it began operating in the English teaching segment , with a specialized course in accelerated learning .
    It also develops exclusive materials such as German books and English books in physical and digital versions.
  `,
  colors: {
    primary: {
      main: "#ebbd17",
      "50": "#fdf7e4",
      "100": "#f5e090",
      "200": "#ecc125",
      "300": "#c9a114",
      "400": "#b39012",
      "500": "#ebbd17",
      "600": "#80670c",
      "700": "#66520a",
      "800": "#564608",
      "900": "#3e3206",
    },
    secondary: {
      main: "#cd5658",
      "50": "#fcf6f6",
      "100": "#f4dadb",
      "200": "#ebbabb",
      "300": "#df9192",
      "400": "#d8797b",
      "500": "#cd5658",
      "600": "#c33335",
      "700": "#ab0d10",
      "800": "#920b0d",
      "900": "#6b080a",
    },
    tertiary: {
      main: "#ff5659",
      "50": "#fff5f5",
      "100": "#ffd7d7",
      "200": "#ffb1b3",
      "300": "#ff7f81",
      "400": "#ff5c5e",
      "500": "#db4a4c",
      "600": "#b93e41",
      "700": "#953234",
      "800": "#7e2b2c",
      "900": "#5c1f20",
    },
    light: {
      main: "#f8dfaa",
      alt: "#ececec",
    },
    // https://palette.saas-ui.dev/
    gray: {
      "50": "#fafafa",
      "100": "#f1f1f1",
      "200": "#e7e7e7",
      "300": "#d4d4d4",
      "400": "#adadad",
      "500": "#7f7f7f",
      "600": "#545454",
      "700": "#373737",
      "800": "#202020",
      "900": "#191919",
    },
    yellow: {
      "50": "#fffefa",
      "100": "#fcfade",
      "200": "#f6f09a",
      "300": "#efe341",
      "400": "#dccf16",
      "500": "#b6ab12",
      "600": "#91890e",
      "700": "#716a0b",
      "800": "#555008",
      "900": "#464207",
    },
    green: {
      "50": "#f2fef9",
      "100": "#b6f9de",
      "200": "#4df0ae",
      "300": "#15d98a",
      "400": "#13be79",
      "500": "#10a368",
      "600": "#0d8756",
      "700": "#0a6943",
      "800": "#085637",
      "900": "#07472d",
    },
    teal: {
      "50": "#effcfe",
      "100": "#b8f2f9",
      "200": "#70e6f3",
      "300": "#16d1e5",
      "400": "#13b1c3",
      "500": "#1097a6",
      "600": "#0d7b87",
      "700": "#0a5f69",
      "800": "#095057",
      "900": "#074148",
    },
    cyan: {
      "50": "#f3fbfe",
      "100": "#ceeffb",
      "200": "#b7e7f9",
      "300": "#9cdef6",
      "400": "#46c2ef",
      "500": "#17b2eb",
      "600": "#15a0d4",
      "700": "#1185af",
      "800": "#0e6d90",
      "900": "#0b5570",
    },
    blue: {
      "50": "#f1f6fe",
      "100": "#cddffb",
      "200": "#a8c8f7",
      "300": "#7eadf4",
      "400": "#5694f0",
      "500": "#307ced",
      "600": "#1564da",
      "700": "#104da7",
      "800": "#0d3f89",
      "900": "#0b3370",
    },
    purple: {
      "50": "#f9f5fe",
      "100": "#e8d9fc",
      "200": "#d7bcf9",
      "300": "#bd91f6",
      "400": "#aa73f3",
      "500": "#9047ef",
      "600": "#7a22ec",
      "700": "#6314cb",
      "800": "#5210a8",
      "900": "#3e0c7e",
    },
    pink: {
      "50": "#fef5f9",
      "100": "#fcd7e6",
      "200": "#f9b7d1",
      "300": "#f588b3",
      "400": "#f1629b",
      "500": "#eb1a6e",
      "600": "#cb145d",
      "700": "#a7104d",
      "800": "#830d3c",
      "900": "#610a2d",
    },
    red: {
      "50": "#fef5f4",
      "100": "#fbd8d4",
      "200": "#f8b5ae",
      "300": "#f4877b",
      "400": "#f16a5b",
      "500": "#ec3521",
      "600": "#cb2614",
      "700": "#a41f10",
      "800": "#8c1a0e",
      "900": "#66130a",
    },
    orange: {
      "50": "#fefaf4",
      "100": "#fbebd3",
      "200": "#f7d39f",
      "300": "#f0b050",
      "400": "#e29016",
      "500": "#c27c13",
      "600": "#a46910",
      "700": "#83530d",
      "800": "#67420a",
      "900": "#553608",
    },
  },
};
