import { useState, useEffect } from "react";

export function useLocalStorage<T>(storageKey: string, initialValue?: T) {
  const [storageData, setStorageData] = useState<T>();
  const [isLoadingStorage, setIsLoadingStorage] = useState(true);

  useEffect(() => {
    if (initialValue !== undefined) {
      setStorageData(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    // Retrieve data from localStorage during the client-side hydration process
    const storedData = localStorage.getItem(storageKey);
    if (storedData) {
      setStorageData(JSON.parse(storedData));
    }

    setIsLoadingStorage(false);
  }, [storageKey]);

  useEffect(() => {
    if (storageData !== undefined) {
      // Store data to localStorage whenever 'data' changes
      localStorage.setItem(storageKey, JSON.stringify(storageData));
    }
  }, [storageData, storageKey]);

  const clearStorage = () => {
    localStorage.removeItem(storageKey);
    setStorageData(undefined);
  };

  return {
    storageData,
    setStorageData,
    isLoadingStorage,
    clearStorage,
  };
}
