import { baseTheme, extendTheme } from "@chakra-ui/react";
import { theme as proTheme } from "@chakra-ui/pro-theme";
import { siteConfig } from "../configuration/config";

const partnerFonts = { fonts: siteConfig?.fonts } || {};
const primaryColors = siteConfig.colors?.primary || {
  main: "#f6d78b",
  mainContrast: "#000",
  "50": "#fdf7e7",
  "100": "#f8de9f",
  "200": "#dfc37e",
  "300": "#bba46a",
  "400": "#a7925e",
  "500": "#f6d78b",
  "600": "#c5a351",
  "700": "#5f5336",
  "800": "#50462d",
  "900": "#3a3321",
};

export const theme = extendTheme(proTheme, {
  space: {
    ...baseTheme.space,
    desktopSidebarLogoTopOffset: "30px",
    ...siteConfig.siteSpacing,
  },
  colors: {
    ...baseTheme.colors,
    special: {
      desktopSidebar: "#202020",
      desktopSidebarBorderColor: "transparent",
      desktopSidebarText: "#e2e8f0",
      desktopSidebarTextHover: "#545454",
      mobileNavBackgroundColor: "#202020",
      mobileNavBackgroundContrastColor: "white",
      mobileNavBorderColor: "transparent",
      chatAsideMenu: "#202020",
      speakInputIdle: "var(--chakra-colors-brand-secondary-700)",
      speakInputRecording: "var(--chakra-colors-brand-secondary-900)",
      chatIconColor: "#000",
      ...siteConfig.siteColors?.special,
    },
    brand: {
      ...primaryColors,
      primary: primaryColors,
      secondary: {
        main: "#506c8d",
        mainContrast: "#fff",
        "50": "#f0f7fe",
        "100": "#c9e0fb",
        "200": "#a0c9f8",
        "300": "#81afe4",
        "400": "#6f97c4",
        "500": "#5f81a8",
        "600": "#506c8d",
        "700": "#3d536c",
        "800": "#324458",
        "900": "#283748",
        ...siteConfig.colors?.secondary,
      },
      tertiary: {
        main: "#a07ddd",
        mainContrast: "#000",
        "50": "#f9f5fe",
        "100": "#e6d9fc",
        "200": "#d3bdfa",
        "300": "#b893f7",
        "400": "#a07ddd",
        "500": "#7f64b0",
        "600": "#6a5393",
        "700": "#574478",
        "800": "#473762",
        "900": "#342948",
        ...siteConfig.colors?.tertiary,
      },
      light: {
        main: "#f8f7f1",
        alt: "#f8f7f1",
        ...siteConfig.colors?.light,
      },
      // https://palette.saas-ui.dev/
      gray: {
        "50": "#fafafa",
        "100": "#f1f1f1",
        "200": "#e7e7e7",
        "300": "#d4d4d4",
        "400": "#adadad",
        "500": "#7f7f7f",
        "600": "#545454",
        "700": "#373737",
        "800": "#202020",
        "900": "#191919",
        ...siteConfig.colors?.gray,
      },
      yellow: {
        "50": "#fdf7e7",
        "100": "#f8de9f",
        "200": "#dfc37e",
        "300": "#bba46a",
        "400": "#f6d78b",
        "500": "#a7925e",
        "600": "#c5a351",
        "700": "#5f5336",
        "800": "#50462d",
        "900": "#3a3321",
        ...siteConfig.colors?.yellow,
      },
      green: {
        "50": "#f2fef8",
        "100": "#b5f9d9",
        "200": "#84e9ba",
        "300": "#75d0a6",
        "400": "#67b691",
        "500": "#589c7c",
        "600": "#498167",
        "700": "#396450",
        "800": "#2e5241",
        "900": "#264336",
        ...siteConfig.colors?.green,
      },
      teal: {
        "50": "#ecfdfe",
        "100": "#a9f5f8",
        "200": "#83e4e8",
        "300": "#75cccf",
        "400": "#63adb0",
        "500": "#549395",
        "600": "#447779",
        "700": "#355d5e",
        "800": "#2c4d4e",
        "900": "#243f40",
        ...siteConfig.colors?.teal,
      },
      cyan: {
        "50": "#f2fcfe",
        "100": "#c6f0fb",
        "200": "#abe9f9",
        "300": "#8be1f6",
        "400": "#76bfd1",
        "500": "#6db0c0",
        "600": "#629ead",
        "700": "#51838f",
        "800": "#426b75",
        "900": "#33535b",
        ...siteConfig.colors?.cyan,
      },
      blue: {
        "50": "#f0f7fe",
        "100": "#c9e0fb",
        "200": "#a0c9f8",
        "300": "#81afe4",
        "400": "#6f97c4",
        "500": "#5f81a8",
        "600": "#506c8d",
        "700": "#3d536c",
        "800": "#324458",
        "900": "#283748",
        ...siteConfig.colors?.blue,
      },
      purple: {
        "50": "#f9f5fe",
        "100": "#e6d9fc",
        "200": "#d3bdfa",
        "300": "#b893f7",
        "400": "#a07ddd",
        "500": "#7f64b0",
        "600": "#6a5393",
        "700": "#574478",
        "800": "#473762",
        "900": "#342948",
        ...siteConfig.colors?.purple,
      },
      pink: {
        "50": "#fef5f9",
        "100": "#fcd7e8",
        "200": "#f9b6d5",
        "300": "#f289ba",
        "400": "#d478a3",
        "500": "#ae6386",
        "600": "#955473",
        "700": "#79455d",
        "800": "#5e3548",
        "900": "#452735",
        ...siteConfig.colors?.pink,
      },
      red: {
        "50": "#fef5f5",
        "100": "#fcd8d6",
        "200": "#f9b4b2",
        "300": "#ed8a86",
        "400": "#d47b78",
        "500": "#b36865",
        "600": "#975855",
        "700": "#794645",
        "800": "#663c3a",
        "900": "#4a2b2a",
        ...siteConfig.colors?.red,
      },
      orange: {
        "50": "#fefaf5",
        "100": "#fcead7",
        "200": "#f8d2a7",
        "300": "#e1b47f",
        "400": "#c39b6e",
        "500": "#a8855f",
        "600": "#8d7150",
        "700": "#705a40",
        "800": "#584632",
        "900": "#493a29",
        ...siteConfig.colors?.orange,
      },
    },
  },
  ...partnerFonts,

  // fonts: {
  //   body: `'Open Sans Variable', sans-serif`,
  //   heading: `'Raleway Variable', sans-serif`,
  // },
});
