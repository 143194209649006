import {
  AudioConfig,
  SpeechSynthesizer,
  VoiceInfo as AzureVoiceInfo,
} from "microsoft-cognitiveservices-speech-sdk";
import { AppError } from "../../utils/app-error";
import { voiceConfigFilter } from "../../utils/avatar-voice-config-filter";
import { codeToLangName } from "../../utils/code-to-lang-name";
import { logger } from "../../utils/logger";
import { staticCharacterListWithBios } from "../characters/static-character-list-with-bios";
import { BrowserVoiceInfo, getBrowserVoices } from "./get-browser-voices";
import { getSpeechConfig } from "./speech-config";
import { staticLangSupportList } from "./static-lang-list";
import { ttsLangCodes } from "./text-to-speech-lang-codes";

export async function getSpeechSynthesis(
  voiceName?: string,
  audioConfig?: AudioConfig,
): Promise<SpeechSynthesizer> {
  const speechConfig = await getSpeechConfig();

  if (!speechConfig) {
    throw new AppError("Unable to contact speech synthesis server");
  }

  if (voiceName) {
    speechConfig.speechSynthesisVoiceName = voiceName;
  }

  // if (detectIOS()) {
  //   speechConfig.speechSynthesisOutputFormat =
  //     SpeechSynthesisOutputFormat.Webm16Khz16BitMonoOpus;
  // }

  return new SpeechSynthesizer(speechConfig, audioConfig);
}

export type Voice = {
  langCode: string;
  id: string;
  displayName: string;
  avatarName: string;
  gender: string;
};

// let allVoices: (AzureVoiceInfo | BrowserVoiceInfo)[] = [];
let azureVoices: AzureVoiceInfo[] = [];
let browserVoices: BrowserVoiceInfo[] = [];

async function getAzureVoices() {
  const synthesizer: SpeechSynthesizer = await getSpeechSynthesis();
  const result = await synthesizer.getVoicesAsync();

  return result.voices;
}
export async function getAllVoices() {
  if (azureVoices.length > 0 || browserVoices.length > 0) {
    logger.info("Return saved voices");

    return {
      azureVoices,
      browserVoices,
    };
  } else {
    logger.info("Fetch voices from service");

    try {
      azureVoices = await getAzureVoices();
    } catch (err: any) {
      logger.error("Failed to get azure voices", err);
    }

    try {
      browserVoices = await getBrowserVoices();
    } catch (err: any) {
      logger.error("Failed to get browser voices", err);
    }

    return {
      azureVoices,
      browserVoices,
    };
  }
}

export async function getVoicesFromLang(
  langCode: string,
  generate: boolean = false,
): Promise<Voice[]> {
  const { azureVoices } = await getAllVoices();

  const filteredAzureVoices = azureVoices
    .filter((voice) => !voice.name.includes("JennyMultilingualNeural"))
    .filter((voice) => staticCharacterListWithBios.some((b) => b.id === voice.name))
    .map((voice) => ({
      langCode: voice.locale,
      id: voice.name,
      avatarName: voice.localName,
      displayName: `${voice.localName} (${voice.locale})`,
      gender: voice.gender === 1 ? "Female" : "Male",
    }))
    .filter((voice) => (generate ? true : voice.langCode === langCode))
    .sort((a, b) => {
      const character = staticCharacterListWithBios.find((c) => c.id === a.id);
      return character?.isDefaultForLanguage ? -1 : 1;
    });

  return [
    ...voiceConfigFilter(filteredAzureVoices),
    ...voiceConfigFilter(getBrowserVoicesFromLang(langCode, generate)),
  ];
}

export function getBrowserVoicesFromLang(langCode: string, generate: boolean = false) {
  return browserVoices
    .filter((voice) =>
      staticCharacterListWithBios.some(
        (b) =>
          b.browserVoice?.chromeURI === voice.browserVoiceCharacter.browserVoice?.chromeURI ||
          b.browserVoice?.edgeURI === voice.browserVoiceCharacter.browserVoice?.edgeURI,
      ),
    )
    .map((voice) => ({
      langCode: voice.browserVoiceCharacter.langCode,
      id: voice.browserVoiceCharacter.id,
      avatarName: voice.speechSynthesisVoice.voiceURI,
      displayName: `${voice.speechSynthesisVoice.voiceURI} (${voice.browserVoiceCharacter.langCode})`,
      gender: voice.browserVoiceCharacter.gender,
    }))
    .filter((voice) => (generate ? true : voice.langCode === langCode));
}

export type SupportedLang = [string, string];

export async function getLangSupportList(): Promise<SupportedLang[]> {
  // un comment and run code below to get new list
  return Promise.resolve(staticLangSupportList);

  // let groupedVoices: [string, string][] = [];

  // const voices = await getAllVoices();

  // voices.forEach((voice) => {
  //   const hasGroup = groupedVoices.some(([locale]) => voice.locale === locale);
  //   if (voice.name.includes("Ryan")) {
  //     console.log(voice);
  //   }

  //   if (!hasGroup) {
  //     const langName = codeToLangName(voice.locale) ?? voice.locale;
  //     const capitalize = (str: string) =>
  //       str.charAt(0).toUpperCase() + str.slice(1);

  //     groupedVoices.push([voice.locale, capitalize(langName)]);
  //   }
  // });

  // const res = groupedVoices
  //   .filter(([langCode]) => {
  //     return ttsLangCodes.some((c) => c === langCode);
  //   })
  //   .sort(([aCode, aName], [bCode, bName]) => aName.localeCompare(bName));

  // return res;
}
