import { SpeechRecognitionProvider } from "../database/entity/Settings";

export type ChatPreference = "text" | "voice";

export type ProficiencyKey =
  | "beginner"
  | "elementary"
  | "intermediate"
  | "upper-intermediate"
  | "advanced";

export interface Proficiency {
  label: string;
  key: ProficiencyKey;
  shortDescription?: string;
}

export const unavailableSettings = {
  showChatRomanization: false,
  autoPunctuation: false,
};

export interface Settings {
  id?: string;
  readAloudSpeed: string;
  practiceLanguage: string;
  practiceLanguageProficiency: ProficiencyKey;
  voicePreference?: string;
  userLanguage: string;
  autoSubmitTranscription: boolean;
  chatPreference: ChatPreference;
  firstVisit?: boolean;
  showSubtitles?: boolean;
  inputPreference?: "voice" | "text";
  showSuggestions?: boolean;
  autoPunctuation: boolean;
  multiLingualSpeechRecognition: boolean;
  showChatTranslations: boolean;
  showChatRomanization: boolean;
  speechRecognitionProvider: SpeechRecognitionProvider;
  unavailableSettings: typeof unavailableSettings;
  uiLanguage: string;
  showLiveFeedback: boolean;
  autoPlayTutorResponse: boolean;
  playbackUiAudio: boolean;
  submitWithTriggerWord: boolean;
  triggerWord: string;
}
