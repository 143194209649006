import { logger } from "../../utils/logger";
import {
  VoiceCharacter,
  staticCharacterListWithBios,
} from "../characters/static-character-list-with-bios";

export interface BrowserVoiceInfo {
  speechSynthesisVoice: SpeechSynthesisVoice;
  browserVoiceCharacter: VoiceCharacter;
}

function getVoiceList(): Promise<SpeechSynthesisVoice[]> {
  return new Promise((resolve, reject) => {
    const tryGetVoices = (retryCount = 0) => {
      const voices = window.speechSynthesis.getVoices();

      if (voices.length > 0) {
        resolve(voices);
        return;
      }

      if (retryCount > 10) {
        reject("Unable to get browser voices.");
        return;
      }

      logger.warn("Unable to get browser voices. Retrying...");
      setTimeout(() => tryGetVoices(retryCount + 1), 500);
    };

    tryGetVoices();
  });
}

export async function getBrowserVoices() {
  const browserVoices = await getVoiceList();
  const browserCharacters = staticCharacterListWithBios.filter((c) => c.browserVoice);

  return browserVoices
    .map((voice) => {
      const browserVoiceCharacter = browserCharacters.find(
        (v) =>
          v.browserVoice?.chromeURI === voice.voiceURI ||
          v.browserVoice?.edgeURI === voice.voiceURI,
      );

      if (browserVoiceCharacter) {
        return {
          speechSynthesisVoice: voice,
          browserVoiceCharacter,
        };
      }

      return undefined;
    })
    .filter(Boolean) as BrowserVoiceInfo[];
}
