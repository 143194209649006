import { Box, Flex } from "@chakra-ui/react";
import { RiWhatsappFill } from "react-icons/ri";

interface Props {
  whatsAppNumber: number;
}

export function WhatsAppBubble(props: Props) {
  return (
    <Flex
      position="fixed"
      target="_blank"
      as="a"
      href={`https://wa.me/${props.whatsAppNumber}`}
      backgroundColor="#25d366"
      w="50px"
      h="50px"
      borderRadius={"100%"}
      right={20}
      bottom={20}
      alignItems="center"
      justifyContent="center"
      zIndex={10}
      _hover={{ backgroundColor: "#288c4d" }}
    >
      <RiWhatsappFill color="#fff" fontSize="20px" />
    </Flex>
  );
}
