import { Text } from "@chakra-ui/react";

interface Logo {
  fontSize?: number;
}

export default function Logo({ fontSize = 40 }: Logo) {
  return (
    <Text
      fontSize={fontSize}
      fontFamily={"Raleway Variable, InterVariable, -apple-system, system-ui, sans-serif"}
      fontWeight={200}
    >
      Talkio AI
    </Text>
  );
}
