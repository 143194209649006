import { logger } from "./logger";

export function readAloudFallbackForMac(
  lang: string,
  rate: number,
  lastUtter: number,
  lastTimeout: number,
  lastUtterCharCount: number,
  lastTimeSinceLastUtter: number,
): number[] {
  const timeSinceLastUtter = Date.now() - lastUtter;
  const timeout = getReadAloudTimeout(lang, lastUtterCharCount, rate);

  logger.warn(
    `[resumeInfinity] Time since last utter: ${timeSinceLastUtter.toFixed(
      1,
    )}. Timeout: ${timeout.toFixed(1)}. Last char count: ${lastUtterCharCount}`,
  );

  const isMac = window.navigator.userAgent.search("Mac") > -1;

  if (isMac && timeSinceLastUtter === 0 && lastTimeSinceLastUtter > 0) {
    const safetyGapMs = lastTimeout - lastTimeSinceLastUtter;
    const safetyGapPct = (safetyGapMs / lastTimeout) * 100;

    logger.warn(
      `Last timeout safety gap: ${safetyGapMs.toFixed(1)}ms. ${safetyGapPct.toFixed(1)}%`,
    );

    if (safetyGapPct < 25) {
      logger.error(`________Safety gap ${safetyGapPct.toFixed(1)}% too low!________`);
    }
  }

  if (timeSinceLastUtter > timeout) {
    logger.error(`No utter timeout ${timeout.toFixed(1)} - cancel.`);
    window.speechSynthesis.cancel();

    setTimeout(() => {
      window.speechSynthesis.resume();
    }, 50);
    return [0, 0];
  }

  return [timeout, timeSinceLastUtter];
}

function getReadAloudTimeout(lang: string, lastCharCount: number, rate: number) {
  let offset = 100;

  if (lang === "zh-CN" || lang === "zh-TW" || lang === "zh-HK") {
    offset = 240;
  }

  if (lang === "zh-TW") {
    offset = 300;
  }

  if (lang === "ja-JP") {
    offset = 260;
  }

  if (lang === "ko-KR") {
    offset = 240;
  }

  return 7000 + lastCharCount * offset * (1 / rate);
}
