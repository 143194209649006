import { Flex } from "@chakra-ui/react";

export function Section(props: { children: React.ReactNode; id?: string }) {
  return (
    <Flex
      id={props.id}
      p={25}
      maxW="1100px"
      w="100%"
      m="0 auto"
      flexDir={{ base: "column", md: "row" }}
    >
      {props.children}
    </Flex>
  );
}
