import { SiteConfig } from "../../types";
import { invitationTemplate } from "./invitation";

export const _config: SiteConfig = {
  isDefault: false,
  showTidio: false,
  serviceName: "Magyar Iskola AI Tutor",
  pwaServiceName: "Speakifyr",
  appLogoPath: "/partner/magyar-iskola/app-logo.svg",
  appLogoHeight: "80px",
  landingLogoPath: "/partner/magyar-iskola/app-logo.svg",
  landingLogoHeight: "60px",
  hubLogoPath: "/partner/magyar-iskola/app-logo.svg",
  hubLogoHeight: "60px",
  favicon: "/partner/magyar-iskola/favicon.png",
  pwaIcons: [
    {
      src: "/partner/magyar-iskola/app-logo.svg",
      type: "image/png",
      sizes: "192x192",
    },
    {
      src: "/partner/magyar-iskola/app-logo.svg",
      type: "image/png",
      sizes: "256x256",
    },
    {
      src: "/partner/magyar-iskola/app-logo.svg",
      type: "image/png",
      sizes: "512x512",
    },
  ],
  overrideLanding: true,
  emailsSender: "info@lang-services.com",
  adminEmail: "info@hlschool.hu",
  whatsAppNumber: 36204241012,
  reviewLink: null,
  tocLink: "https://www.magyar-iskola.hu/gdpr",
  privacyLink: "https://www.magyar-iskola.hu/gdpr",
  emailInvitationSubject: "Access to Magyar Iskola AI",
  emailInvitationHtml: invitationTemplate,
  copyright: "Magyar Iskola",
  showLanguageGuides: false,
  bioPrompt: `
    You are a tutor at Hungarian Language School.
    Hungarian Language School would like as many people as possible to be able to get to know and use the Hungarian language. Our aim is to bring the Hungarian community and culture together with our international clients. Speak local but thinking globally.
  `,
  siteColors: {
    special: {
      desktopSidebar: "#ccc",
      desktopSidebarBorderColor: "#ccc",
      desktopSidebarText: "#000",
      desktopSidebarTextHover: "#eee",
      chatAsideMenu: "#333",
      mobileNavBackgroundColor: "#333",
      mobileNavBackgroundContrastColor: "#ffffff",
      mobileNavBorderColor: "#333",
      speakInputIdle: "var(--chakra-colors-brand-blue-900)",
      speakInputRecording: "var(--chakra-colors-brand-primary-900)",
      //chatIconColor: "var(--chakra-colors-brand-primary-main)",
    },
  },
  colors: {
    primary: {
      main: "#FF3B3F",
      mainContrast: "#fff",
      "50": "#fff5f5",
      "100": "#ffd7d7",
      "200": "#ffb1b3",
      "300": "#ff7f81",
      "400": "#ff5c5f",
      "500": "#ea363a",
      "600": "#c62e31",
      "700": "#a02528",
      "800": "#881f22",
      "900": "#631719",
    },

    secondary: {
      main: "#19446a",
      mainContrast: "#fff",
      "50": "#f6f7f9",
      "100": "#dae1e7",
      "200": "#bac7d3",
      "300": "#94a8ba",
      "400": "#7f97ac",
      "500": "#63809a",
      "600": "#4b6d8a",
      "700": "#31587a",
      "800": "#204a6f",
      "900": "#143553",
    },
    tertiary: {
      main: "#FF3B3F",
      mainContrast: "#fff",
      "50": "#fff5f5",
      "100": "#ffd7d7",
      "200": "#ffb1b3",
      "300": "#ff7f81",
      "400": "#ff5c5f",
      "500": "#ea363a",
      "600": "#c62e31",
      "700": "#a02528",
      "800": "#881f22",
      "900": "#631719",
    },
    light: {
      main: "#F6F9F5",
      alt: "#F6F9F5",
    },
    // https://palette.saas-ui.dev/
    gray: {
      "50": "#f9fafa",
      "100": "#f1f1f2",
      "200": "#e6e7e9",
      "300": "#d2d4d7",
      "400": "#a9adb2",
      "500": "#797f88",
      "600": "#4d5560",
      "700": "#2e3744",
      "800": "#19202b",
      "900": "#141a23",
    },
    orange: {
      "50": "#fffaf5",
      "100": "#ffe9d7",
      "200": "#ffd0a7",
      "300": "#ffa85d",
      "400": "#eb8a36",
      "500": "#ca772f",
      "600": "#ab6427",
      "700": "#88501f",
      "800": "#6b3f19",
      "900": "#583414",
    },
    yellow: {
      "50": "#fffefa",
      "100": "#fff9e1",
      "200": "#ffeda4",
      "300": "#ffdd55",
      "400": "#edc937",
      "500": "#c3a62d",
      "600": "#9c8424",
      "700": "#7a671c",
      "800": "#5b4d15",
      "900": "#4b4011",
    },
    green: {
      "50": "#effff6",
      "100": "#9bffc9",
      "200": "#38f38f",
      "300": "#32d87f",
      "400": "#2cbd6f",
      "500": "#26a360",
      "600": "#1f874f",
      "700": "#18693e",
      "800": "#145633",
      "900": "#10472a",
    },
    teal: {
      "50": "#e4fffe",
      "100": "#5dfff9",
      "200": "#37ece5",
      "300": "#31d2cd",
      "400": "#29b3ae",
      "500": "#239894",
      "600": "#1d7b78",
      "700": "#16605d",
      "800": "#13504e",
      "900": "#0f4240",
    },
    cyan: {
      "50": "#edfdff",
      "100": "#b0f5ff",
      "200": "#86efff",
      "300": "#4ce7ff",
      "400": "#33c5dc",
      "500": "#2fb5ca",
      "600": "#2aa3b6",
      "700": "#238796",
      "800": "#1d6f7c",
      "900": "#165660",
    },
    blue: {
      "50": "#eef7ff",
      "100": "#c0e2ff",
      "200": "#90ccff",
      "300": "#58b2ff",
      "400": "#3798ec",
      "500": "#2f83cb",
      "600": "#276daa",
      "700": "#1e5482",
      "800": "#19446a",
      "900": "#143857",
    },
    purple: {
      "50": "#f8f5ff",
      "100": "#e5d9ff",
      "200": "#d1bdff",
      "300": "#b493ff",
      "400": "#9e75ff",
      "500": "#804aff",
      "600": "#6a35e6",
      "700": "#572cbd",
      "800": "#48249b",
      "900": "#351b73",
    },
    pink: {
      "50": "#fff5fa",
      "100": "#ffd6ec",
      "200": "#ffb2db",
      "300": "#ff7ec3",
      "400": "#ff4fad",
      "500": "#dc338e",
      "600": "#bd2c7a",
      "700": "#9a2463",
      "800": "#791c4e",
      "900": "#591539",
    },
  },
  templates: [
    {
      type: "free-talk",
      titleKey: "dashboard.template.freeTalk.title",
      descriptionKey: "dashboard.template.freeTalk.description",
      backgroundColor: "brand.primary.main",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "role-play",
      titleKey: "dashboard.template.rolePlay.title",
      descriptionKey: "dashboard.template.rolePlay.description",
      backgroundColor: "brand.yellow.100",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "topic",
      titleKey: "dashboard.template.topics.title",
      descriptionKey: "dashboard.template.topics.description",
      backgroundColor: "brand.red.400",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "quiz",
      titleKey: "dashboard.template.quiz.title",
      descriptionKey: "dashboard.template.quiz.description",
      backgroundColor: "brand.green.500",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "case",
      titleKey: "dashboard.template.caseStudy.title",
      descriptionKey: "dashboard.template.caseStudy.description",
      backgroundColor: "brand.blue.400",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
  ],
};
