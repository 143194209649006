const l = {
  "base.and": "og",
  "base.toc": "Vilkår og betingelser",
  "base.privacyPolicy": " Privatlivspolitik",
  "base.createAccount": "Opret konto",
  "base.signin": "Log ind",
  "base.signup": "Tilmeld dig",
  "base.logOut": "Log ud",
  "authentication.description": "Log ind eller opret en konto",
  "invitation.wrongEmailTitle": "Allerede logget ind",
  "invitation.wrongEmailText":
    "Det ser ud til, at du allerede er logget ind på en anden konto. Log venligst ud og prøv igen.",
  "invitation.invalidLinkTitle": "Ugyldig invitation",
  "invitation.invalidLinkText": "Invitationslinket er ugyldigt",
  "invitation.completeSignup": "Færdiggør venligst tilmeldingen til ",
  "invitation.completeSignupDescription":
    "En verifikations e-mail er blevet sendt til din adresse. Hvis du ikke ser den i din indbakke, skal du tjekke din spam-mappe.",
  "invitation.completeSignupButton": "Færdiggør tilmelding",
  "invitation.mustSignupTitle": "Du er blevet inviteret til ",
  "invitation.signInToAccept":
    "Log ind for at acceptere invitationen. I invitations-e-mailen, du modtog, finder du et midlertidigt kodeord.",
  "invitation.acceptTermsOnSignup": "Når du logger ind, accepterer du automatisk vores",
  "invitation.accept": "Log ind for at acceptere invitationen",
  "invitation.failed":
    "Fejl ved accept af invitation. Prøv venligst at logge ud og logge ind - eller bed administratoren om at invitere dig igen.",
  "invitation.accepted": "Invitation accepteret",
  "invitation.emailNotVerified":
    "E-mail ikke verificeret. Bekræft venligst din e-mail og prøv igen.",
  "country.venezuela": "Venezuela",
  "country.japan": "Japan",
  "country.algeria": "Algeriet",
  "country.france": "Frankrig",
  "country.spain": "Spanien",
  "country.china": "Kina",
  "country.usa": "USA",
  "country.england": "England",
  "country.brazil": "Brasilien",
  "country.italy": "Italien",
  "country.denmark": "Danmark",
  "head.title": "Talkio AI | Øv sprog med AI",
  "landing.title1": "Øv ",
  "landing.title2": "Sprog ",
  "landing.title3": "med AI ",
  "landing.subtitle1":
    "Den ultimative sprogtræningsapp, der bruger AI-teknologi til at hjælpe dig med at forbedre dine mundtlige sprogkundskaber.",
  "landing.button.freeTrial": "7 dage gratis",
  "landing.creditCardNotice": "Kreditkort kræves - annuller når som helst",
  "landing.text.voiceControlTeam": "Fra teamet bag Voice Control til ChatGPT",
  "landing.text.trustedBy": "Trusted by more than 500.000 users",
  "landing.card.title.advancedAI": "Bygget med avanceret AI-teknologi",
  "landing.card.text.advancedAI":
    "Talkio AI er bygget med avanceret AI-teknologi og lader dig interagere med AI gennem stemme for at træne dine mundtlige sprogkundskaber.",
  "landing.card.title.multipleLanguages": "Understøtter flere sprog",
  "landing.card.text.multipleLanguages":
    "Vi tilbyder et bredt udvalg af sprogmuligheder, herunder engelsk, kinesisk, fransk og flere andre sprog.",
  "landing.card.title.premiumVoice": "Premium stemmeteknologi",
  "landing.card.text.premiumVoice":
    "Talkio AI tilbyder premium stemmer og understøtter flere dialekter for de mest populære sprog. Med vores avancerede sprogteknologi kan du fordybe dig i autentiske samtaler og opnå færdigheder i de dialekter, der betyder mest for dig.",
  "landing.sectionTitle.meetTutors": "Mød AI-tutorialerne",
  "landing.sectionText.moreThanTutors":
    "Talkio AI har mere end 400 undervisere med unikke kunstige personligheder, der er klar til at hjælpe dig på din sprogrejse.",
  "landing.text.andManyMore": "Og mange flere",
  "landing.text.learnWithAITutors": "Lær med AI-tutorer",
  "landing.text.wonderedAboutTutor":
    "Har du nogensinde spekuleret på, hvordan det ville være at have en personlig sprogunderviser tilgængelig når som helst, hvor som helst? Hos Talkio AI gør vi denne drøm til virkelighed. Vores AI-tutorer er de perfekte ledsagere til at forbedre dine mundtlige sprogkundskaber. Drevet af avanceret AI-teknologi efterligner de menneskelig interaktion og samtale og tilbyder en fordybende sprogindlæringsoplevelse, der er både engagerende og effektiv.",
  "landing.text.rememberLanguageLearning":
    "Husk, sprogindlæring er mere end bare grammatik og ordforråd; det handler om at kunne udtrykke sig selv med selvtillid. Med Talkio AIs intelligente tutorer kan du tale, øve og overvinde dine sprogmål!",
  "landing.sectionTitle.featureSpotlight": "Funktion i fokus",
  "landing.sectionText.featureSpotlight":
    "Naviger gennem vores udvalg af funktioner, der er designet til at forvandle din sprogindlæring til en fordybende og engagerende rejse.",
  "landing.text.safeEnvironment": "Sikker og støttende miljø",
  "landing.text.supportiveEnvironment":
    "Vores app giver et sikkert og støttende miljø for dig at øve at tale med selvtillid. Med Talkio AI kan du have meningsfulde samtaler ved hjælp af AI-teknologi, hvilket giver dig mulighed for at opnå flydende tale og udtrykke dig i det sprog, du lærer.",
  "landing.sectionTitle.insights": "Indsigter",
  "landing.sectionText.realStories": "Virkelige historier fra virkelige brugere af Talkio AI",
  "landing.sectionTitle.faq": "Ofte stillede spørgsmål",
  "landing.sectionText.faq": "Svar på ofte stillede spørgsmål om Talkio AI",
  "landing.sectionTitle.pricing": "Priser",
  "landing.sectionText.unlockPotential":
    "Lås dit sprogpotentiale op med Talkio. Din rejse til mestre starter nu.",
  "landing.sectionText.offersForPartners": "Vi tilbyder også planer for skoler og team",
  "landing.imageAlt.landing": "Landing",
  "landing.imageAlt.laurelLeft": "Laurbær venstre",
  "landing.imageAlt.laurelRight": "Laurbær højre",
  "landing.imageAlt.speakingWithAI": "Kvinde, der taler med AI",
  "testimonial.text1":
    "Jeg har personligt brugt Talkio AI til at forbedre mit spansk, og lad mig sige dig, det er en utrolig mulighed.",
  "testimonial.text2":
    "Talkio AI er et meget imponerende værktøj og burde inkluderes i hvert sprogindlæres værktøjskasse.",
  "testimonial.text3": "Dette værktøj Talkio AI blæser mit sind til sprogindlæring.",
  "testimonial.text4": "Talkio AI - det er et fantastisk produkt.",
  "feature.lifeLikeConversations": "Livagtige samtaler med AI-tutorer",
  "feature.pronunciationTool": "Udtalepraksis værktøj",
  "feature.detailedFeedback": "Detaljeret feedback på dine taleevner",
  "feature.interactiveWordbook": "Interaktiv ordbog",
  "feature.supportsLanguages": "Understøtter 40+ sprog",
  "landing.text.voiceConversations": "Stemmesamtaler",
  "landing.text.voiceConversationsDescription":
    "Ha' engagerende stemmesamtaler med vores AI-tutorer",
  "landing.text.pronunciation": "Udtale",
  "landing.text.pronunciationDescription": "Vurder og øv din udtale med ord-for-ord feedback",
  "landing.text.feedback": "Feedback",
  "landing.text.feedbackDescription":
    "Få øjeblikkelig feedback på dine sprogkompetencer, og tips til hvordan du kan forbedre",
  "landing.text.multipleLanguages": "Flere sprog og dialekter",
  "landing.text.multipleLanguagesDescription":
    "Vælg imellem 134 sprog og dialekter for at øve dine tale færdigheder",
  "landing.text.progress": "Fremskridt",
  "landing.text.progressDescription":
    "Hold styr på dine fremskridt og få ugentlige rapporter om din sprogindlæringsrejse",
  "landing.text.wordbook": "Ordbog",
  "landing.text.wordbookDescription": "Gem og genbesøg ord lært under træning",
  "landing.text.streaks": "Streaks",
  "landing.text.streaksDescription":
    "Hold styr på dine fremskridt med streaks for at opbygge en læringsvaner",
  "landing.text.crosstalk": "Crosstalk",
  "landing.text.crosstalkDescription":
    "Kommuniker med tutoren på både dit modersmål og det sprog, du lærer",
  "landing.text.translations": "Oversættelser",
  "landing.text.translationsDescription":
    "Øjeblikkelige oversættelser for at støtte dig i dine samtaler",
  "landing.text.featureSpotlight": "Funktion i fokus",
  "landing.text.featureSpotlightDescription":
    "Naviger gennem vores udvalg af funktioner, der er designet til at forvandle din sprogindlæring til en fordybende og engagerende rejse",
  "feature.practiceSpeaking.title": "Øv at tale",
  "feature.practiceSpeaking.bullet1": "Livagtige samtaler med en AI-tutor",
  "feature.practiceSpeaking.bullet2": "Stemme-først interaktion",
  "feature.practiceSpeaking.bullet3": "Samtalehjælp",
  "feature.practiceSpeaking.bullet4": "Gentag og prøv igen",
  "feature.worldOfLanguages.title": "En verden af sprog",
  "feature.worldOfLanguages.bullet1": "Understøtter 40+ sprog",
  "feature.worldOfLanguages.bullet2": "Øjeblikkelige oversættelser",
  "feature.worldOfLanguages.bullet3": "Flersproget talegenkendelse",
  "feature.worldOfLanguages.bullet4": "Romanisering af skriftlige sprog",
  "feature.actionableFeedback.title": "Handlingsbar feedback",
  "feature.actionableFeedback.bullet1": "Sætning scoring",
  "feature.actionableFeedback.bullet2": "Forbedringsstrategier",
  "feature.actionableFeedback.bullet3": "AI-forslag",
  "feature.actionableFeedback.bullet4": "Tale præcisionsscore",
  "feature.pronunciationPractice.title": "Udtalepraksis",
  "feature.pronunciationPractice.bullet1": "Ord-for-ord score",
  "feature.pronunciationPractice.bullet2": "Udtalevurdering",
  "feature.pronunciationPractice.bullet3": "Nøjagtighed, fuldstændighed og flydende",
  "feature.pronunciationPractice.bullet4": "Gentag og prøv igen",
  "feature.interactiveWordbook.title": "Interaktiv ordbog",
  "feature.interactiveWordbook.bullet1": "Hold styr på de ord, du lærer",
  "feature.interactiveWordbook.bullet2": "Forstærket memorering via tutor gentagelse",
  "feature.interactiveWordbook.bullet3": "Oversættelse og læs højt",
  "feature.interactiveWordbook.bullet4": "Sætningseksempler",
  "feature.button.startTrial": "Start din gratis prøveversion",
  "faq.question1": "Er der en gratis plan?",
  "faq.answer1":
    "Nej, alle planer er betalte. Der er dog en 7-dages gratis prøveversion, der kan annulleres når som helst. Ingen forpligtelser.",
  "faq.question2": "Hvem står bag Talkio AI?",
  "faq.answer2.part1": "Talkio AI er et produkt af",
  "faq.answer2.part2":
    "noteret i EU. Aidia ApS overholder den generelle databeskyttelsesforordning (GDPR) for at levere en sikker og tryg oplevelse for alle brugere.",
  "faq.question3": "Er Talkio AI egnet til begyndere?",
  "faq.answer3":
    "Talkio AI er bedst egnet til dem, der har en grundlæggende viden om et sprog og ønsker at forbedre deres mundtlige færdigheder. For begyndere har vi udviklet en introduktionsvejledning for at komme i gang. Denne vejledning dækker grundlæggende aspekter af dit valgte sprog, så du kan få fuldt udbytte af Talkio AI. Den er gratis og et fantastisk værktøj for lærere, der starter deres rejse. Tjek vejledningen og begynd at forbedre dine sprogkundskaber effektivt.",
  "faq.button.gettingStartedGuide": "Prøv den GRATIS vejledning til at komme i gang",
  "faq.question4": "Er betaling sikker?",
  "faq.answer4":
    "Ja, vi bruger Stripe til finansiel behandling. Vi opbevarer ikke nogen af ​​dine kreditkortoplysninger.",
  "faq.question5": "Hvor er mine data gemt?",
  "faq.answer5":
    "Dine data gemmes på servere i Den Europæiske Union: Microsoft (stemmes og data), Auth0 (autentifikation), Stripe (betalinger). Desuden bruger vi tjenester fra OpenAI, Anthropic og Groq til at drive AI. Disse data behandles i USA.",
  "faq.question6": "Kan jeg få en refundering?",
  "faq.answer6":
    "Den gratis prøveversion kan annulleres når som helst; dog, hvis du glemmer at annullere prøveversionen, kan vi refundere dig inden for 14 dage, forudsat at du ikke har brugt tjenesten efter prøveperioden sluttede. Kontakt os venligst på support@talkio.ai",
  "faq.question7": "Hvilke betalingsmetoder accepterer I?",
  "faq.answer7":
    "For abonnementer accepterer vi alle større kreditkort og Paypal. For engangskøb accepterer vi også WeChat, Przelewy24, Giropay, Alipay og mere. Tilgængelighed afhænger af din placering.",
  "faq.question8": "Har Talkio en mobilapp?",
  "faq.answer8":
    "Talkio er en progressiv webapp, hvilket betyder, at du kan installere det fra browseren uden at gå til App Store eller Google Play. På Android, mens du bruger Talkio i din browser, vil du finde en installationsknap efter tilmelding. På iOS, åbn Talkio i Safari, tryk på delingsikonet, og vælg 'Tilføj til startskærm' for at installere det som en almindelig app på din telefon.",
  "faq.question9": "Hvilke browsere kan jeg bruge?",
  "faq.answer9":
    "Talkio AI fungerer i alle moderne browsere, inklusive Chrome, Firefox, Safari og Edge. Det fungerer også på mobile browsere på iPhone og Android.",
  "faq.question10": "Hvilke AI-modeller bruger I?",
  "faq.answer10":
    "Talkio AI bruger en blanding af forskellige AI-modeller fra OpenAI, Anthropic og Meta. Hver model har en specifik opgave, de er dygtige til, og sammen skaber de en kraftfuld AI-tutor.",
  "faq.question11": "Hvordan adskiller Talkio AI sig fra Voice Control for ChatGPT?",
  "faq.answer11":
    "Talkio AI tilbyder en sammenhængende oplevelse med ChatGPT, der er tilpasset sprogindlæring. Det tilbyder også flere funktioner, herunder udtalervurdering, premium stemmeteknologier, flersproget support, kuraterede træningsmaterialer, integreret feedback og oversættelser.",
  "faq.question12": "Hvordan kan jeg kontakte jer?",
  "faq.answer12": "Du er meget velkommen til at skrive en e-mail til hello@talkio.ai",
  "layout.serviceBar.text": "Stiftet i Danmark. Vi respekterer dit privatliv.",
  "layout.serviceBar.community": "Deltag i et worldwide community af sprogindlærere",
  "layout.nav.pricing": "Priser",
  "layout.nav.faq": "FAQ",
  "layout.nav.languages": "Sprog",
  "layout.nav.blog": "Blog",
  "layout.nav.schools": "For skoler",
  "layout.nav.teams": "For teams",
  "layout.nav.affiliateProgram": "Affiliate-program",
  "layout.nav.signIn": "Log ind",
  "layout.nav.goToApp": "Gå til appen",
  "layout.nav.menu": "Menu",
  "layout.cta.description":
    "Den ultimative sprogtræningsapp, der bruger AI-teknologi til at hjælpe dig med at forbedre dine mundtlige sprogkundskaber.",
  "layout.cta.button": "Prøv Talkio AI",
  "layout.footer.pages": "Sider",
  "layout.footer.learningHub": "Læringshub",
  "layout.footer.blog": "Blog",
  "layout.footer.about": "Om",
  "layout.footer.affiliateProgram": "Affiliate-program",
  "layout.footer.schools": "Talkio til skoler",
  "layout.footer.teams": "Talkio til teams",
  "layout.footer.languageGuide": "Gratis sprogguide for begyndere",
  "layout.footer.termsConditions": "Vilkår og betingelser",
  "layout.footer.privacyPolicy": "Privatlivspolitik",
  "layout.footer.logout": "Log ud",
  "layout.footer.login": "Log ind",
  "layout.footer.blogPosts": "Blogindlæg",
  "layout.footer.blogPost1": "Ny funktion på Talkio AI: Interaktiv sprogguide",
  "layout.footer.blogPost2": "Talkio integrerer tre nye tilpassede GPT'er",
  "layout.footer.blogPost3":
    "Forbedr dine IELTS mundtlige færdigheder med Talkio AI: En trin-for-trin guide",
  "layout.footer.blogPost4": "3 effektive måder at øve mundtlig engelsk på",
  "layout.footer.blogPost5": "At klare sig i dine engelske færdighedstest",
  "layout.footer.blogPost6": "At mestre TOEFL mundtlig med Talkio AI: Din essentielle guide",
  "layout.footer.contact": "Kontakt",
  "layout.footer.rights": "Alle rettigheder forbeholdes.",
  "layout.footer.illustrationCredits": "Frontpage illustrationer af vectorjuice",
  "pricing.description.perMonthBilledOnce": "pr. måned - faktureres én gang",
  "pricing.description.perMonthBilledQuarterly": "pr. måned - faktureres kvartalsvis",
  "pricing.description.perMonthBilledYearly": "pr. måned - faktureres årligt",
  "pricing.description.perMonth": "pr. måned",
  "pricing.button.buyNow": "Køb nu med 7-dages pengene tilbage garanti",
  "pricing.button.startFreeTrial": "Start din gratis prøveversion",
  "pricing.features.refund": "7-dages fuld refundering - annuller når som helst",
  "pricing.features.freeTrial": "7-dages gratis prøveversion - annuller når som helst",
  "pricing.features.supportsLanguages": "Understøtter 40+ sprog",
  "pricing.features.voiceConversations": "Livagtige stemmesamtaler",
  "pricing.features.instantFeedback": "Øjeblikkelig feedback",
  "pricing.features.weeklyProgressReport": "Ugentlig fremskridtsrapport",
  "pricing.features.pronunciationPractice": "Udtalepraksis",
  "pricing.features.interactiveWordbook": "Interaktiv ordbog",
  "pricing.features.speechRecognition": "Flersproget talegenkendelse",
  "pricing.features.extraFeatures": "Rollespil, emner, quizzer & mere!",
  "accessRequest.pageNotExist": "404 Siden findes ikke.",
  "accessRequest.createAccountTitle": "Opret en konto for at komme i gang",
  "accessRequest.createAccountMessage":
    "For at deltage skal du først oprette en konto. Efter tilmelding kan du anmode om adgang til systemet.",
  "accessRequest.createAccountButton": "Opret din konto",
  "accessRequest.alreadyHaveAccount": "Har du allerede en konto?",
  "accessRequest.signIn": "Log ind",
  "accessRequest.grantedTitle": "Tillykke!",
  "accessRequest.grantedMessage":
    "Du har fået adgang til {serviceName}. Klik nedenfor for at begynde at bruge appen.",
  "accessRequest.goToApp": "Gå til app",
  "accessRequest.accountExists": "Konto findes allerede",
  "accessRequest.accountExistsMessage":
    "Du er allerede medlem med denne konto. For at fortsætte skal du logge ud og tilmelde dig med en anden e-mail.",
  "accessRequest.logOut": "Log ud",
  "accessRequest.requestAccessTitle": "Anmod om adgang for at fortsætte",
  "accessRequest.requestAccessMessage": "Din anmodning skal godkendes, før du kan få adgang.",
  "accessRequest.checkBackLater":
    "Tjek venligst tilbage senere for at se, om din adgang er blevet givet.",
  "accessRequest.submitRequest": "Indsend anmodning",
  "accessRequest.pending": "Adgangsanmodning afventer",
};

export default l;
