export const invitationTemplate = `<p style="line-height: 115%; font-size: 16px; font-family: 'Aptos',sans-serif; margin: 0cm 0cm 8.0pt 0cm;"><strong>Dear Student,</strong></p>
<p style="line-height: 115%; font-size: 16px; font-family: 'Aptos',sans-serif; margin: 0cm 0cm 8.0pt 0cm;">&nbsp;</p>
<p style="line-height: 115%; font-size: 16px; font-family: 'Aptos',sans-serif; margin: 0cm 0cm 8.0pt 0cm;">We are thrilled to invite you to join <em>Speakifyr</em>, the AI-powered teacher from the Hungarian Language School!</p>
<p style="line-height: 115%; font-size: 16px; font-family: 'Aptos',sans-serif; margin: 0cm 0cm 8.0pt 0cm;">As part of our community, you now have access to our innovative AI teachers, designed to help you master Hungarian more effectively and interactively.</p>
<p style="line-height: 115%; font-size: 16px; font-family: 'Aptos',sans-serif; margin: 0cm 0cm 8.0pt 0cm;">To get started, we've created an account for you with the following login credentials:</p>
<ul style="margin-bottom: 0cm; margin-top: 0cm;" type="disc">
<li style="line-height: 115%; font-size: 16px; font-family: 'Aptos',sans-serif; margin: 0cm 0cm 8.0pt 0cm;"><strong>Email Address: {email}</strong></li>
<li style="line-height: 115%; font-size: 16px; font-family: 'Aptos',sans-serif; margin: 0cm 0cm 8.0pt 0cm;"><strong>Password: {password}</strong></li>
</ul>
<p style="line-height: 115%; font-size: 16px; font-family: 'Aptos',sans-serif; margin: 0cm 0cm 8.0pt 0cm;">For security reasons, we highly recommend changing your password after your first login.</p>
<p style="line-height: 115%; font-size: 16px; font-family: 'Aptos',sans-serif; margin: 0cm 0cm 8.0pt 0cm;">Simply click the button below to accept your invitation and begin your language learning journey with us.</p>
<p style="line-height: 115%; font-size: 16px; font-family: 'Aptos',sans-serif; margin: 0cm 0cm 8.0pt 0cm;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <a href="{link}"> <img src="https://www.ai.magyar-iskola.hu/partner/magyar-iskola/email-button.png" width="213" height="45" /></a></p>
<p style="line-height: 115%; font-size: 16px; font-family: 'Aptos',sans-serif; margin: 0cm 0cm 8.0pt 0cm;">We are excited to have you on board and look forward to supporting your progress!</p>
<p style="line-height: 115%; font-size: 16px; font-family: 'Aptos',sans-serif; margin: 0cm 0cm 8.0pt 0cm;"><strong>&nbsp;</strong></p>
<p style="line-height: 115%; font-size: 16px; font-family: 'Aptos',sans-serif; margin: 0cm 0cm 8.0pt 0cm;"><strong>Kind regards, <br />Magyar Iskola - Hungarian Language School team</strong></p>
<p style="text-align: left;"><span style="font-size: 11pt;">1052 Budapest, Petőfi S&aacute;ndor u. 11.<br />Phone: +36 20 424 1012<br />0-24 AI Chatbot via <a href="https://wa.me/36204241012">WhatsApp</a><br /><a href="https://www.magyar-iskola.hu&nbsp;">https://www.magyar-iskola.hu&nbsp;</a><br />Adult education registration number: B/2020/000830<br /><strong>Office hours:</strong><br />Monday - Thursday: 10.00 -15.00</span></p>
<p style="text-align: left;"><img src="https://www.ai.magyar-iskola.hu/partner/magyar-iskola/email-logo.png" /></p>
<p style="line-height: 115%; font-size: 16px; font-family: Aptos, sans-serif; margin: 0cm 0cm 8pt; text-align: center;">@ <u><a href="http://www.magyar-iskola.hu">www.magyar-iskola.hu</a>&nbsp;</u><br /><em>Copyright (C) 2024 Magyar Iskola. All rights reserved.</em></p>
`;
