// hooks/useAuth.ts
import { useEffect } from "react";
import { useSession, signIn } from "next-auth/react";

export const useAuth = () => {
  const { data: session, status } = useSession();

  useEffect(() => {
    if (status === "loading") return; // Do nothing while loading
    if (!session) signIn(); // Redirect to sign in if not authenticated
  }, [session, status]);
};
