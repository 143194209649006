import { Box, Flex } from "@chakra-ui/react";
import { RiWhatsappFill } from "react-icons/ri";
import { siteConfig } from "../configuration/config";
import { MdSupportAgent } from "react-icons/md";

interface Props {
  supportLink: string;
}

/**
 * Note: component outside of Chakra context provide, hence the color formatting
 */
export function SupportBubble(props: Props) {
  return (
    <Flex
      position="fixed"
      target="_blank"
      as="a"
      href={props.supportLink}
      backgroundColor="var(--chakra-colors-brand-primary-main)"
      w="50px"
      h="50px"
      borderRadius={"100%"}
      right={20}
      bottom={20}
      alignItems="center"
      justifyContent="center"
      zIndex={10}
      _hover={{ backgroundColor: "var(--chakra-colors-brand-primary-900)" }}
    >
      <MdSupportAgent color={"var(--chakra-colors-brand-primary-mainContrast)"} fontSize="20px" />
    </Flex>
  );
}
