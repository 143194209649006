import { SiteConfig } from "../../types";

export const _config: SiteConfig = {
  isDefault: false,
  showTidio: false,
  serviceName: "Lingoprof AI",
  appLogoPath: "/partner/lingoprof/logo.png",
  appLogoHeight: "100px",
  landingLogoPath: "/partner/lingoprof/logo.png",
  landingLogoHeight: "60px",
  favicon: "/partner/lingoprof/pwa-icon.png",
  pwaIcons: [
    {
      src: "/partner/lingoprof/pwa-icon.png",
      type: "image/png",
      sizes: "192x192",
    },
  ],
  overrideLanding: true,
  useTokenAuth: false,
  reviewLink: null,
  hubFixedPrice: true,
  hubFacilitatorCanManage: true,
  /// uiLanguage: "pt-BR",
  adminEmail: "contact@germanofcourse.com",
  adminPhoneNumber: "(0030)6908250297",
  emailsSender: "info@lang-services.com",
  emailInvitationSubject: "You have been invited to Lingoprof AI!",
  emailInvitationText: `
  You have been invited to Lingoprof AI! 🎉
  
  We have created a user for you with the following details:
  Email: {email}
  Password: {password}

  Follow this link to accept the invitation:
  {link}
  `,
  copyright: "Lingoprof AI",
  // practiceLanguageLock: "en-US",
  // userLanguageLock: "id-ID",
  showLanguageGuides: true,
  subscriptionDefaults: {
    currency: "eur",
    price: 500,
    characterLimit: 1_050_000,
    productId: "lingoprof-1",
  },
  bioPrompt: `
    You are a language tutor working for Lingoprof AI. 

    About the school:
    We are the Angelopoulos Angelos German and English Language School. Since 1996. Mr. Angelopoulos Angelos has been the director of an exclusive examination center and has personally guided over 1600 students to obtain Goethe-Institut B2-C2 degrees. Since 2018 we now exclusively teach live via ZOOM and Lingoprof AI. And we created through www.germanofcourse.com our own teaching method. We guide you to obtain the most recognized degrees of the Goethe-Institut. In addition, our courses and method follow the Common European Framework of Reference for Languages.
  `,
  avatarExcludeList: [
    "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyouNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, AnaNeural)",
  ],
  siteColors: {
    special: {
      desktopSidebar: "#000",
      desktopSidebarBorderColor: "#333",
      desktopSidebarText: "#fff",
      desktopSidebarTextHover: "#3e82b2",
      chatAsideMenu: "#333",
      speakInputIdle: "var(--chakra-colors-brand-secondary-700)",
      speakInputRecording: "var(--chakra-colors-brand-secondary-900)",
    },
  },
  colors: {
    light: {
      main: "#ececec",
      alt: "#ececec",
    },
    primary: {
      main: "#92d1f0",
      mainContrast: "#000",
      "50": "#f0f9fd",
      "100": "#c3e6f7",
      "200": "#90ceed",
      "300": "#79adc6",
      "400": "#6c9bb1",
      "500": "#92d1f0",
      "600": "#4d6e7e",
      "700": "#3e5865",
      "800": "#344a55",
      "900": "#25363e",
    },
    secondary: {
      main: "#3e82b2",
      mainContrast: "#fff",
      "50": "#f0f9fd",
      "100": "#c3e6f7",
      "200": "#90ceed",
      "300": "#79adc6",
      "400": "#6c9bb1",
      "500": "#3e82b2",
      "600": "#3e82b2",
      "700": "#3e82b2",
      "800": "#344a55",
      "900": "#25363e",
    },

    tertiary: {
      main: "#5a49f8",
      mainContrast: "#000",
      "50": "#f7f6ff",
      "100": "#e0ddfe",
      "200": "#c5bffd",
      "300": "#a49afb",
      "400": "#9085fa",
      "500": "#7667f9",
      "600": "#5c4cf8",
      "700": "#4a3ccb",
      "800": "#3e33ac",
      "900": "#2d257d",
    },
    // https://palette.saas-ui.dev/
    gray: {
      "50": "#fafafa",
      "100": "#f1f1f1",
      "200": "#e7e7e7",
      "300": "#d4d4d4",
      "400": "#adadad",
      "500": "#7f7f7f",
      "600": "#545454",
      "700": "#373737",
      "800": "#202020",
      "900": "#191919",
    },
    blue: {
      "50": "#f0f7fd",
      "100": "#c8e1f7",
      "200": "#9fcaf2",
      "300": "#84b0d9",
      "400": "#7197ba",
      "500": "#6182a0",
      "600": "#516d86",
      "700": "#3e5366",
      "800": "#334453",
      "900": "#293744",
    },
    purple: {
      "50": "#f8f6fe",
      "100": "#e4dafa",
      "200": "#d0bff6",
      "300": "#b396f1",
      "400": "#9b80d3",
      "500": "#7c67a9",
      "600": "#67558c",
      "700": "#544673",
      "800": "#45395e",
      "900": "#332a45",
    },
    pink: {
      "50": "#fef5fa",
      "100": "#fad8e9",
      "200": "#f5b7d8",
      "300": "#e98ebd",
      "400": "#cc7ca5",
      "500": "#a86688",
      "600": "#905775",
      "700": "#75475f",
      "800": "#5b374a",
      "900": "#422836",
    },
    orange: {
      "50": "#fefaf6",
      "100": "#faeadc",
      "200": "#f5d3b3",
      "300": "#e1b389",
      "400": "#c29b76",
      "500": "#a78566",
      "600": "#8d7056",
      "700": "#705944",
      "800": "#584636",
      "900": "#483a2c",
    },
    yellow: {
      "50": "#fffefc",
      "100": "#fcf9eb",
      "200": "#f7edc3",
      "300": "#f0df94",
      "400": "#dccc86",
      "500": "#b5a86e",
      "600": "#918658",
      "700": "#716945",
      "800": "#544e33",
      "900": "#46412a",
    },
    green: {
      "50": "#f4fdf8",
      "100": "#c1f7db",
      "200": "#8de8b8",
      "300": "#7ecfa4",
      "400": "#6eb590",
      "500": "#5e9b7c",
      "600": "#4e8066",
      "700": "#3d644f",
      "800": "#325241",
      "900": "#294335",
    },
    teal: {
      "50": "#eefdfc",
      "100": "#b1f4f3",
      "200": "#8be4e2",
      "300": "#7ccbc9",
      "400": "#69adab",
      "500": "#599391",
      "600": "#487776",
      "700": "#385c5c",
      "800": "#2f4d4c",
      "900": "#263f3f",
    },
    cyan: {
      "50": "#f2fcfd",
      "100": "#c8f0f7",
      "200": "#aee9f4",
      "300": "#91e1ef",
      "400": "#7cbfcb",
      "500": "#71b0ba",
      "600": "#669ea8",
      "700": "#54838b",
      "800": "#456b72",
      "900": "#365358",
    },
  },
};
