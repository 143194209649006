import { useMemo } from "react";
import {
  checkRightToLeftLanguage,
  checkRightToLeftText,
} from "../utils/check-right-to-left-language";
import { useSettings } from "./use-settings";

interface Props {
  text?: string;
}

export function useRighToLeftLanguage(props: Props) {
  const { settings } = useSettings();

  const isRightToLeftText = useMemo(() => {
    return settings.practiceLanguage && props.text
      ? checkRightToLeftText(props.text, settings.practiceLanguage)
      : false;
  }, [props.text, settings.practiceLanguage]);

  const isRightToLeftPracticeLanguage = useMemo(() => {
    return settings.practiceLanguage ? checkRightToLeftLanguage(settings.practiceLanguage) : false;
  }, [settings.practiceLanguage]);

  return { isRightToLeftText, isRightToLeftPracticeLanguage };
}
