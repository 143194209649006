import { curatedLangList } from "../services/speech/static-lang-list";

// E.g. "en_US" --> "en-US"
export const localeToLangCode = (locale: string) => {
  return locale.replace("_", "-");
};

// E.g. "en_US" --> "US"
export const localeToCountryKey = (locale: string) => {
  const regex = /_(.*)/;
  const match = locale.match(regex);

  if (!match) {
    throw new Error(`Invalid locale: ${locale}`);
  }

  return match[1];
};

// E.g. "en-US" --> "US"
export const langCodeToCountryKey = (langCode: string) => {
  const locale = langCode.replace("-", "_");

  return localeToCountryKey(locale);
};

// E.g. "US" --> "en_US"
export const countryKeyToLocale = (countryKey: string) => {
  const res = curatedLangList.find(([k]) => k === countryKey);

  if (!res) {
    throw new Error(`Invalid country key: ${countryKey}`);
  }

  const langCode = res[1];

  return langCodeToLocale(langCode);
};

// E.g. "US" --> "en-US"
export const countryKeyToLangCode = (countryKey: string, selectorLabels: string[][]) => {
  const res = selectorLabels.find(([locale]) => localeToCountryKey(locale) === countryKey);
  const locale = res ? res[0] : "en_US";

  return locale.replace("_", "-");
};

// E.g. "en-US" --> "en_US"
export const langCodeToLocale = (langCode?: string) => {
  return langCode?.replace("-", "_") || "en_US";
};

// E.g. "en_US" -- > "en"
export const localeToBaseLangCode = (locale: string) => {
  return locale.split("_")[0];
};
