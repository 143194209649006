const l = {
  "base.and": "그리고",
  "base.toc": "약관",
  "base.privacyPolicy": "개인정보 처리방침",
  "base.createAccount": "계정 만들기",
  "base.signin": "로그인",
  "base.signup": "가입하기",
  "base.logOut": "로그아웃",
  "authentication.description": "로그인하거나 계정을 만드세요",
  "invitation.wrongEmailTitle": "이미 로그인됨",
  "invitation.wrongEmailText":
    "다른 계정에 이미 로그인한 것 같습니다. 로그아웃하고 다시 시도해 주세요.",
  "invitation.invalidLinkTitle": "유효하지 않은 초대",
  "invitation.invalidLinkText": "초대 링크가 유효하지 않습니다.",
  "invitation.completeSignup": "다음을 위해 가입을 완료해 주세요: ",
  "invitation.completeSignupDescription":
    "확인 이메일이 귀하의 주소로 전송되었습니다. 수신함에서 보이지 않는 경우 스팸 폴더를 확인해 주세요.",
  "invitation.completeSignupButton": "가입 완료",
  "invitation.mustSignupTitle": "귀하께서는 다음에 초대받았습니다: ",
  "invitation.signInToAccept":
    "초대를 수락하려면 로그인해 주세요. 귀하가 받은 초대 이메일에는 임시 비밀번호가 포함되어 있습니다.",
  "invitation.acceptTermsOnSignup": "로그인할 때 자동으로 우리 약관에 동의합니다.",
  "invitation.accept": "초대를 수락하려면 로그인",
  "invitation.failed":
    "초대 수락에 실패했습니다. 로그아웃하고 로그인 시도해 보세요 - 또는 관리자에게 다시 초대해 달라고 요청하세요.",
  "invitation.accepted": "초대 수락됨",
  "invitation.emailNotVerified":
    "이메일이 확인되지 않았습니다. 이메일을 확인하고 다시 시도해 주세요.",
  "country.venezuela": "베네수엘라",
  "country.japan": "일본",
  "country.algeria": "알제리",
  "country.france": "프랑스",
  "country.spain": "스페인",
  "country.china": "중국",
  "country.usa": "미국",
  "country.england": "영국",
  "country.brazil": "브라질",
  "country.italy": "이탈리아",
  "country.denmark": "덴마크",
  "head.title": "Talkio AI | AI와 함께하는 언어 연습",
  "landing.title1": "연습하기 ",
  "landing.title2": "언어 ",
  "landing.title3": "AI와 함께 ",
  "landing.subtitle1":
    "AI 기술을 활용하여 구술 언어 능력을 향상시키는 궁극적인 언어 훈련 앱입니다.",
  "landing.button.freeTrial": "7일 무료",
  "landing.creditCardNotice": "신용카드 필요 - 언제든지 취소 가능",
  "landing.text.voiceControlTeam": "Voice Control for ChatGPT 팀에서",
  "landing.text.trustedBy": "500,000명 이상의 사용자에게 신뢰받음",
  "landing.card.title.advancedAI": "고급 AI 기술로 구축됨",
  "landing.card.text.advancedAI":
    "Talkio AI는 고급 AI 기술로 구축되어, 음성을 통해 AI와 상호작용하여 구술 언어 능력을 훈련할 수 있습니다.",
  "landing.card.title.multipleLanguages": "다양한 언어 지원",
  "landing.card.text.multipleLanguages": "영어, 중국어, 프랑스어 등 다양한 언어 옵션을 제공합니다.",
  "landing.card.title.premiumVoice": "프리미엄 음성 기술",
  "landing.card.text.premiumVoice":
    "Talkio AI는 프리미엄 음성을 제공하며, 가장 인기 있는 언어에 대해 여러 방언을 지원합니다. 우리의 고급 언어 기술로 진정한 대화에 몰입하여 가장 중요한 방언에 대한 능숙함을 쌓을 수 있습니다.",
  "landing.sectionTitle.meetTutors": "AI 튜터 만나기",
  "landing.sectionText.moreThanTutors":
    "Talkio AI는 언어 여정을 지원하기 위해 독특한 인공지능 개성을 가진 400명 이상의 튜터가 있습니다.",
  "landing.text.andManyMore": "그리고 더 많은 것들",
  "landing.text.learnWithAITutors": "AI 튜터와 함께 배우기",
  "landing.text.wonderedAboutTutor":
    "언제 어디서나 개인 언어 튜터가 있다는 것이 궁금하신가요? Talkio AI에서 우리는 이 꿈을 현실로 만듭니다. 우리의 AI 튜터는 구술 언어 능력을 향상시키기 위한 완벽한 동반자입니다. 고급 AI 기술로 구동되어 인간의 상호작용과 대화를 모방하여 몰입형 언어 학습 경험을 제공합니다.",
  "landing.text.rememberLanguageLearning":
    "언어 학습은 문법과 어휘를 넘어서 자신을 자신있게 표현하는 것입니다. Talkio AI의 똑똑한 튜터와 함께 말하고, 연습하고, 언어 목표를 정복하세요!",
  "landing.sectionTitle.featureSpotlight": "기능 주목",
  "landing.sectionText.featureSpotlight":
    "언어 학습을 몰입도 높은 경험으로 바꾸기 위해 설계된 다양한 기능을 탐색해 보세요.",
  "landing.text.safeEnvironment": "안전하고 지원하는 환경",
  "landing.text.supportiveEnvironment":
    "우리 앱은 자신있게 말할 수 있는 안전하고 지원하는 환경을 제공합니다. Talkio AI와 함께 AI 기술의 도움으로 의미 있는 대화를 나누고 유창함을 얻고 학습하는 언어로 자신을 표현할 수 있습니다.",
  "landing.sectionTitle.insights": "인사이트",
  "landing.sectionText.realStories": "Talkio AI의 실제 사용자들의 이야기",
  "landing.sectionTitle.faq": "자주 묻는 질문",
  "landing.sectionText.faq": "Talkio AI에 대한 자주 묻는 질문에 대한 답변",
  "landing.sectionTitle.pricing": "가격",
  "landing.sectionText.unlockPotential":
    "Talkio로 언어 잠재력을 잠금 해제하세요. 마스터리가 시작됩니다.",
  "landing.sectionText.offersForPartners": "학교 및 팀을 위한 계획도 제공",
  "landing.imageAlt.landing": "랜딩",
  "landing.imageAlt.laurelLeft": "월계관 왼쪽",
  "landing.imageAlt.laurelRight": "월계관 오른쪽",
  "landing.imageAlt.speakingWithAI": "AI와 이야기하는 여성",
  "testimonial.text1":
    "저는 개인적으로 Talkio AI를 사용하여 스페인어를 향상시켰고, 정말 훌륭한 옵션이라고 말씀드립니다.",
  "testimonial.text2":
    "Talkio AI는 매우 인상적인 도구이며 모든 언어 학습자의 도구 상자에 들어가야 합니다.",
  "testimonial.text3": "이 도구 Talkio AI는 언어 학습을 위해 저를 놀라게 하고 있습니다.",
  "testimonial.text4": "Talkio AI - 정말 놀라운 제품입니다.",
  "feature.lifeLikeConversations": "AI 튜터와 사실적인 대화",
  "feature.pronunciationTool": "발음 연습 도구",
  "feature.detailedFeedback": "구술 능력에 대한 상세한 피드백",
  "feature.interactiveWordbook": "인터랙티브 단어장",
  "feature.supportsLanguages": "40개 이상의 언어 지원",
  "landing.text.voiceConversations": "음성 대화",
  "landing.text.voiceConversationsDescription": "우리 AI 튜터와 매력적인 음성 대화를 나누세요",
  "landing.text.pronunciation": "발음",
  "landing.text.pronunciationDescription": "단어별 피드백으로 발음을 평가하고 연습하세요",
  "landing.text.feedback": "피드백",
  "landing.text.feedbackDescription": "언어 능력에 대한 즉각적인 피드백과 개선 팁을 받으세요",
  "landing.text.multipleLanguages": "다중 언어 및 방언",
  "landing.text.multipleLanguagesDescription":
    "구술 능력을 연습할 언어 및 방언을 134개 선택할 수 있습니다",
  "landing.text.progress": "진척",
  "landing.text.progressDescription":
    "진척 상황을 추적하고 언어 학습 여정에 대한 주간 보고서를 받으세요",
  "landing.text.wordbook": "단어장",
  "landing.text.wordbookDescription": "훈련 중에 배운 단어를 저장하고 다시 방문하세요",
  "landing.text.streaks": "연속 학습",
  "landing.text.streaksDescription": "잇따른 학습으로 학습 습관을 기르세요",
  "landing.text.crosstalk": "상호 대화",
  "landing.text.crosstalkDescription": "튜터와 모국어와 배우고 있는 언어로 대화하세요",
  "landing.text.translations": "번역",
  "landing.text.translationsDescription": "대화에서 도움을 받기 위한 즉각적인 번역",
  "landing.text.featureSpotlight": "기능 주목",
  "landing.text.featureSpotlightDescription":
    "언어 학습을 몰입도 높은 여정으로 바꾸기 위해 설계된 기능을 탐색하세요",
  "feature.practiceSpeaking.title": "말하기 연습",
  "feature.practiceSpeaking.bullet1": "AI 튜터와 사실적인 대화",
  "feature.practiceSpeaking.bullet2": "음성 우선 상호작용",
  "feature.practiceSpeaking.bullet3": "대화 도움",
  "feature.practiceSpeaking.bullet4": "반복 및 재시도",
  "feature.worldOfLanguages.title": "언어의 세계",
  "feature.worldOfLanguages.bullet1": "40개 이상의 언어 지원",
  "feature.worldOfLanguages.bullet2": "즉각적인 번역",
  "feature.worldOfLanguages.bullet3": "다국어 음성 인식",
  "feature.worldOfLanguages.bullet4": "스크립트 언어의 로마자 표기",
  "feature.actionableFeedback.title": "실용적인 피드백",
  "feature.actionableFeedback.bullet1": "문장 점수",
  "feature.actionableFeedback.bullet2": "개선 전략",
  "feature.actionableFeedback.bullet3": "AI 제안",
  "feature.actionableFeedback.bullet4": "발음 정확성 점수",
  "feature.pronunciationPractice.title": "발음 연습",
  "feature.pronunciationPractice.bullet1": "단어별 점수",
  "feature.pronunciationPractice.bullet2": "발음 평가",
  "feature.pronunciationPractice.bullet3": "정확성, 완전성 및 유창성",
  "feature.pronunciationPractice.bullet4": "반복 및 재시도",
  "feature.interactiveWordbook.title": "인터랙티브 단어장",
  "feature.interactiveWordbook.bullet1": "배운 단어 추적 유지",
  "feature.interactiveWordbook.bullet2": "튜터 반복을 통한 강화된 암기",
  "feature.interactiveWordbook.bullet3": "번역 및 소리 내기",
  "feature.interactiveWordbook.bullet4": "문장 예시",
  "feature.button.startTrial": "무료 체험 시작",
  "faq.question1": "무료 요금제가 있나요?",
  "faq.answer1":
    "아니요, 모든 요금제는 유료입니다. 그러나 언제든지 취소할 수 있는 7일 무료 체험이 있습니다.",
  "faq.question2": "Talkio AI는 누구의 제품인가요?",
  "faq.answer2.part1": "Talkio AI는",
  "faq.answer2.part2":
    "유럽 연합에 등록된 회사입니다. Aidia ApS는 모든 사용자에게 안전하고 보안된 경험을 제공하기 위해 일반 데이터 보호 규정(GDPR)을 준수합니다.",
  "faq.question3": "Talkio AI는 초보자에게 적합한가요?",
  "faq.answer3":
    "Talkio AI는 기본 언어 지식을 갖춘 사람들에게 가장 적합하며, 구술 능력을 향상하려고 하는 분들에게 적합합니다. 초보자를 위해 언어 학습시작 가이드가 준비되어 있습니다. 이 가이드는 선택한 언어의 기본적인 측면을 다루어 Talkio AI의 모든 혜택을 누릴 수 있도록 준비합니다. 무료이며 언어 여정을 시작하는 학습자에게 훌륭한 도구입니다.",
  "faq.button.gettingStartedGuide": "무료로 학습 시작 가이드를 시도해 보세요",
  "faq.question4": "결제가 안전한가요?",
  "faq.answer4":
    "네, 우리는 재무 처리를 위해 Stripe를 사용합니다. 귀하의 신용 카드 정보는 저장하지 않습니다.",
  "faq.question5": "내 데이터는 어디에 저장되나요?",
  "faq.answer5":
    "귀하의 데이터는 유럽 연합의 서버에 저장됩니다: Microsoft(음성 및 데이터), Auth0(인증), Stripe(결제). 게다가 OpenAI, Anthropic, Groq의 서비스를 이용하여 AI를 구동합니다. 이 데이터는 미국에서 처리됩니다.",
  "faq.question6": "환불 받을 수 있나요?",
  "faq.answer6":
    "무료 체험은 언제든지 취소할 수 있습니다. 하지만 체험이 종료된 후 서비스를 사용하지 않았다면 14일 이내에 환불해 드립니다.",
  "faq.question7": "어떤 결제 방법을 지원하나요?",
  "faq.answer7":
    "구독에 대해서는 주요 신용 카드와 PayPal을 지원합니다. 일회성 구매의 경우 WeChat, Przelewy24, Giropay, Alipay 등을 추가로 지원합니다. 지역에 따라 제공 여부가 달라질 수 있습니다.",
  "faq.question8": "Talkio AI는 모바일 앱이 있나요?",
  "faq.answer8":
    "Talkio는 진보된 웹 앱으로서 브라우저에서 설치할 수 있으며, 앱 스토어나 구글 플레이 스토어에서 다운로드할 필요가 없습니다. 안드로이드에서는 Talkio를 브라우저에서 사용할 때 가입 후 설치 버튼을 찾을 수 있습니다. iOS에서는 Safari에서 Talkio를 열고 공유 아이콘을 탭한 후 '홈 화면에 추가'를 선택하여 일반 앱처럼 설치할 수 있습니다.",
  "faq.question9": "어떤 브라우저를 사용할 수 있나요?",
  "faq.answer9":
    "Talkio AI는 Chrome, Firefox, Safari, Edge 등 모든 최신 브라우저에서 작동합니다. iPhone 및 Android의 모바일 브라우저에서도 작동합니다.",
  "faq.question10": "어떤 AI 모델을 사용하나요?",
  "faq.answer10":
    "Talkio AI는 OpenAI, Anthropic 및 Meta의 다양한 AI 모델을 혼합하여 사용합니다. 각 모델은 특정 작업에서 탁월하며 함께 강력한 AI 튜터를 생성합니다.",
  "faq.question11": "Talkio AI는 ChatGPT의 음성 제어와 어떻게 다릅니까?",
  "faq.answer11":
    "Talkio AI는 언어 학습을 위해 맞춤화된 ChatGPT와의 일관된 경험을 제공합니다. 또한 발음 평가, 프리미엄 음성 기술, 다국어 지원, 선별된 교육 자료, 통합 피드백, 번역 등을 포함한 더 많은 기능을 제공합니다.",
  "faq.question12": "어떻게 연락하나요?",
  "faq.answer12": "hello@talkio.ai로 이메일을 보내주시면 됩니다.",
  "layout.serviceBar.text": "덴마크에서 설립되었습니다. 귀하의 개인정보를 존중합니다.",
  "layout.serviceBar.community": "전 세계 언어 학습자 커뮤니티에 참여하세요",
  "layout.nav.pricing": "가격",
  "layout.nav.faq": "자주 묻는 질문",
  "layout.nav.languages": "언어",
  "layout.nav.blog": "블로그",
  "layout.nav.schools": "학교를 위한",
  "layout.nav.teams": "팀을 위한",
  "layout.nav.affiliateProgram": "제휴 프로그램",
  "layout.nav.signIn": "로그인",
  "layout.nav.goToApp": "앱으로 가기",
  "layout.nav.menu": "메뉴",
  "layout.cta.description":
    "AI 기술을 활용하여 구술 언어 능력을 향상시키는 궁극적인 언어 훈련 앱입니다.",
  "layout.cta.button": "Talkio AI 시도해 보기",
  "layout.footer.pages": "페이지",
  "layout.footer.learningHub": "학습 허브",
  "layout.footer.blog": "블로그",
  "layout.footer.about": "소개",
  "layout.footer.affiliateProgram": "제휴 프로그램",
  "layout.footer.schools": "Talkio for Schools",
  "layout.footer.teams": "Talkio for Teams",
  "layout.footer.languageGuide": "초보자를 위한 무료 언어 가이드",
  "layout.footer.termsConditions": "약관",
  "layout.footer.privacyPolicy": "개인정보 처리방침",
  "layout.footer.logout": "로그아웃",
  "layout.footer.login": "로그인",
  "layout.footer.blogPosts": "블로그 게시물",
  "layout.footer.blogPost1": "Talkio AI의 새로운 기능: 대화형 언어 가이드",
  "layout.footer.blogPost2": "Talkio가 세 가지 새로운 맞춤형 GPT를 통합합니다.",
  "layout.footer.blogPost3": "Talkio AI로 IELTS 스피킹 기술 향상: 단계별 가이드",
  "layout.footer.blogPost4": "구술 영어 연습을 위한 3가지 효과적인 방법",
  "layout.footer.blogPost5": "영어 능력 테스트에서의 성공",
  "layout.footer.blogPost6": "Talkio AI로 TOEFL 스피킹 마스터하기: 필수 가이드",
  "layout.footer.contact": "연락처",
  "layout.footer.rights": "모든 권리 보유.",
  "layout.footer.illustrationCredits": "앞쪽 페이지 일러스트레이션: vectorjuice",
  "pricing.description.perMonthBilledOnce": "한 달 기준 - 일회 청구",
  "pricing.description.perMonthBilledQuarterly": "한 달 기준 - 분기별 청구",
  "pricing.description.perMonthBilledYearly": "한 달 기준 - 연간 청구",
  "pricing.description.perMonth": "한 달 기준",
  "pricing.button.buyNow": "7일 환불 보장이 있는 지금 구매하기",
  "pricing.button.startFreeTrial": "무료 체험 시작하기",
  "pricing.features.refund": "7일 전액 환불 - 언제든지 취소 가능",
  "pricing.features.freeTrial": "7일 무료 체험 - 언제든지 취소 가능",
  "pricing.features.supportsLanguages": "40개 이상의 언어 지원",
  "pricing.features.voiceConversations": "실제같은 음성 대화",
  "pricing.features.instantFeedback": "즉각적인 피드백",
  "pricing.features.weeklyProgressReport": "주간 진척 보고서",
  "pricing.features.pronunciationPractice": "발음 연습",
  "pricing.features.interactiveWordbook": "인터랙티브 단어장",
  "pricing.features.speechRecognition": "다국어 음성 인식",
  "pricing.features.extraFeatures": "역할 놀이, 주제, 퀴즈 등!",
  "accessRequest.pageNotExist": "404 페이지가 존재하지 않습니다.",
  "accessRequest.createAccountTitle": "시작하기 위해 계정 생성",
  "accessRequest.createAccountMessage":
    "참여하려면 먼저 계정을 생성해야 합니다. 가입 후 시스템에 대한 접근을 요청할 수 있습니다.",
  "accessRequest.createAccountButton": "계정 만들기",
  "accessRequest.alreadyHaveAccount": "이미 계정이 있습니까?",
  "accessRequest.signIn": "로그인",
  "accessRequest.grantedTitle": "축하합니다!",
  "accessRequest.grantedMessage":
    "{serviceName}에 성공적으로 접근하셨습니다. 아래를 클릭하여 앱을 사용하세요.",
  "accessRequest.goToApp": "앱으로 가기",
  "accessRequest.accountExists": "이미 존재하는 계정",
  "accessRequest.accountExistsMessage":
    "귀하는 이미 이 계정의 회원입니다. 계속하려면 로그아웃하고 다른 이메일로 가입해 주세요.",
  "accessRequest.logOut": "로그아웃",
  "accessRequest.requestAccessTitle": "계속하기 위해 접근 요청",
  "accessRequest.requestAccessMessage": "귀하의 요청은 승인되어야 접근할 수 있습니다.",
  "accessRequest.checkBackLater": "나중에 다시 확인하여 접근 권한이 부여되었는지 확인해주세요.",
  "accessRequest.submitRequest": "요청 제출",
  "accessRequest.pending": "접근 요청 대기 중",
};

export default l;
