export const invitationTemplate = `<!DOCTYPE html>
<html
  xmlns="http://www.w3.org/1999/xhtml"
  xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office"
>
  <head>
    <title></title>
    
  </head>
  <body>
    <p style="text-align: center;"><span style="font-size:11pt;"><span style="border:none;"><img src="https://ai.inicijativa.biz/partner/inicijativa/email-logo.jpeg" style="width: 472px; height: 175.487px;" width="472" height="175.487"></span></span></p>
<p><br></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Aptos",sans-serif;'>Dear Student,</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Aptos",sans-serif;'>Exciting news!&nbsp;<span style='font-family:"Segoe UI Emoji",sans-serif;'>🎉</span> Your personal AI English tutor is ready to transform your language skills.</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Aptos",sans-serif;'><span style='font-family:"Segoe UI Emoji",sans-serif;'>🔗</span> Access Now: {link}</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Aptos",sans-serif;'>Login:<br>&nbsp;<span style='font-family:"Segoe UI Emoji",sans-serif;'>📧</span> Email: {email}<br>&nbsp;<span style='font-family:"Segoe UI Emoji",sans-serif;'>🔑</span> Password: {password}</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Aptos",sans-serif;'>Why you&apos;ll love it:<br>&nbsp;&bull;&nbsp;<span style='font-family:"Segoe UI Emoji",sans-serif;'>🕰️</span> Available 24/7 - practice anytime, anywhere<br>&nbsp;&bull;&nbsp;<span style='font-family:"Segoe UI Emoji",sans-serif;'>🎭</span> Endless scenarios - from casual chats to job interviews<br>&nbsp;&bull;&nbsp;<span style='font-family:"Segoe UI Emoji",sans-serif;'>🎯</span> Instant feedback on pronunciation and fluency<br>&nbsp;&bull;&nbsp;<span style='font-family:"Segoe UI Emoji",sans-serif;'>📚</span> Interactive wordbook to boost your vocabulary<br>&nbsp;&bull;&nbsp;<span style='font-family:"Segoe UI Emoji",sans-serif;'>🌍</span> Customizable for your unique learning journey</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Aptos",sans-serif;'>Don&apos;t wait - log in now and start your journey to English fluency!</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Aptos",sans-serif;'>&nbsp;</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Aptos",sans-serif;'>Happy learning!&nbsp;<span style='font-family:"Segoe UI Emoji",sans-serif;'>📚😊</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Aptos",sans-serif;'>The Inicijativa Team</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Aptos",sans-serif;'>P.S. Need help? Just reply to this email. <span style='font-family:"Segoe UI Emoji",sans-serif;'>💬</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Aptos",sans-serif;'>&nbsp;</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Aptos",sans-serif;'>&nbsp;</p>
<p style="margin: 0cm 0cm 8pt; font-size: 11pt; font-family: Aptos, sans-serif; text-align: center;"><img width="207" src="https://ai.inicijativa.biz/partner/inicijativa/email-logo.jpeg" alt="Obraz zawierający tekst, Czcionka, Grafika, logoOpis wygenerowany automatycznie" height="77"></p>
<p style="margin: 0cm 0cm 8pt; font-size: 11pt; font-family: Aptos, sans-serif; text-align: center;">@ <a href="mailto:inicijativa@inicijativa.biz">inicijativa@inicijativa.biz</a> ; &nbsp;00 38 514818542&nbsp;</p>
<p style="margin: 0cm 0cm 8pt; font-size: 11pt; font-family: Aptos, sans-serif; text-align: center;"><em>Copyright (C) 2024 Inicijativa. All rights reserved.</em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Aptos",sans-serif;'>&nbsp;</p>
  </body>
</html>
`;
