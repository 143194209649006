export const invitationTemplate = `<!DOCTYPE html>
<html
  xmlns="http://www.w3.org/1999/xhtml"
  xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office"
>
  <head>
    <title></title>
    <!--[if !mso]><!-->
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <!--<![endif]-->
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <style type="text/css">
      #outlook a {
        padding: 0;
      }
      body {
        margin: 0;
        padding: 0;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
      }
      table,
      td {
        border-collapse: collapse;
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
      }
      img {
        border: 0;
        height: auto;
        line-height: 100%;
        outline: none;
        text-decoration: none;
        -ms-interpolation-mode: bicubic;
      }
      p {
        display: block;
        margin: 13px 0;
      }
    </style>
    <!--[if mso]>
      <noscript>
        <xml>
          <o:OfficeDocumentSettings>
            <o:AllowPNG />
            <o:PixelsPerInch>96</o:PixelsPerInch>
          </o:OfficeDocumentSettings>
        </xml>
      </noscript>
    <![endif]-->
    <!--[if lte mso 11]>
      <style type="text/css">
        .mj-outlook-group-fix {
          width: 100% !important;
        }
      </style>
    <![endif]-->

    <!--[if !mso]><!-->
    <link
      href="https://fonts.googleapis.com/css?family=Ubuntu:400,700"
      rel="stylesheet"
      type="text/css"
    />
    <style type="text/css">
      @import url(https://fonts.googleapis.com/css?family=Ubuntu:400,700);
    </style>
    <!--<![endif]-->

    <style type="text/css">
      @media only screen and (min-width: 480px) {
        .mj-column-per-100 {
          width: 100% !important;
          max-width: 100%;
        }
      }
    </style>
    <style media="screen and (min-width:480px)">
      .moz-text-html .mj-column-per-100 {
        width: 100% !important;
        max-width: 100%;
      }
    </style>

    <style type="text/css">
      @media only screen and (max-width: 479px) {
        table.mj-full-width-mobile {
          width: 100% !important;
        }
        td.mj-full-width-mobile {
          width: auto !important;
        }
      }
    </style>
    <style type="text/css">
      .hide_on_mobile {
        display: none !important;
      }
      @media only screen and (min-width: 480px) {
        .hide_on_mobile {
          display: block !important;
        }
      }
      .hide_section_on_mobile {
        display: none !important;
      }
      @media only screen and (min-width: 480px) {
        .hide_section_on_mobile {
          display: table !important;
        }

        div.hide_section_on_mobile {
          display: block !important;
        }
      }
      .hide_on_desktop {
        display: block !important;
      }
      @media only screen and (min-width: 480px) {
        .hide_on_desktop {
          display: none !important;
        }
      }
      .hide_section_on_desktop {
        display: table !important;
        width: 100%;
      }
      @media only screen and (min-width: 480px) {
        .hide_section_on_desktop {
          display: none !important;
        }
      }

      p,
      h1,
      h2,
      h3 {
        margin: 0px;
      }

      ul,
      li,
      ol {
        font-size: 11px;
        font-family: Ubuntu, Helvetica, Arial;
      }

      a {
        text-decoration: none;
        color: inherit;
      }

      @media only screen and (max-width: 480px) {
        .mj-column-per-100 {
          width: 100% !important;
          max-width: 100% !important;
        }
        .mj-column-per-100 > .mj-column-per-100 {
          width: 100% !important;
          max-width: 100% !important;
        }
      }
    </style>
  </head>
  <body style="max-width: 700px; margin: 0 auto; padding: 20px">
    <p class="sample" style="color: red; text-align: center">
      <img
        src="https://www.ai.elingwista.com/partner/elingwista/logo.png"
        width="306"
        height="74"
        style="margin: 20px 0"
      />
    </p>
    <div style="text-align: center; color: rgb(0, 0, 0); font-size: 13px">
      Welcome to AI-powered language tutor
    </div>
    <div style="text-align: center; color: rgb(0, 0, 0); font-size: 13px">
      Już tylko krok dzieli Cię od lekcji z Twoim lektorem wspomaganym
      technologią AI
    </div>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      <br />
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      <br />
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      Cześć!
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      <br />
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      Już wkr&oacute;tce rozpoczniesz swoją przygodę językową z Twoim nowym
      prywatnym lektorem AI.
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      &nbsp;
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      Zaloguj się na swoje konto i dokonaj wstępnych ustawień &ndash; języka
      ojczystego, języka kt&oacute;rego chcesz się uczyć oraz wyb&oacute;r
      poziomu językowego. Następnie wybierz ulubionego tutora z listy
      os&oacute;b i przejdź do rozmowy wybierając jeden z moduł&oacute;w nauki:
      dzienna sesja, scenka rodzajowa, tematyczna, quiz lub dyskusja. Pamiętaj,
      że możesz także zalogować się z telefonu oraz tabletu, by jeszcze łatwiej
      uczyć się kiedy tylko masz na to ochotę.
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      <br />
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      Twoje dane do logowania:
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      Email: {email}
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      Hasło: {password}
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      Adres logowania: {link}
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      <br />
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      A zatem do dzieła!
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      &nbsp;
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      <br />
    </p>

    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      Hi!
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      <br />
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      You are about to take on a language journey with your new private AI
      tutor.
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      <br />
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      Log in to your account and go to settings &ndash; choose your language and
      practice language as well as language proficiency. Then pick up your
      favourite tutor from the list and initiate conversation using one of the
      following modes: daily session, role play, topic, quiz, case discussion.
      Remember that you can also log in from your mobile or tablet to practice
      your language whenever you like!
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      <br />
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      You login details:
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      Email: {email}
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      Password: {password}
    </p>
    <p
      style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 8pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 16px;
        font-family: 'Aptos', sans-serif;
      "
    >
      Login adress: {link}
    </p>
    <p><br /></p>
    <p>
      <span
        style="
          font-size: 16px;
          line-height: 115%;
          font-family: 'Aptos', sans-serif;
        "
        >Let&rsquo;s roll!</span
      >
    </p>
    <p><br /></p>
    <p style="text-align: center"><br /></p>
    <p style="text-align: center">
      <img
        src="https://www.ai.elingwista.com/partner/elingwista/logo.png"
        style="width: 158px; margin-bottom: 10px"
        width="158"
        height="38.2092"
      />
    </p>
    <div style="text-align: center; color: rgb(0, 0, 0); font-size: 13px">
      @
      <u
        ><a
          href="mailto:biuro@elingwista.com"
          target="_blank"
          style="color: rgb(60, 97, 170)"
          >biuro@elingwista.com</a
        ></u
      >
      &nbsp;<u
        ><a
          target="_blank"
          href="http://www.elingwista.com/"
          rel="noopener"
          style="color: rgb(60, 97, 170)"
          >www.elingwista.com</a
        ></u
      >
    </div>
    <div style="text-align: center; color: rgb(0, 0, 0); font-size: 13px">
      <br />
    </div>
    <div style="text-align: center; color: rgb(0, 0, 0); font-size: 13px">
      <em style="text-align: center; font-size: 16px"
        ><span style="font-size: 12px"
          >Copyright (C) 2024 eLingwista. All rights reserved.</span
        ></em
      >&nbsp;<br style="text-align: center; font-size: 16px" /><span
        style="text-align: center"
        >You are receiving this email because you opted in via our
        website.</span
      >
    </div>
    <p><br /></p>
    <p><br /></p>
  </body>
</html>
`;
