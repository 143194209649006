import { useCallback, useEffect, useRef, useState } from "react";
import useStateRef from "react-usestateref";
import { useSettings } from "../../../hooks/use-settings";
import { useVoicePreference } from "../../../hooks/user-voice-preference";
import { BrowserVoiceInfo, getBrowserVoices } from "../../../services/speech/get-browser-voices";
import { ConversationMessage } from "../../../types/conversation";
import { readAloudFallbackForMac } from "../../../utils/browser-tts";
import { logger } from "../../../utils/logger";

interface Props {
  browserVoice: BrowserVoiceInfo | undefined;
  browserVoiceRef: React.MutableRefObject<BrowserVoiceInfo | undefined>;
}

export function useTextToSpeechBrowser(props: Props) {
  const { browserVoiceRef, browserVoice } = props;
  const { settings } = useSettings();

  const [isReadingLatest, setIsReadingLatest] = useState(false);

  const [readAloudMessage, setReadAloudMessage] = useState<ConversationMessage>();
  const [isLoadingAudioForMessageId, setIsLoadingAudioForMessageId] = useState<string>();

  const finishedReadingTimer = useRef<NodeJS.Timer>();
  const timeoutResumeInfinity = useRef<NodeJS.Timer>();
  const timeoutFailedToStart = useRef<NodeJS.Timer>();
  const timeoutPendingMessage = useRef<NodeJS.Timer>();
  const lastUtter = useRef<number>(0);
  const lastTimeout = useRef<number>(0);
  const lastUtterCharCount = useRef<number>(0);
  const lastTimeSinceLastUtter = useRef<number>(0);
  const readAloudSpeedRef = useRef(1);
  const isMsVoice = useRef(false);

  const speakQueue = useRef<{ message: ConversationMessage; isReadingLatest: boolean }[]>([]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Stop all ongoing speech synthesis when the page is about to unload
      speakQueue.current = [];
      window.speechSynthesis.cancel();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // Translate Azure speed to browser speed
    const speed = parseInt(settings.readAloudSpeed);

    if (!speed || isNaN(speed)) {
      readAloudSpeedRef.current = 1;
      return;
    }

    isMsVoice.current = Boolean(browserVoice?.speechSynthesisVoice.name.includes("Microsoft"));

    switch (speed) {
      case 1:
        readAloudSpeedRef.current = isMsVoice.current ? 0.65 : 0.75;
        break;

      case 2:
        readAloudSpeedRef.current = isMsVoice.current ? 0.85 : 0.9;
        break;

      case 3:
        readAloudSpeedRef.current = 1;
        break;
      case 4:
        readAloudSpeedRef.current = isMsVoice.current ? 1.25 : 1.15;
        break;
      case 5:
        readAloudSpeedRef.current = isMsVoice.current ? 1.4 : 1.3;
        break;

      default:
        readAloudSpeedRef.current = 1;
    }
  }, [settings.readAloudSpeed, browserVoice]);

  // https://stackoverflow.com/a/40508243/3052707
  const resumeInfinity = () => {
    if (!browserVoiceRef.current?.speechSynthesisVoice) {
      return;
    }

    if (isMsVoice.current) {
      logger.warn("Not resuming infinity for MS voices");
      return;
    }

    const [timeout, timeSinceLastUtter] = readAloudFallbackForMac(
      browserVoiceRef.current.speechSynthesisVoice.lang,
      readAloudSpeedRef.current,
      lastUtter.current,
      lastTimeout.current,
      lastUtterCharCount.current,
      lastTimeSinceLastUtter.current
    );

    clearTimeout(timeoutFailedToStart.current);
    lastTimeout.current = timeout;
    lastTimeSinceLastUtter.current = timeSinceLastUtter;
    window.speechSynthesis.pause();
    window.speechSynthesis.resume();
    timeoutResumeInfinity.current = setTimeout(resumeInfinity, 7000);
  };

  const executeSpeak = async () => {
    if (!browserVoiceRef.current?.speechSynthesisVoice) {
      logger.error("No browser voice available");
      return;
    }

    const queuedItem = speakQueue.current.shift();

    if (!queuedItem) {
      logger.warn("No queued item");
      return;
    }

    const utterThis = new SpeechSynthesisUtterance(queuedItem.message.content);
    utterThis.volume = 1;
    utterThis.voice = browserVoiceRef.current.speechSynthesisVoice;
    utterThis.rate = readAloudSpeedRef.current;

    const audioEnd = () => {
      logger.info("Audio ended " + queuedItem.message.id);

      clearTimeout(timeoutFailedToStart.current);
      clearTimeout(timeoutResumeInfinity.current);

      if (speakQueue.current.length > 0) {
        executeSpeak();
      } else {
        setIsReadingLatest(false);
        setReadAloudMessage(undefined);
        setIsLoadingAudioForMessageId((prev) => {
          if (prev === queuedItem.message.id) {
            return undefined;
          }

          return prev;
        });
      }
    };

    utterThis.addEventListener("error", (event) => {
      logger.error(`Read aloud error ${event.error}`, event);
      audioEnd();
    });

    utterThis.addEventListener("start", () => {
      lastUtter.current = Date.now();
      lastUtterCharCount.current = utterThis.text.length;

      clearTimeout(timeoutPendingMessage.current);
      resumeInfinity();

      logger.info(`Start audio for ID: ${queuedItem.message.id}`);
      setIsReadingLatest(queuedItem.isReadingLatest);
      setReadAloudMessage(queuedItem.message);
      setIsLoadingAudioForMessageId(undefined);
    });

    utterThis.addEventListener("end", function () {
      logger.info("Speech has ended");
      audioEnd();
    });

    window.speechSynthesis.speak(utterThis);
  };

  const startAiSpeak = async (
    message: ConversationMessage,
    isReadingLatest = false,
    overrideVoice: string | undefined = undefined
  ) => {
    await stopAiSpeak(false);

    if (isMsVoice.current) {
      speakQueue.current.push({ message, isReadingLatest });
    } else {
      message.content.split(".").forEach((sentence) => {
        const chunkedMessage = { ...message, content: sentence };
        speakQueue.current.push({ message: chunkedMessage, isReadingLatest });
      });
    }

    executeSpeak();
  };

  const stopAiSpeak = (clearLoader = true) => {
    return new Promise<void>((resolve) => {
      const timer = setTimeout(() => onResolve(), 1000);

      const onResolve = () => {
        clearTimeout(timer);
        clearTimeout(timeoutFailedToStart.current);
        clearTimeout(timeoutResumeInfinity.current);

        if (clearLoader) {
          logger.info("clearLoader");
          setIsLoadingAudioForMessageId(undefined);
        }

        setReadAloudMessage(undefined);
        resolve();
      };

      speakQueue.current = [];
      window.speechSynthesis.cancel();
    });
  };

  return {
    isReadingLatest,
    readAloudMessage,
    isLoadingAudioForMessageId,
    startAiSpeak,
    stopAiSpeak,
  };
}
