export const invitationTemplate = `<p class="sample" style="color: red; text-align: center">
  <img
    src="https://ai.say-so.pl/partner/say-so/email-1.jpeg"
    width="538"
    height="110"
  />
</p>
<div style="text-align: center; color: rgb(0, 0, 0); font-size: 13px">
  <br />
</div>
<p
  style="
    margin: 0cm 0cm 8pt;
    line-height: 115%;
    font-size: 16px;
    font-family: Aptos, sans-serif;
    text-align: center;
  "
>
  Witaj w aplikacji SaySo!
</p>
<p
  style="
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-weight: 400;
    letter-spacing: normal;
    orphans: 2;
    text-align: start;
    text-indent: 0px;
    text-transform: none;
    widows: 2;
    word-spacing: 0px;
    -webkit-text-stroke-width: 0px;
    white-space: normal;
    text-decoration-thickness: initial;
    text-decoration-style: initial;
    text-decoration-color: initial;
    margin: 0cm 0cm 8pt;
    line-height: 18.4px;
    font-family: Aptos, sans-serif;
  "
>
  <br />
</p>
<p
  style="
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-weight: 400;
    letter-spacing: normal;
    orphans: 2;
    text-align: center;
    text-indent: 0px;
    text-transform: none;
    widows: 2;
    word-spacing: 0px;
    -webkit-text-stroke-width: 0px;
    white-space: normal;
    text-decoration-thickness: initial;
    text-decoration-style: initial;
    text-decoration-color: initial;
    margin: 0cm 0cm 8pt;
    line-height: 18.4px;
    font-family: Aptos, sans-serif;
  "
>
  Cieszymy się, że dołączyłeś do naszej społeczności.
</p>
<p
  style="
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-weight: 400;
    letter-spacing: normal;
    orphans: 2;
    text-align: start;
    text-indent: 0px;
    text-transform: none;
    widows: 2;
    word-spacing: 0px;
    -webkit-text-stroke-width: 0px;
    white-space: normal;
    text-decoration-thickness: initial;
    text-decoration-style: initial;
    text-decoration-color: initial;
    margin: 0cm 0cm 8pt;
    line-height: 18.4px;
    font-family: Aptos, sans-serif;
  "
>
  <br />
</p>
<p
  style="
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-weight: 400;
    letter-spacing: normal;
    orphans: 2;
    text-align: center;
    text-indent: 0px;
    text-transform: none;
    widows: 2;
    word-spacing: 0px;
    -webkit-text-stroke-width: 0px;
    white-space: normal;
    text-decoration-thickness: initial;
    text-decoration-style: initial;
    text-decoration-color: initial;
    margin: 0cm 0cm 8pt;
    line-height: 18.4px;
    font-family: Aptos, sans-serif;
  "
>
  Poniżej znajdziesz swoje dane logowania
</p>
<p
  style="
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-weight: 400;
    letter-spacing: normal;
    orphans: 2;
    text-align: center;
    text-indent: 0px;
    text-transform: none;
    widows: 2;
    word-spacing: 0px;
    -webkit-text-stroke-width: 0px;
    white-space: normal;
    text-decoration-thickness: initial;
    text-decoration-style: initial;
    text-decoration-color: initial;
    margin: 0cm 0cm 8pt;
    line-height: 18.4px;
    font-family: Aptos, sans-serif;
  "
>
  Email: {email}
</p>
<p
  style="
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-weight: 400;
    letter-spacing: normal;
    orphans: 2;
    text-align: center;
    text-indent: 0px;
    text-transform: none;
    widows: 2;
    word-spacing: 0px;
    -webkit-text-stroke-width: 0px;
    white-space: normal;
    text-decoration-thickness: initial;
    text-decoration-style: initial;
    text-decoration-color: initial;
    margin: 0cm 0cm 8pt;
    line-height: 18.4px;
    font-family: Aptos, sans-serif;
  "
>
  Hasło: {password}
</p>
<p
  style="
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-weight: 400;
    letter-spacing: normal;
    orphans: 2;
    text-align: center;
    text-indent: 0px;
    text-transform: none;
    widows: 2;
    word-spacing: 0px;
    -webkit-text-stroke-width: 0px;
    white-space: normal;
    text-decoration-thickness: initial;
    text-decoration-style: initial;
    text-decoration-color: initial;
    margin: 0cm 0cm 8pt;
    line-height: 18.4px;
    font-family: Aptos, sans-serif;
  "
>
  Adres logowania: {link}
</p>
<p
  style="
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-weight: 400;
    letter-spacing: normal;
    orphans: 2;
    text-align: start;
    text-indent: 0px;
    text-transform: none;
    widows: 2;
    word-spacing: 0px;
    -webkit-text-stroke-width: 0px;
    white-space: normal;
    text-decoration-thickness: initial;
    text-decoration-style: initial;
    text-decoration-color: initial;
    margin: 0cm 0cm 8pt;
    line-height: 18.4px;
    font-family: Aptos, sans-serif;
  "
>
  <br />
</p>
<p
  style="
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-weight: 400;
    letter-spacing: normal;
    orphans: 2;
    text-align: start;
    text-indent: 0px;
    text-transform: none;
    widows: 2;
    word-spacing: 0px;
    -webkit-text-stroke-width: 0px;
    white-space: normal;
    text-decoration-thickness: initial;
    text-decoration-style: initial;
    text-decoration-color: initial;
    margin: 0cm 0cm 8pt;
    line-height: 18.4px;
    font-family: Aptos, sans-serif;
  "
>
  <br />
</p>
<p
  style="
    margin: 0cm 0cm 8pt;
    line-height: 115%;
    font-size: 16px;
    font-family: Aptos, sans-serif;
    text-align: center;
  "
>
  <img
    src="https://ai.say-so.pl/partner/say-so/email-2.jpeg"
    width="157"
    height="50"
  />
</p>
<p
  style="
    margin: 0cm 0cm 8pt;
    line-height: 115%;
    font-size: 16px;
    font-family: Aptos, sans-serif;
    text-align: center;
  "
>
  <span
    style="
      color: rgb(0, 0, 0);
      font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
        'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
      font-size: 13px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
      display: inline !important;
      float: none;
    "
    >@&nbsp;</span
  ><u
    style="
      color: rgb(0, 0, 0);
      font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
        'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
      font-size: 13px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
    "
    ><a
      href="mailto:info@say-so.pl"
      class="mailto-link"
      target="_blank"
      style="
        word-break: break-word;
        text-decoration: underline;
        color: rgb(60, 97, 170);
      "
      >info@say-so.pl</a
    ></u
  ><span
    style="
      color: rgb(0, 0, 0);
      font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
        'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
      font-size: 13px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
      display: inline !important;
      float: none;
    "
    >&nbsp;&nbsp;</span
  ><u
    style="
      color: rgb(0, 0, 0);
      font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
        'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
      font-size: 13px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
    "
    ><a
      target="_blank"
      href="http://www.say-so.pl/"
      rel="noopener"
      style="
        word-break: break-word;
        text-decoration: underline;
        color: rgb(60, 97, 170);
      "
      >www.say-so.pl</a
    ></u
  ><br
    style="
      color: rgb(0, 0, 0);
      font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
        'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
      font-size: 13px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  /><em
    style="
      color: rgb(0, 0, 0);
      font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
        'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
      font-size: 13px;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
    >Copyright (C) 2024 SaySo. All rights reserved.</em
  >
</p>
`;
