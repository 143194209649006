const l = {
  "base.and": "e",
  "base.toc": "Termos e Condições",
  "base.privacyPolicy": "Política de Privacidade",
  "base.createAccount": "Criar conta",
  "base.signin": "Entrar",
  "base.signup": "Inscrever-se",
  "base.logOut": "Sair",
  "authentication.description": "Entre ou crie uma conta",
  "invitation.wrongEmailTitle": "Já está logado",
  "invitation.wrongEmailText":
    "Parece que você já está logado em outra conta. Por favor, saia e tente novamente.",
  "invitation.invalidLinkTitle": "Convite inválido",
  "invitation.invalidLinkText": "O link do convite é inválido",
  "invitation.completeSignup": "Por favor, complete a inscrição para ",
  "invitation.completeSignupDescription":
    "Um e-mail de verificação foi enviado para seu endereço. Se não o vir na sua caixa de entrada, verifique sua pasta de spam.",
  "invitation.completeSignupButton": "Completar Inscrição",
  "invitation.mustSignupTitle": "Você foi convidado para ",
  "invitation.signInToAccept":
    "Por favor, entre para aceitar o convite. No e-mail de convite que você recebeu, encontrará uma senha temporária.",
  "invitation.acceptTermsOnSignup": "Ao entrar, você aceita automaticamente nossos",
  "invitation.accept": "Entrar para Aceitar o Convite",
  "invitation.failed":
    "Falha ao aceitar o convite. Por favor, tente sair e entrar novamente - ou peça ao administrador para convidá-lo novamente.",
  "invitation.accepted": "Convite aceito",
  "invitation.emailNotVerified":
    "E-mail não verificado. Por favor, verifique seu e-mail e tente novamente.",
  "country.venezuela": "Venezuela",
  "country.japan": "Japão",
  "country.algeria": "Argélia",
  "country.france": "França",
  "country.spain": "Espanha",
  "country.china": "China",
  "country.usa": "EUA",
  "country.england": "Inglaterra",
  "country.brazil": "Brasil",
  "country.italy": "Itália",
  "country.denmark": "Dinamarca",
  "head.title": "Talkio AI | Pratique Idioma com IA",
  "landing.title1": "Pratique ",
  "landing.title2": "Idioma ",
  "landing.title3": "com IA ",
  "landing.subtitle1":
    "O aplicativo de treinamento de idiomas definitivo que usa tecnologia de IA para ajudá-lo a aprimorar suas habilidades orais.",
  "landing.button.freeTrial": "7 dias grátis",
  "landing.creditCardNotice": "Cartão de crédito necessário - cancele a qualquer momento",
  "landing.text.voiceControlTeam": "Da equipe por trás do Controle de Voz para ChatGPT",
  "landing.text.trustedBy": "Confiado por mais de 500.000 usuários",
  "landing.card.title.advancedAI": "Construído com Tecnologia de IA Avançada",
  "landing.card.text.advancedAI":
    "Talkio AI é construído com tecnologia de IA avançada e permite que você interaja com a IA por meio da voz para treinar suas habilidades de fala.",
  "landing.card.title.multipleLanguages": "Suporta Múltiplos Idiomas",
  "landing.card.text.multipleLanguages":
    "Oferecemos uma ampla gama de opções de idiomas, incluindo inglês, chinês, francês e vários outros idiomas.",
  "landing.card.title.premiumVoice": "Tecnologia de Voz Premium",
  "landing.card.text.premiumVoice":
    "Talkio AI oferece vozes premium e suporta vários dialetos para os idiomas mais populares. Com nossa tecnologia avançada de idiomas, você pode se imergir em conversas autênticas e obter proficiência nos dialetos que mais importam para você.",
  "landing.sectionTitle.meetTutors": "Conheça os Tutores de IA",
  "landing.sectionText.moreThanTutors":
    "Talkio AI tem mais de 400 tutores com personalidades artificiais únicas prontos para ajudá-lo em sua jornada de idiomas.",
  "landing.text.andManyMore": "E muitos mais",
  "landing.text.learnWithAITutors": "Aprenda com Tutores de IA",
  "landing.text.wonderedAboutTutor":
    "Já se perguntou como seria ter um tutor pessoal de idiomas disponível a qualquer hora, em qualquer lugar? No Talkio AI, transformamos esse sonho em realidade. Nossos Tutores de IA são os companheiros perfeitos para melhorar suas habilidades orais. Impulsionados por tecnologia de IA avançada, eles imitam a interação e a conversa humanas, oferecendo uma experiência de aprendizagem de idiomas envolvente e eficaz.",
  "landing.text.rememberLanguageLearning":
    "Lembre-se, aprender idiomas é mais do que apenas gramática e vocabulário; trata-se de se expressar com confiança. Com os tutores inteligentes do Talkio AI, você pode falar, praticar e conquistar seus objetivos linguísticos!",
  "landing.sectionTitle.featureSpotlight": "Destaques de Recursos",
  "landing.sectionText.featureSpotlight":
    "Navegue pela nossa gama de recursos, projetados para transformar seu aprendizado de idiomas em uma jornada imersiva e envolvente.",
  "landing.text.safeEnvironment": "Ambiente Seguro e Apoiado",
  "landing.text.supportiveEnvironment":
    "Nosso aplicativo fornece um ambiente seguro e apoiador para você praticar a fala com confiança. Com o Talkio AI, você pode ter conversas significativas com a ajuda da tecnologia de IA, permitindo que você adquira fluência e se expresse no idioma que está aprendendo.",
  "landing.sectionTitle.insights": "Percepções",
  "landing.sectionText.realStories": "Histórias reais de usuários reais do Talkio AI",
  "landing.sectionTitle.faq": "Perguntas Frequentes",
  "landing.sectionText.faq": "Respostas a perguntas frequentes sobre o Talkio AI",
  "landing.sectionTitle.pricing": "Preços",
  "landing.sectionText.unlockPotential":
    "Desbloqueie seu potencial linguístico com o Talkio. Sua jornada para a maestria começa agora.",
  "landing.sectionText.offersForPartners": "Nós também oferecemos planos para escolas e equipes",
  "landing.imageAlt.landing": "Página inicial",
  "landing.imageAlt.laurelLeft": "Loureiro esquerdo",
  "landing.imageAlt.laurelRight": "Loureiro direito",
  "landing.imageAlt.speakingWithAI": "Mulher falando com IA",
  "testimonial.text1":
    "Eu usei pessoalmente o Talkio AI para melhorar meu espanhol, e deixe-me dizer, é uma opção incrível.",
  "testimonial.text2":
    "Talkio AI é uma ferramenta muito impressionante e deve ser incluída no arsenal de todo aprendiz de idiomas.",
  "testimonial.text3": "Essa ferramenta Talkio AI está me impressionando para aprender idiomas.",
  "testimonial.text4": "Talkio AI - é um produto incrível.",
  "feature.lifeLikeConversations": "Conversas realistas com tutores de IA",
  "feature.pronunciationTool": "Ferramenta de prática de pronúncia",
  "feature.detailedFeedback": "Feedback detalhado sobre suas habilidades de fala",
  "feature.interactiveWordbook": "Wordbook interativo",
  "feature.supportsLanguages": "Suporta mais de 40 idiomas",
  "landing.text.voiceConversations": "Conversas por Voz",
  "landing.text.voiceConversationsDescription":
    "Tenha conversas envolventes por voz com nossos tutores de IA",
  "landing.text.pronunciation": "Pronúncia",
  "landing.text.pronunciationDescription":
    "Avalie e pratique sua pronúncia com feedback palavra por palavra",
  "landing.text.feedback": "Feedback",
  "landing.text.feedbackDescription":
    "Receba feedback instantâneo sobre suas habilidades linguísticas e dicas sobre como melhorar",
  "landing.text.multipleLanguages": "Múltiplos Idiomas e Dialetos",
  "landing.text.multipleLanguagesDescription":
    "Escolha entre 134 idiomas e dialetos para praticar suas habilidades de fala",
  "landing.text.progress": "Progresso",
  "landing.text.progressDescription":
    "Acompanhe seu progresso e receba relatórios semanais sobre sua jornada de aprendizado de idiomas",
  "landing.text.wordbook": "Wordbook",
  "landing.text.wordbookDescription": "Salve e revise palavras aprendidas durante o treinamento",
  "landing.text.streaks": "Sequências",
  "landing.text.streaksDescription":
    "Mantenha o controle do seu progresso com sequências para criar um hábito de aprendizado",
  "landing.text.crosstalk": "Conversação Cruzada",
  "landing.text.crosstalkDescription":
    "Comunique-se com o tutor na sua língua nativa e no idioma que você está aprendendo",
  "landing.text.translations": "Traduções",
  "landing.text.translationsDescription": "Traduções instantâneas para ajudá-lo em suas conversas",
  "landing.text.featureSpotlight": "Destaques de Recursos",
  "landing.text.featureSpotlightDescription":
    "Navegue pela nossa gama de recursos, projetados para transformar seu aprendizado de idiomas em uma jornada imersiva e envolvente",
  "feature.practiceSpeaking.title": "Pratique a fala",
  "feature.practiceSpeaking.bullet1": "Conversas realistas com um tutor de IA",
  "feature.practiceSpeaking.bullet2": "Interação de Voz Primer",
  "feature.practiceSpeaking.bullet3": "Ajuda de Conversação",
  "feature.practiceSpeaking.bullet4": "Repetir e Repetir",
  "feature.worldOfLanguages.title": "Um Mundo de Idiomas",
  "feature.worldOfLanguages.bullet1": "Suporta mais de 40 idiomas",
  "feature.worldOfLanguages.bullet2": "Traduções instantâneas",
  "feature.worldOfLanguages.bullet3": "Reconhecimento de Fala Multilíngue",
  "feature.worldOfLanguages.bullet4": "Romanização de Idiomas de Script",
  "feature.actionableFeedback.title": "Feedback Acionável",
  "feature.actionableFeedback.bullet1": "Pontuação de Sentenças",
  "feature.actionableFeedback.bullet2": "Estratégias de Melhoria",
  "feature.actionableFeedback.bullet3": "Sugestões de IA",
  "feature.actionableFeedback.bullet4": "Pontuação de Precisão na Fala",
  "feature.pronunciationPractice.title": "Prática de Pronúncia",
  "feature.pronunciationPractice.bullet1": "Pontuação Palavra por Palavra",
  "feature.pronunciationPractice.bullet2": "Avaliação de Pronúncia",
  "feature.pronunciationPractice.bullet3": "Precisão, Completação e Fluência",
  "feature.pronunciationPractice.bullet4": "Repetir e Repetir",
  "feature.interactiveWordbook.title": "Wordbook Interativo",
  "feature.interactiveWordbook.bullet1": "Acompanhe as Palavras que Você Aprende",
  "feature.interactiveWordbook.bullet2": "Memorização Reforçada através da Repetição do Tutor",
  "feature.interactiveWordbook.bullet3": "Tradução e Leitura em Voz Alta",
  "feature.interactiveWordbook.bullet4": "Exemplos de Frases",
  "feature.button.startTrial": "Comece seu Período de Teste Grátis",
  "faq.question1": "Há um plano gratuito?",
  "faq.answer1":
    "Não, todos os planos são pagos. No entanto, há um período de teste gratuito de 7 dias que pode ser cancelado a qualquer momento. Sem compromisso.",
  "faq.question2": "Quem está por trás do Talkio AI?",
  "faq.answer2.part1": "Talkio AI é um produto da",
  "faq.answer2.part2":
    "listada na União Europeia. A Aidia ApS cumpre o Regulamento Geral sobre a Proteção de Dados (GDPR) para fornecer uma experiência segura e protegida para todos os usuários.",
  "faq.question3": "O Talkio AI é adequado para iniciantes?",
  "faq.answer3":
    "Talkio AI é mais adequado para aqueles com conhecimento básico de um idioma, que buscam melhorar suas habilidades orais. Para iniciantes, desenvolvemos um guia introdutório para ajudá-lo a começar. Este guia cobre aspectos fundamentais do idioma escolhido, preparando-o para tirar pleno proveito do Talkio AI. É gratuito e uma ótima ferramenta para aprendizes iniciando sua jornada. Confira o guia e comece a aprimorar suas habilidades linguísticas de forma eficaz.",
  "faq.button.gettingStartedGuide": "Experimente o guia gratuito de início rápido",
  "faq.question4": "O pagamento é seguro?",
  "faq.answer4":
    "Sim, usamos o Stripe para processamento financeiro. Não armazenamos nenhuma informação do seu cartão de crédito.",
  "faq.question5": "Onde meus dados são armazenados?",
  "faq.answer5":
    "Seus dados são armazenados em servidores na União Europeia: Microsoft (voz e dados), Auth0 (autenticação), Stripe (pagamentos). Além disso, usamos serviços da OpenAI, Anthropic e Groq para alimentar a IA. Esses dados são processados nos Estados Unidos.",
  "faq.question6": "Posso obter um reembolso?",
  "faq.answer6":
    "O período de teste gratuito pode ser cancelado a qualquer momento; no entanto, se você esquecer de cancelar o teste, podemos reembolsá-lo em até 14 dias, desde que você não tenha usado o serviço após o término do teste. Entre em contato conosco pelo e-mail support@talkio.ai.",
  "faq.question7": "Quais métodos de pagamento você aceita?",
  "faq.answer7":
    "Para assinaturas, aceitamos todos os principais cartões de crédito e Paypal. Para compras únicas, também aceitamos WeChat, Przelewy24, Giropay, Alipay e mais. A disponibilidade depende de sua localização.",
  "faq.question8": "O Talkio tem um aplicativo móvel?",
  "faq.answer8":
    "Talkio é um Aplicativo Web Progressivo, o que significa que você pode instalá-lo pelo navegador sem ir à App Store ou Google Play. No Android, ao usar o Talkio no seu navegador, você encontrará um botão de instalação após se inscrever. No iOS, abra o Talkio no Safari, toque no ícone de compartilhamento e selecione 'Adicionar à Tela de Início' para instalá-lo como um aplicativo comum no seu telefone.",
  "faq.question9": "Quais navegadores posso usar?",
  "faq.answer9":
    "Talkio AI funciona em todos os navegadores modernos, incluindo Chrome, Firefox, Safari e Edge. Também funciona em navegadores móveis em iPhone e Android.",
  "faq.question10": "Quais modelos de IA vocês usam?",
  "faq.answer10":
    "Talkio AI usa uma mistura de diferentes modelos de IA da OpenAI, Anthropic e Meta. Cada modelo tem uma tarefa específica em que se destaca e juntos criam um poderoso tutor de IA.",
  "faq.question11": "Como o Talkio AI é diferente do Controle de Voz para ChatGPT?",
  "faq.answer11":
    "Talkio AI oferece uma experiência coerente com o ChatGPT personalizado para aprendizado de idiomas. Também oferece mais recursos, incluindo avaliação de pronúncia, tecnologias de voz premium, suporte multilíngue, materiais de treinamento selecionados, feedback integrado e traduções.",
  "faq.question12": "Como posso contatá-los?",
  "faq.answer12": "Sinta-se à vontade para enviar um e-mail para hello@talkio.ai",
  "layout.serviceBar.text": "Fundado na Dinamarca. Respeitamos sua privacidade.",
  "layout.serviceBar.community": "Junte-se a uma comunidade mundial de aprendizes de idiomas",
  "layout.nav.pricing": "Preços",
  "layout.nav.faq": "FAQ",
  "layout.nav.languages": "Idiomas",
  "layout.nav.blog": "Blog",
  "layout.nav.schools": "Para Escolas",
  "layout.nav.teams": "Para Equipes",
  "layout.nav.affiliateProgram": "Programa de afiliados",
  "layout.nav.signIn": "Entrar",
  "layout.nav.goToApp": "Ir para o aplicativo",
  "layout.nav.menu": "Menu",
  "layout.cta.description":
    "O aplicativo de treinamento de idiomas definitivo que usa tecnologia de IA para ajudá-lo a aprimorar suas habilidades orais.",
  "layout.cta.button": "Experimente o Talkio AI",
  "layout.footer.pages": "Páginas",
  "layout.footer.learningHub": "Hub de Aprendizado",
  "layout.footer.blog": "Blog",
  "layout.footer.about": "Sobre",
  "layout.footer.affiliateProgram": "Programa de afiliados",
  "layout.footer.schools": "Talkio para Escolas",
  "layout.footer.teams": "Talkio para Equipes",
  "layout.footer.languageGuide": "Guia de Idiomas Gratuito para Iniciantes",
  "layout.footer.termsConditions": "Termos e Condições",
  "layout.footer.privacyPolicy": "Política de Privacidade",
  "layout.footer.logout": "Sair",
  "layout.footer.login": "Entrar",
  "layout.footer.blogPosts": "Postagens do blog",
  "layout.footer.blogPost1": "Novo Recurso no Talkio AI: Guia de Idioma Interativo",
  "layout.footer.blogPost2": "Talkio Integra Três Novos GPTs Personalizados",
  "layout.footer.blogPost3":
    "Eleve suas Habilidades de Fala no IELTS com Talkio AI: Um Guia Passo a Passo",
  "layout.footer.blogPost4": "3 Maneiras Eficazes de Praticar o Inglês Oral",
  "layout.footer.blogPost5": "Passando nos Testes de Proficiência em Inglês",
  "layout.footer.blogPost6": "Dominando a Fala do TOEFL com Talkio AI: Seu Guia Essencial",
  "layout.footer.contact": "Contato",
  "layout.footer.rights": "Todos os direitos reservados.",
  "layout.footer.illustrationCredits": "Ilustrações da frontpage por vectorjuice",
  "pricing.description.perMonthBilledOnce": "por mês - cobrado uma vez",
  "pricing.description.perMonthBilledQuarterly": "por mês - cobrado trimestralmente",
  "pricing.description.perMonthBilledYearly": "por mês - cobrado anualmente",
  "pricing.description.perMonth": "por mês",
  "pricing.button.buyNow": "Compre Agora com Garantia de Reembolso de 7 Dias",
  "pricing.button.startFreeTrial": "Comece seu Período de Teste Grátis",
  "pricing.features.refund": "Reembolso completo em 7 dias - cancele a qualquer momento",
  "pricing.features.freeTrial": "Período de teste gratuito de 7 dias - cancele a qualquer momento",
  "pricing.features.supportsLanguages": "Suporta mais de 40 idiomas",
  "pricing.features.voiceConversations": "Conversas por voz realistas",
  "pricing.features.instantFeedback": "Feedback instantâneo",
  "pricing.features.weeklyProgressReport": "Relatório de progresso semanal",
  "pricing.features.pronunciationPractice": "Prática de pronúncia",
  "pricing.features.interactiveWordbook": "Wordbook interativo",
  "pricing.features.speechRecognition": "Reconhecimento de fala multilíngue",
  "pricing.features.extraFeatures": "Jogos de papéis, tópicos, questionários e mais!",
  "accessRequest.pageNotExist": "404 A página não existe.",
  "accessRequest.createAccountTitle": "Crie uma Conta para Começar",
  "accessRequest.createAccountMessage":
    "Para se juntar, você primeiro precisará criar uma conta. Após se inscrever, você pode solicitar acesso ao sistema.",
  "accessRequest.createAccountButton": "Crie sua conta",
  "accessRequest.alreadyHaveAccount": "Já tem uma conta?",
  "accessRequest.signIn": "Entrar",
  "accessRequest.grantedTitle": "Parabéns!",
  "accessRequest.grantedMessage":
    "Você ganhou acesso ao {serviceName}. Clique abaixo para começar a usar o aplicativo.",
  "accessRequest.goToApp": "Ir para o Aplicativo",
  "accessRequest.accountExists": "Conta Já Existe",
  "accessRequest.accountExistsMessage":
    "Você já é membro com esta conta. Para continuar, saia e inscreva-se com um e-mail diferente.",
  "accessRequest.logOut": "Sair",
  "accessRequest.requestAccessTitle": "Solicitar Acesso para Continuar",
  "accessRequest.requestAccessMessage":
    "Sua solicitação precisa ser aprovada antes que você possa obter acesso.",
  "accessRequest.checkBackLater":
    "Por favor, volte mais tarde para ver se seu acesso foi concedido.",
  "accessRequest.submitRequest": "Enviar Solicitação",
  "accessRequest.pending": "Solicitação de Acesso Pendente",
};

export default l;
