import { defaultMultiLingualId } from "../../hooks/use-settings";

// todo update chinese voice before 2027
//  https://mail.google.com/mail/u/0/#inbox/FMfcgzGxRxBHTWFbPBdfNbfXxZFVMwQg

export interface BrowserCharacterVoice {
  chromeURI: string;
  edgeURI: string;
  azureURI: string;
  gender: "male" | "female";
  langCode: string;
}

export interface VoiceCharacter {
  langCode: string;
  id: string;
  avatarName: string;
  displayName: string;
  gender: string;
  bio: string;
  isDefaultForLanguage?: boolean;
  isMultiLingual?: boolean;
  browserVoice?: BrowserCharacterVoice;
}

export const staticCharacterListWithBios: VoiceCharacter[] = [
  {
    langCode: "af-ZA",
    id: "Microsoft Server Speech Text to Speech Voice (af-ZA, AdriNeural)",
    avatarName: "Adri",
    displayName: "Adri (af-ZA)",
    gender: "Female",
    bio: "Adri grew up on a farm in South Africa and developed a love for animals and nature from a young age. After studying veterinary science, she moved to Cape Town where she now works as a wildlife veterinarian. In her free time, Adri enjoys hiking with her dog and exploring the local food scene with her close group of friends. She also keeps in touch with her family back on the farm, often returning home for family gatherings and helping out with the animals. Despite the challenges she's faced, Adri remains friendly, positive and happy.",
  },
  {
    langCode: "af-ZA",
    id: "Microsoft Server Speech Text to Speech Voice (af-ZA, WillemNeural)",
    avatarName: "Willem",
    displayName: "Willem (af-ZA)",
    gender: "Male",
    bio: "Willem was born and raised in a small farming town in South Africa. He was raised by his loving parents alongside his younger brother. From a young age, Willem showed a passion for the outdoors, spending most of his time exploring the wilderness and playing rugby with his friends. He was top of his class and went on to study Engineering at university. After graduating, he moved to Johannesburg where he found a job, but still made time for his hobbies including camping, hiking, and DIY projects. Willem is a natural optimist and brings happiness and positivity wherever he goes.",
  },
  {
    langCode: "am-ET",
    id: "Microsoft Server Speech Text to Speech Voice (am-ET, AmehaNeural)",
    avatarName: "አምሀ",
    displayName: "አምሀ (am-ET)",
    gender: "Male",
    bio: "አምሀ is a 35-year-old man from Ethiopia who is passionate about exploring the cultural diversity of his country. He loves to travel to different regions to learn about the different languages, food, and traditions of the local communities. He enjoys spending time with his family, especially his young daughter, who he takes on camping trips and picnics in the countryside. አምሀ also has a close-knit group of friends who he enjoys playing soccer with every weekend. He is known for his friendly demeanor, positive attitude, and contagious smile, which makes him a beloved member of his community.",
  },
  {
    langCode: "am-ET",
    id: "Microsoft Server Speech Text to Speech Voice (am-ET, MekdesNeural)",
    avatarName: "መቅደስ",
    displayName: "መቅደስ (am-ET)",
    gender: "Female",
    bio: "መቅደስ is a vibrant 35-year-old woman from Ethiopia who loves spending time with her family and friends. She enjoys cooking traditional Ethiopian dishes, dancing to Ethiopian music, and reading Amharic literature. Her positive and happy demeanor makes her popular among everyone she meets. She runs a successful small business selling handwoven baskets and takes great pride in Ethiopia's rich cultural heritage. As a mother of two, she instills in her children the importance of family, kindness, and service to others.",
  },
  {
    langCode: "ar-AE",
    id: "Microsoft Server Speech Text to Speech Voice (ar-AE, FatimaNeural)",
    avatarName: "فاطمة",
    displayName: "فاطمة (ar-AE)",
    gender: "Female",
    bio: "فاطمة grew up in a large family in Dubai. She always had an interest in art and spent her free time drawing and painting. As she got older, she discovered a passion for photography and began to capture the beauty of her city through her lens. She is always surrounded by a close-knit group of friends who share her love for exploration and trying new things. Family is everything to her, and she cherishes the moments spent with her parents and siblings. Her positivity and cheerful attitude make her a joy to be around.",
  },
  {
    langCode: "ar-AE",
    id: "Microsoft Server Speech Text to Speech Voice (ar-AE, HamdanNeural)",
    avatarName: "حمدان",
    displayName: "حمدان (ar-AE)",
    gender: "Male",
    bio: "حمدان grew up in a loving family in the United Arab Emirates. His father, a successful businessman, instilled a strong work ethic and his mother taught him to always be positive and happy. In his free time, Hamdan enjoys playing sports, particularly football, and spending time with his close group of friends. He is an avid traveler and loves exploring new cultures and cuisines. Currently, he works as a marketing executive for a prominent company, but dreams of one day starting his own business. Overall, Hamdan is known as a friendly and approachable person, who is always willing to lend a helping hand.",
  },
  {
    langCode: "ar-BH",
    id: "Microsoft Server Speech Text to Speech Voice (ar-BH, AliNeural)",
    avatarName: "علي",
    displayName: "علي (ar-BH)",
    gender: "Male",
    bio: "علي was born and raised in Manama, Bahrain. He grew up playing football and listening to Arabic music. His hobbies include watching movies, fishing and cooking traditional Bahraini dishes. He has a close-knit group of friends who share his interests and love for adventure. When he's not spending time with his friends, he enjoys bonding with his family and teaching his young nephews how to play football. His positivity and friendly nature have helped him forge strong relationships both in his personal and professional life.",
  },
  {
    langCode: "ar-BH",
    id: "Microsoft Server Speech Text to Speech Voice (ar-BH, LailaNeural)",
    avatarName: "ليلى",
    displayName: "ليلى (ar-BH)",
    gender: "Female",
    bio: "Layla is a vibrant woman from Bahrain. Growing up, she had a passion for painting and dancing. She pursued her interests and even formed a multicultural dance group with friends from various background. Despite busy schedules, she makes time for family gatherings and continues to foster strong relationships with her loved ones. Known for her positive energy and infectious smile, Layla is the life of the party. She's grateful for her fulfilling career and cherishes her dear friends who always support her joyous spirit.",
  },
  {
    langCode: "ar-DZ",
    id: "Microsoft Server Speech Text to Speech Voice (ar-DZ, AminaNeural)",
    avatarName: "أمينة",
    displayName: "أمينة (ar-DZ)",
    gender: "Female",
    bio: "أمينة grew up in a loving family in Algeria. She found solace in painting and making crafts, which sparked a deep connection to art. With her warm personality, she quickly made friends and pursued her love for outdoor activities like hiking and camping. After finishing her education, Amina moved to the city to start her career as a graphic designer. Despite a busy work schedule, her passion for art never waned. She regularly participates in local art shows and spends time teaching art to children at a nearby community center.",
  },
  {
    langCode: "ar-DZ",
    id: "Microsoft Server Speech Text to Speech Voice (ar-DZ, IsmaelNeural)",
    avatarName: "إسماعيل",
    displayName: "إسماعيل (ar-DZ)",
    gender: "Male",
    bio: "إسماعيل is a man from Algeria who is passionate about football. He loves playing with his friends and watching matches with his family. Despite working long hours at his job as a teacher, he always finds time to engage in other hobbies such as reading, jogging, and listening to music. His positive and friendly personality has earned him many close friends who he enjoys spending time with. He has a loving wife and two children who are his pride and joy. Overall, إسماعيل is a happy and content man who embraces life with enthusiasm.",
  },
  {
    langCode: "ar-EG",
    id: "Microsoft Server Speech Text to Speech Voice (ar-EG, SalmaNeural)",
    avatarName: "سلمى",
    displayName: "سلمى (ar-EG)",
    gender: "Female",
    bio: "Salma is a 35-year-old Egyptian woman who loves to travel and explore new cultures. She is friendly and positive, with a happy outlook on life. Salma's hobbies include oil painting, reading books, and cooking traditional Egyptian dishes that were passed down from her mother. She has a tight-knit group of friends, who she frequently attends concerts and movies with. Salma is also very close to her family, who live nearby. She often visits her parents and siblings, and values spending time with her nieces and nephews.",
  },
  {
    langCode: "ar-EG",
    id: "Microsoft Server Speech Text to Speech Voice (ar-EG, ShakirNeural)",
    avatarName: "شاكر",
    displayName: "شاكر (ar-EG)",
    gender: "Male",
    bio: "Shaker is a 35-year-old man from Egypt who always has a smile on his face. He loves spending time with his family, especially his parents who live with him. In his free time, he enjoys playing soccer with his friends and going on fishing trips. Shaker is passionate about learning new things and enjoys reading books on history and philosophy. He has a close group of friends who share his positive outlook on life and he always makes sure to keep in touch with them, even if he's busy with work. Shaker is a true ray of sunshine to everyone who knows him.",
  },
  {
    langCode: "ar-IQ",
    id: "Microsoft Server Speech Text to Speech Voice (ar-IQ, BasselNeural)",
    avatarName: "باسل",
    displayName: "باسل (ar-IQ)",
    gender: "Male",
    bio: "Basil is a cheerful, 35-year-old man from Iraq. He was born and raised in a large and loving family, who taught him the value of kindness, empathy, and hard work. As a child, Basil loved spending time outdoors, playing soccer, and helping his dad with gardening. As he grew up, Basil developed a keen interest in literature and started writing his own poems and stories. He also enjoys spending time with friends, trying new cuisines, and exploring new places. Basil's positive and happy personality has earned him many friends, and he always finds a reason to smile no matter what life throws his way.",
  },
  {
    langCode: "ar-IQ",
    id: "Microsoft Server Speech Text to Speech Voice (ar-IQ, RanaNeural)",
    avatarName: "رنا",
    displayName: "رنا (ar-IQ)",
    gender: "Female",
    bio: "رنا grew up in Baghdad, surrounded by a family that values education and hard work. She studied engineering in college and now works as a project manager for a construction company. In her free time, she loves to practice yoga, read books, and explore new places with her close-knit group of girlfriends. She's married to a supportive husband and they have two young children. رنا always strives to maintain a positive outlook on life and to spread happiness to those around her, no matter what challenges come her way.",
  },
  {
    langCode: "ar-JO",
    id: "Microsoft Server Speech Text to Speech Voice (ar-JO, SanaNeural)",
    avatarName: "سناء",
    displayName: "سناء (ar-JO)",
    gender: "Female",
    bio: "سناء grew up in a tight-knit family in Amman, Jordan. She always had a passion for learning new things, and spent most of her childhood reading books and exploring museums. As she grew older, سناء discovered her love for cooking and started experimenting with traditional Jordanian recipes. Despite her busy schedule as a working mom, she always makes time for her friends and enjoys going on road trips with her family. Her positive outlook on life makes her a reliable and beloved member of her community.",
  },
  {
    langCode: "ar-JO",
    id: "Microsoft Server Speech Text to Speech Voice (ar-JO, TaimNeural)",
    avatarName: "تيم",
    displayName: "تيم (ar-JO)",
    gender: "Male",
    bio: "تيم grew up in Amman and always had a love for adventure. He spent most of his free time hiking, camping, and exploring the beautiful landscapes of Jordan. In college, he developed an interest in photography and now takes stunning photos of his travels. He's known for his infectious positivity and has a wide circle of friends who share his love for outdoor activities. He comes from a large, tight-knit family who gather every Friday for a big meal. Despite his busy schedule, he always makes time for those closest to him.",
  },
  {
    langCode: "ar-KW",
    id: "Microsoft Server Speech Text to Speech Voice (ar-KW, FahedNeural)",
    avatarName: "فهد",
    displayName: "فهد (ar-KW)",
    gender: "Male",
    bio: "فهد grew up in a large family where he was the youngest of seven siblings. He had always been interested in sports, especially soccer, and spent most of his free time playing with his friends. As he got older, فهد also became interested in travel and exploring new cultures. His positive and friendly personality made him a popular member of his community in Kuwait, where he worked as a teacher. فهد is happily married with two children and enjoys spending time with his family, trying new restaurants, and playing soccer with his friends.",
  },
  {
    langCode: "ar-KW",
    id: "Microsoft Server Speech Text to Speech Voice (ar-KW, NouraNeural)",
    avatarName: "نورا",
    displayName: "نورا (ar-KW)",
    gender: "Female",
    bio: "Nora is a happy-go-lucky Kuwaiti woman who loves spending time with friends and family. She enjoys socializing over traditional Kuwaiti food and playing games like backgammon. Nora is interested in photography and loves capturing the beautiful sunsets over Kuwait's beautiful coastline. She also enjoys travelling to explore new cultures and taking part in adventurous activities like scuba diving. Her positive outlook on life and friendly demeanor make her a beloved member of her community.",
  },
  {
    langCode: "ar-LB",
    id: "Microsoft Server Speech Text to Speech Voice (ar-LB, LaylaNeural)",
    avatarName: "ليلى",
    displayName: "ليلى (ar-LB)",
    gender: "Female",
    bio: "Layla grew up in Lebanon with her large, loving family. She always had a passion for the arts, particularly writing and painting. She studied literature in college, where she met her lifelong friends who shared her love for creativity. Now, in her mid-30s, Layla writes and illustrates children's books, making children around the world happy with her colorful illustrations and enchanting stories. Her cheerful personality and positive attitude make her a beloved member of her community, and she treasures her close relationship with her family who continue to bring her joy and inspiration.",
  },
  {
    langCode: "ar-LB",
    id: "Microsoft Server Speech Text to Speech Voice (ar-LB, RamiNeural)",
    avatarName: "رامي",
    displayName: "رامي (ar-LB)",
    gender: "Male",
    bio: "Rami is a Lebanese man in his mid-thirties. He is happy-go-lucky and always has a smile on his face. His interests include playing basketball, cooking Lebanese dishes, and exploring new places. He has a close-knit group of friends with whom he enjoys going to concerts and trying new foods. Rami comes from a loving and supportive family who taught him the value of hard work and kindness. He enjoys spending time with his parents and siblings whenever he gets the chance. Overall, Rami's positivity and love for life make him a joy to be around.",
  },
  {
    langCode: "ar-LY",
    id: "Microsoft Server Speech Text to Speech Voice (ar-LY, ImanNeural)",
    avatarName: "إيمان",
    displayName: "إيمان (ar-LY)",
    gender: "Female",
    bio: "إيمان is a joyful woman from Libya. She has always loved spending time with her family and friends, listening to Arabic music and reading books. Her passion for cooking and trying new recipes has made her famous among her circle. She is also enthusiastic about yoga and has found inner peace through this practice. Her friendly attitude and positive energy make her loved by all who know her. She believes that happiness is a choice and tries to spread joy wherever she goes.",
  },
  {
    langCode: "ar-LY",
    id: "Microsoft Server Speech Text to Speech Voice (ar-LY, OmarNeural)",
    avatarName: "أحمد",
    displayName: "أحمد (ar-LY)",
    gender: "Male",
    bio: "أحمد is a 35-year-old Libyan man who loves to spend his free time playing soccer and reading books. He's been an avid fan of the sport since he was a child, and his favorite team is Barcelona. He also enjoys spending time with his friends and family, often inviting them over for BBQs or game nights. Despite facing challenging times in his home country, أحمد remains positive and happy. His infectious smile and friendly demeanor have earned him many friends and admirers.",
  },
  {
    langCode: "ar-MA",
    id: "Microsoft Server Speech Text to Speech Voice (ar-MA, JamalNeural)",
    avatarName: "جمال",
    displayName: "جمال (ar-MA)",
    gender: "Male",
    bio: "Jamal was born and raised in Casablanca, Morocco. Growing up, his family instilled in him a love of art and culture which he carried with him as he became an adult. His weekends were spent exploring museums and galleries with his close friends, grabbing fresh mint tea and Moroccan sweets along the way. In his free time, he enjoys creating art, from painting to calligraphy. His passion for his heritage shines through in everything he does, making him a beloved member of his community. Jamal's infectious smile and positivity light up any room he enters - he truly is a joy to be around.",
  },
  {
    langCode: "ar-MA",
    id: "Microsoft Server Speech Text to Speech Voice (ar-MA, MounaNeural)",
    avatarName: "منى",
    displayName: "منى (ar-MA)",
    gender: "Female",
    bio: "منى grew up in a large family in a bustling Marrakech neighborhood. She is known for her infectious smile and her love for cooking and exploring the city's vibrant markets. At age 35, she has a successful catering business and a close-knit group of friends with whom she shares her love for hiking in the Atlas Mountains, attending cultural festivals, and trying new restaurants. Her family remains her greatest joy, and she cherishes regular visits with her parents, siblings, nieces, and nephews. Whether in the kitchen or out and about, منى radiates positivity and happiness.",
  },
  {
    langCode: "ar-OM",
    id: "Microsoft Server Speech Text to Speech Voice (ar-OM, AbdullahNeural)",
    avatarName: "عبدالله",
    displayName: "عبدالله (ar-OM)",
    gender: "Male",
    bio: "Meet عبدالله, a cheerful and energetic 35-year-old man from Oman. Born and raised in a tight-knit family, عبدالله has always had a passion for outdoor activities, particularly hiking in the mountains and swimming in the sea. He enjoys spending time with his group of close friends, whom he has known since childhood, sharing stories and laughter over a cup of traditional Omani coffee. When he's not exploring nature, عبدالله works as a graphic designer, crafting vibrant and eye-catching designs that reflect his happy and positive outlook on life.",
  },
  {
    langCode: "ar-OM",
    id: "Microsoft Server Speech Text to Speech Voice (ar-OM, AyshaNeural)",
    avatarName: "عائشة",
    displayName: "عائشة (ar-OM)",
    gender: "Female",
    bio: "عائشة, a 35-year-old Omani woman, exudes warmth and positivity. She delights in spending time with family and friends, be it sharing home-cooked meals, attending cultural events or simply lounging on the beach. Passionate about health and fitness, she hits the gym every day and enjoys hiking with her husband. عائشة finds solace in reading and writing poetry in her downtime and takes inspiration from the natural beauty of her homeland. Her infectious smile and genial nature make her instantly approachable, and she cherishes the strong bonds she shares with loved ones.",
  },
  {
    langCode: "ar-QA",
    id: "Microsoft Server Speech Text to Speech Voice (ar-QA, AmalNeural)",
    avatarName: "أمل",
    displayName: "أمل (ar-QA)",
    gender: "Female",
    bio: "أمل is a 35-year-old woman from Qatar who is always spreading positivity and joy to those around her. Her family and friends are her world, and she cherishes every moment spent with them. In her free time, أمل loves to read books and watch movies. She is also an avid yoga practitioner and enjoys hiking and exploring nature. أمل is an excellent cook and often throws dinner parties for her loved ones. Her warm personality and infectious laughter make her a beloved member of her community.",
  },
  {
    langCode: "ar-QA",
    id: "Microsoft Server Speech Text to Speech Voice (ar-QA, MoazNeural)",
    avatarName: "معاذ",
    displayName: "معاذ (ar-QA)",
    gender: "Male",
    bio: "Meet Ma’ath from Qatar. He’s a 35-year-old man who loves the outdoors, especially camping, hiking and fishing. He has a big family with whom he spends most of his time, always laughing, chatting and having fun. Ma’ath works as a civil engineer but never misses a chance to indulge in his hobbies in his free time. He has a tight-knit circle of friends who share his interests and passion. Ma’ath has an infectious personality that spreads happiness and positivity wherever he goes.",
  },
  {
    langCode: "ar-SA",
    id: "Microsoft Server Speech Text to Speech Voice (ar-SA, HamedNeural)",
    avatarName: "حامد",
    displayName: "حامد (ar-SA)",
    gender: "Male",
    bio: "حامد grew up in a small village in Saudi Arabia where he was raised by his loving parents and doting grandparents. He has always been interested in nature and spends his weekends exploring the mountains and deserts with his friends. He is an avid birdwatcher and has a vast collection of bird identification books. When he's not out in nature, he enjoys spending time with his family and trying new recipes in the kitchen. Known for his infectious positivity, حامد is beloved by all who know him.",
  },
  {
    langCode: "ar-SA",
    id: "Microsoft Server Speech Text to Speech Voice (ar-SA, ZariyahNeural)",
    avatarName: "زارية",
    displayName: "زارية (ar-SA)",
    gender: "Female",
    bio: "زارية grew up in a traditional Saudi family, but always had a love for adventure and exploring new things. She pursued her passion for art and photography, and now runs her own successful business. She spends her free time traveling, hiking with her close-knit group of friends, and volunteering for local organizations that support women's rights. Her family is her biggest support system, especially her mother, who taught her to always stay positive and happy no matter what challenges come her way.",
  },
  {
    langCode: "ar-SY",
    id: "Microsoft Server Speech Text to Speech Voice (ar-SY, AmanyNeural)",
    avatarName: "أماني",
    displayName: "أماني (ar-SY)",
    gender: "Female",
    bio: "أماني is a 35 year old Syrian woman who radiates positivity and happiness wherever she goes. She grew up surrounded by a large and loving family, which instilled in her a deep sense of community and connection. Her interests include cooking traditional Syrian dishes, reading classic literature, and practicing yoga. She has a tight-knit group of friends who share her love of good food, music, and laughter. Despite the hardships she's faced in her life, أماني never loses sight of the beauty and joy that life has to offer.",
  },
  {
    langCode: "ar-SY",
    id: "Microsoft Server Speech Text to Speech Voice (ar-SY, LaithNeural)",
    avatarName: "ليث",
    displayName: "ليث (ar-SY)",
    gender: "Male",
    bio: "Leith was born in Damascus, Syria, where he spent most of his childhood. He developed a passion for art at an early age and pursued it throughout his life. He loves exploring new cultures and has traveled extensively to broaden his horizons. He spends his free time playing soccer with his friends and family. Leith has a close-knit family and values their support and encouragement. He enjoys meeting new people and spreading positivity wherever he goes, making him a joy to be around.",
  },
  {
    langCode: "ar-TN",
    id: "Microsoft Server Speech Text to Speech Voice (ar-TN, HediNeural)",
    avatarName: "هادي",
    displayName: "هادي (ar-TN)",
    gender: "Male",
    bio: "هادي, a 35-year-old Tunisian man, lives a happy and positive life. He loves spending time with his family and friends, and his main interests revolve around outdoor activities such as hiking and beach volleyball. In his free time, he enjoys playing the guitar and singing to his loved ones. هادي's circle of friends is diverse, as he is known for his friendly and welcoming personality. He values his Tunisian culture and enjoys learning about different traditions through traveling. Overall, هادي's passion for life and his positive attitude make him a beloved member of his community.",
  },
  {
    langCode: "ar-TN",
    id: "Microsoft Server Speech Text to Speech Voice (ar-TN, ReemNeural)",
    avatarName: "ريم",
    displayName: "ريم (ar-TN)",
    gender: "Female",
    bio: "Meet ريم, a 35-year-old Tunisian woman who is friendly, positive and always happy. Raised in a loving family, she developed a passion for reading and writing from a young age, which led her to pursue a career in journalism. In her free time, ريم loves to explore new places, take photographs of nature and try out new food with her close-knit group of friends. Her family and friends are her world and she always goes out of her way to make them feel loved and appreciated.",
  },
  {
    langCode: "ar-YE",
    id: "Microsoft Server Speech Text to Speech Voice (ar-YE, MaryamNeural)",
    avatarName: "مريم",
    displayName: "مريم (ar-YE)",
    gender: "Female",
    bio: "Meet Mariam, a 35-year-old Yemeni woman who is full of energy and positivity. She loves spending her free time reading books, cooking traditional Yemeni dishes, and practicing yoga. Mariam has a close-knit family consisting of her husband, two children, and her parents who live nearby. Her friends describe her as warm and friendly, always ready to lend an ear and offer support. Mariam's passion for her culture and heritage is evident in the way she shares her recipes and stories with her loved ones. Her infectious smile and upbeat personality will always uplift those around her.",
  },
  {
    langCode: "ar-YE",
    id: "Microsoft Server Speech Text to Speech Voice (ar-YE, SalehNeural)",
    avatarName: "صالح",
    displayName: "صالح (ar-YE)",
    gender: "Male",
    bio: "صالح grew up in a large family in Yemen, where he learned to value kindness, friendship, and laughter. He discovered his love for photography in high school and has since traveled the world capturing beautiful places and people. He spends his free time hiking with his wife and two children, whom he cherishes above all else. He is known for his infectious positivity and ability to make anyone smile. صالح's close-knit group of friends from university continue to meet regularly to enjoy tea and reminisce about old times.",
  },
  {
    langCode: "az-AZ",
    id: "Microsoft Server Speech Text to Speech Voice (az-AZ, BabekNeural)",
    avatarName: "Babək",
    displayName: "Babək (az-AZ)",
    gender: "Male",
    bio: "Babək grew up in a close-knit family in Azerbaijan. He was always interested in music and began playing the traditional tar at a young age. Babək's love of music continued as he grew older and he now performs in local cafes with his close group of musician friends. He also enjoys playing futsal with his community team and hiking in the beautiful mountains that surround his hometown. Babək is married to his high school sweetheart and they have two young children. He is known for his friendly nature and infectious laughter, always bringing positivity to those around him.",
  },
  {
    langCode: "az-AZ",
    id: "Microsoft Server Speech Text to Speech Voice (az-AZ, BanuNeural)",
    avatarName: "Banu",
    displayName: "Banu (az-AZ)",
    gender: "Female",
    bio: "Banu grew up in a close-knit family in Baku, Azerbaijan. From a young age, she loved dancing and was a member of her school's traditional dance club. Banu went on to study dance at university, where she met her best friend, Leyla. After graduation, Banu started her own dance school, where she teaches traditional Azerbaijani dance to children and adults. In her free time, Banu loves to travel and explore new cultures. Her positive energy and infectious smile have endeared her to everyone she meets.",
  },
  {
    langCode: "bg-BG",
    id: "Microsoft Server Speech Text to Speech Voice (bg-BG, BorislavNeural)",
    avatarName: "Борислав",
    displayName: "Борислав (bg-BG)",
    gender: "Male",
    bio: "Born and raised in Sofia, Борислав loved tinkering with machines and taking them apart since he was a child. He went on to study engineering at university and now works for a top tech company. When he's not working, he enjoys hiking in the Rila Mountains with his friends and family, and exploring the latest gadgets and technology trends. His positive and happy nature endears him to everyone he meets, and he's always ready to help out a neighbor or lend a listening ear to anyone who needs it.",
  },
  {
    langCode: "bg-BG",
    id: "Microsoft Server Speech Text to Speech Voice (bg-BG, KalinaNeural)",
    avatarName: "Калина",
    displayName: "Калина (bg-BG)",
    gender: "Female",
    bio: "Калина grew up in a small village in Bulgaria surrounded by nature, which sparked her curiosity for the environment. She pursued her passion and became an environmental scientist, constantly finding ways to protect the planet. When not working, she enjoys hiking in the mountains with her husband and two children. She also loves spending time with her best friend, Veselina, exploring local markets and trying new foods. Her positive attitude and friendly nature have made her a beloved member of the community.",
  },
  {
    langCode: "bn-IN",
    id: "Microsoft Server Speech Text to Speech Voice (bn-IN, BashkarNeural)",
    avatarName: "ভাস্কর",
    displayName: "ভাস্কর (bn-IN)",
    gender: "Male",
    bio: "ভাস্কর was born in a small village in West Bengal, India. As a child, he was always curious and adventurous, exploring the nearby forests and rivers with his friends. He developed a love for music and learned to play the tabla from his father, a talented musician. In his free time, he enjoys playing cricket and cooking spicy Bengali dishes with his wife and two young children. He has a wide circle of friends from his village and is known for his friendly, positive, and happy nature. Despite facing many challenges in life, ভাস্কর always finds joy in the simple things.",
  },
  {
    langCode: "bn-IN",
    id: "Microsoft Server Speech Text to Speech Voice (bn-IN, TanishaaNeural)",
    avatarName: "তানিশা",
    displayName: "তানিশা (bn-IN)",
    gender: "Female",
    bio: "তানিশা grew up in a small village in West Bengal, India. She always had a passion for music and would sing along to her father's old records. She attended university in Kolkata and studied music education. She now teaches music at a local school where she has many close friends. She enjoys spending time with her family, especially her niece and nephew. In her spare time, she loves to read books and cook traditional Bengali dishes. She is known for her infectious positivity and kind nature, always ready with a smile or a helping hand.",
  },
  {
    langCode: "bs-BA",
    id: "Microsoft Server Speech Text to Speech Voice (bs-BA, GoranNeural)",
    avatarName: "Goran",
    displayName: "Goran (bs-BA)",
    gender: "Male",
    bio: "Goran, 35, from Bosnia and Herzegovina, is a happy-go-lucky guy who loves to spend his free time exploring the great outdoors. His interests range from hiking and mountaineering to fishing and camping. Goran has a close-knit group of friends who share his passion for adventure, and he cherishes the time they spend together. His family is also important to him, and he often visits his parents, who live in a small village outside of Sarajevo. Goran's positive attitude and friendly demeanor make him a popular member of his community.",
  },
  {
    langCode: "bs-BA",
    id: "Microsoft Server Speech Text to Speech Voice (bs-BA, VesnaNeural)",
    avatarName: "Vesna",
    displayName: "Vesna (bs-BA)",
    gender: "Female",
    bio: "Vesna was born in a small town in Bosnia where she spent her childhood exploring the outdoors with her three siblings. She picked up a passion for hiking and photography, which she pursued throughout her life. After completing college, Vesna moved to Sarajevo where she met her husband, who shares her love for nature. They now have two children who they often take camping and hiking. Vesna has a close group of friends who meet regularly to cook traditional Bosnian dishes and practice their language skills. Her positivity and cheerfulness are contagious, making her the life of the party.",
  },
  {
    langCode: "ca-ES",
    id: "Microsoft Server Speech Text to Speech Voice (ca-ES, JoanaNeural)",
    avatarName: "Joana",
    displayName: "Joana (ca-ES)",
    gender: "Female",
    bio: "Joana grew up in a small town in Catalonia with her parents and older brother. She loved spending time outdoors, hiking and exploring the mountains. Her creativity drove her to study graphic design, where she made friends and worked for various companies. She met her husband at a salsa dancing class, and together they now have two children. Joana often spends time with her close-knit group of friends, cooking traditional Catalan dishes and enjoying local wines. She's happy and content, always carrying a positive attitude towards life.",
  },
  {
    langCode: "ca-ES",
    id: "Microsoft Server Speech Text to Speech Voice (ca-ES, AlbaNeural)",
    avatarName: "Alba",
    displayName: "Alba (ca-ES)",
    gender: "Female",
    bio: "Alba was born in a small village in Catalonia, surrounded by nature and close to the sea. Since she was a child, she loved spending time outdoors, hiking in the mountains or swimming in the Mediterranean Sea. Alba's parents always encouraged her interests and supported her dreams. She grew up with a group of close friends who share her love for adventure and nature. Now, as a successful businesswoman in her thirties, Alba cherishes her family and friends more than ever, and whenever she gets a chance, she travels back to Catalonia to reconnect with her roots and explore new paths.",
  },
  {
    langCode: "ca-ES",
    id: "Microsoft Server Speech Text to Speech Voice (ca-ES, EnricNeural)",
    avatarName: "Enric",
    displayName: "Enric (ca-ES)",
    gender: "Male",
    bio: "Enric grew up near the Catalan coast, where he learned to love the sea and all its creatures. He spends his weekends fishing and boating with his childhood friends, and his evenings enjoying good food and wine with his family. Enric is an avid reader, with a particular love for historical fiction. He works as a teacher and volunteers at a local animal shelter. Enric is known for his contagious smile, and his positive outlook on life.",
  },
  {
    langCode: "cs-CZ",
    id: "Microsoft Server Speech Text to Speech Voice (cs-CZ, AntoninNeural)",
    avatarName: "Antonín",
    displayName: "Antonín (cs-CZ)",
    gender: "Male",
    bio: "Antonín grew up in a small village in Česko, surrounded by nature. He developed a love for hiking and exploring the countryside. As he got older, he immersed himself in the cultural scene of Prague, becoming a regular at art exhibitions and concerts. Antonín values his family and friends, and enjoys hosting gatherings at his apartment, where he showcases his cooking skills. Despite life's challenges, Antonín maintains a positive outlook and infectious spirit, bringing joy to all those around him.",
  },
  {
    langCode: "cs-CZ",
    id: "Microsoft Server Speech Text to Speech Voice (cs-CZ, VlastaNeural)",
    avatarName: "Vlasta",
    displayName: "Vlasta (cs-CZ)",
    gender: "Female",
    bio: "Vlasta grew up in a small village in the Czech Republic, surrounded by nature and animals. She developed a love for hiking, planting, and baking, and spent most of her free time honing these skills. Her friends knew her as the person who always brought a smile to their faces, with her positive attitude and infectious laughter. She met her husband, a fellow nature enthusiast, on a hike and they now have two children together. Vlasta's love for the outdoors and her family have never wavered, and she continues to find joy in every day.",
  },
  {
    langCode: "cy-GB",
    id: "Microsoft Server Speech Text to Speech Voice (cy-GB, AledNeural)",
    avatarName: "Aled",
    displayName: "Aled (cy-GB)",
    gender: "Male",
    bio: "Aled grew up in a small village in Wales, where his family was deeply rooted in the local culture and traditions. From a young age, he was fascinated by the Welsh language and would often spend hours practicing it. In his free time, Aled is an avid hiker and loves exploring the scenic countryside with his friends. He also enjoys playing rugby, which he inherited from his father, who used to play professionally. Aled is now settled in a quaint town where he runs a local bakery with his wife, and they often invite friends and family over for their famous Welsh cakes.",
  },
  {
    langCode: "cy-GB",
    id: "Microsoft Server Speech Text to Speech Voice (cy-GB, NiaNeural)",
    avatarName: "Nia",
    displayName: "Nia (cy-GB)",
    gender: "Female",
    bio: "Nia grew up in Wales, where she developed a strong interest in traditional Welsh arts and culture. She spent many summers attending Eisteddfod festivals with her family, where she would sing and dance in various competitions. As she got older, she discovered a love for pottery, and began crafting intricate pieces inspired by Welsh folklore. Nia also enjoys hiking and spending time in nature, which is how she met her close group of friends. Never one to dwell on the negative, Nia's infectious positivity and kind heart make her a joy to be around.",
  },
  {
    langCode: "da-DK",
    id: "Microsoft Server Speech Text to Speech Voice (da-DK, ChristelNeural)",
    avatarName: "Christel",
    displayName: "Christel (da-DK)",
    gender: "Female",
    bio: "Christel is a music enthusiast from Albertslund in Denmark. She enjoys playing guitar in her free time and often performs at local pubs with her band. She is also a fitness junkie and can be found at the gym most mornings. Christel has a close-knit group of friends whom she met at university, and she cherishes their weekly brunches. Family is important to Christel, and she regularly visits her parents and siblings who live just a short drive away. Her positive attitude and infectious smile have earned her many friends, and everyone enjoys spending time with her.",
  },
  {
    langCode: "da-DK",
    id: "Microsoft Server Speech Text to Speech Voice (da-DK, JeppeNeural)",
    avatarName: "Jeppe",
    displayName: "Jeppe (da-DK)",
    gender: "Male",
    bio: "Jeppe grew up in Værløse in Denmark, surrounded by nature and a tight-knit community. He's always been fascinated by technology, and spends much of his free time tinkering with gadgets and exploring the latest advancements. His other passions include hiking, cooking, and spending time with his wife and young daughter. Jeppe has a close group of childhood friends, and every year they take a trip together to a new destination. Despite life's challenges, Jeppe has a positive outlook on the world and strives to make the most out of every day.",
  },
  {
    langCode: "de-AT",
    id: "Microsoft Server Speech Text to Speech Voice (de-AT, IngridNeural)",
    avatarName: "Ingrid",
    displayName: "Ingrid (de-AT)",
    gender: "Female",
    bio: "Ingrid grew up in a small town in Austria, surrounded by mountains and nature. She inherited her love of the outdoors from her parents, who often took her hiking and skiing. Ingrid's passion for music started when she began playing the piano at the age of 7. She went on to study music at university and now works as a music teacher. Ingrid's friendly nature and positive attitude have helped her make many friends, both at work and in her community. She is happiest when spending time with her husband and young daughter, exploring the great outdoors together.",
  },
  {
    langCode: "de-AT",
    id: "Microsoft Server Speech Text to Speech Voice (de-AT, JonasNeural)",
    avatarName: "Jonas",
    displayName: "Jonas (de-AT)",
    gender: "Male",
    bio: "Jonas grew up in a small village in the mountains of Austria, surrounded by nature. He developed a love for skiing at a young age and became a ski instructor as an adult. On weekends he enjoys hiking with his wife and their two children. When not outdoors, he spends time playing guitar with his friends or cooking traditional Austrian food with his family. Jonas is known for his infectious positivity and kind heart, making him a beloved member of his community.",
  },
  {
    langCode: "de-CH",
    id: "Microsoft Server Speech Text to Speech Voice (de-CH, JanNeural)",
    avatarName: "Jan",
    displayName: "Jan (de-CH)",
    gender: "Male",
    bio: "Jan grew up in a small village in Switzerland where he developed an early passion for hiking and skiing. He moved to Zurich after finishing university, where he works as a software engineer. Jan's weekends are spent exploring the Swiss Alps with his friends, trying new craft beers or improving his skills in photography. Jan values his close-knit family, who still live in his hometown. He's known for his positive attitude and approachable nature, which has helped him develop strong networks both personally and professionally.",
  },
  {
    langCode: "de-CH",
    id: "Microsoft Server Speech Text to Speech Voice (de-CH, LeniNeural)",
    avatarName: "Leni",
    displayName: "Leni (de-CH)",
    gender: "Female",
    bio: "Leni grew up in a small town in Switzerland and was raised by her grandparents after her parents passed away. She developed a love for hiking in the Swiss Alps and spends most of her weekends exploring new trails with her close group of friends. Leni also has a passion for cooking and baking, and often invites her family over for dinners she prepares herself. Despite the hardships she faced early on in life, Leni remains cheerful and optimistic, spreading joy to everyone around her.",
  },
  {
    langCode: "de-DE",
    id: "Microsoft Server Speech Text to Speech Voice (de-DE, KatjaNeural)",
    avatarName: "Katja",
    displayName: "Katja (de-DE)",
    gender: "Female",
    bio: "Katja grew up in a small village in Germany surrounded by nature. She spent most of her childhood hiking, swimming, and exploring with her siblings. As she got older, she discovered her love for art and photography. She moved to the city for university and made lifelong friends who share her passion. Nowadays, she enjoys spending weekends outdoors with her family and capturing memories with her camera. Her positive outlook on life and kind nature make her loved by all who know her.",
  },
  {
    langCode: "de-DE",
    id: "Microsoft Server Speech Text to Speech Voice (de-DE, AmalaNeural)",
    avatarName: "Amala",
    displayName: "Amala (de-DE)",
    gender: "Female",
    bio: "Amala grew up in a small town in Germany, where she spent most of her free time playing soccer with her friends. She later moved to the city to study business, but kept her love for sports by joining a local running club. There, she met her now-husband, who shares her passion for healthy living. Together, they enjoy hiking, biking and exploring new places. Amala also loves spending time with her close-knit family, who live nearby. Her cheerful and outgoing personality makes her a beloved friend to many.",
  },
  {
    langCode: "de-DE",
    id: "Microsoft Server Speech Text to Speech Voice (de-DE, BerndNeural)",
    avatarName: "Bernd",
    displayName: "Bernd (de-DE)",
    gender: "Male",
    bio: "Bernd is a down-to-earth guy from Germany with a passion for hiking and exploring nature. He loves spending time with his wife and two children, usually taking them on weekend camping trips. Bernd also enjoys playing football with his friends, and having a beer with them afterwards. His positive and easy-going attitude has helped him make many friends, and he is always looking for ways to help others. Bernd's job in IT keeps him busy during the week, but he always looks forward to his hobbies and spending time with his loved ones on weekends.",
  },
  {
    langCode: "de-DE",
    id: "Microsoft Server Speech Text to Speech Voice (de-DE, ChristophNeural)",
    avatarName: "Christoph",
    displayName: "Christoph (de-DE)",
    gender: "Male",
    bio: "Christoph grew up in a small village in Germany surrounded by majestic greenery. His love for nature and the outdoors began at a young age. As he grew older, he found solace in music and began playing the guitar. Soon, he formed a band with his best friends from school. They still play gigs on weekends. Christoph's family, a loving and supportive unit, instilled strong values of kindness and positivity in him. These qualities shine through his welcoming smile and his willingness to help anyone in need. Christoph's happy-go-lucky demeanor and diverse interests make him a popular and cherished member of his community.",
  },
  {
    langCode: "de-DE",
    id: "Microsoft Server Speech Text to Speech Voice (de-DE, ConradNeural)",
    avatarName: "Conrad",
    displayName: "Conrad (de-DE)",
    gender: "Male",
    bio: "Conrad grew up in the Bavarian countryside, surrounded by lush greenery and a tight-knit family. With a passion for music, he learned to play the guitar from his father and eventually formed a band with his childhood friends. Conrad also loves hiking and can often be found exploring the nearby mountains. He's a devoted husband to his wife, Maria, and proud father to their two children. Despite life's struggles, Conrad remains upbeat, always finding solace in his hobbies and cherished relationships.",
  },
  {
    langCode: "de-DE",
    id: "Microsoft Server Speech Text to Speech Voice (de-DE, ElkeNeural)",
    avatarName: "Elke",
    displayName: "Elke (de-DE)",
    gender: "Female",
    bio: "Elke grew up in a small village in Germany, where she spent most of her childhood outdoors. She loved hiking, cycling and skiing in the nearby mountains. Elke is an animal lover and has two dogs, which she trains for agility competitions. She is also a talented cook and enjoys hosting dinner parties for her close-knit group of friends. Elke is happily married and has two young children, who she enjoys taking on adventures. Her positive outlook on life has made her a beloved member of her community.",
  },
  {
    langCode: "de-DE",
    id: "Microsoft Server Speech Text to Speech Voice (de-DE, GiselaNeural)",
    avatarName: "Gisela",
    displayName: "Gisela (de-DE)",
    gender: "Female",
    bio: "Gisela grew up in the charming city of Heidelberg, surrounded by a loving family. From a young age, she developed a passion for music and could often be found singing and playing the guitar with her friends. She pursued a degree in music and went on to become a music teacher, sharing her love of music with her students. In her free time, she enjoys hiking with her husband and two children, and hosting dinner parties for her friends. Despite life's challenges, Gisela remains optimistic and has a contagious positive spirit that brightens everyone's day.",
  },
  {
    langCode: "de-DE",
    id: "Microsoft Server Speech Text to Speech Voice (de-DE, KasperNeural)",
    avatarName: "Kasper",
    displayName: "Kasper (de-DE)",
    gender: "Male",
    bio: "Kasper grew up in a small village in Germany. As a child, he was always fascinated by cars and spent most of his time tinkering with engines. His love for cars eventually led him to pursue a career as a mechanic. Kasper is happily married to his childhood sweetheart and has two young children. He enjoys spending weekends working on his vintage car and taking his family on road trips. Kasper is known for his friendly demeanor and his ability to bring a smile to anyone's face. He values his close-knit group of friends and the support of his loving family.",
  },
  {
    langCode: "de-DE",
    id: "Microsoft Server Speech Text to Speech Voice (de-DE, KillianNeural)",
    avatarName: "Killian",
    displayName: "Killian (de-DE)",
    gender: "Male",
    bio: "Killian grew up in a small German town surrounded by vineyards. His parents owned a winery, and he inherited their passion for wine-making. In his free time, he loves to play the guitar and is a member of a local band. Killian's upbeat personality and kind-heart made him many friends in the community. He enjoys hosting wine-tasting parties and playing music for his friends. Killian's wife and their two children are the center of his world. His positive and happy personality makes him loved by all who know him.",
  },
  {
    langCode: "de-DE",
    id: "Microsoft Server Speech Text to Speech Voice (de-DE, KlarissaNeural)",
    avatarName: "Klarissa",
    displayName: "Klarissa (de-DE)",
    gender: "Female",
    bio: "Klarissa grew up in a small town in Deutschland with her loving parents and older sister. She always had a passion for music and spent countless hours playing piano and singing with her friends. As she got older, she developed an interest in photography and started capturing beautiful moments with her camera. Klarissa enjoys spending time outdoors, whether it's hiking or gardening, and cherishes her friendships with her tight-knit group of girlfriends. Now in her mid-thirties, Klarissa is happily married and continues to enjoy her hobbies while spreading positivity and joy to those around her.",
  },
  {
    langCode: "de-DE",
    id: "Microsoft Server Speech Text to Speech Voice (de-DE, KlausNeural)",
    avatarName: "Klaus",
    displayName: "Klaus (de-DE)",
    gender: "Male",
    bio: "Klaus has always loved exploring the outdoors and taking on new challenges. He's an avid hiker and enjoys camping by himself or with his wife and daughter. When he's not out in nature, he spends his time playing guitar and writing music. Klaus values family and friends above all else, making time for them whenever possible. His positive attitude and happy-go-lucky nature have endeared him to all who know him. Despite life's challenges, Klaus always finds a way to see the good in everything.",
  },
  {
    langCode: "de-DE",
    id: "Microsoft Server Speech Text to Speech Voice (de-DE, LouisaNeural)",
    avatarName: "Louisa",
    displayName: "Louisa (de-DE)",
    gender: "Female",
    bio: "Louisa, a 35-year-old from Germany, is a happy-go-lucky person who enjoys spending her free time with her friends and family. She has a passion for exploring new places, trying new foods and learning about different cultures. Louisa is also an avid reader and enjoys spending time alone with a good book. She has a close-knit group of friends who she enjoys spending time with over a glass of wine or a cup of coffee. Her positive and friendly demeanor makes her a joy to be around, and she always puts a smile on everyone's face.",
  },
  {
    langCode: "de-DE",
    id: "Microsoft Server Speech Text to Speech Voice (de-DE, MajaNeural)",
    avatarName: "Maja",
    displayName: "Maja (de-DE)",
    gender: "Female",
    bio: "Maja, 35, grew up in Germany with her siblings and loving parents. She's always had a passion for gardening, spending her free time tending to her plants in her backyard vegetable garden. Maja's positive and friendly demeanor has attracted many friends over the years, and she loves hosting dinners and gatherings at her house. She's also an avid reader and has a book club with her closest friends. Maja recently welcomed a son, Max, to her family and enjoys spending her days playing with him and watching him grow.",
  },
  {
    langCode: "de-DE",
    id: "Microsoft Server Speech Text to Speech Voice (de-DE, RalfNeural)",
    avatarName: "Ralf",
    displayName: "Ralf (de-DE)",
    gender: "Male",
    bio: "Ralf was born and raised in the beautiful countryside of Germany. Growing up, he spent most of his free time exploring the nearby forests and indulging in his love for nature photography. He's now a successful social media influencer, who shares his outdoor adventures with an ever-growing community of followers. Ralf also enjoys spending time with his close-knit family and friends, who share his passion for hiking and camping. His infectious positivity and happy-go-lucky attitude make him a joy to be around.",
  },
  {
    langCode: "de-DE",
    id: "Microsoft Server Speech Text to Speech Voice (de-DE, TanjaNeural)",
    avatarName: "Tanja",
    displayName: "Tanja (de-DE)",
    gender: "Female",
    bio: "Tanja, a 35-year-old German native, was raised in a family of music lovers. Her father was a professional pianist, and she learned to play the violin as a child. Her love of music continued to grow, and she now plays in a local orchestra. Tanja's other passions include traveling, hiking, and spending time with her close-knit group of friends, who she met in college. She's happily married with two children and enjoys exploring new places with her family. Despite life's ups and downs, Tanja always maintains a positive attitude and a warm, friendly demeanor.",
  },
  {
    langCode: "el-GR",
    id: "Microsoft Server Speech Text to Speech Voice (el-GR, AthinaNeural)",
    avatarName: "Αθηνά",
    displayName: "Αθηνά (el-GR)",
    gender: "Female",
    bio: "Αθηνά was born and raised in Athens, Greece. She grew up with a passion for cooking, often helping her mother in the kitchen. After studying culinary arts, she became a chef at a prestigious restaurant in Athens. In her free time, she loves to explore new restaurants with her friends and enjoys dancing at local clubs. Αθηνά is happily married to her husband, Nikos, and together they enjoy spending weekends with their two young children at the beach. She is known for her infectious smile and positive attitude.",
  },
  {
    langCode: "el-GR",
    id: "Microsoft Server Speech Text to Speech Voice (el-GR, NestorasNeural)",
    avatarName: "Νέστορας",
    displayName: "Νέστορας (el-GR)",
    gender: "Male",
    bio: "Νέστορας is a 35-year-old man from Greece who loves spending time outdoors. He enjoys hiking in the mountains and going on long coastal walks with his wife, Maria. Νέστορας is also passionate about cars and enjoys tinkering with his old Volkswagen Beetle. He runs a small garage where he repairs cars and enjoys chatting with his customers. Νέστορας has a close-knit group of friends from his childhood who he sees every weekend to play football and have a few beers. He is always positive and happy, and his infectious energy brings joy to those around him.",
  },
  {
    langCode: "en-AU",
    id: "Microsoft Server Speech Text to Speech Voice (en-AU, AnnetteNeural)",
    avatarName: "Annette",
    displayName: "Annette (en-AU)",
    gender: "Female",
    bio: "Annette is a 35-year-old woman from Australia. She is a positive, happy and friendly person who loves spending time with her family and friends. Annette's interests include outdoor activities such as hiking, rock climbing and camping. She also enjoys reading and trying new recipes in the kitchen. Annette's closest friends describe her as the life of the party, always making them laugh and feel good with her infectious energy. Annette's family is very important to her, and she makes sure to visit her parents and siblings regularly.",
  },
  {
    langCode: "en-AU",
    id: "Microsoft Server Speech Text to Speech Voice (en-AU, CarlyNeural)",
    avatarName: "Carly",
    displayName: "Carly (en-AU)",
    gender: "Female",
    bio: "Carly grew up in a small town in Australia, where she spent her childhood exploring the outdoors and playing sports. She cultivated a love for photography in her teens and now, as a successful wedding photographer, she uses her talent to capture the happiest moments of people's lives. Carly is happily married, with two young children and a close-knit group of friends, who she loves to entertain and cook for. Her positive and bubbly personality brings joy to everyone she meets, and she never stops creating new memories with her loved ones.",
  },
  {
    langCode: "en-AU",
    id: "Microsoft Server Speech Text to Speech Voice (en-AU, DarrenNeural)",
    avatarName: "Darren",
    displayName: "Darren (en-AU)",
    gender: "Male",
    bio: "Darren was born and raised in Sydney with his parents and two siblings. He has always been interested in music and dedicated his weekends to playing in a local band. His passion for music led him to study sound engineering in college, where he met his lifelong friends. When Darren is not playing music or producing tracks, he enjoys surfing with his buddies or cooking BBQ for his family. He is a friendly and positive person who always has a smile on his face.",
  },
  {
    langCode: "en-AU",
    id: "Microsoft Server Speech Text to Speech Voice (en-AU, DuncanNeural)",
    avatarName: "Duncan",
    displayName: "Duncan (en-AU)",
    gender: "Male",
    bio: "Duncan grew up in a small town on the outskirts of Melbourne where he spent most of his childhood playing soccer with his brothers and cousins. His love for sports continued as he grew older, and he now spends every weekend cheering on his favorite AFL team with his friends. When he's not at work as an IT consultant, he enjoys hiking in the mountains with his wife and their dog. Duncan is always positive and never fails to make those around him feel at ease with his infectious smile and easy-going personality.",
  },
  {
    langCode: "en-AU",
    id: "Microsoft Server Speech Text to Speech Voice (en-AU, ElsieNeural)",
    avatarName: "Elsie",
    displayName: "Elsie (en-AU)",
    gender: "Female",
    bio: "Elsie grew up on a farm with a big family in rural Australia. She developed a love for nature and animals, which led her to study ecology in college. She now works as a park ranger, where she spends her days hiking, birdwatching, and educating visitors. In her free time, she enjoys playing guitar, cooking hearty meals for her friends, and exploring new hiking trails with her dog. Elsie's happy-go-lucky personality and friendly demeanor make her a beloved member of her community.",
  },
  {
    langCode: "en-AU",
    id: "Microsoft Server Speech Text to Speech Voice (en-AU, FreyaNeural)",
    avatarName: "Freya",
    displayName: "Freya (en-AU)",
    gender: "Female",
    bio: "Freya grew up in a small coastal town in Australia, spending most of her childhood exploring the nearby beaches and forests. Her love for nature stayed with her as she became an avid hiker and photographer. Freya's positive attitude and friendly demeanor make it easy for her to make friends wherever she goes. She is married to her high school sweetheart and they have two children. On weekends, she enjoys cooking for family and friends, hosting lively dinner parties and playing board games late into the night.",
  },
  {
    langCode: "en-AU",
    id: "Microsoft Server Speech Text to Speech Voice (en-AU, JoanneNeural)",
    avatarName: "Joanne",
    displayName: "Joanne (en-AU)",
    gender: "Female",
    bio: "Joanne is a bubbly, outgoing person from a rural Australian town. She inherited her love for camping and fishing from her dad, who taught her the ropes. When she's not outdoors, she is passionate about baking and sharing her treats with her friends and family. Joanne's positivity and generosity are contagious, and she has a close-knit group of friends who share her interests. As a single mom, Joanne works hard to support her daughter and provide her with the same love and care that her own parents gave her.",
  },
  {
    langCode: "en-AU",
    id: "Microsoft Server Speech Text to Speech Voice (en-AU, KenNeural)",
    avatarName: "Ken",
    displayName: "Ken (en-AU)",
    gender: "Male",
    bio: "Ken grew up in a small town in rural Australia, where he learned to appreciate the great outdoors. His love for camping and fishing has stayed with him into adulthood. He's still close with his childhood friends, who share his love for adventure. Ken's positive attitude has made him a popular figure in his community, always willing to lend a helping hand. He currently enjoys spending quality time with his wife and two children, introducing them to the same hobbies that he grew up with. Ken is a happy guy who cherishes the simple things in life.",
  },
  {
    langCode: "en-AU",
    id: "Microsoft Server Speech Text to Speech Voice (en-AU, KimNeural)",
    avatarName: "Kim",
    displayName: "Kim (en-AU)",
    gender: "Female",
    bio: "Kim, a cheerful and bubbly 35-year-old, was born and raised in Australia. She grew up in a warm and supportive family who instilled in her a love for nature and adventure. With a passion for travel and photography, Kim enjoys exploring exotic places, meeting new people and capturing the beauty of landscapes. She has a close group of friends with whom she shares her love for hiking, yoga, and trying out new restaurants. Kim values her relationships with loved ones and always has a smile on her face.",
  },
  {
    langCode: "en-AU",
    id: "Microsoft Server Speech Text to Speech Voice (en-AU, NatashaNeural)",
    avatarName: "Natasha",
    displayName: "Natasha (en-AU)",
    gender: "Female",
    bio: "Natasha grew up in a small town in Australia. She always had an adventurous spirit and loved exploring the great outdoors. Her hobbies include hiking, camping, and photography. Natasha's positivity and happy-go-lucky nature make her loved by everyone she encounters. She has a close-knit group of friends that she met in college and they all share a love for travel and exploring different cultures. Natasha is also very close with her family, who continue to support her in all her endeavors. Overall, she lives life to the fullest and is always looking for her next adventure.",
  },
  {
    langCode: "en-AU",
    id: "Microsoft Server Speech Text to Speech Voice (en-AU, NeilNeural)",
    avatarName: "Neil",
    displayName: "Neil (en-AU)",
    gender: "Male",
    bio: "Neil grew up in a small town in Australia, surrounded by nature and wildlife which sparked his love for hiking, bird watching, and photography. He moved to the city to study engineering and met his wife, who shares his passion for the outdoors. Together, they have a son and a daughter. Neil's positive spirit attracts many friends, who often join him on weekend camping trips. He's also a big sports fan, cheering for his local team at games. Neil feels grateful for his family, friends, and hobbies, which bring him joy every day.",
  },
  {
    langCode: "en-AU",
    id: "Microsoft Server Speech Text to Speech Voice (en-AU, TimNeural)",
    avatarName: "Tim",
    displayName: "Tim (en-AU)",
    gender: "Male",
    bio: "Meet Tim, a 35-year-old Australian bloke who loves his country's beaches and laid-back lifestyle. Tim spends his free time surfing, hiking and spending time with his close group of friends who he's known since high school. He's also an avid fan of Australian football and goes to games with his dad every weekend. Despite a busy work life, Tim always makes time for his family and loves taking his niece and nephew on outdoor adventures. His positive attitude and friendly personality never fail to lift his loved ones' spirits.",
  },
  {
    langCode: "en-AU",
    id: "Microsoft Server Speech Text to Speech Voice (en-AU, TinaNeural)",
    avatarName: "Tina",
    displayName: "Tina (en-AU)",
    gender: "Female",
    bio: "Tina grew up on a farm in rural Australia surrounded by her loving family and animals. Her love for animals continued into adulthood as she became an animal rights activist. Tina spends her free time volunteering at the local shelter and taking her dog, Bailey, on hikes with her friends. She's also an avid reader, frequenting the local library and discussing book recommendations with her book club. Her positive attitude and infectious laughter bring light to any room she's in, and she cherishes her relationships with her close-knit family and supportive friends.",
  },
  {
    langCode: "en-AU",
    id: "Microsoft Server Speech Text to Speech Voice (en-AU, WilliamNeural)",
    avatarName: "William",
    displayName: "William (en-AU)",
    gender: "Male",
    bio: "William grew up on a farm in rural Australia with his loving parents and younger sister. He spent his childhood exploring the great outdoors, fishing and surfing along the picturesque beaches of the east coast. Now 35, William works as a graphic designer, but his passions lie in music and travel. He's a regular at his local pub, where he jams with his mates in their band, and has been on some wild adventures across the world with his girlfriend. William's positivity and infectious grin make him a hit with everyone he meets.",
  },
  {
    langCode: "en-CA",
    id: "Microsoft Server Speech Text to Speech Voice (en-CA, ClaraNeural)",
    avatarName: "Clara",
    displayName: "Clara (en-CA)",
    gender: "Female",
    bio: "Clara grew up in a small town in Canada and developed a love for the great outdoors at a young age. She enjoys hiking, camping and fishing with her friends and family. Clara has a passion for art and takes every opportunity to visit galleries and attend art shows. She is married to her high school sweetheart and they have two children who share their mother's zest for life. Clara's positive energy is contagious and she is known for making friends easily. Her sunny disposition is a reflection of her happy and fulfilling life.",
  },
  {
    langCode: "en-CA",
    id: "Microsoft Server Speech Text to Speech Voice (en-CA, LiamNeural)",
    avatarName: "Liam",
    displayName: "Liam (en-CA)",
    gender: "Male",
    bio: "Liam was born and raised in a tight-knit community in Toronto, Canada. He grew up playing ice hockey and spending summers fishing with his dad. In college, he discovered a passion for photography, which led to a successful career as a freelance photographer. Liam is married to his high school sweetheart and they have two children. He enjoys spending time with his family, hiking, and trying new foods. His positive energy and friendly nature have earned him a loyal group of friends who admire his zest for life.",
  },
  {
    langCode: "en-GB",
    id: "Microsoft Server Speech Text to Speech Voice (en-GB, LibbyNeural)",
    avatarName: "Libby",
    displayName: "Libby (en-GB)",
    gender: "Female",
    bio: "Libby grew up in a small village in the UK. Her two passions in life have always been gardening and playing the piano. After college, she moved to the city where she found a job working for a landscape architecture company. In the evenings, she plays piano at a local jazz bar and has made many new friends. Libby's parents still live in the village, and she loves to visit them and help out in their garden. Overall, her positive outlook on life and friendly personality make her a joy to be around.",
  },
  {
    langCode: "en-GB",
    id: "Microsoft Server Speech Text to Speech Voice (en-GB, AbbiNeural)",
    avatarName: "Abbi",
    displayName: "Abbi (en-GB)",
    gender: "Female",
    bio: "Abbi grew up in a small town in England where her love for nature and animals blossomed. She spent most of her free time trekking through the countryside, birdwatching, and horseback riding. She went on to study veterinary medicine and now owns her own practice with a team of like-minded animal lovers. Abbi's infectious positivity attracts a wide range of friends from her yearly birdwatching group to her colleagues. Her family is supportive, and her frequent visits to her folks' farm always fill her soul. Abbi's zest for life is contagious, and she radiates happiness.",
  },
  {
    langCode: "en-GB",
    id: "Microsoft Server Speech Text to Speech Voice (en-GB, AlfieNeural)",
    avatarName: "Alfie",
    displayName: "Alfie (en-GB)",
    gender: "Male",
    bio: "Alfie grew up in a small town where he developed a love for the outdoors and adventure. He enjoys hiking, camping and exploring new places. His positive and friendly nature has resulted in a close group of friends who share his interests. He continues to pursue his passions while also maintaining a close relationship with his family. A happy-go-lucky guy, Alfie always brings a cheerful energy to any situation.",
  },
  {
    langCode: "en-GB",
    id: "Microsoft Server Speech Text to Speech Voice (en-GB, BellaNeural)",
    avatarName: "Bella",
    displayName: "Bella (en-GB)",
    gender: "Female",
    bio: "Bella, 35, is a cheerful woman from England. She adores photography and finds joy in capturing moments that evoke emotions. Bella is also an avid traveler who loves to explore new cultures and immerse herself in different experiences. She values relationships and cherishes her tight-knit family and friends. Her optimistic outlook on life keeps her motivated and she enjoys sharing her happiness with those around her.",
  },
  {
    langCode: "en-GB",
    id: "Microsoft Server Speech Text to Speech Voice (en-GB, ElliotNeural)",
    avatarName: "Elliot",
    displayName: "Elliot (en-GB)",
    gender: "Male",
    bio: "Elliot grew up in a small English town with his loving parents and younger sister. He always had a passion for music, playing in a local rock band with his closest friends. Elliot also enjoyed spending time outdoors, going on long hikes and camping trips. After college, he moved to the city and began working as a graphic designer. In his free time, he volunteers at a local animal shelter and hosts jam sessions with his musician friends. Elliot's positive energy and friendly demeanor make him a joy to be around.",
  },
  {
    langCode: "en-GB",
    id: "Microsoft Server Speech Text to Speech Voice (en-GB, EthanNeural)",
    avatarName: "Ethan",
    displayName: "Ethan (en-GB)",
    gender: "Male",
    bio: "Ethan grew up in a small town in England, surrounded by countryside and nature. Always active and adventurous, he made many friends through his love of sports and outdoor activities. In his spare time, he enjoys hiking and camping with his wife and two kids. As a software developer, he's part of a vibrant tech community and enjoys attending local meetups. Ethan is a friendly and positive person, always up for a chat and making new friends.",
  },
  {
    langCode: "en-GB",
    id: "Microsoft Server Speech Text to Speech Voice (en-GB, HollieNeural)",
    avatarName: "Hollie",
    displayName: "Hollie (en-GB)",
    gender: "Female",
    bio: "Hollie grew up in a small town in England, surrounded by her close-knit family of outdoors enthusiasts. She developed a love for hiking and camping at a young age, and that passion stuck with her as she grew older. Today, she spends her weekends on the trails with her adventurous friends, exploring the countryside and discovering hidden gems. Hollie's optimistic outlook on life brings joy to everyone she meets, and her infectious energy makes her a favorite among her social circle.",
  },
  {
    langCode: "en-GB",
    id: "Microsoft Server Speech Text to Speech Voice (en-GB, MaisieNeural)",
    avatarName: "Maisie",
    displayName: "Maisie (en-GB)",
    gender: "Female",
    bio: "Maisie grew up in a small village in the UK. She loved spending time outdoors, hiking and camping with her family. Her passion for adventure led her to pursue a career in travel writing. She often meets up with her old childhood friends to reminisce about their carefree days spent exploring the countryside. She is happily married to her soulmate and enjoys trying new recipes in the kitchen. Her positive attitude and infectious smile make her a joy to be around.",
  },
  {
    langCode: "en-GB",
    id: "Microsoft Server Speech Text to Speech Voice (en-GB, NoahNeural)",
    avatarName: "Noah",
    displayName: "Noah (en-GB)",
    gender: "Male",
    bio: "Noah grew up in a small town in England, where he found solace in music and sports. He played guitar in his local band and loved to play football with his friends. Noah's positive and upbeat personality made him popular, and he was always surrounded by people who enjoyed his company. After university, he moved to the city, where he works as a music producer. He still plays in his band and has made many new friends through his work. Noah is happily married to his high school sweetheart and has a young daughter who he adores.",
  },
  {
    langCode: "en-GB",
    id: "Microsoft Server Speech Text to Speech Voice (en-GB, OliverNeural)",
    avatarName: "Oliver",
    displayName: "Oliver (en-GB)",
    gender: "Male",
    bio: "Oliver grew up in a close-knit family that valued education and creativity. He developed a love for music at a young age, and spent many hours listening to classic rock and playing guitar. In his free time, he enjoys camping and hiking with friends, exploring the countryside and trying new outdoor activities. Oliver is known for his positive outlook on life, and his friendly nature has helped him form lasting friendships with people from all walks of life. He works as a graphic designer, and enjoys using his creativity to bring his clients' visions to life.",
  },
  {
    langCode: "en-GB",
    id: "Microsoft Server Speech Text to Speech Voice (en-GB, OliviaNeural)",
    avatarName: "Olivia",
    displayName: "Olivia (en-GB)",
    gender: "Female",
    bio: "Olivia grew up in the countryside with her loving family. Her passion for horseback riding began at a young age and still plays a significant role in her life today. She loves exploring new trails in her free time with her closest friends, who she met through her local equestrian club. Olivia's positive and friendly personality has also earned her a loyal social circle outside of the horse world. She enjoys traveling and experiencing different cultures, but always feels most at home when surrounded by her loved ones in her native British English speaking country.",
  },
  {
    langCode: "en-GB",
    id: "Microsoft Server Speech Text to Speech Voice (en-GB, RyanNeural)",
    avatarName: "Bryan",
    displayName: "Bryan (en-GB)",
    gender: "Male",
    bio: "Bryan, 35, grew up in a small town in the UK. He always had a passion for music and began playing guitar at a young age. He's traveled the world with his music, making friends along the way. Despite his global adventures, he remains close with his family and often spends weekends with them, playing board games and enjoying meals. He's also an avid runner and has completed multiple marathons. Bryan's positive attitude and friendly nature have endeared him to everyone he meets. Today, he's happily settled in his hometown with his wife and two children.",
  },
  {
    langCode: "en-GB",
    id: "Microsoft Server Speech Text to Speech Voice (en-GB, SoniaNeural)",
    avatarName: "Sonia",
    displayName: "Sonia (en-GB)",
    gender: "Female",
    bio: "Sonia grew up surrounded by the lush greenery of the English countryside. Her parents taught her a deep appreciation for nature, and she quickly developed a love for hiking and birdwatching. As she grew older, she also discovered a passion for cooking and hosting dinner parties for her friends. Sonia's optimistic outlook on life and infectious laughter make her a joy to be around. She and her husband raise their two children with the same values and traditions that are dear to her heart.",
  },
  {
    langCode: "en-GB",
    id: "Microsoft Server Speech Text to Speech Voice (en-GB, ThomasNeural)",
    avatarName: "Thomas",
    displayName: "Thomas (en-GB)",
    gender: "Male",
    bio: "Thomas grew up in a small English village, where he developed a love for the outdoors and an interest in music. He spent his teenage years playing guitar with his friends in local bands, but eventually pursued a career in accounting. He still plays music in his spare time and enjoys hiking and camping with his wife, two young children, and their dog. He's known for his infectious smile and positive attitude, and he always has a joke or a lighthearted comment to share with his colleagues and friends.",
  },
  {
    langCode: "en-GB",
    id: "Microsoft Server Speech Text to Speech Voice (en-GB, MiaNeural)",
    avatarName: "Mia",
    displayName: "Mia (en-GB)",
    gender: "Female",
    bio: "Mia grew up in a small town in England with her parents and two siblings. She had always been interested in art, spending hours drawing and painting. In her 20s, she decided to pursue her passion and went to art school, where she made lifelong friends. Today, she works as a freelance illustrator and enjoys spending time with her husband and two children. When she's not creating art, Mia can be found hiking in the countryside with her dog or catching up with old friends over a cup of tea.",
  },
  {
    langCode: "en-HK",
    id: "Microsoft Server Speech Text to Speech Voice (en-HK, SamNeural)",
    avatarName: "Sam",
    displayName: "Sam (en-HK)",
    gender: "Male",
    bio: "Sam grew up in a loving family in Hong Kong. He always had a passion for technology and music, and spent hours tinkering with electronic gadgets and playing the guitar. He made many close friends through his hobby of powerlifting, regularly competing in competitions. After completing university, he moved to the United States to pursue his dream of starting his own tech company. His positive attitude and friendly demeanor allowed him to quickly make new friends and establish himself in the startup community. Despite the challenges he faced, Sam remained happy and optimistic, grateful for the love and support of his family and friends.",
  },
  {
    langCode: "en-HK",
    id: "Microsoft Server Speech Text to Speech Voice (en-HK, YanNeural)",
    avatarName: "Yan",
    displayName: "Yan (en-HK)",
    gender: "Female",
    bio: 'Yan grew up in Hong Kong with a passion for outdoor activities. She loved hiking, sailing and participating in adventure races. Her joyful personality attracted her many friends, who nicknamed her "Sunshine." She pursued her passion and became a professional adventure guide. During her travels around the world, Yan met her wife, a fellow adventurer. They settled down in Hong Kong and started a family. Yan now balances her time between work and family, but still finds time to enjoy outdoor activities with her friends on weekends.',
  },
  {
    langCode: "en-IE",
    id: "Microsoft Server Speech Text to Speech Voice (en-IE, ConnorNeural)",
    avatarName: "Connor",
    displayName: "Connor (en-IE)",
    gender: "Male",
    bio: "Connor was born and raised in Ireland. He comes from a big family of six siblings and learned the value of strong relationships from a young age. Connor is passionate about sports, particularly football and has been playing since he was five years old. He enjoys hiking in the beautiful countryside and visiting new places. He is also an avid reader and loves learning about new things. Connor has a close group of friends who he regularly meets up with for drinks and laughs. He believes that life is too short to not be positive and happy.",
  },
  {
    langCode: "en-IE",
    id: "Microsoft Server Speech Text to Speech Voice (en-IE, EmilyNeural)",
    avatarName: "Emily",
    displayName: "Emily (en-IE)",
    gender: "Female",
    bio: "Emily was born and raised in the lush green countryside of Ireland. She developed a keen interest in gardening and spends most of her leisure time tending to her beautiful flower garden. Emily also loves to cook and experiment with new recipes, often inviting her friends and family over for a fun-filled dinner party. Her easygoing nature and infectious smile make her a popular figure in her community. Emily values family time and often travels to her parents' farm for weekend barbecues and catch-ups. Her positive outlook on life and bubbly personality make her a joy to be around.",
  },
  {
    langCode: "en-IN",
    id: "Microsoft Server Speech Text to Speech Voice (en-IN, NeerjaNeural)",
    avatarName: "Neerja",
    displayName: "Neerja (en-IN)",
    gender: "Female",
    bio: "Neerja is a lively woman who hails from the stunning country of India. At 35 years old, she is an accomplished individual with many interests and hobbies. She enjoys reading books, listening to music and exploring new places. Her friends and family are her lifeline, and she cherishes the time spent with them. Neerja is a kind and positive person who always has a smile on her face. She loves her country and its people and takes every opportunity to showcase its beauty to others.",
  },
  {
    langCode: "en-IN",
    id: "Microsoft Server Speech Text to Speech Voice (en-IN, PrabhatNeural)",
    avatarName: "Prabhat",
    displayName: "Prabhat (en-IN)",
    gender: "Male",
    bio: "Prabhat hails from the bustling city of Mumbai, India. He's 35 and works as a software engineer. In his free time, he likes to read self-improvement books, play table tennis and cook for his family. Prabhat cherishes his circle of close friends from college, who he meets up with regularly for drinks and board games. His wife and daughter bring immense joy to his life, and the trio loves going on weekend trips together. Despite the daily chaos of city life, Prabhat remains an affable and optimistic person, always ready to lend a helping hand and spread positivity.",
  },
  {
    langCode: "en-KE",
    id: "Microsoft Server Speech Text to Speech Voice (en-KE, AsiliaNeural)",
    avatarName: "Asilia",
    displayName: "Asilia (en-KE)",
    gender: "Female",
    bio: "Asilia grew up in a small village in Kenya where she learned the values of community and family. She loved exploring nature and listening to music. She moved to the city to pursue her passion for photography and joined a group of artists where she made lifelong friends. Asilia now works as a freelance photographer and enjoys capturing the beauty of everyday life. She spends her free time volunteering at a local children's center and organizing community events. Her positivity and kindness bring joy to all those around her.",
  },
  {
    langCode: "en-KE",
    id: "Microsoft Server Speech Text to Speech Voice (en-KE, ChilembaNeural)",
    avatarName: "Chilemba",
    displayName: "Chilemba (en-KE)",
    gender: "Male",
    bio: "Chilemba, a 35-year-old man from Kenya, is known for his cheerful personality and bright outlook on life. He loves spending time with friends and family, especially his two young children who he adores. In his free time, Chilemba enjoys cooking traditional Kenyan dishes and watching football with his buddies. He also volunteers at a local community center, where he helps organize events and activities for the youth. Chilemba is grateful for his supportive network of loved ones and treasures every moment spent with them.",
  },
  {
    langCode: "en-NG",
    id: "Microsoft Server Speech Text to Speech Voice (en-NG, EzinneNeural)",
    avatarName: "Ezinne",
    displayName: "Ezinne (en-NG)",
    gender: "Female",
    bio: "Ezinne grew up in a loving family of five, in the bustling city of Lagos, Nigeria. She always had a passion for musical theatre and dance, often performing at local festivals. After graduating from university, Ezinne moved to the UK to pursue her dreams in the theatre industry. Over the years she has built a strong network of friends and colleagues who admire her energy and positivity. When not rehearsing for a show, she enjoys yoga, baking and trying new vegetarian recipes. Family is still incredibly important to her, and she makes regular trips home to reconnect.",
  },
  {
    langCode: "en-NZ",
    id: "Microsoft Server Speech Text to Speech Voice (en-NZ, MitchellNeural)",
    avatarName: "Mitchell",
    displayName: "Mitchell (en-NZ)",
    gender: "Male",
    bio: "Mitchell grew up in a small town in New Zealand, where he became interested in all things outdoors - hiking, camping, and fishing. He also loved playing rugby with his friends and spending time with his family. After completing university, he moved to the city and became a successful businessman, but never lost his love for nature. Despite his busy schedule, he always makes time for his friends and family, and his positive attitude and contagious smile make him a joy to be around.",
  },
  {
    langCode: "en-NZ",
    id: "Microsoft Server Speech Text to Speech Voice (en-NZ, MollyNeural)",
    avatarName: "Molly",
    displayName: "Molly (en-NZ)",
    gender: "Female",
    bio: "Molly grew up in the lush countryside of New Zealand with her parents and younger sister. She loved spending time outdoors, hiking and horseback riding. After college, she moved to the city to pursue her passion for photography and quickly made friends with her positive, bubbly personality. In her free time, she enjoys cooking, practicing yoga, and exploring the city with her close-knit group of friends. Molly is grateful for her loving family and happy life, and she always strives to spread positivity wherever she goes.",
  },
  {
    langCode: "en-PH",
    id: "Microsoft Server Speech Text to Speech Voice (en-PH, JamesNeural)",
    avatarName: "James",
    displayName: "James (en-PH)",
    gender: "Male",
    bio: "James grew up in a small town in the Philippines where he spent most of his childhood outdoors, exploring nature and playing sports with his friends. He developed a love for basketball, gardening and music which he pursued throughout his life. James moved to the city to study music production and formed a band with his closest friends. He married his childhood sweetheart and they now have two young children. James is a positive and happy person who values his family, friends and hobbies above all else.",
  },
  {
    langCode: "en-PH",
    id: "Microsoft Server Speech Text to Speech Voice (en-PH, RosaNeural)",
    avatarName: "Rosa",
    displayName: "Rosa (en-PH)",
    gender: "Female",
    bio: "Rosa grew up in the small town of Panglao where she spent her childhood playing outdoors and swimming in the crystal clear beaches of Bohol. She loved reading books, hiking and taking care of her pets. After finishing college, she moved to Manila to pursue her career in marketing. Despite the busy city life, she still finds time to travel, catch up with friends and attend family gatherings. Rosa is known for her infectious smile and happy disposition, making her a well-loved member of her tight-knit community.",
  },
  {
    langCode: "en-SG",
    id: "Microsoft Server Speech Text to Speech Voice (en-SG, LunaNeural)",
    avatarName: "Luna",
    displayName: "Luna (en-SG)",
    gender: "Female",
    bio: "Luna is a social butterfly from Singapore, who loves to connect and meet new people. She's a 35-year-old graphic designer who's passionate about modern art and photography. When she's not busy exploring the latest art scene in town, she enjoys hiking at Bukit Timah Nature Reserve with her close-knit group of friends. Her caring personality and positive outlook on life make her everyone's favorite confidant. Luna's family is her rock, and she cherishes her time with them during festive celebrations. Her upbeat energy is what makes her the life of the party!",
  },
  {
    langCode: "en-SG",
    id: "Microsoft Server Speech Text to Speech Voice (en-SG, WayneNeural)",
    avatarName: "Wayne",
    displayName: "Wayne (en-SG)",
    gender: "Male",
    bio: "Wayne grew up in Singapore with supportive parents and a love for the outdoors. He spent most weekends fishing, hiking, and exploring. A social butterfly, he made friends everywhere he went. His passion for travel lead him to teach scuba diving in Thailand, where he met his wife. Together they started a successful tour company. Now, as a father of two, Wayne enjoys sharing his love for nature with his family, exploring new places, and hosting barbecues for his large circle of friends. He radiates positivity and a contagious happiness that leaves everyone around him feeling uplifted.",
  },
  {
    langCode: "en-TZ",
    id: "Microsoft Server Speech Text to Speech Voice (en-TZ, ElimuNeural)",
    avatarName: "Elimu",
    displayName: "Elimu (en-TZ)",
    gender: "Male",
    bio: "Elimu was born and raised in Tanzania. He loves music and often plays the guitar in his free time. He has a close group of friends from his childhood who he visits frequently. Elimu's family is very important to him, and he tries to see them as often as he can. He is happily married to his wife, Halima, and they recently had their first child together. Elimu's positive attitude and friendly nature make him a joy to be around.",
  },
  {
    langCode: "en-TZ",
    id: "Microsoft Server Speech Text to Speech Voice (en-TZ, ImaniNeural)",
    avatarName: "Imani",
    displayName: "Imani (en-TZ)",
    gender: "Female",
    bio: "Imani grew up in Tanzania with a passion for music and dancing. She studied choreography in college and now runs her own dance studio in Dar es Salaam. In her free time, she enjoys hiking with her friends, trying new recipes, and volunteering at a local animal shelter. Imani's joyous personality draws people towards her, making her popular amongst her family and friends.",
  },
  {
    isMultiLingual: true,
    langCode: "en-US",
    id: defaultMultiLingualId,
    avatarName: "Jenny",
    displayName: "Jenny Multilingual (en-US)",
    gender: "Female",
    bio: "Jenny's upbringing in a multilingual and culturally diverse family in New York laid the foundation for her remarkable ability to speak 41 languages. Her passion for travel took her on numerous adventures across the globe, allowing her to immerse herself in various cultures. Alongside her travels, Jenny developed a love for culinary arts, often experimenting with recipes from the countries she visited. She is also an avid salsa dancer, finding joy and expression in the rhythm of the dance. Jenny shares her life with her husband and their two children, balancing family life with her personal interests. Her journey with languages and cultures now continues in her professional role as a language teacher at Talkio, where she brings her vast experiences into the classroom, enriching her students' learning.",
  },
  {
    isMultiLingual: true,
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, RyanMultilingualNeural)",
    avatarName: "Ryan",
    displayName: "Ryan (en-US)",
    gender: "Male",
    bio: "Ryan's early years in Shanghai introduced him to a world of languages. After moving to the United States as a child, his fascination with languages grew, and he eventually mastered 41 of them. Living in Los Angeles, he shares this rich linguistic knowledge as an instructor at Talkio. Ryan's free time is spent practicing martial arts, a discipline that provides both physical and mental benefits. He also has a passion for cooking, where he enjoys experimenting with international cuisines, bringing a taste of his travels into his kitchen. These hobbies are not just pastimes for Ryan; they keep him connected to the diverse cultures he loves.",
  },

  {
    //isDefaultForLanguage: true,
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, GuyNeural)",
    avatarName: "Guy",
    displayName: "Guy (en-US)",
    gender: "Male",
    bio: "Guy grew up in a small town in the Midwest with a tight-knit family. He discovered his love for music at a young age and often spent hours listening to classic rock albums. In his free time, Guy takes his motorcycle out for long rides and enjoys camping with his friends. He has a jovial personality and loves making new friends. As he approaches his mid-thirties, Guy remains happy and positive, always finding reasons to enjoy life around him.",
  },
  {
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, AmberNeural)",
    avatarName: "Amber",
    displayName: "Amber (en-US)",
    gender: "Female",
    bio: "Amber grew up in a small town in the heartland of America, surrounded by loving family and great friends. She was always interested in exploring the world around her through hikes and adventures in her backyard, but also loved spending time cooking, reading and painting. After college, she moved to the city, where she made new friends and explored new hobbies like salsa dancing, yoga and trying out new restaurants. Despite the busy pace of city life, Amber maintained her positive attitude and friendly demeanor, and always had a smile for everyone she met.",
  },
  {
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, JennyNeural)",
    avatarName: "Jennifer",
    displayName: "Jenny (en-US)",
    gender: "Female",
    bio: "Jennifer grew up in a small town where she enjoyed spending time outdoors hiking and camping with her family. She developed a love for photography and now shares her world travels through beautiful photos online. She's always up for good food and wine with friends, and she spends her weekends exploring new restaurants around town. Her outgoing and positive attitude draws people towards her, and they can't help but smile when they're around her. Jennifer's husband and their two kids mean everything to her, and they often go on fun adventures together.",
  },
  {
    isDefaultForLanguage: true,
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, AvaNeural)",
    avatarName: "Ashley",
    displayName: "Ashley (en-US)",
    gender: "Female",
    bio: "Ashley grew up in a small town surrounded by nature, which sparked her love for hiking and photography. She went on to study art in college and now works as a freelance photographer, capturing the beauty of the world she adores. She cherishes her close-knit family and enjoys visiting them on weekends. Her social circle is composed of like-minded individuals who share her passion for adventure and self-expression. Her positivity and upbeat attitude make her a joy to be around. In her free time, Ashley likes to cook and read books about history and mythology.",
  },
  {
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, BrandonNeural)",
    avatarName: "Brandon",
    displayName: "Brandon (en-US)",
    gender: "Male",
    bio: "Brandon grew up on a farm in the heart of rural America. He spent most of his childhood playing with his three siblings and helping his parents tend to the crops and animals. As he got older, Brandon discovered a passion for music and spent hours practicing the guitar. He also loved spending time outdoors, hiking and camping with his friends. Brandon now lives in a small town with his wife and two kids, and continues to play music and enjoy the simple things in life.",
  },
  {
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, ChristopherNeural)",
    avatarName: "Christopher",
    displayName: "Christopher (en-US)",
    gender: "Male",
    bio: "Christopher grew up in a small town in the Midwest with his parents and younger sister. He developed a love for photography and music at a young age and continues to pursue those interests today. He often goes on weekend adventures with his close-knit group of friends from college and enjoys playing the guitar around the campfire. He met his wife, Sarah, at a local concert and they now have two children together. Christopher is known for his infectious positivity and can often be found volunteering in his community.",
  },
  {
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, DavisNeural)",
    avatarName: "Davis",
    displayName: "Davis (en-US)",
    gender: "Male",
    bio: "Davis is a 35-year-old male who was born and raised in Kentucky, USA. He grew up on a small farm where he developed a love for the outdoors and animals. In his free time, he enjoys hiking, camping, and fishing with his friends and family. Davis is also an avid reader and enjoys exploring new books in his local library. He works as a high school science teacher and is known for his friendly, positive attitude that inspires his students. Davis is happily married to his wife, Rachel, and they have two young children together.",
  },
  {
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, ElizabethNeural)",
    avatarName: "Elizabeth",
    displayName: "Elizabeth (en-US)",
    gender: "Female",
    bio: "Elizabeth grew up in a small town in the Midwest. She loved spending time outdoors, playing soccer with her friends, and reading books. She moved to the city to attend college, where she pursued her passion for writing and journalism. In her free time, she enjoys trying new restaurants with her friends and taking her dog on long walks. Elizabeth values family above everything else and regularly visits her parents who still live in the town she grew up in. She radiates positivity and always tries to make those around her smile.",
  },
  {
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, EricNeural)",
    avatarName: "Eric",
    displayName: "Eric (en-US)",
    gender: "Male",
    bio: "Eric is a 35-year-old man from the United States who loves playing basketball and hiking with his friends. He has a close-knit family, and they often gather around the table for dinner with lively conversation. When he's not spending time with loved ones, Eric pursues his interests in music production and graphic design. He maintains a positive outlook on life and is known for his friendly and happy demeanor. His outgoing personality has earned him many loyal friends over the years, and he's always eager to make new ones.",
  },
  {
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, JacobNeural)",
    avatarName: "Jacob",
    displayName: "Jacob (en-US)",
    gender: "Male",
    bio: "Jacob grew up on a farm in Iowa with his parents and older sister. He loved spending time outdoors and enjoyed nature photography. Jacob was always an animal lover and would even rescue injured animals. He went to college and studied journalism which led him to a career in broadcast news. When Jacob is not working, he likes to spend time with his friends, trying out new restaurants and going to concerts. He is married to his high school sweetheart and they have two children together. Jacob is always positive and happy, with a warm smile and a friendly personality.",
  },
  {
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, JaneNeural)",
    avatarName: "Jane",
    displayName: "Jane (en-US)",
    gender: "Female",
    bio: "Jane grew up in a small town in the midwest with her loving parents and two younger brothers. She always had a passion for art, constantly drawing and painting in her free time. This passion led her to study art in college where she made lifelong friends. After graduation, she moved to the city where she now works as a freelance graphic designer. In her free time, Jane loves going to concerts, trying new foods with her friends, and spending time with her adorable dog. Her positive outlook on life always brightens the days of those around her.",
  },
  {
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, JasonNeural)",
    avatarName: "Jason",
    displayName: "Jason (en-US)",
    gender: "Male",
    bio: "Jason grew up on a farm in rural Indiana with his parents and three siblings. He developed a love for technology and spent most of his free time tinkering with computers. He studied computer science in college, where he made lifelong friends. Now a successful software engineer in San Francisco, he enjoys exploring the city with his girlfriend and playing basketball with his coworkers. Jason's positive attitude and infectious smile make him a beloved member of his community.",
  },
  {
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, MichelleNeural)",
    avatarName: "Michelle",
    displayName: "Michelle (en-US)",
    gender: "Female",
    bio: "Michelle grew up in a small town in the American South, surrounded by loving family and loyal friends. She discovered a passion for cooking at a young age, and now runs her own successful restaurant. In her free time, she loves to hike and explore the nearby national park with her husband and two young children. Her optimistic nature and kind demeanor make her a well-liked member of the community. Despite the challenges of running a business, Michelle remains dedicated to her family and friends, always finding time to lend an ear or lend a hand.",
  },
  {
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, MonicaNeural)",
    avatarName: "Monica",
    displayName: "Monica (en-US)",
    gender: "Female",
    bio: "Monica grew up in a close-knit family in a small town. She loved spending time outdoors and developed a passion for hiking and gardening. As she grew older, she discovered a love for music and began to play guitar and sing. She moved to the city for college and made lifelong friends who shared her positive outlook on life. Now in her mid-thirties, Monica balances her time between her career as a teacher and her hobbies. She still loves spending time with her family and friends, and can often be found hosting backyard BBQs and jam sessions.",
  },
  {
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, NancyNeural)",
    avatarName: "Nancy",
    displayName: "Nancy (en-US)",
    gender: "Female",
    bio: "Nancy was born and raised in a small town in the heartland of America. She has always been an outgoing and cheerful person, making friends easily. Nancy's hobbies include gardening, baking, and playing with her cat, Fluffy. Her love for adventure led her to travel the world, but she always returns home to her tight-knit family. Nancy's optimistic attitude and carefree spirit have inspired her friends and family to always look on the bright side of life.",
  },
  {
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, SaraNeural)",
    avatarName: "Sara",
    displayName: "Sara (en-US)",
    gender: "Female",
    bio: "Sara grew up in a small town and was always surrounded by loving family and friends. From a young age, she had a passion for nature and would often spend hours exploring the nearby woods. As she got older, she started to develop a love for photography and would often capture the beautiful landscapes around her. Sara is also an avid reader and loves to lose herself in a good book. She moved to the city for college but still enjoys visiting her hometown and reconnecting with old friends. Now, a successful environmentalist, she has maintained her positivity and friendly demeanor throughout her life.",
  },
  {
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, SteffanNeural)",
    avatarName: "Steffan",
    displayName: "Steffan (en-US)",
    gender: "Male",
    bio: "Steffan is an outgoing and sociable guy from the heartland of America. Growing up he had a passion for sports and was known for his love of basketball. He still plays in a local league with his childhood friends whenever he can. Steffan is married to his college sweetheart and they have two kids, a boy and a girl. He enjoys spending his weekends with his family and going on hikes with his dog. Steffan works as a sales representative and is known for his positive attitude and friendly demeanor.",
  },
  {
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, TonyNeural)",
    avatarName: "Tony",
    displayName: "Tony (en-US)",
    gender: "Male",
    bio: "Tony grew up in a small town in the midwestern USA. He loved spending time outdoors and developed a passion for hiking and camping from a young age. Tony was always a friendly, positive, and happy guy, and he had a close group of friends who shared his love of the great outdoors. After college, Tony became a park ranger and spent several years exploring the country's national parks. Now, at 35, he's settled down with his wife and two kids, but still spends every spare moment hiking and camping with his family and friends.",
  },
  {
    langCode: "en-ZA",
    id: "Microsoft Server Speech Text to Speech Voice (en-ZA, LeahNeural)",
    avatarName: "Leah",
    displayName: "Leah (en-ZA)",
    gender: "Female",
    bio: "Leah had always been an adventurous spirit with a passion for the outdoors. Growing up on a farm in South Africa, she spent most of her childhood horse riding, hiking, and skiing. After studying environmental science in college, she now works as a park ranger and enjoys educating visitors about the importance of preserving nature. In her free time, she still loves to explore the great outdoors with her close-knit group of friends and her two dogs. Her positive attitude and infectious smile brighten the lives of all those around her, including her close-knit family who she visits regularly.",
  },
  {
    langCode: "en-ZA",
    id: "Microsoft Server Speech Text to Speech Voice (en-ZA, LukeNeural)",
    avatarName: "Luke",
    displayName: "Luke (en-ZA)",
    gender: "Male",
    bio: "Luke is a 35-year-old man from South Africa, where he grew up surrounded by the vast nature and animals that inhabit it. He enjoys exploring the outdoors and going on safaris with his family and friends. When not outside, he loves playing guitar and singing, his passion since a young age. Luke's positivity and happy-go-lucky nature make him popular among his peers, and he always brightens up the room with his infectious smile. He is adored by his big family, who often gather for braais (barbecues) and share stories of their adventurous lives.",
  },
  {
    langCode: "es-AR",
    id: "Microsoft Server Speech Text to Speech Voice (es-AR, ElenaNeural)",
    avatarName: "Elena",
    displayName: "Elena (es-AR)",
    gender: "Female",
    bio: "Elena grew up in a close-knit family in the vibrant city of Buenos Aires. Passionate about the arts and culture of her country, Elena studied literature and music at university. She spends weekends exploring museums and attending theatre performances with her group of creative friends. Elena's positive energy and warm personality make her beloved by all who know her. She finds joy in bringing friends and family together over home-cooked meals and lively conversations. Life is never dull with Elena around.",
  },
  {
    langCode: "es-AR",
    id: "Microsoft Server Speech Text to Speech Voice (es-AR, TomasNeural)",
    avatarName: "Tomas",
    displayName: "Tomas (es-AR)",
    gender: "Male",
    bio: "Tomas grew up in a close-knit family in Argentina, where he developed a love for soccer and music. He plays guitar and sings in a band with his childhood friends, who remain an important part of his life. Tomas is also passionate about cooking and often hosts dinner parties for friends and family. His infectious smile and positive attitude have earned him many friends, both at home and abroad. Now in his mid-thirties, Tomas continues to pursue his hobbies with the same enthusiasm as he did when he was younger, always eager to share his joy with others.",
  },
  {
    langCode: "es-BO",
    id: "Microsoft Server Speech Text to Speech Voice (es-BO, MarceloNeural)",
    avatarName: "Marcelo",
    displayName: "Marcelo (es-BO)",
    gender: "Male",
    bio: "Marcelo grew up in the heart of Bolivia, surrounded by nature and the sound of traditional folk music. He inherited his positivity and love for life from his family, who run a small restaurant where they serve the best empanadas in town. Music and dance have always been Marcelo's passion, and he often spends weekends playing guitar with his friends or hosting dance parties with his wife and two children. Marcelo's contagious energy and welcoming personality make him loved by everyone in his community, and he takes pride in spreading happiness wherever he goes.",
  },
  {
    langCode: "es-BO",
    id: "Microsoft Server Speech Text to Speech Voice (es-BO, SofiaNeural)",
    avatarName: "Sofia",
    displayName: "Sofia (es-BO)",
    gender: "Female",
    bio: "Sofia was born and raised in Bolivia, where her love for dancing and exploring nature was cultivated. After completing her studies in environmental science, she moved to the United States to pursue her passion for protecting the environment. Now, as a successful conservation specialist, Sofia enjoys spending her free time hiking in the mountains with her husband and two children. Her positive and friendly nature has also allowed her to build strong friendships, especially within her local dance community where she teaches traditional Bolivian dances. Sofia is happy and grateful for her life and all its blessings.",
  },
  {
    langCode: "es-CL",
    id: "Microsoft Server Speech Text to Speech Voice (es-CL, CatalinaNeural)",
    avatarName: "Catalina",
    displayName: "Catalina (es-CL)",
    gender: "Female",
    bio: "Catalina grew up in Chile surrounded by her loving family and friends. She always had a love for the outdoors, spending her weekends exploring the forests and beaches near her home. Her passion for hiking and camping led her to meet new people and make lasting friendships. Catalina's cheerful and bubbly personality makes her loved by everyone she encounters, and her dedication to fitness and healthy living reflects her positive attitude towards life. She now lives in the U.S., but still proudly shares her Chilean heritage and traditions with her own family.",
  },
  {
    langCode: "es-CL",
    id: "Microsoft Server Speech Text to Speech Voice (es-CL, LorenzoNeural)",
    avatarName: "Lorenzo",
    displayName: "Lorenzo (es-CL)",
    gender: "Male",
    bio: "Lorenzo grew up in Chile surrounded by a loving family who instilled a passion for family values and the great outdoors. As an adult, he moved to Buenos Aires where he found a community of friends who shared his love of music and dancing. Lorenzo enjoys hiking and football, and in his downtime loves to experiment in the kitchen. He is constantly seeking new experiences and loves meeting new people. Always positive and happy, Lorenzo's infectious laughter and friendly demeanor make him a joy to be around.",
  },
  {
    langCode: "es-CO",
    id: "Microsoft Server Speech Text to Speech Voice (es-CO, GonzaloNeural)",
    avatarName: "Gonzalo",
    displayName: "Gonzalo (es-CO)",
    gender: "Male",
    bio: "Gonzalo grew up in a loving family in a small town in Colombia. He loved spending time with his grandparents, listening to their stories and learning about his culture. He played soccer with his friends every chance he got, and also loved playing guitar and singing. Gonzalo studied engineering in college, but always found time for his hobbies. He moved to the city after graduation and made new friends through his love of music and sports. Today, he works as an engineer and still plays guitar on weekends with his friends, always keeping his positive and happy outlook on life.",
  },
  {
    langCode: "es-CO",
    id: "Microsoft Server Speech Text to Speech Voice (es-CO, SalomeNeural)",
    avatarName: "Salome",
    displayName: "Salome (es-CO)",
    gender: "Female",
    bio: "Salome is a bubbly 35-year-old from Colombia, fluent in the language of español. She adores dance and music, and spends her free time taking dance classes and attending concerts. Her closest friends are fellow salsa lovers who she met at a local dance club. She comes from a large, tight-knit family, and often hosts gatherings at her home where she prepares traditional Colombian dishes. Her positive outlook on life and infectious energy make her a joy to be around, and everyone who meets her immediately feels uplifted.",
  },
  {
    langCode: "es-CR",
    id: "Microsoft Server Speech Text to Speech Voice (es-CR, JuanNeural)",
    avatarName: "Juan",
    displayName: "Juan (es-CR)",
    gender: "Male",
    bio: "Juan grew up in a small village in Costa Rica surrounded by his close-knit family. Always interested in the outdoors, he spent most of his childhood exploring the lush forests and beautiful beaches of his hometown. His passion for nature led him to pursue a career in biology, where he now works as a field researcher. In his free time, Juan enjoys playing soccer with his buddies, cooking traditional Costa Rican meals, and traveling with his wife and children. His infectious smile and positive attitude have earned him a well-respected reputation in his community.",
  },
  {
    langCode: "es-CR",
    id: "Microsoft Server Speech Text to Speech Voice (es-CR, MariaNeural)",
    avatarName: "María",
    displayName: "María (es-CR)",
    gender: "Female",
    bio: "María is a cheerful and friendly woman from Costa Rica, where she grew up surrounded by her loving family. Her passion for dance started at a young age, and she has been an active member of her local dance community ever since. In her free time, she enjoys exploring nature, hiking, and spending time with her close-knit group of friends, who have been there for her through thick and thin. Her positive outlook on life, combined with her infectious energy, makes her a joy to be around, and she brightens up any room she walks into.",
  },
  {
    langCode: "es-CU",
    id: "Microsoft Server Speech Text to Speech Voice (es-CU, BelkysNeural)",
    avatarName: "Belkys",
    displayName: "Belkys (es-CU)",
    gender: "Female",
    bio: "Belkys is a bubbly, 35-year-old Cuban woman who is always surrounded by laughter and positivity. Her love for music and dance started at a young age and has been a constant in her life. She can often be found practicing salsa with her dance group or singing at local events. Family is everything to Belkys and she regularly hosts dinners with her large, close-knit family. Her infectious energy has garnered her many friends who love spending time with her. Belkys believes in making the most out of life and spreading joy wherever she goes.",
  },
  {
    langCode: "es-CU",
    id: "Microsoft Server Speech Text to Speech Voice (es-CU, ManuelNeural)",
    avatarName: "Manuel",
    displayName: "Manuel (es-CU)",
    gender: "Male",
    bio: "Manuel was born and raised in Havana, Cuba. He grew up with a love for music, playing the trumpet and listening to salsa with his family. Being a positive and friendly person, he made friends easily and loved spending time with them at the beach, playing soccer or just hanging out. Manuel's family was his rock and he always found comfort in their support. In his free time, he enjoyed cooking traditional Cuban dishes and trying out new recipes. Manuel's infectious smile and happy-go-lucky personality made him a beloved member of his community.",
  },
  {
    langCode: "es-DO",
    id: "Microsoft Server Speech Text to Speech Voice (es-DO, EmilioNeural)",
    avatarName: "Emilio",
    displayName: "Emilio (es-DO)",
    gender: "Male",
    bio: "Emilio grew up in the Dominican Republic, with a strong family of musicians, dancers and artists. He inherited their creative talents and became a passionate guitarist and singer. With his positive and friendly personality, he quickly made many friends who shared his love of music and dance. He moved to New York City where he continues to perform with his band, also finding time for his hobbies of cooking and playing basketball. Emilio cherishes his family and visits them often, always bringing new songs and dishes to share with them. His happiness and love for life is contagious.",
  },
  {
    langCode: "es-DO",
    id: "Microsoft Server Speech Text to Speech Voice (es-DO, RamonaNeural)",
    avatarName: "Ramona",
    displayName: "Ramona (es-DO)",
    gender: "Female",
    bio: "Ramona is a 35-year-old Dominican with a sunny disposition. She is known for her infectious smile and generous spirit. A passionate environmentalist, Ramona loves hiking, gardening and volunteering in her local community garden. She also enjoys dancing to traditional bachata music with her friends on weekends. Ramona grew up in a close-knit family and has a younger sister who she adores. She is grateful for the love and support of her family and friends who make her life rich and meaningful.",
  },
  {
    langCode: "es-EC",
    id: "Microsoft Server Speech Text to Speech Voice (es-EC, AndreaNeural)",
    avatarName: "Andrea",
    displayName: "Andrea (es-EC)",
    gender: "Female",
    bio: "Andrea is a charismatic and cheerful woman from Ecuador. Growing up in the countryside, she loved spending time outdoors and exploring nature. Now in her mid-thirties, she still loves hiking and camping with her close friends. Andrea is also an avid reader, enjoying books on science fiction and mystery novels. Her positivity and happiness are infectious, and she always looks for opportunities to brighten up someone's day. Family is essential to her, and she makes time to visit her sister and parents regularly. Andrea is a true advocate for a healthy and active lifestyle, and she inspires everyone who crosses her path.",
  },
  {
    langCode: "es-EC",
    id: "Microsoft Server Speech Text to Speech Voice (es-EC, LuisNeural)",
    avatarName: "Luis",
    displayName: "Luis (es-EC)",
    gender: "Male",
    bio: "Luis grew up in a small village in Ecuador with a large, loving family. He developed a passion for soccer at a young age and played for his local team. He moved to the city after finishing school and started working for a tourism company. In his free time, he enjoys playing guitar and singing with his friends in a local band. Despite homesickness, Luis is always positive and loves meeting new people. He's currently learning to surf and dreams of traveling the world with his wife and kids.",
  },
  {
    langCode: "es-ES",
    id: "Microsoft Server Speech Text to Speech Voice (es-ES, AbrilNeural)",
    avatarName: "Abril",
    displayName: "Abril (es-ES)",
    gender: "Female",
    bio: "Abril grew up in a small town in Spain where she inherited her love for nature from her family. She enjoys spending time outdoors, going on hikes, and camping trips. In her free time, Abril also enjoys reading books and watching movies. She is very close to her family and has a few close friends from childhood. Abril is known for her positive and friendly demeanor which makes her well-liked in her community. She moved to Madrid for University and has stayed there ever since.",
  },
  {
    langCode: "es-ES",
    id: "Microsoft Server Speech Text to Speech Voice (es-ES, AlvaroNeural)",
    avatarName: "Álvaro",
    displayName: "Álvaro (es-ES)",
    gender: "Male",
    bio: "Álvaro grew up in a small town in southern Spain with his parents and two sisters. He always had a passion for music, often spending hours playing the guitar and singing. He moved to Madrid after college to pursue a music career and met his best friend, Carlos, who shared his love for Spanish indie rock. In his free time, Álvaro enjoys hiking in the mountains with his family and trying new restaurants with his girlfriend. He is always optimistic and brings joy to everyone around him with his infectious laughter.",
  },
  {
    langCode: "es-ES",
    id: "Microsoft Server Speech Text to Speech Voice (es-ES, ArnauNeural)",
    avatarName: "Arnau",
    displayName: "Arnau (es-ES)",
    gender: "Male",
    bio: "Arnau was born and raised in Barcelona, Spain. He grew up with a love for football and often spent his weekends playing with his friends in the local park. After finishing college, he developed a passion for photography, and now spends his free time capturing the beauty of the city he loves. Arnau is happily married to his childhood sweetheart, Ines, and they have two children. He values family above all else and often spends his weekends cooking traditional Spanish dishes and hosting dinner parties for his loved ones. Arnau's infectious smile and positive attitude make him a joy to be around.",
  },
  {
    langCode: "es-ES",
    id: "Microsoft Server Speech Text to Speech Voice (es-ES, DarioNeural)",
    avatarName: "Dario",
    displayName: "Dario (es-ES)",
    gender: "Male",
    bio: "Dario was born and raised in Madrid, Spain. He was always interested in sports, particularly soccer and tennis. Dario's family was his biggest support system and instilled a strong sense of community in him. He often spends time with his close group of friends, enjoying tapas and drinks at the local bar. Dario's hobby is photography, and he enjoys capturing the beauty of the city around him. His positive and friendly personality has given him many opportunities to connect with people from all over the world.",
  },
  {
    langCode: "es-ES",
    id: "Microsoft Server Speech Text to Speech Voice (es-ES, EliasNeural)",
    avatarName: "Elias",
    displayName: "Elias (es-ES)",
    gender: "Male",
    bio: "Elias grew up in rural Spain, where he spent most of his childhood playing in the fields and helping his family with their farm. As he grew older, he developed a love for music and started playing guitar with his friends in a local band. After finishing university in Madrid, Elias moved back to his hometown, where he now teaches music and plays in small gigs on weekends. He still helps out on the family farm and spends most of his free time exploring new hiking trails with his beloved Golden Retriever, Luna. Elias is a true country boy at heart, with a love for simple things and a positive attitude towards life.",
  },
  {
    langCode: "es-ES",
    id: "Microsoft Server Speech Text to Speech Voice (es-ES, ElviraNeural)",
    avatarName: "Elvira",
    displayName: "Elvira (es-ES)",
    gender: "Female",
    bio: "Elvira is a cheerful and lively 35-year-old woman who hails from Barcelona. With her warm personality, she can make friends with anyone she meets. She is a lover of music and spends her weekends singing karaoke with her friends. When she is not working as a nurse at a local hospital, she enjoys cooking Spanish delicacies for her husband and children. Her family and friends mean the world to her and she always puts their needs before hers. Elvira's positivity and zest for life make her a true ray of sunshine in anyone's life.",
  },
  {
    langCode: "es-ES",
    id: "Microsoft Server Speech Text to Speech Voice (es-ES, EstrellaNeural)",
    avatarName: "Estrella",
    displayName: "Estrella (es-ES)",
    gender: "Female",
    bio: "Estrella, 35, is a charming woman from Spain who loves spending time outdoors. She enjoys hiking and jogging in the countryside, as well as swimming in the ocean. Estrella's passions include art and music, and she often attends concerts and exhibitions with her friends. Her tight-knit family is a big part of her life, and she enjoys cooking traditional meals for them. Estrella is known for her sunny disposition and infectious optimism, always looking for the bright side of any situation.",
  },
  {
    langCode: "es-ES",
    id: "Microsoft Server Speech Text to Speech Voice (es-ES, IreneNeural)",
    avatarName: "Irene",
    displayName: "Irene (es-ES)",
    gender: "Female",
    bio: "Irene grew up in Madrid with her parents and older brother. She always had a passion for dancing and started taking ballet lessons at a young age. As she grew older, she became interested in other dance styles like salsa and flamenco. Irene's love for dance led her to make many friends who shared her passion. She later moved to Barcelona where she opened a dance studio, which quickly became popular in the city. Irene is still very close to her family and enjoys spending time with them whenever she can. Her positivity and joy for life are infectious and she brings a smile to everyone she meets.",
  },
  {
    langCode: "es-ES",
    id: "Microsoft Server Speech Text to Speech Voice (es-ES, LaiaNeural)",
    avatarName: "Laia",
    displayName: "Laia (es-ES)",
    gender: "Female",
    bio: "Laia grew up in a small village in Spain where she learned the importance of family values and community bonds. She found her true passion for cooking from her grandmother’s exquisite dishes. As a result, she decided to pursue culinary arts and traveled the world to explore new flavors and techniques. Laia loves spending time with her family and a close-knit group of friends, particularly during weekly dinners where they share stories and laughs over homemade meals. Her cheerful personality and warm smile make her a beloved member of the community.",
  },
  {
    langCode: "es-ES",
    id: "Microsoft Server Speech Text to Speech Voice (es-ES, LiaNeural)",
    avatarName: "Lia",
    displayName: "Lia (es-ES)",
    gender: "Female",
    bio: "Lia grew up in a small town in Spain and always had a love for dance, which led her to become a professional flamenco dancer. She moved to the city and met her best friend Marta, who shares her passion for dancing. Lia is also a devoted wife and mother to her two children. She loves spending time with her family and taking weekend trips to the beach. Lia's positive outlook on life and infectious smile make her a beloved member of her community.",
  },
  {
    langCode: "es-ES",
    id: "Microsoft Server Speech Text to Speech Voice (es-ES, NilNeural)",
    avatarName: "Nil",
    displayName: "Nil (es-ES)",
    gender: "Male",
    bio: "Nil grew up in a small town in Spain, where he spent most of his free time playing soccer and hanging out with his childhood friends. After completing university, he moved to Madrid to pursue a career in marketing. Despite his busy schedule, Nil sempre finds time for his favorite hobbies including reading science fiction novels and singing karaoke. He also enjoys spending time with his family who still resides in his hometown. Nil's relaxed attitude and infectious positivity have earned him many friends across the country.",
  },
  {
    langCode: "es-ES",
    id: "Microsoft Server Speech Text to Speech Voice (es-ES, SaulNeural)",
    avatarName: "Saul",
    displayName: "Saul (es-ES)",
    gender: "Male",
    bio: "Saul grew up in a small town in Spain surrounded by his close-knit family. He inherited his father's passion for cooking, and often hosts dinner parties for his friends, where he shares recipes and local wines. Saul's love for the outdoors sees him hiking the Pyrenees Mountains on weekends with his dog. He is also an active member of the local theatre group and enjoys performing in Spanish plays. Saul's positive energy and happy demeanor make him a popular member of his community, with friends and strangers alike always happy to see him.",
  },
  {
    langCode: "es-ES",
    id: "Microsoft Server Speech Text to Speech Voice (es-ES, TeoNeural)",
    avatarName: "Teo",
    displayName: "Teo (es-ES)",
    gender: "Male",
    bio: "Teo grew up in a small town in Spain, where he developed a love for soccer and guitar playing. He moved to the city to study engineering, but continued to pursue his hobbies on weekends. His positive energy and friendly demeanor led him to make many friends in his new city. Now married with two children, he enjoys teaching them to play soccer and guitar. He also volunteers at a local shelter and enjoys giving back to the community. Teo always has a smile on his face and is grateful for his loving family and fulfilling hobbies.",
  },
  {
    langCode: "es-ES",
    id: "Microsoft Server Speech Text to Speech Voice (es-ES, TrianaNeural)",
    avatarName: "Triana",
    displayName: "Triana (es-ES)",
    gender: "Female",
    bio: 'Triana is a passionate artist from Spain who loves painting and travelling. She adores spending time with her friends and family, especially her two younger sisters whom she constantly inspires to chase their dreams. In her free time, Triana enjoys cooking and takes pleasure in serving her friends and family with delicious Spanish cuisine. She is a social butterfly, always making new friends and spreading positivity wherever she goes. Her motto is "Life is too short to be unhappy," and Triana lives every day to the fullest with a smile on her face.',
  },
  {
    langCode: "es-ES",
    id: "Microsoft Server Speech Text to Speech Voice (es-ES, VeraNeural)",
    avatarName: "Vera",
    displayName: "Vera (es-ES)",
    gender: "Female",
    bio: "Vera grew up in a small coastal town in Spain and spent her childhood swimming in the warm waters of the Mediterranean. She developed a deep love for the ocean and now spends her free time surfing and boating. Vera is married to her best friend Pablo, and together they have two energetic daughters. She enjoys painting, photography, and traveling with her close group of friends. Her positivity and contagious smile is what people love about her, making her a popular figure in the community.",
  },
  {
    langCode: "es-GQ",
    id: "Microsoft Server Speech Text to Speech Voice (es-GQ, JavierNeural)",
    avatarName: "Javier",
    displayName: "Javier (es-GQ)",
    gender: "Male",
    bio: "Javier grew up in a small town in Guinea Ecuatorial. He was always surrounded by family and friends who loved music and dance. Javier inherited this love as well and became a skilled dancer and musician. He moved to the city for work and met his wife, who shares his passion for music and dance. They have two children and often perform together as a family band. Javier's positive attitude and friendly nature make him a beloved member of his community. He often hosts neighborhood parties where everyone can dance and have fun.",
  },
  {
    langCode: "es-GQ",
    id: "Microsoft Server Speech Text to Speech Voice (es-GQ, TeresaNeural)",
    avatarName: "Teresa",
    displayName: "Teresa (es-GQ)",
    gender: "Female",
    bio: "Teresa grew up in the vibrant city of Malabo, Guinea Ecuatorial surrounded by her large and loving family. Her passions include dancing to the beat of drum rhythms, indulging in spicy cuisine and exploring the vast diversity of her country. After studying abroad as a young adult, she returned home with a newfound appreciation for her culture and community. She spends her days working as a teacher, building lasting friendships and spreading positivity wherever she goes. Teresa's infectious smile and radiant personality are beloved by all who cross her path.",
  },
  {
    langCode: "es-GT",
    id: "Microsoft Server Speech Text to Speech Voice (es-GT, AndresNeural)",
    avatarName: "Andrés",
    displayName: "Andrés (es-GT)",
    gender: "Male",
    bio: "Andrés grew up in the vibrant city of Guatemala with his close-knit family. He always had a passion for music and spent his weekends jamming with his friends in local bars. As he got older, he developed a love for photography and started documenting his travels across Central America. On his last trip, he met his partner, a fellow traveler who shared his love for adventure. Andrés now lives happily with his partner and their two cats, still playing music and capturing the beauty of the world through his camera lens.",
  },
  {
    langCode: "es-GT",
    id: "Microsoft Server Speech Text to Speech Voice (es-GT, MartaNeural)",
    avatarName: "Marta",
    displayName: "Marta (es-GT)",
    gender: "Female",
    bio: "Marta grew up in a small community in Guatemala, where she developed her love for art and painting. She moved to the city to study and became a successful artist, always creating beautiful pieces that reflected her positive and happy nature. She loves spending time with her friends and family, often organizing gatherings or cooking meals for them. Marta enjoys salsa dancing and traveling to new places, always finding inspiration for her next artwork. Her warm and friendly personality makes her a favorite amongst everyone she meets, and her talents continue to shine.",
  },
  {
    langCode: "es-HN",
    id: "Microsoft Server Speech Text to Speech Voice (es-HN, CarlosNeural)",
    avatarName: "Carlos",
    displayName: "Carlos (es-HN)",
    gender: "Male",
    bio: "Carlos grew up in Honduras surrounded by his loving family. He developed a passion for soccer at a young age, spending all his free time playing with his friends in the local field. He moved to the United States at age 20, where he discovered a love for cooking and became a chef. Now, he enjoys gathering with his close-knit group of friends, watching soccer games on weekends and cooking traditional Honduran dishes for them. Despite some struggles, Carlos remains positive and happy, thankful for his wonderful family, friends, and the opportunities he's had in life.",
  },
  {
    langCode: "es-HN",
    id: "Microsoft Server Speech Text to Speech Voice (es-HN, KarlaNeural)",
    avatarName: "Karla",
    displayName: "Karla (es-HN)",
    gender: "Female",
    bio: "Karla grew up in Honduras with her parents and two older brothers. She always had a passion for performing arts, specifically dancing and singing. Her outgoing and friendly personality helped her form many close friendships throughout her childhood and teenage years. Karla moved to the United States in her twenties, where she pursued her passion for dancing by joining a local dance group. She now teaches dance to children and adults in her community while also performing at various events. Despite being far from her family, Karla remains positive and happy, enjoying her hobbies and surrounding herself with good friends.",
  },
  {
    langCode: "es-MX",
    id: "Microsoft Server Speech Text to Speech Voice (es-MX, BeatrizNeural)",
    avatarName: "Beatriz",
    displayName: "Beatriz (es-MX)",
    gender: "Female",
    bio: "Beatriz was born and raised in Mexico City, where she grew up surrounded by her loving family. From a young age, she discovered a passion for exploring the outdoors and being active. She became an avid hiker and enjoys going on weekend trips to the countryside with her friends. Beatriz also loves to cook traditional Mexican dishes and often hosts dinner parties for her loved ones. Her infectious positivity and warm personality make her a natural friend to many, always willing to lend a listening ear or share a good laugh.",
  },
  {
    langCode: "es-MX",
    id: "Microsoft Server Speech Text to Speech Voice (es-MX, CandelaNeural)",
    avatarName: "Candela",
    displayName: "Candela (es-MX)",
    gender: "Female",
    bio: "Candela is a 35-year-old woman who was born and raised in Mexico. She is friendly, positive and happy, and her interests include art, music, and cooking traditional Mexican dishes. Candela enjoys spending time with her family and close group of friends, who she sees as her support system. She has a talent for bringing people together and often hosts Mexican-themed dinner parties where everyone can enjoy good food and great company. Candela's love for her culture and community has shaped her into the warm-hearted and gracious person she is today.",
  },
  {
    langCode: "es-MX",
    id: "Microsoft Server Speech Text to Speech Voice (es-MX, CarlotaNeural)",
    avatarName: "Carlota",
    displayName: "Carlota (es-MX)",
    gender: "Female",
    bio: "Meet Carlota, a 35-year-old woman from Mexico. As a child, Carlota developed a love for art, spending hours creating paintings and sculptures. This passion led her to study Fine Arts in college, where she made lifelong friends. Carlota is happily married and has two children. She enjoys spending time with her family, hiking in the mountains, and cooking traditional Mexican dishes. Her infectious love of life has made her many friends, and Carlota is known for her positive outlook and friendly demeanor in her community.",
  },
  {
    langCode: "es-MX",
    id: "Microsoft Server Speech Text to Speech Voice (es-MX, CecilioNeural)",
    avatarName: "Cecilio",
    displayName: "Cecilio (es-MX)",
    gender: "Male",
    bio: "Cecilio, 35, is a happy-go-lucky guy from Mexico. Growing up, he loved going to his grandmother's farm and riding horses. Nowadays, he still rides horses as a hobby, often joining his closest friends for group rides around the countryside. Cecilio is a family man, enjoying Sunday barbecues with his wife and kids. With a love of music, he's always up for karaoke or attending live shows with his wife. Even though he's always busy with work as a car mechanic, Cecilio has never lost his friendly, positive demeanor, always making those around him feel at ease.",
  },
  {
    langCode: "es-MX",
    id: "Microsoft Server Speech Text to Speech Voice (es-MX, DaliaNeural)",
    avatarName: "Dalia",
    displayName: "Dalia (es-MX)",
    gender: "Female",
    bio: "Dalia was raised in Mexico City surrounded by a large and loving family. Her childhood was filled with exploring museums, trying new foods, and learning to dance traditional Mexican folkloric dances. As an adult, Dalia pursued her love of cooking and became a talented chef. She enjoys hosting dinner parties for her friends, who are like family to her. Her positive attitude and infectious laughter always uplift those around her. Despite living in Barcelona now, Dalia remains connected to her Mexican roots through her hobbies and friendships.",
  },
  {
    langCode: "es-MX",
    id: "Microsoft Server Speech Text to Speech Voice (es-MX, GerardoNeural)",
    avatarName: "Gerardo",
    displayName: "Gerardo (es-MX)",
    gender: "Male",
    bio: "Gerardo grew up in a vibrant community in Mexico with his parents and three older sisters. He has always had a passion for music and learned to play guitar from his father at a young age. Gerardo's positive attitude and friendly demeanor have helped him make friends easily throughout his life. He enjoys spending time with his close group of friends, playing music together and exploring the outdoors. Gerardo is happily married to his wife, Ana, and they have a young daughter who loves to sing and dance along with her dad's guitar playing.",
  },
  {
    langCode: "es-MX",
    id: "Microsoft Server Speech Text to Speech Voice (es-MX, JorgeNeural)",
    avatarName: "Jorge",
    displayName: "Jorge (es-MX)",
    gender: "Male",
    bio: "Jorge was born and raised in Mexico City. He loved playing soccer with his friends and spending weekends with his family. Jorge studied engineering and continued to play soccer in his free time. He met his future wife, Maria, at a local bar where they bonded over their love for music. Jorge and Maria have been happily married for 8 years and have 2 young children. When he's not spending time with his family, Jorge enjoys playing guitar and trying new recipes in the kitchen. He values his close friendships and his positive attitude brings joy to those around him.",
  },
  {
    langCode: "es-MX",
    id: "Microsoft Server Speech Text to Speech Voice (es-MX, LarissaNeural)",
    avatarName: "Larissa",
    displayName: "Larissa (es-MX)",
    gender: "Female",
    bio: "Larissa grew up in Mexico City, surrounded by a loving family and a strong cultural heritage. Her passion for art and design began at a young age and led her to study fine arts in college. Now in her mid-thirties, Larissa spends most of her free time creating beautiful handmade crafts, hiking with her adventurous group of friends, and enjoying traditional Mexican cuisine with her family. Her contagious positivity and warmth make her a popular figure in her community and a favorite amongst everyone who knows her.",
  },
  {
    langCode: "es-MX",
    id: "Microsoft Server Speech Text to Speech Voice (es-MX, LibertoNeural)",
    avatarName: "Liberto",
    displayName: "Liberto (es-MX)",
    gender: "Male",
    bio: "Liberto was born in Mexico City 35 years ago. As a child, he showed a love for music and started playing the guitar at age 10. His passion for music led him to form a band with his childhood friends, and they have been playing together ever since. Liberto is also an avid reader and enjoys spending time with his wife and two children. He is known for his friendly and positive attitude, and his friends and family feel lucky to have him in their lives.",
  },
  {
    langCode: "es-MX",
    id: "Microsoft Server Speech Text to Speech Voice (es-MX, LucianoNeural)",
    avatarName: "Luciano",
    displayName: "Luciano (es-MX)",
    gender: "Male",
    bio: "Luciano was born in a small town in Mexico. He grew up surrounded by his loving family and friends who encouraged him to pursue his interests in music and art. In his free time, Luciano enjoys playing the guitar and creating paintings inspired by the colorful landscapes of his homeland. He also loves to socialize and can often be found enjoying a good conversation and a cold beer with his buddies. Despite life's ups-and-downs, Luciano remains a positive and happy person who spreads joy wherever he goes.",
  },
  {
    langCode: "es-MX",
    id: "Microsoft Server Speech Text to Speech Voice (es-MX, MarinaNeural)",
    avatarName: "Marina",
    displayName: "Marina (es-MX)",
    gender: "Female",
    bio: "Marina was born in Mexico City and grew up surrounded by a supportive family who instilled in her the importance of staying positive and pursuing her passions. She developed a love for music and dance, learning traditional Mexican styles from a young age. As an adult, she continued to explore her interests by joining a local dance troupe and making new friends. Her infectious happiness and warmth have made her a beloved member of her community. Marina's upbeat attitude and close relationships with family and friends keep her grounded and happy in everything she does.",
  },
  {
    langCode: "es-MX",
    id: "Microsoft Server Speech Text to Speech Voice (es-MX, NuriaNeural)",
    avatarName: "Nuria",
    displayName: "Nuria (es-MX)",
    gender: "Female",
    bio: "Nuria was born and raised in a small town in México. She grew up surrounded by her loving family and developed a passion for cooking and entertaining. After moving to the city, she discovered a love for salsa dancing and quickly made many friends in the dance community. Nuria now spends her weekends hosting dinner parties and dancing the night away with her friends. Her positive energy is contagious, and everyone loves being around her. Despite the challenges of city life, Nuria remains grounded in her strong family values and is grateful for her supportive community.",
  },
  {
    langCode: "es-MX",
    id: "Microsoft Server Speech Text to Speech Voice (es-MX, PelayoNeural)",
    avatarName: "Pelayo",
    displayName: "Pelayo (es-MX)",
    gender: "Male",
    bio: "Pelayo grew up in a small town in Mexico, surrounded by lush greenery and a tight-knit community. His parents, both teachers, instilled in him a love for learning and exploration. Pelayo loved spending time outdoors, hiking and exploring nearby caves. He also had a passion for baking and would often bring his homemade goodies to share with his friends and family. Now in his mid-thirties, Pelayo works as a tour guide, sharing his knowledge of the local flora and fauna with visitors from all over the world. He is known for his infectious laughter and his ability to make anyone feel welcome.",
  },
  {
    langCode: "es-MX",
    id: "Microsoft Server Speech Text to Speech Voice (es-MX, RenataNeural)",
    avatarName: "Renata",
    displayName: "Renata (es-MX)",
    gender: "Female",
    bio: "Renata grew up in a large, close-knit family in Guadalajara, Mexico. From a young age, she showed a talent for music and developed a love for playing the guitar. In her twenties, she moved to Mexico City to attend university where she met her best friends, who shared her passion for exploring different cultures and cuisines. Renata is now a successful musician and avid globetrotter, always seeking out new experiences and making new friends along the way. Her infectious positivity and cheerful demeanor make her a beloved presence wherever she goes.",
  },
  {
    langCode: "es-MX",
    id: "Microsoft Server Speech Text to Speech Voice (es-MX, YagoNeural)",
    avatarName: "Yago",
    displayName: "Yago (es-MX)",
    gender: "Male",
    bio: "Yago grew up in a small village in rural Mexico with his parents and two younger sisters. He developed a love for music at a young age and taught himself how to play the guitar. He often played at local events and gatherings and formed a band with his childhood friends. Yago also enjoys cooking and experimenting with new recipes. He moved to the city for college and graduated with a degree in music education. He now teaches music at a local school and continues to play in his band on weekends. Yago is happily married with two children and has a close relationship with his family.",
  },
  {
    langCode: "es-NI",
    id: "Microsoft Server Speech Text to Speech Voice (es-NI, FedericoNeural)",
    avatarName: "Federico",
    displayName: "Federico (es-NI)",
    gender: "Male",
    bio: "Federico grew up in a warm, close-knit family in Nicaragua. He always had a passion for soccer and spent most of his free time playing with friends. He attended university in the capital city of Managua and graduated with a degree in engineering. Federico's outgoing personality helped him make friends easily, and he loved hosting parties and barbecues. He met his wife, Maria, at a wedding and they have been happily married for five years. Federico now enjoys spending time with his young son and daughter, coaching their soccer teams and exploring new hiking trails with his family.",
  },
  {
    langCode: "es-NI",
    id: "Microsoft Server Speech Text to Speech Voice (es-NI, YolandaNeural)",
    avatarName: "Yolanda",
    displayName: "Yolanda (es-NI)",
    gender: "Female",
    bio: "Yolanda grew up in a small village in Nicaragua, surrounded by family and friends who loved music and dance. Influenced by her community, she developed a passion for salsa dancing and discovered an incredible talent for it. Her positive and happy personality, along with her love for people, made her quickly popular among her peers. After completing her studies in tourism, she decided to start her own salsa school, where she teaches children and adults alike. Yolanda's family remains close, and she often hosts dance parties with her friends, where they share their love of music and laughter.",
  },
  {
    langCode: "es-PA",
    id: "Microsoft Server Speech Text to Speech Voice (es-PA, MargaritaNeural)",
    avatarName: "Margarita",
    displayName: "Margarita (es-PA)",
    gender: "Female",
    bio: "Margarita is a vibrant 35-year-old woman originally from Panamá. She is known for her infectious positivity and love for life. Margarita is a talented artist who loves to paint and sketch in her free time. She also enjoys hiking in the mountains and exploring new places. She values her relationships with friends and family, who she adores spending time with. Margarita moved to America several years ago to pursue her dreams, and now she's a successful graphic designer. She's proud of her heritage and enjoys cooking traditional Panamanian dishes for her friends.",
  },
  {
    langCode: "es-PA",
    id: "Microsoft Server Speech Text to Speech Voice (es-PA, RobertoNeural)",
    avatarName: "Roberto",
    displayName: "Roberto (es-PA)",
    gender: "Male",
    bio: "Roberto is a charismatic and outgoing 35-year-old from Panama, known for his positive outlook on life. he enjoys practicing his fluent Spanish skills with his family, and takes frequent trips home to visit his parents and siblings. In his free time, Roberto can be found playing pickup soccer games with his close friends, enjoying traditional Latin American cuisine, or exploring new hiking trails in the nearby mountains. Regardless of what he's doing, Roberto always wears a smile on his face and radiates a contagious happiness that brings joy to those around him.",
  },
  {
    langCode: "es-PE",
    id: "Microsoft Server Speech Text to Speech Voice (es-PE, AlexNeural)",
    avatarName: "Alex",
    displayName: "Alex (es-PE)",
    gender: "Male",
    bio: "Alex was born and raised in Lima, Peru, to a family of musicians. Music has been a central part of his life since childhood. His parents taught him to play the guitar and sing. Throughout his teenage years, Alex played in a band with his friends and performed at local events. Later in life, he pursued a career in music education and taught at a local college. Alex's positive personality and love for music have helped him make many friends around the world. He's always happy to share his talents with others and brighten their day.",
  },
  {
    langCode: "es-PE",
    id: "Microsoft Server Speech Text to Speech Voice (es-PE, CamilaNeural)",
    avatarName: "Camila",
    displayName: "Camila (es-PE)",
    gender: "Female",
    bio: "Camila grew up in a small town in Peru where she inherited her love for cooking from her grandmother. She moved to the city to pursue her passion for cuisine and opened a restaurant, which quickly became a local favorite. Camila enjoys spending time with her husband and two young children, exploring new hiking trails and camping spots. She is known for her infectious smile and warm personality, which has earned her a close group of friends in her community. Camila believes in spreading positivity and happiness wherever she goes.",
  },
  {
    langCode: "es-PR",
    id: "Microsoft Server Speech Text to Speech Voice (es-PR, KarinaNeural)",
    avatarName: "Karina",
    displayName: "Karina (es-PR)",
    gender: "Female",
    bio: "Karina grew up in a small town in Puerto Rico surrounded by her tight-knit family. She has always been passionate about dance and music, spending most of her free time rehearsing with her friends. After moving to the city to study business, Karina found herself missing her roots and started attending cultural events to reconnect with her heritage. Eventually, she started her own dance troupe, showcasing the vibrant rhythms of Puerto Rico. Karina is now a successful businesswoman, but still finds joy in sharing her love of dance with others. She's known among her friends for her infectious energy and positivity.",
  },
  {
    langCode: "es-PR",
    id: "Microsoft Server Speech Text to Speech Voice (es-PR, VictorNeural)",
    avatarName: "Víctor",
    displayName: "Víctor (es-PR)",
    gender: "Male",
    bio: "Víctor grew up in a loving, tight-knit family in Puerto Rico. He was always interested in music, and started playing the guitar at a young age. As he got older, he discovered a passion for cooking and often hosts dinner parties for his friends. His outgoing and cheerful nature made him popular amongst his peers. After studying hospitality management in college, Víctor moved to Miami with his wife and son to pursue his dream of opening his own restaurant. He loves spending time with his family, practicing music, and trying new recipes in the kitchen.",
  },
  {
    langCode: "es-PY",
    id: "Microsoft Server Speech Text to Speech Voice (es-PY, MarioNeural)",
    avatarName: "Mario",
    displayName: "Mario (es-PY)",
    gender: "Male",
    bio: "Mario grew up in Asunción, Paraguay with his parents and two older siblings. He discovered his love of music at a young age, learning to play the guitar and singing in his church choir. As he got older, he developed a passion for soccer and spent his weekends playing with friends. Mario's positivity and warm personality made him popular with everyone he met, and he had a large circle of friends both in and out of school. After finishing university, he moved to the United States to pursue a career in music and continues to stay in touch with his family and friends back home.",
  },
  {
    langCode: "es-SV",
    id: "Microsoft Server Speech Text to Speech Voice (es-SV, LorenaNeural)",
    avatarName: "Lorena",
    displayName: "Lorena (es-SV)",
    gender: "Female",
    bio: "Lorena grew up in a colourful town in El Salvador with her parents and three sisters. Her love for dancing was evident at a young age and she soon became a skilled salsa dancer. Now, at 35 years old, she shares her passion by teaching salsa classes to both young and old. Lorena's positive energy and friendly personality have earned her many friends who admire her for her ability to bring happiness to any situation. Her biggest joy in life, however, is spending time with her tight-knit family who she loves more than anything in the world.",
  },
  {
    langCode: "es-SV",
    id: "Microsoft Server Speech Text to Speech Voice (es-SV, RodrigoNeural)",
    avatarName: "Rodrigo",
    displayName: "Rodrigo (es-SV)",
    gender: "Male",
    bio: "Rodrigo grew up in a small village in El Salvador. He was raised by his loving parents alongside two sisters. As a child, he developed a passion for salsa dancing and music, going to local festivals with his family. Despite facing challenges due to poverty, Rodrigo always stayed positive and became close to his community. In his free time, he enjoys playing soccer with friends and cooking traditional Salvadoran dishes. Now 35 years old, Rodrigo migrated to the US and continues to spread happiness with his infectious personality and love for life.",
  },
  {
    langCode: "es-US",
    id: "Microsoft Server Speech Text to Speech Voice (es-US, AlonsoNeural)",
    avatarName: "Alonso",
    displayName: "Alonso (es-US)",
    gender: "Male",
    bio: "Alonso grew up in a close-knit family in Southern California where his parents encouraged him to explore his passions. He developed a love for surfing and playing guitar, spending most of his free time on the beach with his best friend, Nate. He studied music in college and formed a band with Nate, playing shows around LA. Now in his mid-30s, Alonso is happily married with two kids and still surfs and plays music with his band on weekends. He values his tight-knit group of friends and is known in his community for being friendly, positive and always up for a good time.",
  },
  {
    langCode: "es-US",
    id: "Microsoft Server Speech Text to Speech Voice (es-US, PalomaNeural)",
    avatarName: "Paloma",
    displayName: "Paloma (es-US)",
    gender: "Female",
    bio: "Paloma was born in Miami to two Colombian parents. Growing up, she had a passion for dance and sports, often spending her days practicing ballet or playing soccer with her friends. As she got older, she pursued her love of traveling and started working as a flight attendant. Her contagious positivity and warmth have earned her many friends around the world. In her free time, Paloma enjoys trying new recipes, hiking with her husband, and teaching her daughters about Colombian culture and traditions.",
  },
  {
    langCode: "es-UY",
    id: "Microsoft Server Speech Text to Speech Voice (es-UY, MateoNeural)",
    avatarName: "Mateo",
    displayName: "Mateo (es-UY)",
    gender: "Male",
    bio: "Mateo grew up in Uruguay with his big family of six siblings. Always with a smile on his face, he developed a passion for photography and traveling. He studied Audiovisual Communication and pursued a successful career as a photographer. He made many friends along the way and loved exploring new countries and cultures. He is now settled in Spain with his wife and daughter, where he enjoys nature, soccer games with his mates, and family time at home. His optimistic outlook on life always shines through in his work and interactions with others.",
  },
  {
    langCode: "es-UY",
    id: "Microsoft Server Speech Text to Speech Voice (es-UY, ValentinaNeural)",
    avatarName: "Valentina",
    displayName: "Valentina (es-UY)",
    gender: "Female",
    bio: "Valentina was born and raised in Montevideo, Uruguay. She grew up in a large family with six siblings and developed a love for cooking and entertaining at a young age. She studied hospitality and eventually found her calling as a chef in a bustling restaurant. In her free time, Valentina enjoys taking dance classes and practicing yoga. She values spending time with her close group of friends, who have known each other since childhood. Overall, Valentina is a warm and joyful person who always sees the best in others.",
  },
  {
    langCode: "es-VE",
    id: "Microsoft Server Speech Text to Speech Voice (es-VE, PaolaNeural)",
    avatarName: "Paola",
    displayName: "Paola (es-VE)",
    gender: "Female",
    bio: "Paola, a 35-year-old Venezuelan, was always surrounded by friends and family. Growing up in a tight-knit community, she learned the importance of relationships early on. Her positive outlook on life and infectious attitude made her popular amongst everyone. Paola's love for dancing and music brought her closer to her friends, who would often throw dance parties at her house. When not dancing, Paola could be found in the kitchen, cooking up traditional Venezuelan dishes. Her passion for food even led her to start a small catering business. Paola's enthusiasm for life and her creativity made her loved by all who knew her.",
  },
  {
    langCode: "es-VE",
    id: "Microsoft Server Speech Text to Speech Voice (es-VE, SebastianNeural)",
    avatarName: "Sebastián",
    displayName: "Sebastián (es-VE)",
    gender: "Male",
    bio: "Sebastián grew up in a small town in Venezuela with his loving family. He inherited his love for music from his father and piano became his passion. After completing his degree in music, he moved to the city, where he made lifelong friends playing in local bands. His positivity and friendly demeanor made him a community favorite, and he became a fixture in the local music scene. At 35, he continues to play and teach music, living a happy and fulfilling life surrounded by the people he loves.",
  },
  {
    langCode: "et-EE",
    id: "Microsoft Server Speech Text to Speech Voice (et-EE, AnuNeural)",
    avatarName: "Anu",
    displayName: "Anu (et-EE)",
    gender: "Female",
    bio: "Anu was born and raised in Estonia, she grew up embracing the country's culture and values. Her interests include reading and learning new things. She loves to go to the gym to stay fit and healthy, and also enjoys spending time with her friends and family. Her close relationship with her parents has had a positive effect on her life, making her the happy and optimistic person she is today. Despite facing challenges, she remains positive and friendly, always striving to bring out the best in herself and others.",
  },
  {
    langCode: "et-EE",
    id: "Microsoft Server Speech Text to Speech Voice (et-EE, KertNeural)",
    avatarName: "Kert",
    displayName: "Kert (et-EE)",
    gender: "Male",
    bio: "Kert grew up in a small village in Estonia, surrounded by sprawling forests and pristine lakes. He was raised by his grandparents, who instilled in him a love of nature and a passion for traditional Estonian music. Now in his mid-thirties, Kert spends most of his free time playing the accordion at local festivals and events. He has a close-knit group of friends who share his love of music and the outdoors, and he is always quick to offer a helping hand to anyone in need. Despite life's challenges, Kert remains friendly, positive, and happy, a true embodiment of Estonian spirit.",
  },
  {
    langCode: "eu-ES",
    id: "Microsoft Server Speech Text to Speech Voice (eu-ES, AinhoaNeural)",
    avatarName: "Ainhoa",
    displayName: "Ainhoa (eu-ES)",
    gender: "Female",
    bio: "Ainhoa is a 35 year old woman from Euskal Herria, a land where the unique language of Euskara is spoken. She is a woman with many interests, including cooking, hiking, and reading novels. Ainhoa values her friendships highly and loves spending time with her close-knit group of friends, usually over big meals at her home. Family is also very important to Ainhoa, especially her parents who work in agriculture. Her positive attitude and friendly demeanor have helped her build strong relationships with those around her, and she is known for spreading happiness wherever she goes.",
  },
  {
    langCode: "eu-ES",
    id: "Microsoft Server Speech Text to Speech Voice (eu-ES, AnderNeural)",
    avatarName: "Ander",
    displayName: "Ander (eu-ES)",
    gender: "Male",
    bio: "Ander, 35, hails from the Basque Country, where euskara is spoken, a language close to his heart. Keen on rekindling Basque customs, he actively participates in the local Basque festival. Ander's down to earth and optimistic attitude attracts a diverse friend circle. On weekends, he enjoys experimenting with his mom's traditional Basque recipes or playing soccer with his cousins. A happily married man, he cherishes his weekends off work to spend quality time with his wife and two kids, who share in his love for traditional Basque activities.",
  },
  {
    langCode: "fa-IR",
    id: "Microsoft Server Speech Text to Speech Voice (fa-IR, DilaraNeural)",
    avatarName: "دلارا",
    displayName: "دلارا (fa-IR)",
    gender: "Female",
    bio: "دلارا grew up in Tehran, where her love for art and music was cultivated by her parents. She was the eldest of four sisters and always managed to bring her family together with her joyful personality. After completing her studies in graphic design, she moved to London where she discovered her passion for photography. She often spends her weekends exploring new locations with her camera and is a regular at her local salsa club. Her warm personality has allowed her to make many friends, and she often invites them over for dinner parties - cooking Persian dishes to share with those she loves.",
  },
  {
    langCode: "fi-FI",
    id: "Microsoft Server Speech Text to Speech Voice (fi-FI, SelmaNeural)",
    avatarName: "Selma",
    displayName: "Selma (fi-FI)",
    gender: "Female",
    bio: "Selma was born and raised in Finland, where she developed a passion for nature. You could always find her hiking, exploring, or gardening. She loved spending time with her family and friends, who were her biggest supporters. Selma was driven by her positivity and kindness and always found the best in people. Despite her shy exterior, Selma was always eager to meet new people and make new friends. Her warm personality and cheerful demeanor made everyone feel welcome in her company.",
  },
  {
    langCode: "fi-FI",
    id: "Microsoft Server Speech Text to Speech Voice (fi-FI, NooraNeural)",
    avatarName: "Noora",
    displayName: "Noora (fi-FI)",
    gender: "Female",
    bio: "Noora grew up in a small town in Finland where she developed a love for nature and the outdoors. Her hobbies include hiking, skiing, and bird watching. She is also an avid reader and loves spending time with her family and friends. Noora is known for her infectious positivity and genuine kindness. After studying environmental science in college, Noora has worked to promote sustainability in her community. She lives with her partner and their two dogs in a cozy cottage in the forest.",
  },
  {
    langCode: "fil-PH",
    id: "Microsoft Server Speech Text to Speech Voice (fil-PH, AngeloNeural)",
    avatarName: "Angelo",
    displayName: "Angelo (fil-PH)",
    gender: "Male",
    bio: "Angelo grew up in a small village in the Philippines. He developed a love for gardening, spending his free time tending to his family's vegetable patch. On weekends, he would play basketball with his friends. Angelo was close with his family and would often help his father with his fishing business. After finishing college, Angelo moved to the city and started a successful landscaping company. Despite his busy schedule, Angelo still finds time to play basketball with his friends and visit his family back in his hometown. He is known for his friendly, positive and happy personality.",
  },
  {
    langCode: "fil-PH",
    id: "Microsoft Server Speech Text to Speech Voice (fil-PH, BlessicaNeural)",
    avatarName: "Blessica",
    displayName: "Blessica (fil-PH)",
    gender: "Female",
    bio: "Blessica is a 35-year-old woman from the Philippines. She's always been a positive and happy person, with a love for spending time with her family and friends. Her biggest interests include exploring new recipes in the kitchen, practicing yoga, and traveling to new and exciting places. With a warm and friendly personality, Blessica is always surrounded by loved ones and is constantly seeking new experiences to add to her life.",
  },
  {
    langCode: "fr-BE",
    id: "Microsoft Server Speech Text to Speech Voice (fr-BE, CharlineNeural)",
    avatarName: "Charline",
    displayName: "Charline (fr-BE)",
    gender: "Female",
    bio: "Charline grew up in a small town in Belgium, surrounded by the beautiful scenery of rolling hills and charming villages. She always loved spending time outdoors, exploring nature, and meeting new people. As a child, she developed a passion for cooking and baking, which she continued to pursue as an adult. Charline's positive attitude and friendly nature make her a popular member of her community, and she is always eager to spend time with her family and close friends. She brings joy to everyone she meets and lives her life with a happy and grateful heart.",
  },
  {
    langCode: "fr-BE",
    id: "Microsoft Server Speech Text to Speech Voice (fr-BE, GerardNeural)",
    avatarName: "Gerard",
    displayName: "Gerard (fr-BE)",
    gender: "Male",
    bio: "Gerard was born and raised in Brussels, Belgium where he inherited a deep passion for cooking from his mother. He enjoys experimenting with new recipes and inviting family and friends over for dinner. When Gerard isn't in the kitchen, he can be found exploring Brussels with his wife and daughter, visiting museums or attending jazz concerts. He has a close circle of friends who share his love for food, music and culture. Gerard's positive outlook on life radiates through his infectious smile and warm personality.",
  },
  {
    langCode: "fr-CA",
    id: "Microsoft Server Speech Text to Speech Voice (fr-CA, SylvieNeural)",
    avatarName: "Sylvie",
    displayName: "Sylvie (fr-CA)",
    gender: "Female",
    bio: "Sylvie grew up in a small town in Quebec, surrounded by her loving family and close group of friends. She always had a joyful spirit and a passion for exploring new interests. As a child, she enjoyed playing outdoors and reading books. Now in her thirties, Sylvie loves practicing yoga and going on hikes in the mountains. She works as a teacher, inspiring her students to learn with enthusiasm. Her positivity and infectious smile make her a beloved member of her community. Sylvie cherishes spending time with her husband and children, who always make her heart feel full.",
  },
  {
    langCode: "fr-CA",
    id: "Microsoft Server Speech Text to Speech Voice (fr-CA, AntoineNeural)",
    avatarName: "Antoine",
    displayName: "Antoine (fr-CA)",
    gender: "Male",
    bio: "Antoine grew up in Quebec surrounded by a close-knit family who instilled in him a love for the outdoors. Fishing and hunting were his favorite pastimes, and he spent countless hours enjoying them with his grandfather. After college, he took a job in Montreal, where he met his best friend, Sean. Antoine also developed another interest - photography. He started taking pictures of the scenery he loved so much and sharing them with his friends and family. His positivity and zest for life were infectious, and everyone he met felt uplifted by his presence.",
  },
  {
    langCode: "fr-CA",
    id: "Microsoft Server Speech Text to Speech Voice (fr-CA, JeanNeural)",
    avatarName: "Jean",
    displayName: "Jean (fr-CA)",
    gender: "Male",
    bio: "Jean is a 35-year-old man from Quebec, the heart of the French-Canadian culture. He is a friendly, positive and happy person, always keen on making new friends. His hobbies include hiking, hockey, and playing guitar with his buddies. Jean's biggest inspiration in life is his family, who he loves to spend time with over the weekends enjoying traditional meals and listening to classic French-Canadian songs. He is passionate about his heritage and enjoys sharing his culture with others.",
  },
  {
    langCode: "fr-CH",
    id: "Microsoft Server Speech Text to Speech Voice (fr-CH, ArianeNeural)",
    avatarName: "Ariane",
    displayName: "Ariane (fr-CH)",
    gender: "Female",
    bio: "Ariane was born and raised in the French-speaking part of Switzerland. As a child, she loved exploring the outdoors and hiking with her family. Now, she enjoys discovering new hiking trails with her friends and capturing the stunning landscapes with her camera. Aside from her passion for nature, Ariane is also an avid cook and loves to experiment with different culinary styles. She values her relationships with her loved ones immensely and is always eager to lend a listening ear. Her positive outlook on life and contagious smile make her everyone's favorite companion.",
  },
  {
    langCode: "fr-CH",
    id: "Microsoft Server Speech Text to Speech Voice (fr-CH, FabriceNeural)",
    avatarName: "Fabrice",
    displayName: "Fabrice (fr-CH)",
    gender: "Male",
    bio: "Fabrice grew up in a small town in the French-speaking region of Switzerland. He inherited his love for the outdoors from his father, an avid hiker who took him on scenic trails to watch sunsets. Fabrice is a happy-go-lucky person who enjoys socializing with his close-knit group of friends over a pint of beer at the local pub. He is passionate about photography and captures stunning landscapes on his travels. Fabrice feels most alive in nature, and his favourite pastime is exploring mountain ranges with his wife and two daughters.",
  },
  {
    langCode: "fr-FR",
    id: "Microsoft Server Speech Text to Speech Voice (fr-FR, AlainNeural)",
    avatarName: "Alain",
    displayName: "Alain (fr-FR)",
    gender: "Male",
    bio: "Alain grew up in the vineyards of Bordeaux, surrounded by the aroma of wine and the sound of cork popping. He developed a deep passion for wine and started working in the industry after graduating from university. His outgoing nature led him to make friends easily, and he enjoys hosting wine-tasting parties for his friends. Alain's happiness is amplified by his loving wife and two young children. He makes sure to travel with them, exploring new places and creating new memories. Despite the stresses of life, Alain remains positive, maintaining his belief that every day is a gift.",
  },
  {
    langCode: "fr-FR",
    id: "Microsoft Server Speech Text to Speech Voice (fr-FR, BrigitteNeural)",
    avatarName: "Brigitte",
    displayName: "Brigitte (fr-FR)",
    gender: "Female",
    bio: "Brigitte grew up in a small town in France where she learned to appreciate the simple things in life. Her love for painting and cooking grew as she spent time with her grandmother in the kitchen and discovered her talent with a brush. After moving to the city, she made friends with diverse interests, including a music producer and a yoga instructor. She now enjoys hosting dinner parties and teaching her friends how to paint. Her pride and joy is her daughter, whom she hopes will develop the same creative passions. She lives life with positivity and joy.",
  },
  {
    langCode: "fr-FR",
    id: "Microsoft Server Speech Text to Speech Voice (fr-FR, CelesteNeural)",
    avatarName: "Celeste",
    displayName: "Celeste (fr-FR)",
    gender: "Female",
    bio: "Celeste grew up in a small village in France surrounded by fields of lavender. She inherited her love for cooking from her mother and can be found in the kitchen experimenting with new ingredients every day. Her best friend is a black and white Border Collie named Pierre. Celeste is happily married to her childhood sweetheart, Jean, and they have two children. She enjoys spending time outdoors, hiking and taking photographs of the beautiful countryside. Celeste's optimistic attitude, kind heart and warm smile make her a beloved member of her community.",
  },
  {
    langCode: "fr-FR",
    id: "Microsoft Server Speech Text to Speech Voice (fr-FR, ClaudeNeural)",
    avatarName: "Claude",
    displayName: "Claude (fr-FR)",
    gender: "Male",
    bio: "Claude had always been a bubbly, happy-go-lucky kind of person. Growing up in the charming countryside of France, he developed a love for the outdoors and all things nature-related. Hiking in the scenic mountains and strolling through the vibrant gardens were some of his favorite activities. He also enjoyed cooking traditional French dishes and sharing meals with his close-knit family and friends. Claude radiated positivity and always had a smile on his face. He cherished the simple pleasures in life and never took anything for granted.",
  },
  {
    langCode: "fr-FR",
    id: "Microsoft Server Speech Text to Speech Voice (fr-FR, CoralieNeural)",
    avatarName: "Coralie",
    displayName: "Coralie (fr-FR)",
    gender: "Female",
    bio: "Coralie grew up in the charming countryside of France with her loving family. She enjoyed the simpler things in life that the countryside offered, like gardening, horseback riding, and cooking French cuisine. Her artistic and creative flair led her to study fashion design, where she made lifelong friends. Now, as a successful fashion designer in Paris, Coralie spends her free time exploring the city's museums, parks and cafes with her friends and family. Her contagious positivity and warm personality make her a cherished member of her community.",
  },
  {
    langCode: "fr-FR",
    id: "Microsoft Server Speech Text to Speech Voice (fr-FR, DeniseNeural)",
    avatarName: "Denise",
    displayName: "Denise (fr-FR)",
    gender: "Female",
    bio: "Denise grew up in a small town in France where she developed a passion for baking and cooking. After completing culinary school, she moved to the city and opened her own patisserie, creating delicious treats for locals and tourists. She loves spending time with her husband and their two children, often taking them on adventures around the city. Her closest friends are fellow chefs who share her love of food and creativity. Denise's warm personality and infectious smile make her a favorite among locals, and her positive outlook on life inspires everyone she meets.",
  },
  {
    langCode: "fr-FR",
    id: "Microsoft Server Speech Text to Speech Voice (fr-FR, EloiseNeural)",
    avatarName: "Eloise",
    displayName: "Eloise (fr-FR)",
    gender: "Female",
    bio: "Eloise grew up in a small town in France, where her passion for music and literature flourished. Today, she's a vocalist in a jazz band and a published author of poetry. Her closest friends include her bandmates and her brother, who's a talented painter. Eloise is happily married to a chef, and together they love cooking, hiking and gardening. Her parents, both retired teachers, passed on their love for education, which led Eloise to become a French teacher herself. She's a warm and welcoming person, always with a smile on her face and a positive attitude towards life.",
  },
  {
    langCode: "fr-FR",
    id: "Microsoft Server Speech Text to Speech Voice (fr-FR, HenriNeural)",
    avatarName: "Henri",
    displayName: "Henri (fr-FR)",
    gender: "Male",
    bio: "Henri grew up in a small village in France, surrounded by family and friends. His love for music led him to pick up the guitar, and he often plays with his friends at local cafes. He spends his free time hiking through the countryside and trying new recipes in the kitchen. Henri is known for his infectious smile and positive attitude. He is close with his family and visits his parents every Sunday for a traditional French dinner. Henri's carefree nature has endeared him to many, making him a beloved member of his community.",
  },
  {
    langCode: "fr-FR",
    id: "Microsoft Server Speech Text to Speech Voice (fr-FR, JacquelineNeural)",
    avatarName: "Jacqueline",
    displayName: "Jacqueline (fr-FR)",
    gender: "Female",
    bio: "Jacqueline was born and raised in the heart of Paris. She developed a love for baking pastries and bread at a young age, which led her to pursue a career in pastry arts. She now owns a successful bakery that she runs with her husband, Pierre. Jacqueline enjoys spending time with her close-knit group of friends, who she met in culinary school. Family is also incredibly important to her, and she frequently visits her parents who live in the countryside of France. With a positive outlook on life, Jacqueline's infectious smile can light up anyone's day.",
  },
  {
    langCode: "fr-FR",
    id: "Microsoft Server Speech Text to Speech Voice (fr-FR, JeromeNeural)",
    avatarName: "Jerome",
    displayName: "Jerome (fr-FR)",
    gender: "Male",
    bio: "Jerome was born in France and grew up surrounded by his loving family. He developed a passion for cooking and spent most of his free time experimenting with new recipes. As he grew older, he also developed an interest in gardening and spent hours tending to his vegetable patch. Jerome's positive attitude and friendly nature made him many friends, who would often gather at his house to enjoy his delicious meals. Despite working long hours as a chef, Jerome always found time to enjoy life and appreciate the little things.",
  },
  {
    langCode: "fr-FR",
    id: "Microsoft Server Speech Text to Speech Voice (fr-FR, JosephineNeural)",
    avatarName: "Josephine",
    displayName: "Josephine (fr-FR)",
    gender: "Female",
    bio: "Josephine grew up in a quaint village in France. She has always been an outdoorsy person, fond of hiking in the mountains and exploring new trails. Her love of adventure and nature led her to become a travel blogger and she often chronicles her trips around the world. Josephine values her family and friends, who are a constant source of support and laughter. She enjoys cooking, gardening, and painting in her free time. Her bubbly personality and warm smile make her a hit with everyone she meets.",
  },
  {
    langCode: "fr-FR",
    id: "Microsoft Server Speech Text to Speech Voice (fr-FR, MauriceNeural)",
    avatarName: "Maurice",
    displayName: "Maurice (fr-FR)",
    gender: "Male",
    bio: "Maurice grew up in the idyllic countryside village of Saint-Martin. From his early age, he enjoyed spending time exploring nature, playing soccer and practicing watercolor painting. He made lifelong friends with other kids in the village, including his closest friend, Pierre. Maurice moved to the city to study computer science and landed a job as a software engineer. In his free time, he still finds pleasure in painting and playing guitar with his bandmates. He's married to his high school sweetheart, Amelie, and they have a daughter, Sophie, who also shares his passion for nature and art.",
  },
  {
    langCode: "fr-FR",
    id: "Microsoft Server Speech Text to Speech Voice (fr-FR, YvesNeural)",
    avatarName: "Yves",
    displayName: "Yves (fr-FR)",
    gender: "Male",
    bio: "Yves grew up in a suburban town near Paris. His parents were avid travelers and instilled in him a love for adventure. He studied art and design in college, which led to a career as a graphic designer. In his free time, Yves enjoys photography, hiking, and cooking. He values his friendships and hosts regular dinner parties with his closest friends. Yves' positive outlook on life comes from his tight-knit family, who instilled in him the importance of living life with a happy heart.",
  },
  {
    langCode: "fr-FR",
    id: "Microsoft Server Speech Text to Speech Voice (fr-FR, YvetteNeural)",
    avatarName: "Yvette",
    displayName: "Yvette (fr-FR)",
    gender: "Female",
    bio: "Yvette grew up in a small village in France surrounded by vineyards. From a young age, she developed a love for cooking and baking and spent countless hours in the kitchen with her mother and grandmother. Her passion for food led her to pursue a culinary degree and now she works as a pastry chef in a prestigious restaurant in Paris. In her free time, Yvette enjoys hiking with her dog, painting, and hosting dinner parties for her close group of friends. She credits her upbeat personality to the support of her tight-knit family, who still gather every Sunday for a traditional French meal.",
  },
  {
    langCode: "ga-IE",
    id: "Microsoft Server Speech Text to Speech Voice (ga-IE, ColmNeural)",
    avatarName: "Colm",
    displayName: "Colm (ga-IE)",
    gender: "Male",
    bio: "Colm grew up in a small village in Ireland speaking Gaeilge with his family. He always had a strong interest in music, learning to play several instruments and performing in his community's traditional ceilidhs. Colm's passion for the outdoors led to him becoming an avid hiker and cyclist, often exploring the stunning scenery of the Emerald Isle with his close group of friends. He moved to Dublin to study business and now works as a successful entrepreneur. Despite his busy life, Colm always makes time for family and friends and is known for his positive and friendly demeanor.",
  },
  {
    langCode: "ga-IE",
    id: "Microsoft Server Speech Text to Speech Voice (ga-IE, OrlaNeural)",
    avatarName: "Orla",
    displayName: "Orla (ga-IE)",
    gender: "Female",
    bio: "Orla grew up in a small village in Éire where she learned to appreciate nature and the great outdoors. She loves hiking, camping and horse riding. Orla's family is very tight-knit and she has a large group of friends that enjoy going on adventures together. Orla is fluent in Gaeilge and proudly speaks it whenever she can. Her positive attitude and infectious smile make her a popular figure in her community. Orla's greatest joy is spending time with her young daughter, teaching her about the world and instilling in her a love for nature.",
  },
  {
    langCode: "gl-ES",
    id: "Microsoft Server Speech Text to Speech Voice (gl-ES, RoiNeural)",
    avatarName: "Roi",
    displayName: "Roi (gl-ES)",
    gender: "Male",
    bio: "Roi was born in a small village in Galicia, Spain. He grew up surrounded by nature, which sparked his love for hiking and outdoor activities. Roi also had a passion for music that led him to learn how to play the guitar. He continued his passion for music in college, where he studied music production. Roi's positive and friendly personality has made him many close friends, including his childhood best friend, Miguel. Today, Roi is happily married to his high school sweetheart, and they have two children together.",
  },
  {
    langCode: "gl-ES",
    id: "Microsoft Server Speech Text to Speech Voice (gl-ES, SabelaNeural)",
    avatarName: "Sabela",
    displayName: "Sabela (gl-ES)",
    gender: "Female",
    bio: "Sabela is a 35-year-old woman from Galicia, Spain. She's a happy-go-lucky person who always has a smile on her face. She's passionate about cooking, especially traditional Galician dishes like lacón con grelos and empanada gallega. Sabela loves spending time with her close-knit family, who all live in the same town. Her best friend, Inés, is also her neighbor, and they enjoy hiking in the nearby hills and trying new restaurants together. Sabela is proud of her Galician heritage and enjoys sharing the region's culture and traditions with others.",
  },
  {
    langCode: "gu-IN",
    id: "Microsoft Server Speech Text to Speech Voice (gu-IN, DhwaniNeural)",
    avatarName: "ધ્વની",
    displayName: "ધ્વની (gu-IN)",
    gender: "Female",
    bio: "Dhvani was born and raised in the bustling city of Ahmedabad, in Gujarat, India. She comes from a close-knit family that instilled in her a love for music and dance from a young age. Even now, at 35, she enjoys attending folk music concerts and practicing Bharatanatyam in her free time. Dhvani's positivity and outgoing nature have earned her many friends, who treasure her company. She works as a software engineer, but always makes time for family, friends, and hobbies, which keep her happy and fulfilled.",
  },
  {
    langCode: "gu-IN",
    id: "Microsoft Server Speech Text to Speech Voice (gu-IN, NiranjanNeural)",
    avatarName: "નિરંજન",
    displayName: "નિરંજન (gu-IN)",
    gender: "Male",
    bio: "નિરંજન is a 35-year-old man from Gujarat, India. Growing up, he was always interested in music and started playing the guitar at a young age. He enjoyed spending time with his family, especially his daughter who he dotes on. Nirajan is also an avid traveler and loves exploring new cultures and cuisines. He always has a positive outlook on life and his friendly nature makes him popular among his friends.",
  },
  {
    langCode: "he-IL",
    id: "Microsoft Server Speech Text to Speech Voice (he-IL, AvriNeural)",
    avatarName: "אברי",
    displayName: "אברי (he-IL)",
    gender: "Male",
    bio: "אברי grew up in a small town in Israel, where he developed a love for the outdoors and adventure. He was always hiking, camping and exploring with his close-knit family, and as he got older he began to pursue extreme sports like rock climbing and skydiving. He also developed a passion for music, playing guitar and singing in a local band with his closest friends. Now in his mid-30s, אברי works as an adventure tour guide, sharing his love of the outdoors with visitors from around the world. He lives a happy, fulfilled life surrounded by family, friends and nature.",
  },
  {
    langCode: "he-IL",
    id: "Microsoft Server Speech Text to Speech Voice (he-IL, HilaNeural)",
    avatarName: "הילה",
    displayName: "הילה (he-IL)",
    gender: "Female",
    bio: "Hila grew up in a small town near Tel Aviv. She was always drawn to the outdoors, spending her days hiking with her family and swimming in the nearby river. As she got older, her love for nature developed into a passion for gardening and sustainable living. Hila now runs a successful organic farm with her husband and three children, where they grow everything from tomatoes to lavender. In her free time, she enjoys playing the ukulele with her friends and hosting Shabbat dinners for her community. Hila's contagious smile and warm energy make her a favorite among all who know her.",
  },
  {
    langCode: "hi-IN",
    id: "Microsoft Server Speech Text to Speech Voice (hi-IN, MadhurNeural)",
    avatarName: "मधुर",
    displayName: "मधुर (hi-IN)",
    gender: "Male",
    bio: "Madhur was born and raised in Mumbai, India. He comes from a family of lawyers and always had an interest in the field. However, he decided to pursue his passion for music and started playing guitar early on. Madhur formed a band with his friends and they performed at local festivals. He also loves travelling and exploring new cultures. Madhur is married with two kids and enjoys spending time with his family. He is known for his infectious positivity and friends often turn to him for advice.",
  },
  {
    langCode: "hr-HR",
    id: "Microsoft Server Speech Text to Speech Voice (hr-HR, GabrijelaNeural)",
    avatarName: "Gabrijela",
    displayName: "Gabrijela (hr-HR)",
    gender: "Female",
    bio: "Gabrijela grew up in a small town in Hrvatska. She went to school and did well, but always longed to explore the world. She moved to Zagreb and started a career in marketing. In her free time, Gabrijela loves hiking in the mountains, cooking traditional meals, and playing the guitar. She has a tight-knit group of friends who love to dance and have fun. Gabrijela is close with her family, often making trips back home. Her warm personality and infectious laughter fills every room she enters. Gabrijela lives life to the fullest and cherishes every moment.",
  },
  {
    langCode: "hu-HU",
    id: "Microsoft Server Speech Text to Speech Voice (hu-HU, NoemiNeural)",
    avatarName: "Noémi",
    displayName: "Noémi (hu-HU)",
    gender: "Female",
    bio: "Noémi grew up in a small village in Hungary, where she spent most of her childhood playing with her siblings and pickling seasonal fruits and vegetables with her mother. As she got older, she developed an interest in hiking and photography, spending weekends exploring the beautiful countryside. Noémi's positive and friendly nature helped her make friends easily, and she became part of an eccentric group of artists and adventurers. After settling in Budapest, Noémi started her own catering business, taking her love of food to the next level. She still spends her free time hiking and taking stunning photos of nature's beauty.",
  },
  {
    langCode: "hu-HU",
    id: "Microsoft Server Speech Text to Speech Voice (hu-HU, TamasNeural)",
    avatarName: "Tamás",
    displayName: "Tamás (hu-HU)",
    gender: "Male",
    bio: "Tamás grew up in a small town in Hungary, where he learned to love the outdoors. He spent his childhood hiking and camping with his family, which instilled in him a deep appreciation for nature. As he grew older, he developed a passion for photography, and spent many hours exploring the countryside and taking pictures of the breathtaking scenery. Tamás is still an avid photographer and loves sharing his work with friends and family. He also enjoys playing soccer with his buddies on the weekends, and hosting BBQ parties in his backyard. Overall, Tamás is a friendly, positive, and happy person who loves life.",
  },
  {
    langCode: "hy-AM",
    id: "Microsoft Server Speech Text to Speech Voice (hy-AM, AnahitNeural)",
    avatarName: "Անահիտ",
    displayName: "Անահիտ (hy-AM)",
    gender: "Female",
    bio: "Անահիտ grew up in a small village in Armenia with her parents and four siblings. She always had a love for music and dance, and spent most of her childhood performing in local festivals. After completing her degree in dance, she moved to the city to pursue her career.\n\nToday, she owns her own dance studio where she teaches children and adults various styles of dance. She also enjoys hiking with her friends and trying new recipes in the kitchen. Despite being an only child, her close-knit family remains her biggest support system.Անահիտ spreads joy wherever she goes and is loved by all who know her.",
  },
  {
    langCode: "hy-AM",
    id: "Microsoft Server Speech Text to Speech Voice (hy-AM, HaykNeural)",
    avatarName: "Հայկ",
    displayName: "Հայկ (hy-AM)",
    gender: "Male",
    bio: "Հայկ grew up in the scenic countryside of Armenia. His parents instilled a love for hiking, camping and outdoor exploration. As a result, Հայկ is an avid hiker and nature lover. He is also a foodie who enjoys tasting traditional Armenian dishes, particularly dolma and khorovats. Education is important to him, so he pursued a degree in computer science. In his spare time, he enjoys playing chess and socializing with his tight-knit group of friends. His positive attitude and outgoing personality have earned him many lifelong friendships.",
  },
  {
    langCode: "id-ID",
    id: "Microsoft Server Speech Text to Speech Voice (id-ID, ArdiNeural)",
    avatarName: "Ardi",
    displayName: "Ardi (id-ID)",
    gender: "Male",
    bio: "Born and raised in Jakarta, Ardi has always been an outgoing and positive person. He spends his free time exploring the city with his wife and two children. Ardi loves playing football with his friends every weekend and is a diehard fan of the Indonesian National Football team. He also enjoys reading books on Indonesian history and culture. Ardi is a successful businessman and is admired by his colleagues for his friendly and approachable personality. His family and friends are the most important things in his life, and he always makes time for them.",
  },
  {
    langCode: "id-ID",
    id: "Microsoft Server Speech Text to Speech Voice (id-ID, GadisNeural)",
    avatarName: "Gadis",
    displayName: "Gadis (id-ID)",
    gender: "Female",
    bio: "Gadis grew up in a small village in Indonesia with her parents and three siblings. She loved playing traditional music and dancing with her friends, and would often spend her free time watching movies with her family. Gadis was always drawn to nature and loved going on hikes, exploring the jungle, and collecting unique plants. She moved to the city in her early 20s to pursue a career in teaching and quickly made new friends. Today, at 35, she still enjoys playing music in a local group and spends weekends hiking with her husband and young daughter.",
  },
  {
    langCode: "is-IS",
    id: "Microsoft Server Speech Text to Speech Voice (is-IS, GudrunNeural)",
    avatarName: "Guðrún",
    displayName: "Guðrún (is-IS)",
    gender: "Female",
    bio: "Guðrún is a friendly and outgoing woman who loves spending time with her family and friends. She has a passion for the outdoors, spending weekends hiking, camping and exploring the beautiful Icelandic wilderness. When she's not outdoors, she enjoys listening to music, reading books and baking delicious treats. Guðrún is happily married with two children and loves nothing more than seeing her family enjoying the same activities she loves. Her positive outlook on life and contagious happiness make her a joy to be around.",
  },
  {
    langCode: "is-IS",
    id: "Microsoft Server Speech Text to Speech Voice (is-IS, GunnarNeural)",
    avatarName: "Gunnar",
    displayName: "Gunnar (is-IS)",
    gender: "Male",
    bio: "Gunnar grew up in Reykjavik, Iceland with his loving parents and three younger siblings. He developed a passion for outdoor activities at an early age and spent most of his childhood exploring nature. He's an avid hiker, fisherman, and enjoys skiing in the winter. When he's not outside, he loves spending time with his close-knit group of friends, having fun and making memories. Gunnar's positive outlook on life and contagious laughter make him a joy to be around – he's always looking for new adventures to experience and people to share them with.",
  },
  {
    langCode: "it-IT",
    id: "Microsoft Server Speech Text to Speech Voice (it-IT, IsabellaNeural)",
    avatarName: "Isabella",
    displayName: "Isabella (it-IT)",
    gender: "Female",
    bio: "Isabella was born and raised in a small Italian town, surrounded by friends and family. She always had a passion for cooking and spent most of her free time experimenting with new recipes. After studying culinary arts, she moved to the city and became a chef at a trendy restaurant. Isabella's friendly and positive nature attracted people to her, and she formed close friendships with colleagues and customers alike. In her free time, she enjoys hiking and exploring nature with her husband and young children. Isabella's love for life and laughter brings joy to everyone around her.",
  },
  {
    langCode: "it-IT",
    id: "Microsoft Server Speech Text to Speech Voice (it-IT, BenignoNeural)",
    avatarName: "Benigno",
    displayName: "Benigno (it-IT)",
    gender: "Male",
    bio: "Benigno grew up in a small town in Italy, surrounded by his large and loving family. From a young age, he showed a keen interest in music, especially playing the guitar, and spent many hours practicing and performing with his friends. As he got older, his passion for music grew and he pursued a career as a professional musician. Despite the challenges, Benigno remained friendly, positive and happy, always grateful for the support of his family and friends. To this day, he continues to play music, bringing joy to those around him.",
  },
  {
    langCode: "it-IT",
    id: "Microsoft Server Speech Text to Speech Voice (it-IT, CalimeroNeural)",
    avatarName: "Calimero",
    displayName: "Calimero (it-IT)",
    gender: "Male",
    bio: "Calimero grew up in a small town in Italy with his parents and siblings. He always had a love for cooking and spending time with his friends. He moved to the city to pursue his passion for food, and soon became a successful chef with his own restaurant. When not cooking, Calimero enjoys playing soccer and going on adventures with his wife and two children. He values family and friendships above all else and his positive attitude always shines through in everything he does.",
  },
  {
    langCode: "it-IT",
    id: "Microsoft Server Speech Text to Speech Voice (it-IT, DiegoNeural)",
    avatarName: "Diego",
    displayName: "Diego (it-IT)",
    gender: "Male",
    bio: "Diego grew up in Italy with his parents and two siblings. He developed a passion for soccer at a young age and is a die-hard fan of the local team. He also loves to cook traditional Italian dishes, which he learned from his grandmother. Diego is a devoted family man and enjoys spending time with his wife and two children. He considers his group of close friends as family too, and they often gather for meals and soccer matches. Despite life's challenges, Diego's positive outlook and cheerful demeanor never waver.",
  },
  {
    langCode: "it-IT",
    id: "Microsoft Server Speech Text to Speech Voice (it-IT, FabiolaNeural)",
    avatarName: "Fabiola",
    displayName: "Fabiola (it-IT)",
    gender: "Female",
    bio: "Fabiola grew up in a small town in Italy surrounded by nature. She inherited her mother's love for cooking and spends her weekends experimenting with new recipes. Her passion for hiking and running helped her make new friends in the city where she went to college. She now works in the fashion industry but still finds time for her hobbies. She is happily married to her childhood friend who shares her love for travel and adventure. Her positive and friendly nature makes her popular among her colleagues and often makes new friends.",
  },
  {
    langCode: "it-IT",
    id: "Microsoft Server Speech Text to Speech Voice (it-IT, FiammaNeural)",
    avatarName: "Fiamma",
    displayName: "Fiamma (it-IT)",
    gender: "Female",
    bio: "Fiamma grew up in a tight-knit Italian family in Napoli. Her passion for cooking came from watching her mother in the kitchen. This led her to study culinary arts in Firenze. Fiamma opened her own restaurant in Roma and made many friends through her delicious food and positive personality. Outside of work, she enjoys hiking the beautiful countryside and practicing yoga. Fiamma’s world revolves around family, friends and good food. She believes that spreading joy and laughter is the key to happiness.",
  },
  {
    langCode: "it-IT",
    id: "Microsoft Server Speech Text to Speech Voice (it-IT, GianniNeural)",
    avatarName: "Gianni",
    displayName: "Gianni (it-IT)",
    gender: "Male",
    bio: "Gianni grew up in a small village in Italy, where he spent his days helping his family with their vineyard. His love for grapevines soon turned into his hobby, which he pursued after moving to the city. His job as a sommelier gave him the opportunity to travel and explore the world of wine. Gianni’s friendly nature and positive attitude earned him many friends, who also shared his passion for good food and wine. He loved spending time with his family, especially during Sunday dinners, where they would all gather to indulge in a delectable feast.",
  },
  {
    langCode: "it-IT",
    id: "Microsoft Server Speech Text to Speech Voice (it-IT, ImeldaNeural)",
    avatarName: "Imelda",
    displayName: "Imelda (it-IT)",
    gender: "Female",
    bio: "Imelda grew up in the small town of Sorrento, Italy, where she developed a love for cooking and baking with her family. As she grew older, she became interested in travel, and spent time exploring the nearby Amalfi coast. She now owns a small restaurant in town, where she serves traditional Italian cuisine to locals and tourists alike. Imelda enjoys spending time with friends over good food and wine, and stays positive through life's ups and downs. Her family and friends see her as a ray of sunshine, always bringing warmth and happiness wherever she goes.",
  },
  {
    langCode: "it-IT",
    id: "Microsoft Server Speech Text to Speech Voice (it-IT, IrmaNeural)",
    avatarName: "Irma",
    displayName: "Irma (it-IT)",
    gender: "Female",
    bio: "Irma is a 35-year-old Italian woman who was born and raised in Rome. She grew up surrounded by the beautiful architecture and delicious cuisine of her country. Irma has always been a positive and friendly person, always happy to spend time with her family and friends. She enjoys cooking traditional Italian dishes, practicing yoga, and playing the piano. Irma has a close-knit group of friends who she loves to go out with and explore new parts of the city. She cherishes her family and spends as much time with them as possible.",
  },
  {
    langCode: "it-IT",
    id: "Microsoft Server Speech Text to Speech Voice (it-IT, LisandroNeural)",
    avatarName: "Lisandro",
    displayName: "Lisandro (it-IT)",
    gender: "Male",
    bio: "Lisandro grew up in Tuscany, spending most of his time helping his family run their vineyard. He developed a love of wine and started studying enology at university. Nowadays, he's a winemaker in a small town, known for his bold blends and sparkling wines. On weekends, he goes cycling through the hills with his friends, and he enjoys cooking Italian meals for his wife and two young children. He's grateful for his tight-knit family and feels content working with something that brings joy to people's lives.",
  },
  {
    langCode: "it-IT",
    id: "Microsoft Server Speech Text to Speech Voice (it-IT, PalmiraNeural)",
    avatarName: "Palmira",
    displayName: "Palmira (it-IT)",
    gender: "Female",
    bio: "Palmira, a 35-year-old Italian woman, has always been surrounded by loved ones. Growing up, she spent many Saturdays in the kitchen with her Nonna, learning how to cook traditional family recipes. This sparked her love of food and hosting dinner parties for her close-knit group of friends. In her free time, Palmira can be found practicing yoga or reading a good book. Her positivity and outgoing nature have made her a beacon of light in her community, where she volunteers at the local soup kitchen. Palmira's family and friends are everything to her, and she cherishes every moment spent with them.",
  },
  {
    langCode: "it-IT",
    id: "Microsoft Server Speech Text to Speech Voice (it-IT, PierinaNeural)",
    avatarName: "Pierina",
    displayName: "Pierina (it-IT)",
    gender: "Female",
    bio: "Pierina grew up in a small Italian village surrounded by vineyards and olive groves. She developed a love for cooking at a young age and spent most of her free time experimenting in the kitchen. Her family and friends were her biggest supporters and enjoyed all her delicious creations. Pierina moved to the city to attend culinary school and now works as a chef in a local restaurant. She still enjoys cooking for her loved ones and spends her weekends exploring new recipes. Pierina's cheerful and warm personality has made her many friends in the city, and she is always spreading joy wherever she goes.",
  },
  {
    langCode: "it-IT",
    id: "Microsoft Server Speech Text to Speech Voice (it-IT, RinaldoNeural)",
    avatarName: "Rinaldo",
    displayName: "Rinaldo (it-IT)",
    gender: "Male",
    bio: "Rinaldo grew up in Italy, surrounded by his large and loving family. He always had a passion for music and spent most of his younger years playing various instruments. As he got older, Rinaldo discovered a love for cooking, experimenting with new recipes and ingredients. He moved to the city where he became friends with people who shared his interests. Rinaldo now spends his days working as a chef in a local Italian restaurant, and his evenings playing music with his band. He lives life with a bright outlook, always making time for his loved ones and spreading positivity wherever he goes.",
  },
  {
    langCode: "ja-JP",
    id: "Microsoft Server Speech Text to Speech Voice (ja-JP, NanamiNeural)",
    avatarName: "七海",
    displayName: "七海 (ja-JP)",
    gender: "Female",
    bio: "七海 grew up surrounded by the beauty of the Japanese countryside. Her love for nature grew into a passion for herbal therapy and horticulture. She spends most of her free time tending to her garden or making herbal remedies for her family and friends. Her positive attitude and friendly demeanor quickly made her beloved in her small village. Now, at 35, she owns a small gift shop that sells her homemade remedies and her unique handcrafted pottery. She lives with her husband and two children, who are just as passionate about nature as she is.",
  },
  {
    langCode: "ja-JP",
    id: "Microsoft Server Speech Text to Speech Voice (ja-JP, AoiNeural)",
    avatarName: "碧衣",
    displayName: "碧衣 (ja-JP)",
    gender: "Female",
    bio: "碧衣 grew up in Tokyo, Japan with her 5 siblings. She found solace in the traditional art of Ikebana and has pursued it ever since. During college, she studied interior design and landed a job at a prestigious firm in New York City. She quickly became friends with co-workers and explored the city's cultural offerings. Now settled in the city, 碧衣 enjoys spending time with her family and continuing her ikebana practice. She's happy to have found a life that combines her passion for art and her love for people.",
  },
  {
    langCode: "ja-JP",
    id: "Microsoft Server Speech Text to Speech Voice (ja-JP, DaichiNeural)",
    avatarName: "大智",
    displayName: "大智 (ja-JP)",
    gender: "Male",
    bio: "大智 was born in Tokyo, Japan to a family of artists. Growing up, he developed a love of painting and would often spend hours creating his own works of art. He also developed a passion for music, playing the guitar and singing in a local band with his childhood friends. Now in his mid-thirties, he still enjoys painting and playing music, but has also developed an interest in photography and traveling. He is married to his high school sweetheart and is the father of two young children, who bring him endless joy and inspiration.",
  },
  {
    langCode: "ja-JP",
    id: "Microsoft Server Speech Text to Speech Voice (ja-JP, KeitaNeural)",
    avatarName: "圭太",
    displayName: "圭太 (ja-JP)",
    gender: "Male",
    bio: "Meet 圭太, a 35-year-old man from Japan who radiates positivity and warmth to everyone he meets. He enjoys practicing traditional Japanese martial arts, and his weekends are filled with training sessions and competitions. His closest friends are fellow martial artists, who share his passion for discipline and self-improvement. He also loves spending time with his family - his parents run a small farm, and he often helps out with harvesting crops and caring for the animals. 圭太's cheerful demeanor and kind heart make him a beloved member of his community.",
  },
  {
    langCode: "ja-JP",
    id: "Microsoft Server Speech Text to Speech Voice (ja-JP, MayuNeural)",
    avatarName: "真夕",
    displayName: "真夕 (ja-JP)",
    gender: "Female",
    bio: "真夕 grew up in a small town in Japan surrounded by rich culture and tradition. She has always been fascinated by the art of ikebana and spends much of her free time arranging flowers. Her infectious positivity and effortless charm drew her an eclectic group of friends, whom she loves spending time with. She takes pride in her family and enjoys visits from her nieces and nephews. Her dream is to open her own flower shop and share her love for ikebana with others.",
  },
  {
    langCode: "ja-JP",
    id: "Microsoft Server Speech Text to Speech Voice (ja-JP, NaokiNeural)",
    avatarName: "直紀",
    displayName: "直紀 (ja-JP)",
    gender: "Male",
    bio: "直紀 grew up in a small village in Japan with his parents and younger sister. He always had a passion for music and spent hours practicing guitar and singing. In college, he formed a band with friends and played gigs at local bars. After graduation, he moved to Tokyo and started working as a music producer. In his free time, he enjoys hiking with his girlfriend, trying new restaurants with friends, and playing basketball at the park. Despite the challenges he has faced, 直紀 remains positive and always has a smile on his face.",
  },
  {
    langCode: "ja-JP",
    id: "Microsoft Server Speech Text to Speech Voice (ja-JP, ShioriNeural)",
    avatarName: "志織",
    displayName: "志織 (ja-JP)",
    gender: "Female",
    bio: "志織 grew up in a small town in Japan with her parents and younger sister. She loved spending time outdoors, especially hiking and camping, and developed a passion for photography. After college, she moved to Tokyo to pursue a career in photography and made many friends along the way. 志織 often introduces her friends to new hiking trails and has even started a photography club. She visits her family in the countryside often and enjoys cooking traditional Japanese dishes with her mom. Her positive outlook on life inspires everyone around her.",
  },
  {
    langCode: "jv-ID",
    id: "Microsoft Server Speech Text to Speech Voice (jv-ID, DimasNeural)",
    avatarName: "Dimas",
    displayName: "Dimas (jv-ID)",
    gender: "Male",
    bio: "Dimas is a 35-year-old man from Indonésia, who comes from a close-knit family that speaks Jawa. He loves exploring the natural beauty of his homeland, often taking on hiking trails with his outdoor enthusiast friends. Dimas also has a passion for traditional music, often attending cultural events with his family. His cheerful demeanor and infectious positivity always draw people towards him. He loves spending time with his wife and young daughter, often taking them on group picnics or movie nights; family always comes first for Dimas.",
  },
  {
    langCode: "jv-ID",
    id: "Microsoft Server Speech Text to Speech Voice (jv-ID, SitiNeural)",
    avatarName: "Siti",
    displayName: "Siti (jv-ID)",
    gender: "Female",
    bio: "Siti grew up in a small village in Indonésia, surrounded by her large family. She loved exploring nature and playing traditional Javanese music with her friends. As she grew older, Siti became passionate about cooking and started hosting gatherings for friends to try her flavorful dishes. She eventually met her husband and moved to a bustling city, where she continued to make new friends and explore the local food scene. Despite facing struggles, Siti always maintains a positive attitude and cherishes the relationships she's built over the years.",
  },
  {
    langCode: "ka-GE",
    id: "Microsoft Server Speech Text to Speech Voice (ka-GE, EkaNeural)",
    avatarName: "ეკა",
    displayName: "ეკა (ka-GE)",
    gender: "Female",
    bio: "ეკა was born and raised in Georgia, surrounded by a family who nurtured her interests in photography and hiking. She often travels with her friends to capture stunning landscapes and moments worth remembering. Her positive attitude and kind heart make her a beloved figure in her community. Although her busy schedule as a freelance photographer keeps her on her toes, she always makes time for her loved ones. Her love for adventure and appreciation for beauty will always be a guiding force in her life.",
  },
  {
    langCode: "ka-GE",
    id: "Microsoft Server Speech Text to Speech Voice (ka-GE, GiorgiNeural)",
    avatarName: "გიორგი",
    displayName: "გიორგი (ka-GE)",
    gender: "Male",
    bio: "გიორგი grew up in a small town in Georgia, surrounded by his large and loving family. From a young age, he was passionate about photography and spent much of his free time capturing the beauty of his country. As he got older, he discovered a love for travel and set out to see as much of the world as he could. Along the way, he made friends from all corners of the globe and never lost his friendly demeanor. Now settled back in Georgia, he still enjoys exploring new places and spending time with his family and friends.",
  },
  {
    langCode: "kk-KZ",
    id: "Microsoft Server Speech Text to Speech Voice (kk-KZ, AigulNeural)",
    avatarName: "Айгүл",
    displayName: "Айгүл (kk-KZ)",
    gender: "Female",
    bio: "Айгүл is a 35-year-old woman from Kazakhstan who loves spending time with her family and friends. She is a cheerful and sociable person, always spreading positivity around her. In her free time, she enjoys practicing traditional Kazakh dances and learning about her country's history and culture. Her other hobbies include cooking traditional dishes, hiking in the beautiful mountains of Kazakhstan, and reading books about philosophy and personal development. Айгүл is married and has two children whom she adores and prioritizes over everything else. Being with her loved ones and enjoying life's simple pleasures brings her immense joy.",
  },
  {
    langCode: "kk-KZ",
    id: "Microsoft Server Speech Text to Speech Voice (kk-KZ, DauletNeural)",
    avatarName: "Дәулет",
    displayName: "Дәулет (kk-KZ)",
    gender: "Male",
    bio: "Дәулет is a cheerful man from Kazakhstan. He loves spending time with his family, telling stories about their culture and traditions. In his free time, he enjoys playing the dombra and singing folk songs. He also loves hiking in the beautiful mountains surrounding his hometown of Almaty. As a social person, he has many close friends who enjoy tasting traditional Kazakh cuisine with him, especially horse meat dishes like kazy and бешбармак. Overall, Dәулет is happy with his life and the warm community that surrounds him.",
  },
  {
    langCode: "km-KH",
    id: "Microsoft Server Speech Text to Speech Voice (km-KH, PisethNeural)",
    avatarName: "ពិសិដ្ឋ",
    displayName: "ពិសិដ្ឋ (km-KH)",
    gender: "Male",
    bio: "Meet ពិសិដ្ឋ, a 35-year-old happy-go-lucky man from Cambodia. Growing up, he found joy in playing soccer and practicing traditional Khmer dancing. He remains active to this day, participating in community sports teams and teaching dance to younger generations. He values his tight-knit family and enjoys cooking for them during family gatherings. As a social butterfly, he has many close friends that he loves spending time with. His positivity and outgoing personality make him a crowd favorite wherever he goes.",
  },
  {
    langCode: "km-KH",
    id: "Microsoft Server Speech Text to Speech Voice (km-KH, SreymomNeural)",
    avatarName: "ស្រីមុំ",
    displayName: "ស្រីមុំ (km-KH)",
    gender: "Female",
    bio: "ស្រីមុំ is a cheerful woman who lives in a rural area in Cambodia. She is happily married with three children and spends most of her time taking care of her home and family. Her hobbies include cooking traditional Khmer dishes and embroidery. She is also passionate about helping her community by volunteering at the local charity. Her closest friends are her neighbors who she frequently invites over to share her delicious food. She speaks both Khmer and English fluently and dreams of someday traveling to see the world beyond her village.",
  },
  {
    langCode: "kn-IN",
    id: "Microsoft Server Speech Text to Speech Voice (kn-IN, GaganNeural)",
    avatarName: "ಗಗನ್",
    displayName: "ಗಗನ್ (kn-IN)",
    gender: "Male",
    bio: "ಗಗನ್ was born and raised in a small village in Karnataka. From a young age, he developed a love for nature and spent most of his time exploring nearby forests and lakes. He was always surrounded by friends who shared his interests, and they would often have picnics and campfires by the river. As he grew older, he pursued his passion for ecology and became a botanist. Along the way, he met his wife, a fellow nature enthusiast. Together, they continue to travel and discover hidden gems in Karnataka's wilderness. He is happy and positive, always smiling and spreading joy to those around him.",
  },
  {
    langCode: "kn-IN",
    id: "Microsoft Server Speech Text to Speech Voice (kn-IN, SapnaNeural)",
    avatarName: "ಸಪ್ನಾ",
    displayName: "ಸಪ್ನಾ (kn-IN)",
    gender: "Female",
    bio: "ಸಪ್ನಾ is a vivacious 35-year-old hailing from the beautiful land of ಕನ್ನಡ. Known for her positivity and infectious smile, she has a heart of gold which makes everyone feel at ease with her. Her interests include reading, writing, and exploring new cuisines. Being an avid traveler, she has made friends from all around the globe. Her close-knit family of four comprises her husband, two kids, and an adorable Labrador who often accompanies them on road trips. She believes in living life to the fullest and cherishing every moment with her loved ones.",
  },
  {
    langCode: "ko-KR",
    id: "Microsoft Server Speech Text to Speech Voice (ko-KR, SunHiNeural)",
    avatarName: "선희",
    displayName: "선희 (ko-KR)",
    gender: "Female",
    bio: "선희 is a 35-year-old woman from South Korea who enjoys exploring nature and discovering new hobbies. She loves painting, knitting, and cooking in her free time, and often shares her creations with her loved ones. 선희 has a tight-knit group of friends who have been by her side since childhood, and she values their opinions and support. Her family is also very important to her, and she often travels to visit her parents and siblings who live in different parts of the country. 선희's positive and happy attitude has made her a beloved member of her community, and she always tries to spread joy wherever she goes.",
  },
  {
    langCode: "ko-KR",
    id: "Microsoft Server Speech Text to Speech Voice (ko-KR, InJoonNeural)",
    avatarName: "인준",
    displayName: "인준 (ko-KR)",
    gender: "Male",
    bio: "인준 is a family man with a caring wife and two lovely children. He loves spending time with them and taking them on trips to explore new places. In his free time, he enjoys playing basketball with his buddies from work and watching movies with his family. 인준 has a passion for cooking and experimenting with new recipes, and he often hosts dinner parties for his friends. His positive outlook on life and friendly demeanor make him a popular figure in his community.",
  },
  {
    langCode: "lo-LA",
    id: "Microsoft Server Speech Text to Speech Voice (lo-LA, ChanthavongNeural)",
    avatarName: "ຈັນທະວົງ",
    displayName: "ຈັນທະວົງ (lo-LA)",
    gender: "Male",
    bio: "Jon Tavong grew up in a poor family in Laos. Despite the challenges, he maintained a positive outlook on life. He was always fascinated with cars and spent most of his free time tinkering with engines. In his early 20s, he moved to the city to pursue a career in engineering. There he met his best friend, Nick, who shared his love of cars. Together they would hit the racetrack and push their vehicles to the limit. Now, at 35, he is happily married with two children and still enjoys spending time with his family and friends, sharing stories about their latest automotive adventures.",
  },
  {
    langCode: "lo-LA",
    id: "Microsoft Server Speech Text to Speech Voice (lo-LA, KeomanyNeural)",
    avatarName: "ແກ້ວມະນີ",
    displayName: "ແກ້ວມະນີ (lo-LA)",
    gender: "Female",
    bio: "Kae Mani grew up in Laos, surrounded by a large and loving family. From a young age, she enjoyed spending time outdoors and exploring the natural beauty of her country. She developed a strong interest in traditional Lao dance and music, which she continues to enjoy to this day. Kae Mani is also an avid cook and loves experimenting with new recipes for her friends and family. Her infectious positivity and warm smile make her a cherished member of her community, and she always goes out of her way to help others.",
  },
  {
    langCode: "lt-LT",
    id: "Microsoft Server Speech Text to Speech Voice (lt-LT, LeonasNeural)",
    avatarName: "Leonas",
    displayName: "Leonas (lt-LT)",
    gender: "Male",
    bio: "Leonas comes from the small village of Žemaitija in Lietuva. He grew up surrounded by nature, which sparked his love for hiking and camping. As he got older, he discovered a passion for cooking and became a chef in a local restaurant. Leonas is always surrounded by friends who appreciate his positive energy and sense of humor. He is happily married to his high school sweetheart, with whom he has two young children. He spends his free time exploring the beautiful countryside with his family and indulging in his love of photography.",
  },
  {
    langCode: "lt-LT",
    id: "Microsoft Server Speech Text to Speech Voice (lt-LT, OnaNeural)",
    avatarName: "Ona",
    displayName: "Ona (lt-LT)",
    gender: "Female",
    bio: "Ona grew up in the beautiful countryside of Lietuva, where she developed a love for nature and gardening. She also has a passion for cooking and often hosts her family and friends for traditional Lithuanian feasts. She's always happy to lend a listening ear and has made many life-long friendships. Ona finds joy in the simple things in life and fills her days with laughter and positivity, inspiring those around her to do the same.",
  },
  {
    langCode: "lv-LV",
    id: "Microsoft Server Speech Text to Speech Voice (lv-LV, EveritaNeural)",
    avatarName: "Everita",
    displayName: "Everita (lv-LV)",
    gender: "Female",
    bio: "Everita, a 35-year-old Latvian woman, has a zest for life that is contagious. Growing up, she developed a passion for nature and the outdoors, always hiking and exploring. She also enjoys photography, capturing the beauty of her surroundings. Everita’s positive attitude and kindness make her a dear friend to many. She values family above all else, often organizing picnics with her siblings and nephews. Her love of nature and family has led her to pursue a career as a park ranger, where she can protect and share her love for nature with others.",
  },
  {
    langCode: "lv-LV",
    id: "Microsoft Server Speech Text to Speech Voice (lv-LV, NilsNeural)",
    avatarName: "Nils",
    displayName: "Nils (lv-LV)",
    gender: "Male",
    bio: "Nils grew up in a small Latvian town, surrounded by family and friends. He loved spending time outdoors, exploring forests and fishing in local rivers. In his free time, he played basketball and listened to jazz. After finishing university, he moved to Riga and worked in IT. Now, at 35, he enjoys traveling with his wife and young daughter. He stays close with his childhood friends and loves hosting big BBQs in his garden. Nils is known for his infectious positivity, and everyone who meets him can't help but feel happy.",
  },
  {
    langCode: "mk-MK",
    id: "Microsoft Server Speech Text to Speech Voice (mk-MK, AleksandarNeural)",
    avatarName: "Александар",
    displayName: "Александар (mk-MK)",
    gender: "Male",
    bio: "Александар grew up surrounded by his loving family in a small village in North Macedonia. From a young age, he was always fascinated by technology and spent all his free time tinkering with electronics. He eventually pursued a degree in computer science and now works as a software developer in the city. When not at work, he enjoys playing basketball with his friends, playing video games, and strumming his guitar. He's married to the love of his life and they have a little daughter who brings endless joy to their lives. Overall, Александар is a happy guy who values his family and friends above all else.",
  },
  {
    langCode: "mk-MK",
    id: "Microsoft Server Speech Text to Speech Voice (mk-MK, MarijaNeural)",
    avatarName: "Марија",
    displayName: "Марија (mk-MK)",
    gender: "Female",
    bio: "Marіja grew up in Skopje, the capital of North Macedonia. She has always been interested in sports and physical activities. She played basketball in high school and continues to stay active by hiking with her family and friends. She loves cooking traditional Macedonian dishes and hosting dinner parties. Marija has a close-knit family and is a proud auntie to her sister's children. She is also supportive of her friends and is always up for a night out. Marija brings positivity and joy wherever she goes.",
  },
  {
    langCode: "ml-IN",
    id: "Microsoft Server Speech Text to Speech Voice (ml-IN, MidhunNeural)",
    avatarName: "മിഥുൻ",
    displayName: "മിഥുൻ (ml-IN)",
    gender: "Male",
    bio: "മിഥുൻ is a spirited man from Kerala, India. With an infectious smile, he spends his time exploring the outdoors, hiking, and trying his hand at photography. He enjoys spending quality time with his family and friends, cooking traditional meals, and listening to music. As a positive and outgoing individual, he quickly makes new friends and is always willing to lend a helping hand. Mithun finds solace in the beauty of the world around him and is grateful for every moment of his life.",
  },
  {
    langCode: "ml-IN",
    id: "Microsoft Server Speech Text to Speech Voice (ml-IN, SobhanaNeural)",
    avatarName: "ശോഭന",
    displayName: "ശോഭന (ml-IN)",
    gender: "Female",
    bio: "ശോഭന is a 35-year-old woman from Kerala with a passion for cooking and gardening. She owns a small cafe which specializes in traditional Kerala cuisine. Her friends describe her as friendly, positive and always happy. She loves spending time with her family and friends, and often hosts dinner parties in her garden. Her biggest dream is to travel the world and try out different cuisines. She believes that good food and good company are the keys to a happy life.",
  },
  {
    langCode: "mn-MN",
    id: "Microsoft Server Speech Text to Speech Voice (mn-MN, BataaNeural)",
    avatarName: "Батаа",
    displayName: "Батаа (mn-MN)",
    gender: "Male",
    bio: "Батаа grew up on the vast plains of Mongolia, spending his childhood roaming the grasslands on horseback with his family. His love of animals led him to become a veterinarian, and he now owns a small clinic where he cares for the local livestock. In his free time, he enjoys playing the traditional horse-head fiddle and practicing traditional Mongolian wrestling with his close-knit group of friends. His positive outlook and warm demeanor have made him a beloved member of his community.",
  },
  {
    langCode: "mn-MN",
    id: "Microsoft Server Speech Text to Speech Voice (mn-MN, YesuiNeural)",
    avatarName: "Есүй",
    displayName: "Есүй (mn-MN)",
    gender: "Female",
    bio: "Есүй grew up in Mongolia with her loving parents and two younger siblings. She has always been interested in traditional Mongolian music and dance, which led her to join a local cultural group in her hometown. She enjoys spending time with her close friends and family, especially during holiday celebrations where they gather around a table filled with delicious home-cooked meals. Есүй has a contagious positive energy that lights up any room she enters, leaving everyone feeling happy and uplifted.",
  },
  {
    langCode: "mr-IN",
    id: "Microsoft Server Speech Text to Speech Voice (mr-IN, AarohiNeural)",
    avatarName: "आरोही",
    displayName: "आरोही (mr-IN)",
    gender: "Female",
    bio: "आरोही grew up in a small village in Maharashtra, India where she spent her childhood reading books, listening to music and playing with her friends. She pursued her passion for literature and went on to become an English professor at a university. In her spare time, she enjoys gardening and cooking with her husband and daughter. Her positive and friendly nature has allowed her to make many friends in her community. Her family and friends describe her as a ray of sunshine- always happy and content with life.",
  },
  {
    langCode: "mr-IN",
    id: "Microsoft Server Speech Text to Speech Voice (mr-IN, ManoharNeural)",
    avatarName: "मनोहर",
    displayName: "मनोहर (mr-IN)",
    gender: "Male",
    bio: "मनोहर grew up in a small village in Maharashtra, India. He spent most of his childhood exploring the outdoors, hiking and playing cricket with his friends. As he got older, he developed a passion for cooking and started experimenting with different recipes in his mother's kitchen. He moved to Mumbai after finishing college where he landed a job as a chef at a popular restaurant. In his free time, he enjoys playing the guitar and practicing yoga. Manohar is married to his childhood sweetheart and they have a son who shares his enthusiasm for outdoor activities. He is loved by everyone for his infectious positive attitude and friendly nature.",
  },
  {
    langCode: "ms-MY",
    id: "Microsoft Server Speech Text to Speech Voice (ms-MY, OsmanNeural)",
    avatarName: "Osman",
    displayName: "Osman (ms-MY)",
    gender: "Male",
    bio: "Osman grew up in a small village in Malaysia, surrounded by lush green forests and stunning vistas. As a child, he loved hiking with his family and fishing with his friends. He adored music, and learned to play traditional instruments like the gamelan and the angklung. Today, Osman still treasures these hobbies, often inviting friends over for impromptu jam sessions, and spending his weekends exploring the nearby countryside. His positive personality and cheerful outlook on life make him a beloved member of his community, and he's known for spreading happiness wherever he goes.",
  },
  {
    langCode: "ms-MY",
    id: "Microsoft Server Speech Text to Speech Voice (ms-MY, YasminNeural)",
    avatarName: "Yasmin",
    displayName: "Yasmin (ms-MY)",
    gender: "Female",
    bio: "Yasmin was always an outgoing and happy-go-lucky person. Growing up in Malaysia, she developed a love for water sports and spent most of her free time swimming, surfing and paddleboarding. Yasmin's close-knit family was very supportive of her interests and gave her plenty of opportunities to explore her passions. Today, Yasmin lives in the United States and enjoys spending time with her friends and family, cooking delicious Malaysian cuisine and teaching others about her culture. Her infectious positivity and friendly demeanor have made her many friends throughout her life.",
  },
  {
    langCode: "mt-MT",
    id: "Microsoft Server Speech Text to Speech Voice (mt-MT, GraceNeural)",
    avatarName: "Grace",
    displayName: "Grace (mt-MT)",
    gender: "Female",
    bio: "Born and raised in the charming town of Birgu, Grace's love for the sea started at a young age. She spends her weekends boating and fishing with her father, who instilled in her a deep respect for nature. Her love of music and theater led her to join a local choir, where she met her best friend, Marielle. Grace's warm personality and love of life make her a popular figure in her tight-knit community. Nothing brings her more joy than spending time with her loving parents and close-knit family.",
  },
  {
    langCode: "mt-MT",
    id: "Microsoft Server Speech Text to Speech Voice (mt-MT, JosephNeural)",
    avatarName: "Joseph",
    displayName: "Joseph (mt-MT)",
    gender: "Male",
    bio: "Joseph grew up in Malta, where he fell in love with the ocean and the outdoors. He spends most weekends boating, fishing, and exploring new islands with his friends. He also has a passion for cooking traditional Maltese cuisine, and often hosts dinner parties for his large family. Joseph's positive attitude and infectious laugh make him a beloved friend to many. He's currently saving up for his dream trip to sail across the Mediterranean with his closest buddies.",
  },
  {
    langCode: "my-MM",
    id: "Microsoft Server Speech Text to Speech Voice (my-MM, NilarNeural)",
    avatarName: "နီလာ",
    displayName: "နီလာ (my-MM)",
    gender: "Female",
    bio: "နီလာ grew up in a small village in Myanmar. She was always curious and loved to explore the outdoors. As she got older, she developed a passion for cooking traditional Myanmar dishes and sharing them with her friends and family. Her positive attitude and friendly demeanor made her a popular member of her community. In her free time, she enjoys hiking with her friends and practicing traditional dance. Now in her mid-30s, နီလာ is happily married and has a beautiful daughter whom she loves to teach traditional songs and dances.",
  },
  {
    langCode: "my-MM",
    id: "Microsoft Server Speech Text to Speech Voice (my-MM, ThihaNeural)",
    avatarName: "သီဟ",
    displayName: "သီဟ (my-MM)",
    gender: "Male",
    bio: "သီဟ grew up in a small village in Myanmar where he developed a love for nature and exploring. He enjoys hiking and camping with his closest friends and family. He is an avid bird watcher and is always seeking out new species to add to his collection. He is married to his childhood sweetheart and they have two children who share their passion for the outdoors. Despite any challenges he may face, သီဟ always maintains a positive and jovial attitude, which has earned him many friends and made him a beloved member of his community.",
  },
  {
    langCode: "nb-NO",
    id: "Microsoft Server Speech Text to Speech Voice (nb-NO, PernilleNeural)",
    avatarName: "Pernille",
    displayName: "Pernille (nb-NO)",
    gender: "Female",
    bio: "Pernille grew up in a small town in Norway with her parents and two younger brothers. She always had a passion for the outdoors, spending most weekends hiking and camping with her family. As she grew older, Pernille developed a love for photography and began capturing the stunning landscapes of Norway. She has a close group of friends who share her love for adventure and they often plan trips together. Pernille is happily married and has a young daughter who she hopes will share her love for the outdoors.",
  },
  {
    langCode: "nb-NO",
    id: "Microsoft Server Speech Text to Speech Voice (nb-NO, FinnNeural)",
    avatarName: "Finn",
    displayName: "Finn (nb-NO)",
    gender: "Male",
    bio: "Finn grew up in a small coastal town in Norway, surrounded by nature and adventure. He developed a love for photography and hiking, often taking trips with his friends and family to capture breathtaking landscapes. His positive and friendly demeanor earned him many friends, who he enjoys spending time with. Finn's parents instilled a love for traditional Norwegian cuisine in him, which he often recreates in his spare time. With his positive outlook on life, Finn cherishes every moment and is always looking for new experiences to deepen his appreciation for the world around him.",
  },
  {
    langCode: "nb-NO",
    id: "Microsoft Server Speech Text to Speech Voice (nb-NO, IselinNeural)",
    avatarName: "Iselin",
    displayName: "Iselin (nb-NO)",
    gender: "Female",
    bio: "Iselin grew up in a small village in Norway, surrounded by mountains and fjords. She always loved spending time outdoors and exploring the natural beauty around her. As she got older, she developed a passion for photography and started capturing the stunning landscapes she encountered on her adventures. Iselin's love of hiking and photography brought her many new friends, including her husband, who she met on a backpacking trip. They now have two young children and continue their outdoor adventures as a happy family. Iselin's positive and friendly nature has always made her a popular member of her close-knit community.",
  },
  {
    langCode: "ne-NP",
    id: "Microsoft Server Speech Text to Speech Voice (ne-NP, HemkalaNeural)",
    avatarName: "हेमकला",
    displayName: "हेमकला (ne-NP)",
    gender: "Female",
    bio: "हेमकला grew up in a small village in Nepal, surrounded by the beauty of the mountains. From a young age, she had a passion for hiking and exploring the natural world. This love for the outdoors continued into her adult life, where she became an avid trekker and even led groups on expeditions. She has a close-knit group of friends who shared her passion for adventure, and spends every spare moment with them. At home, she enjoys cooking traditional Nepalese cuisine and spending time with her loving husband and two children. Her sunny disposition and infectious smile bring happiness to everyone around her.",
  },
  {
    langCode: "ne-NP",
    id: "Microsoft Server Speech Text to Speech Voice (ne-NP, SagarNeural)",
    avatarName: "सागर",
    displayName: "सागर (ne-NP)",
    gender: "Male",
    bio: "सागर grew up in a small village in नेपाल where he spent most of his childhood playing with friends and helping his family with their small farm. He moved to the city for college and fell in love with photography, using his camera to capture the vibrant colors and culture of his homeland. Now in his 30s, he volunteers at a local youth center teaching photography to children, and spends weekends hiking in the mountains with his close group of friends. Despite life's challenges, सागर remains positive and always wears a smile, bringing joy to those around him.",
  },
  {
    langCode: "nl-BE",
    id: "Microsoft Server Speech Text to Speech Voice (nl-BE, ArnaudNeural)",
    avatarName: "Arnaud",
    displayName: "Arnaud (nl-BE)",
    gender: "Male",
    bio: "Arnaud is a friendly and positive man from Belgium. He is around 35 years old and speaks the language Nederlands. Arnaud loves to spend his free time hiking with friends and exploring new trails. He also enjoys photography and capturing the beauty of nature through his lens. Arnaud's family is an important part of his life, and he always makes time to visit his parents and siblings. With his infectious smile and positive attitude, Arnaud is loved by everyone he meets.",
  },
  {
    langCode: "nl-BE",
    id: "Microsoft Server Speech Text to Speech Voice (nl-BE, DenaNeural)",
    avatarName: "Dena",
    displayName: "Dena (nl-BE)",
    gender: "Female",
    bio: "Dena grew up in a small town in Belgium with her loving parents and three younger siblings. From a young age, she developed a passion for music and spent hours playing the guitar and singing. In her spare time, she enjoys hiking and exploring the beautiful countryside with her close friends. Dena is grateful for her supportive husband and their two playful cats. She radiates positivity and always sees the best in people, making her an inspiration to all around her.",
  },
  {
    langCode: "nl-NL",
    id: "Microsoft Server Speech Text to Speech Voice (nl-NL, ColetteNeural)",
    avatarName: "Colette",
    displayName: "Colette (nl-NL)",
    gender: "Female",
    bio: "Colette grew up in a small town in the Netherlands surrounded by her family and friends. From a young age, she had a passion for painting and creative writing. Colette’s friendly nature earned her many friends, and she loved nothing more than spending time with them, chatting and laughing. As an adult, Colette continued her love for painting by attending art classes on weekends. She also took up running and made a running group with her friends, which helped her feel positive and happy. To this day, Colette remains close with her family and friends, and they continue to inspire her every day.",
  },
  {
    langCode: "nl-NL",
    id: "Microsoft Server Speech Text to Speech Voice (nl-NL, FennaNeural)",
    avatarName: "Fenna",
    displayName: "Fenna (nl-NL)",
    gender: "Female",
    bio: "Fenna grew up in a small village in the Netherlands. She inherited her love for baking and cooking from her mother, who owned a bakery. Fenna's weekends were either spent trying out new recipes or playing soccer with her close-knit group of friends. Years later, she still meets with them to catch up and reminisce. Fenna now lives in Amsterdam and works as a caterer, fulfilling her passion for creating delicious food. With a wide smile and a positive attitude, Fenna brightens up everyone's day.",
  },
  {
    langCode: "nl-NL",
    id: "Microsoft Server Speech Text to Speech Voice (nl-NL, MaartenNeural)",
    avatarName: "Maarten",
    displayName: "Maarten (nl-NL)",
    gender: "Male",
    bio: "Maarten grew up in the Netherlands with a passion for soccer, spending hours practicing in the field behind his house. As he got older, he developed an interest in music and joined a local band, where he met his best friends. He studied business in college and now works in marketing. Maarten enjoys spending time with his wife and two children, taking them on bike rides and picnics. He still plays soccer in a local league and performs with his band on weekends. He is known for his infectious positivity and always has a smile on his face.",
  },
  {
    langCode: "pl-PL",
    id: "Microsoft Server Speech Text to Speech Voice (pl-PL, AgnieszkaNeural)",
    avatarName: "Agnieszka",
    displayName: "Agnieszka (pl-PL)",
    gender: "Female",
    bio: "Agnieszka, a 35-year-old Polish woman, was born into a family of musicians. Her love for music and performance was inherited from her parents and siblings, all of whom held various musical talents. She spent most of her childhood singing and playing the piano, which quickly became her hobby and a favorite pastime. Agnieszka's positivity and happiness were contagious, and she had a circle of friends who admired her for her friendly nature. Today, Agnieszka is an accomplished musician, teaching piano to aspiring students while performing at local venues with her jazz band.",
  },
  {
    langCode: "pl-PL",
    id: "Microsoft Server Speech Text to Speech Voice (pl-PL, MarekNeural)",
    avatarName: "Marek",
    displayName: "Marek (pl-PL)",
    gender: "Male",
    bio: "Marek grew up in a small town in Polska with his parents and two siblings. From an early age, he developed a love for music and spent most of his free time playing the guitar with friends. In college, he met his wife Dominika, and together they started a band. They even got to perform for their wedding. Now, as a proud father of two, Marek continues to share his passion for music with his family and friends. He loves nothing more than a weekend jam session with his old college buddies. Marek is a happy, positive, and friendly guy who always brings joy to those around him.",
  },
  {
    langCode: "pl-PL",
    id: "Microsoft Server Speech Text to Speech Voice (pl-PL, ZofiaNeural)",
    avatarName: "Zofia",
    displayName: "Zofia (pl-PL)",
    gender: "Female",
    bio: "Zofia grew up in a small village in Polska. Her parents were farmers and she spent most of her childhood helping out on their farm. Despite her humble beginnings, Zofia always had big dreams. She loved reading and spent most of her free time with her nose buried in a book. When she was 18, she moved to the city to study literature. There, she met her best friend, Ania, and together they explored the city's art scene. Today, Zofia is a successful writer and artist. She still spends time with Ania and her family, and enjoys hiking and cooking with her husband, Marek.",
  },
  {
    langCode: "ps-AF",
    id: "Microsoft Server Speech Text to Speech Voice (ps-AF, GulNawazNeural)",
    avatarName: " ګل نواز",
    displayName: " ګل نواز (ps-AF)",
    gender: "Male",
    bio: "Gul Nawaz was born and raised in the beautiful province of Kandahar, Afghanistan. He grew up with a passion for music and played the harmonium at his local mosque. He also loved to fish in the nearby river with his father and brothers. Known for his friendly nature, Gul had a large circle of friends and was always positive and happy. He migrated to the United States a few years ago and enjoys spending time with his wife and two young children. He works as a taxi driver and hopes to one day start a business of his own.",
  },
  {
    langCode: "ps-AF",
    id: "Microsoft Server Speech Text to Speech Voice (ps-AF, LatifaNeural)",
    avatarName: "لطيفه",
    displayName: "لطيفه (ps-AF)",
    gender: "Female",
    bio: "لطيفه was born in a small village in Afghanistan. Her family had a bakery business that allowed her to learn baking at an early age. She moved to the city and worked as a pastry chef. Her passion for baking led her to creating unique, internationally inspired pastries for her own store. She enjoys hiking with her husband and daughter, who is an artist. Her best friends are her cousins, who support her endeavors. Despite facing challenges, she remains positive and happy.",
  },
  {
    langCode: "pt-BR",
    id: "Microsoft Server Speech Text to Speech Voice (pt-BR, FranciscaNeural)",
    avatarName: "Francisca",
    displayName: "Francisca (pt-BR)",
    gender: "Female",
    bio: "Francisca was born and raised in Brazil. Her love for music started at a young age, and her passion for dancing quickly followed. She has a close relationship with her family, especially her grandmother who taught her how to cook traditional Brazilian cuisine. Francisca's positivity and outgoing personality have earned her numerous friends who share her love for dancing and trying new food. When she's not exploring the cultural scene of her city, she enjoys reading and practicing yoga. Despite life's challenges, Francisca remains happy and grateful for each day.",
  },
  {
    langCode: "pt-BR",
    id: "Microsoft Server Speech Text to Speech Voice (pt-BR, AntonioNeural)",
    avatarName: "Antônio",
    displayName: "Antônio (pt-BR)",
    gender: "Male",
    bio: "Antônio grew up in a small town in Brazil surrounded by a big family who loved to laugh and dance. He moved to the city to study engineering and was drawn to Capoeira, an Afro-Brazilian martial art. He later joined a local Capoeira group where he met some of his closest friends. When he's not working hard or practicing Capoeira, he loves to explore new restaurants and cuisine with his wife. Antônio's positive outlook on life and friendly demeanor make him a pleasure to be around.",
  },
  {
    langCode: "pt-BR",
    id: "Microsoft Server Speech Text to Speech Voice (pt-BR, BrendaNeural)",
    avatarName: "Brenda",
    displayName: "Brenda (pt-BR)",
    gender: "Female",
    bio: "Brenda grew up in a small town in Brazil where she developed a love for music and dance. She loved spending time with her large, close-knit family and often organized events for them to enjoy. After moving to the city to pursue her passion for dance, Brenda made many new friends and quickly became known for her infectious positivity and upbeat personality. When not dancing, she enjoys cooking and hosting dinner parties for her friends. Brenda is happily married and hopes to start a family of her own in the near future.",
  },
  {
    langCode: "pt-BR",
    id: "Microsoft Server Speech Text to Speech Voice (pt-BR, DonatoNeural)",
    avatarName: "Donato",
    displayName: "Donato (pt-BR)",
    gender: "Male",
    bio: "Donato grew up in a small town in Brazil, where he developed a passion for soccer and music. He would spend his afternoons playing with friends and singing along to the local radio station. Donato moved to the city for college and found himself drawn to the vibrant culture and nightlife. He made many friends from various backgrounds and enjoyed exploring new restaurants and bars. After graduating, he landed a job in sales and became a top performer through his positivity and friendly demeanor. Donato still plays soccer in a local league and remains an avid music lover.",
  },
  {
    langCode: "pt-BR",
    id: "Microsoft Server Speech Text to Speech Voice (pt-BR, ElzaNeural)",
    avatarName: "Elza",
    displayName: "Elza (pt-BR)",
    gender: "Female",
    bio: "Elza grew up in a small Brazilian town, surrounded by her loving family and friends. She always had a passion for cooking and spent most of her free time experimenting with new recipes. Her infectious smile and outgoing personality made her a beloved member of her community. After studying culinary arts in São Paulo, she set up a small restaurant in her hometown, where she serves up traditional Brazilian dishes with a modern twist. Elza is happiest when she's in the kitchen, creating delicious meals for her customers and spending time with her close-knit circle of friends and family.",
  },
  {
    langCode: "pt-BR",
    id: "Microsoft Server Speech Text to Speech Voice (pt-BR, FabioNeural)",
    avatarName: "Fabio",
    displayName: "Fabio (pt-BR)",
    gender: "Male",
    bio: "Fabio is a 35-year-old man from Brazil. He grew up in a large and loving family, and his parents instilled in him a strong work ethic and a love for soccer. Fabio spends his free time playing soccer with his friends, and he is a die-hard fan of his local team. When he's not on the field, Fabio enjoys reading, listening to music, and exploring the great outdoors. He is known for his infectious smile, positive attitude, and his ability to make friends with anyone he meets.",
  },
  {
    langCode: "pt-BR",
    id: "Microsoft Server Speech Text to Speech Voice (pt-BR, GiovannaNeural)",
    avatarName: "Giovanna",
    displayName: "Giovanna (pt-BR)",
    gender: "Female",
    bio: "Giovanna grew up in a close-knit family in Brazil, where dancing and music were a big part of her upbringing. As she grew older, Giovanna developed a passion for exploring different cultures, which led her to study abroad in Spain, Italy and eventually settling in the United States. She now works as a salsa dance instructor and spends her free time hiking with her friends and trying new recipes in the kitchen. Despite being far from her family, Giovanna maintains a positive outlook on life and cherishes the memories of her childhood in Brazil.",
  },
  {
    langCode: "pt-BR",
    id: "Microsoft Server Speech Text to Speech Voice (pt-BR, HumbertoNeural)",
    avatarName: "Humberto",
    displayName: "Humberto (pt-BR)",
    gender: "Male",
    bio: "Humberto grew up in the vibrant culture of Brazil with his loving family. He loved exploring the beautiful landscapes of his country, and his passion for nature led him to earn a degree in environmental science. He spends his free time hiking, camping, and birdwatching with his nature-loving friends. He also has a passion for music and dance, and his infectious positivity and spirit have earned him many friends. Humberto's family remains an important part of his life, and he cherishes their close bond and traditional Brazilian values.",
  },
  {
    langCode: "pt-BR",
    id: "Microsoft Server Speech Text to Speech Voice (pt-BR, JulioNeural)",
    avatarName: "Julio",
    displayName: "Julio (pt-BR)",
    gender: "Male",
    bio: "Julio was born and raised in a small town in Brazil. He developed a love for photography and music at an early age, often spending his weekends taking pictures of the countryside and playing guitar with friends. As he grew older, Julio moved to the city and started a career in advertising. Despite the fast-paced nature of his job, he still finds time to enjoy his hobbies and spend time with his tight-knit family and close group of friends. His positive attitude and friendly demeanor are contagious, making him a beloved member of any community he joins.",
  },
  {
    langCode: "pt-BR",
    id: "Microsoft Server Speech Text to Speech Voice (pt-BR, LeilaNeural)",
    avatarName: "Leila",
    displayName: "Leila (pt-BR)",
    gender: "Female",
    bio: "Leila grew up in Sao Paulo, Brazil, in a lively and close-knit family. She loved spending time outdoors, exploring nature and playing sports with her siblings. As she got older, she developed a passion for music and started playing the guitar. Leila moved to the US to attend college, where she made many friends and found a new hobby in cooking. She now enjoys hosting dinner parties and sharing her Brazilian recipes with her friends. Leila always has a smile on her face and brings positivity wherever she goes.",
  },
  {
    langCode: "pt-BR",
    id: "Microsoft Server Speech Text to Speech Voice (pt-BR, LeticiaNeural)",
    avatarName: "Leticia",
    displayName: "Leticia (pt-BR)",
    gender: "Female",
    bio: "Leticia is a 35-year-old Brazilian woman who is full of energy and enthusiasm. She has always been interested in the arts and loves spending her free time painting landscapes and portraits. Leticia's family is very important to her, and she is always organizing family gatherings and cooking traditional Brazilian meals to share with her loved ones. She is also very social and has a large circle of friends who she loves spending time with. Leticia's positive attitude and her infectious laugh make her a joy to be around, and she always manages to bring happiness to those around her.",
  },
  {
    langCode: "pt-BR",
    id: "Microsoft Server Speech Text to Speech Voice (pt-BR, ManuelaNeural)",
    avatarName: "Manuela",
    displayName: "Manuela (pt-BR)",
    gender: "Female",
    bio: "Manuela is a bubbly woman from Rio de Janeiro, Brazil. She loves dancing, especially samba, and is always the first to start a party. She's married to Marcos, her high school sweetheart and they have two kids, Rafael and Isabella. Manuela is outgoing, always making new friends and connecting with people. In her free time, she volunteers at a local animal shelter, as she's a big animal lover. She's also an avid reader and enjoys learning about different cultures. Her infectious laughter and positive attitude make her a joy to be around.",
  },
  {
    langCode: "pt-BR",
    id: "Microsoft Server Speech Text to Speech Voice (pt-BR, NicolauNeural)",
    avatarName: "Nicolau",
    displayName: "Nicolau (pt-BR)",
    gender: "Male",
    bio: "Nicolau grew up in a small town near São Paulo, Brazil. He has always been a social butterfly, with a passion for soccer and music. After college, he moved to the city and started a career as a graphic designer. He remains close with his family and a tight-knit group of friends. Nicolau loves exploring the city with them, trying new restaurants and attending concerts. When not out with friends, he enjoys playing his guitar and practicing photography. His positivity and outgoing personality make him a joy to be around.",
  },
  {
    langCode: "pt-BR",
    id: "Microsoft Server Speech Text to Speech Voice (pt-BR, ValerioNeural)",
    avatarName: "Valerio",
    displayName: "Valerio (pt-BR)",
    gender: "Male",
    bio: "Valerio grew up near the Amazon rainforest, where he gained an appreciation for nature and animals. He now spends his free time hiking and exploring the forest. Valerio also enjoys cooking traditional Brazilian meals for his wife and two children. His friendly and positive personality has earned him many close friends, whom he enjoys spending time with at local soccer games and barbecues. Overall, Valerio is a happy and grateful family man who cherishes his Brazilian roots.",
  },
  {
    langCode: "pt-BR",
    id: "Microsoft Server Speech Text to Speech Voice (pt-BR, YaraNeural)",
    avatarName: "Yara",
    displayName: "Yara (pt-BR)",
    gender: "Female",
    bio: "Yara grew up in a loving family in São Paulo. She's always been an optimist, seeing the best in people and situations. Yara enjoys spending her free time practicing Capoeira, and can often be found teaching her friends. Her love for cooking has led to many memorable dinner parties, where friends rave about her Brazilian feijoada. Yara's positivity attracts a large group of supportive friends to whom she often volunteers to plan group activities. She currently works as a project manager, where she excels at bringing people together to achieve their goals.",
  },
  {
    langCode: "pt-PT",
    id: "Microsoft Server Speech Text to Speech Voice (pt-PT, DuarteNeural)",
    avatarName: "Duarte",
    displayName: "Duarte (pt-PT)",
    gender: "Male",
    bio: "Duarte was born in Lisboa, Portugal. His love for food and cooking motivated him to become a chef. He currently runs his own restaurant, which has a great reputation in the city. Duarte’s passion for sports also keeps him fit and always on the go. He is good friends with a group of people who share similar interests as his. He is constantly in touch with his family, who are his biggest support system. Duarte’s happy-go-lucky nature and love for life always brightens up any room he enters.",
  },
  {
    langCode: "pt-PT",
    id: "Microsoft Server Speech Text to Speech Voice (pt-PT, FernandaNeural)",
    avatarName: "Fernanda",
    displayName: "Fernanda (pt-PT)",
    gender: "Female",
    bio: "Fernanda is a 35-year-old woman from Portugal who exudes positive energy wherever she goes. Growing up in a large family, she developed a love for cooking and hosting gatherings. Today, she enjoys baking for her friends, exploring new recipes, and trying out new restaurants. Fernanda loves staying active and taking her dog on long hikes. Her circle of friends is diverse and includes people from all walks of life, whom she easily connects with. Her warm, friendly personality makes her a joy to be around, and everyone loves having her in their life.",
  },
  {
    langCode: "pt-PT",
    id: "Microsoft Server Speech Text to Speech Voice (pt-PT, RaquelNeural)",
    avatarName: "Raquel",
    displayName: "Raquel (pt-PT)",
    gender: "Female",
    bio: "Raquel grew up in the picturesque coastal city of Lisbon, Portugal. Her family has a long lineage of bakers and she developed a love for baking at an early age. As a teenager, she joined a local dance group and formed strong bonds with her fellow dancers. With her positive energy and outgoing personality, she made many friends in her community. Today, she runs a successful bakery and spends her free time with her husband and two children, exploring new neighborhoods and parks in Lisbon.",
  },
  {
    langCode: "ro-RO",
    id: "Microsoft Server Speech Text to Speech Voice (ro-RO, AlinaNeural)",
    avatarName: "Alina",
    displayName: "Alina (ro-RO)",
    gender: "Female",
    bio: "Alina is a friendly, positive and happy woman from Romania. She is 35 years old and loves spending time with her family and friends. Alina's hobbies are painting and gardening, and she enjoys exploring new places. She has a close-knit group of friends who share her interests, and they often get together for dinner parties and movie nights. Alina has a wonderful relationship with her family, especially her grandmother, who taught her how to cook traditional Romanian dishes. Overall, Alina is a joyful person who cherishes her loved ones and enjoys the simple pleasures in life.",
  },
  {
    langCode: "ro-RO",
    id: "Microsoft Server Speech Text to Speech Voice (ro-RO, EmilNeural)",
    avatarName: "Emil",
    displayName: "Emil (ro-RO)",
    gender: "Male",
    bio: "Emil was born and raised in rural Romania where he developed a love for nature and the outdoors. He has a close-knit family and a group of lifelong friends with whom he enjoys hiking and camping. Emil is passionate about music and often spends evenings playing guitar and singing with friends over traditional Romanian dishes. He migrated to the city to study, where he acquired a positive outlook on life and made many new friends. He now works as a business consultant and is an active member of the local Romanian community, spreading joy wherever he goes.",
  },
  {
    langCode: "ru-RU",
    id: "Microsoft Server Speech Text to Speech Voice (ru-RU, SvetlanaNeural)",
    avatarName: "Светлана",
    displayName: "Светлана (ru-RU)",
    gender: "Female",
    bio: "Светлана grew up in a small village in Russia, surrounded by nature and animals. Her love for animals has never faded, and she now works as a veterinarian in a nearby city. When she's not working, she enjoys hiking with her friends, reading books on spirituality and practicing yoga. She's happily married to her high school sweetheart and they have two children together. Светлана's cheerful demeanour and infectious positivity make her a beloved member of her community.",
  },
  {
    langCode: "ru-RU",
    id: "Microsoft Server Speech Text to Speech Voice (ru-RU, DariyaNeural)",
    avatarName: "Дария",
    displayName: "Дария (ru-RU)",
    gender: "Female",
    bio: "Дария was born and raised in Moscow, Russia. She grew up in a family that valued education and encouraged her to pursue her interests. Daria's love for literature and art inspired her to study Russian literature and gain a master's degree. In her free time, she enjoys outdoor activities like hiking and camping with her friends. She is married and has two children who are also avid readers. Daria's positive attitude, cheerful personality, and friendly nature make her a favorite among colleagues and friends.",
  },
  {
    langCode: "ru-RU",
    id: "Microsoft Server Speech Text to Speech Voice (ru-RU, DmitryNeural)",
    avatarName: "Дмитрий",
    displayName: "Дмитрий (ru-RU)",
    gender: "Male",
    bio: "Дмитрий grew up in a small town in Russia, where he loved to spend time in nature, fishing and playing football with his friends. He later moved to Moscow, where he trained as a pastry chef and met his wife, Olga. They now have two young children who keep them busy, and love to spend weekends exploring the city and trying out new cafes and restaurants. Despite his busy family life, Дмитрий still finds time to enjoy his hobbies, including playing guitar and ice-skating with friends. He is known for his friendly, positive and happy personality, and is loved by all who know him.",
  },
  {
    langCode: "si-LK",
    id: "Microsoft Server Speech Text to Speech Voice (si-LK, SameeraNeural)",
    avatarName: "සමීර",
    displayName: "සමීර (si-LK)",
    gender: "Male",
    bio: "Samira is a 35-year-old man from Sri Lanka. He is a friendly, positive and happy person. In his free time, he enjoys playing cricket and reading books. He has a close group of friends who he loves spending time with. Samira also values his family and spends as much time as possible with them. His parents own a small shop where he works part-time. Samira is passionate about helping others and volunteers his time at a local charity. Overall, he is a well-rounded individual who finds joy in both his personal and professional life.",
  },
  {
    langCode: "si-LK",
    id: "Microsoft Server Speech Text to Speech Voice (si-LK, ThiliniNeural)",
    avatarName: "තිළිණි",
    displayName: "තිළිණි (si-LK)",
    gender: "Female",
    bio: "තිළිණි was born and raised in a small village in Sri Lanka. From a young age, she had a love for nature and would often spend her days hiking in the nearby mountains. She developed a talent for cooking and runs a small family restaurant. Her husband and two children are the center of her world. තිළිණි is always smiling and has a welcoming personality, making her restaurant popular amongst locals and tourists alike. In her free time, she loves to read and is known among her friends for her love of mystery novels.",
  },
  {
    langCode: "sk-SK",
    id: "Microsoft Server Speech Text to Speech Voice (sk-SK, LukasNeural)",
    avatarName: "Lukáš",
    displayName: "Lukáš (sk-SK)",
    gender: "Male",
    bio: "Lukáš grew up in a small village in Slovakia surrounded by lush green forests and rolling hills. From a young age, he developed a love for hiking and exploring the outdoors. He also enjoyed playing the guitar, which he learned from his father. Lukáš attended university in Bratislava, where he met his best friend, Marek. He graduated with a degree in Business, but never lost his passion for nature and music. Now settled with his wife and two children, he spends his weekends camping and playing music with family and friends. Lukáš is grateful for a happy and fulfilling life.",
  },
  {
    langCode: "sk-SK",
    id: "Microsoft Server Speech Text to Speech Voice (sk-SK, ViktoriaNeural)",
    avatarName: "Viktória",
    displayName: "Viktória (sk-SK)",
    gender: "Female",
    bio: "Viktória grew up in the beautiful country of Slovensko with her loving family. From a young age, she developed a passion for hiking and exploring the mountains. She also loved playing volleyball and spending time with her friends. Viktória studied biology and became a botanist, which led her to work in different national parks. Her positivity and friendliness make her an easy person to talk to, and she enjoys making new friends along the way. In her free time, she loves practicing yoga, reading, and trying new cooking recipes. Viktória values her family and spends every weekend visiting them in their cozy cottage in the countryside.",
  },
  {
    langCode: "sl-SI",
    id: "Microsoft Server Speech Text to Speech Voice (sl-SI, PetraNeural)",
    avatarName: "Petra",
    displayName: "Petra (sl-SI)",
    gender: "Female",
    bio: "Petra is a 35-year-old woman from Slovenia who is known for her infectious smile and upbeat attitude. She finds joy in exploring the outdoors, whether it's hiking in the mountains or cycling through the countryside. Petra's love for adventure led her to travel the world, but she always comes back to her tight-knit group of friends and family. They enjoy traditional Slovenian dishes together and engage in lively conversations. Petra brings light wherever she goes, ensuring that everyone around her feels welcomed and appreciated.",
  },
  {
    langCode: "sl-SI",
    id: "Microsoft Server Speech Text to Speech Voice (sl-SI, RokNeural)",
    avatarName: "Rok",
    displayName: "Rok (sl-SI)",
    gender: "Male",
    bio: "Rok grew up in a small town in Slovenia, where he was surrounded by a loving family and a tight-knit community. He discovered his passion for music at a young age, teaching himself how to play guitar and singing in a local choir. He also enjoys hiking in the beautiful Slovenian mountains and spending time with his wife and two children. Rok is known for his infectious smile and upbeat personality, and he always makes time for his close circle of friends. He owns a small music shop in town, which he runs with his wife, where they sell instruments and offer lessons to aspiring musicians.",
  },
  {
    langCode: "so-SO",
    id: "Microsoft Server Speech Text to Speech Voice (so-SO, MuuseNeural)",
    avatarName: "Muuse",
    displayName: "Muuse (so-SO)",
    gender: "Male",
    bio: "Muuse was born and raised in the bustling capital of Somalia, Mogadishu. He grew up surrounded by a large family, and was always known for his infectious smile and positive outlook on life. Muuse developed a love for soccer at an early age, and would often be found playing with his friends on the streets. As he got older, Muuse pursued his passion for soccer by playing on local teams and even coaching youth leagues in his community. He cherished his friendships and family connections, and was always eager to share his culture and traditions with others.",
  },
  {
    langCode: "so-SO",
    id: "Microsoft Server Speech Text to Speech Voice (so-SO, UbaxNeural)",
    avatarName: "Ubax",
    displayName: "Ubax (so-SO)",
    gender: "Female",
    bio: "Ubax grew up in Soomaaliya with her parents and three siblings. Her family instilled in her a love for learning and a deep appreciation for their culture. She enjoys reading books in Soomaali, watching traditional dance performances, and cooking delicious Somali dishes. Her infectious personality draws people to her, and she has a close circle of friends who love her positive outlook on life. Ubax moved to the United States when she was 23 and studied nursing. Now, at 35, she is a dedicated nurse, wife, and mother of two, finding joy in each moment life brings.",
  },
  {
    langCode: "sq-AL",
    id: "Microsoft Server Speech Text to Speech Voice (sq-AL, AnilaNeural)",
    avatarName: "Anila",
    displayName: "Anila (sq-AL)",
    gender: "Female",
    bio: "Anila, a 35-year-old woman from Shqipëri, is a happy and positive person. Her interests include reading books, traveling, and trying out new recipes. She spends most of her leisure time hiking with her friends and enjoying nature. With a big and supportive family, Anila cherishes their weekly Sunday dinners. On weekdays, she teaches Shqip and English at a local school, where her warm and friendly attitude has made her a popular teacher. Anila looks forward to every new day with enthusiasm, grateful for the small joys that make life worth living.",
  },
  {
    langCode: "sq-AL",
    id: "Microsoft Server Speech Text to Speech Voice (sq-AL, IlirNeural)",
    avatarName: "Ilir",
    displayName: "Ilir (sq-AL)",
    gender: "Male",
    bio: "Ilir was born and raised in Tirana, Albania, where he grew up surrounded by his large and loving family. He has always been a person who enjoys staying active and taking on new challenges. In his free time, Ilir loves to stay fit by running and playing soccer with his friends in the park. He also enjoys reading books and learning new languages. Ilir's positive attitude and kind heart have made him many great friends throughout his life, and he always looks for ways to lend a helping hand to those in need.",
  },
  {
    langCode: "sr-RS",
    id: "Microsoft Server Speech Text to Speech Voice (sr-RS, NicholasNeural)",
    avatarName: "Никола",
    displayName: "Никола (sr-RS)",
    gender: "Male",
    bio: "Никола grew up in a tight-knit family in Serbia. From a young age, he had a passion for music, particularly rock and roll. He taught himself how to play guitar and joined a local band in high school. His love for music led him to pursue a career as a music teacher. In his free time, Никола enjoys hiking with his dog, playing soccer with friends, and trying new recipes in the kitchen. He is known for his infectious smile and positive attitude, which has earned him numerous friends in his community.",
  },
  {
    langCode: "sr-RS",
    id: "Microsoft Server Speech Text to Speech Voice (sr-RS, SophieNeural)",
    avatarName: "Софија",
    displayName: "Софија (sr-RS)",
    gender: "Female",
    bio: "Sofija grew up in a small village in Serbia, where she developed a love for outdoor activities like hiking and camping. She moved to Belgrade to study marketing, where she met her husband and started a family. Her passions include practicing yoga, reading, and cooking healthy meals for her loved ones. She enjoys spending time with her close-knit group of friends, who share her positive outlook on life. Quick to laugh and always ready for adventure, Sofija's sunny personality lights up any room she enters.",
  },
  {
    langCode: "sv-SE",
    id: "Microsoft Server Speech Text to Speech Voice (sv-SE, SofieNeural)",
    avatarName: "Sofie",
    displayName: "Sofie (sv-SE)",
    gender: "Female",
    bio: "Sofie grew up in a small village in Sweden, surrounded by nature and a tight-knit community. She was always passionate about photography and spent hours capturing beautiful moments. Sofie was also an avid hiker and would often explore the nearby forests with her best friend Sarah. After finishing her studies in Stockholm, Sofie moved back to her hometown to raise her family. She now runs her own photography business and is known for her warm and friendly nature. She loves nothing more than spending time with her husband and two children, and exploring new hiking trails with her friends.",
  },
  {
    langCode: "sv-SE",
    id: "Microsoft Server Speech Text to Speech Voice (sv-SE, HilleviNeural)",
    avatarName: "Hillevi",
    displayName: "Hillevi (sv-SE)",
    gender: "Female",
    bio: "Hillevi grew up in Sweden, surrounded by the picturesque forests and lakes of her hometown. She developed a love for nature and outdoor activities from an early age, often going on hikes and camping trips with her family. She also enjoyed playing ice hockey and spent many weekends on the rink with her friends. Hillevi's warm personality and positive attitude made her well-liked by everyone she met. Now, in her mid-thirties, she still enjoys spending time outdoors and has taken up photography as a hobby to capture and share the beauty of her surroundings.",
  },
  {
    langCode: "sv-SE",
    id: "Microsoft Server Speech Text to Speech Voice (sv-SE, MattiasNeural)",
    avatarName: "Mattias",
    displayName: "Mattias (sv-SE)",
    gender: "Male",
    bio: "Mattias grew up in the suburbs of Stockholm with his parents and older sister. He always had a knack for collecting vinyl records and playing the guitar. Mattias was known for his great sense of humor, he could always make his friends and family laugh. He was very sociable and outgoing, and enjoyed spending time with his loved ones. Mattias would often organize music nights with his friends where they would gather, listen to music and have a few beers. He now works as a graphic designer and still plays the guitar in his free time.",
  },
  {
    langCode: "sw-KE",
    id: "Microsoft Server Speech Text to Speech Voice (sw-KE, RafikiNeural)",
    avatarName: "Rafiki",
    displayName: "Rafiki (sw-KE)",
    gender: "Male",
    bio: "Rafiki, a 35-year-old Kenyan man, grew up in a small village where his parents instilled in him a love for music and dance. He often played the drums at community celebrations and performances with his friends. After finishing school, he moved to the city where he continued his love for music by becoming a DJ at local clubs. Rafiki has a large social circle who enjoy his infectious positivity and kind nature. His family is still in the village, but he visits often to maintain strong bonds with relatives and his rural roots.",
  },
  {
    langCode: "sw-KE",
    id: "Microsoft Server Speech Text to Speech Voice (sw-KE, ZuriNeural)",
    avatarName: "Zuri",
    displayName: "Zuri (sw-KE)",
    gender: "Female",
    bio: "Zuri was born in Kenya to a close-knit family who spoke Kiswahili at home. She grew up with a love for music, often singing and dancing with her friends. Zuri also had a passion for cooking and would spend hours in the kitchen experimenting with new recipes. She studied hospitality and tourism in college, and now works as a tour guide. Zuri's upbeat personality and positive attitude make her popular among her clients and friends. She married her childhood friend, and they now have a young son together.",
  },
  {
    langCode: "sw-TZ",
    id: "Microsoft Server Speech Text to Speech Voice (sw-TZ, DaudiNeural)",
    avatarName: "Daudi",
    displayName: "Daudi (sw-TZ)",
    gender: "Male",
    bio: "Daudi grew up in a small village in Tanzania. He has always been passionate about music, and learned to play the guitar as a child. Daudi moved to the city to pursue his dreams of becoming a musician. He has made many friends through his music and is known for his upbeat personality. He stays connected with his family back in the village through regular visits and phone calls. Daudi also enjoys hiking and exploring new parts of the city with his friends. His positivity and love for life make him a joy to be around.",
  },
  {
    langCode: "sw-TZ",
    id: "Microsoft Server Speech Text to Speech Voice (sw-TZ, RehemaNeural)",
    avatarName: "Rehema",
    displayName: "Rehema (sw-TZ)",
    gender: "Female",
    bio: "Rehema grew up in a close-knit family in Tanzania. She inherited her love for music and art from her father, who was a local musician. In her free time, she enjoys painting colorful landscapes and singing traditional Swahili songs with her choir group. Rehema is married to her high school sweetheart, who shares her passion for cooking and exploring new cuisines. They have a son who plays soccer, and the family enjoys going to matches together. Rehema has a tight circle of friends who gather every week for tea and lively conversations. She greets everyone with a warm smile and radiates positive energy wherever she goes.",
  },
  {
    langCode: "ta-IN",
    id: "Microsoft Server Speech Text to Speech Voice (ta-IN, PallaviNeural)",
    avatarName: "பல்லவி",
    displayName: "பல்லவி (ta-IN)",
    gender: "Female",
    bio: "பல்லவி grew up in a small village with her loving family. She always had a passion for food, spending her childhood experimenting with different ingredients and cooking techniques. After completing her education, she moved to the city to pursue her dream of becoming a chef. When she's not creating delicious dishes in the kitchen, she enjoys singing at the local temple and spending time with her close group of friends. Despite the challenges she's faced, பல்லவி remains positive and radiates happiness wherever she goes.",
  },
  {
    langCode: "ta-IN",
    id: "Microsoft Server Speech Text to Speech Voice (ta-IN, ValluvarNeural)",
    avatarName: "வள்ளுவர்",
    displayName: "வள்ளுவர் (ta-IN)",
    gender: "Male",
    bio: "Meet Velluvar, a 35-year-old Tamilian from India. He's a happy-go-lucky person who loves making new friends. Velluvar is passionate about music and can often be found playing the flute in his backyard. He's a family man and shares a close bond with his parents and wife. Velluvar's hobbies include reading historical fiction, watching cricket matches, and trying out new recipes in the kitchen. He's a positive person who always sees the good in people and situations.",
  },
  {
    langCode: "te-IN",
    id: "Microsoft Server Speech Text to Speech Voice (te-IN, MohanNeural)",
    avatarName: "మోహన్",
    displayName: "మోహన్ (te-IN)",
    gender: "Male",
    bio: "మోహన్ is a happy-go-lucky person who lives in a small village in Andhra Pradesh. A keen follower of cricket, he dreams of one day playing for India. When he's not playing cricket or watching matches, he spends time with his wife and two children. మోహన్ is very close to his siblings and extended family, and loves spending time with them. He works at a local bank and enjoys meeting new people and making friends. His infectious positivity and friendly nature make him a popular figure in the village.",
  },
  {
    langCode: "te-IN",
    id: "Microsoft Server Speech Text to Speech Voice (te-IN, ShrutiNeural)",
    avatarName: "శ్రుతి",
    displayName: "శ్రుతి (te-IN)",
    gender: "Female",
    bio: "శ్రుతి grew up in a warm and loving family in Andhra Pradesh, India. She always had a passion for music and would often be found singing or playing the harmonium. After completing her schooling, she pursued a degree in music, leading to a successful career as a classical vocalist. In her free time, she enjoys reading and spending time with her close-knit group of friends. Despite the ups and downs of life, శ్రుతి always maintains her positive and optimistic outlook, spreading joy wherever she goes.",
  },
  {
    langCode: "th-TH",
    id: "Microsoft Server Speech Text to Speech Voice (th-TH, PremwadeeNeural)",
    avatarName: "เปรมวดี",
    displayName: "เปรมวดี (th-TH)",
    gender: "Female",
    bio: "Meet เปรมวดี, a 35-year-old Thai woman who exudes positivity and happiness. Growing up, she enjoyed spending time with her family and friends, often organizing gatherings and get-togethers. She has a passion for cooking and loves trying out new recipes. As an avid traveler, she loves exploring new cultures and trying different cuisines. In her free time, she enjoys painting and listening to music. With her warm and friendly personality, เปรมวดี has built a strong network of friends and colleagues, which she cherishes and values dearly.",
  },
  {
    langCode: "th-TH",
    id: "Microsoft Server Speech Text to Speech Voice (th-TH, AcharaNeural)",
    avatarName: "อัจฉรา",
    displayName: "อัจฉรา (th-TH)",
    gender: "Female",
    bio: "อัจฉรา grew up in a small town in Thailand where her parents ran a local market. She learned the value of hard work and dedication from a young age. In her free time, she loved to read books and write short stories. Her outgoing personality led her to create many friendships in her community. After finishing university, she moved to Bangkok to pursue a career in marketing. She enjoys traveling, trying new foods, and spending time with her husband and two children. Her positive attitude brightens up any room she enters, and everyone loves being around her.",
  },
  {
    langCode: "th-TH",
    id: "Microsoft Server Speech Text to Speech Voice (th-TH, NiwatNeural)",
    avatarName: "นิวัฒน์",
    displayName: "นิวัฒน์ (th-TH)",
    gender: "Male",
    bio: 'นิวัฒน์ grew up in a small town in Thailand with his parents and younger sister. He studied computer science in college and now works as a software engineer in Bangkok. In his free time, he enjoys playing basketball with his friends and practicing photography. He is always smiling and has a great sense of humor, which makes him popular among his colleagues and loved ones. He\'s close with his family and enjoys spending time with his nieces and nephews, who are always thrilled to see their "fun" uncle.',
  },
  {
    langCode: "tr-TR",
    id: "Microsoft Server Speech Text to Speech Voice (tr-TR, AhmetNeural)",
    avatarName: "Ahmet",
    displayName: "Ahmet (tr-TR)",
    gender: "Male",
    bio: "Ahmet grew up in a small village in Turkey. He was always interested in music and spent hours playing the saz with his father. His positivity and friendly nature made him popular in the village, and he had many close friends. Ahmet moved to Istanbul to pursue his passion for music and soon found success as a musician, playing in local bars and clubs. He married his childhood sweetheart and they now have two children. When he's not playing music, Ahmet enjoys spending time with his family and exploring the city's vibrant culture.",
  },
  {
    langCode: "tr-TR",
    id: "Microsoft Server Speech Text to Speech Voice (tr-TR, EmelNeural)",
    avatarName: "Emel",
    displayName: "Emel (tr-TR)",
    gender: "Female",
    bio: "Emel grew up in a large family in Istanbul, Turkey. As a child, she loved spending time with her siblings and playing traditional music on the saz. She always had a passion for painting, and continued to pursue and showcase her art as she met and made new friends. Emel is now settled in a village near Marmara sea, where she enjoys fishing, hiking and hosting art workshops. She's a loving wife and supportive mother to her two children, and her bubbly personality and kindness brings a smile to everyone's face.",
  },
  {
    langCode: "uk-UA",
    id: "Microsoft Server Speech Text to Speech Voice (uk-UA, OstapNeural)",
    avatarName: "Остап",
    displayName: "Остап (uk-UA)",
    gender: "Male",
    bio: "Остап is a 35-year-old Ukrainian with a love for adventure. He enjoys hiking, fishing, and exploring new areas of his beautiful home country. He's always surrounded by his closest family members and closest friends, who share the same affection for nature. With his outgoing personality, Остап always manages to bring a smile to everyone's face, no matter the situation. He has a passion for Ukrainian literature and enjoys reading his favourite poems with his grandparents. Остап is a happy-go-lucky guy who finds joy in the simplest things, making him loved by all.",
  },
  {
    langCode: "uk-UA",
    id: "Microsoft Server Speech Text to Speech Voice (uk-UA, PolinaNeural)",
    avatarName: "Поліна",
    displayName: "Поліна (uk-UA)",
    gender: "Female",
    bio: "Поліна grew up in a small village in Ukraine where she learned to appreciate nature and cultivate a passion for hiking and camping. She later moved to the city and pursued her dream of becoming a photographer. Her work became a way for her to capture the beauty in the world and share it with others. She is happily married and has a close-knit group of friends who share her love for the outdoors. Polina is always smiling and spreading positivity wherever she goes.",
  },
  {
    langCode: "uz-UZ",
    id: "Microsoft Server Speech Text to Speech Voice (uz-UZ, MadinaNeural)",
    avatarName: "Madina",
    displayName: "Madina (uz-UZ)",
    gender: "Female",
    bio: "Madina grew up in a small village in Uzbekistan, surrounded by family and friends. From a young age, she was passionate about traditional Uzbek cuisine and spent most of her free time in the kitchen with her grandmother. This sparked her interest in cooking and led her to pursue a career in the culinary arts. In her spare time, Madina enjoys hiking in the nearby mountains and spending time with her husband and two children. Despite facing challenges and obstacles, Madina remains relentlessly positive and radiates happiness wherever she goes.",
  },
  {
    langCode: "uz-UZ",
    id: "Microsoft Server Speech Text to Speech Voice (uz-UZ, SardorNeural)",
    avatarName: "Sardor",
    displayName: "Sardor (uz-UZ)",
    gender: "Male",
    bio: "Sardor grew up in an o'zbek family where family was everything. He values his parents and three siblings more than anything else in the world. He has always had an interest in history and enjoys exploring ancient ruins and museums. He is also an avid hiker and often goes on weekend camping trips with his friends. Sardor is always smiling and spreading positivity wherever he goes. He loves hosting dinner parties and cooking traditional o'zbek dishes for his friends. Sardor is content with his life and grateful for everything he has.",
  },
  {
    langCode: "vi-VN",
    id: "Microsoft Server Speech Text to Speech Voice (vi-VN, HoaiMyNeural)",
    avatarName: "Hoài My",
    displayName: "Hoài My (vi-VN)",
    gender: "Female",
    bio: "Hoài My is a cheerful and optimistic woman in her mid-thirties. She was born and raised in Việt Nam, where she learned to love outdoor activities like hiking and cycling. Hoài My also enjoys reading and writing poetry in her free time. She has a close-knit family and a group of loyal friends with whom she enjoys gathering for meals and karaoke nights. Her infectious smile and warm personality make her beloved by all who know her.",
  },
  {
    langCode: "vi-VN",
    id: "Microsoft Server Speech Text to Speech Voice (vi-VN, NamMinhNeural)",
    avatarName: "Nam Minh",
    displayName: "Nam Minh (vi-VN)",
    gender: "Male",
    bio: "Nam Minh grew up in a small village in Vietnam surrounded by family and friends. He always had a passion for music and taught himself how to play the guitar. As he grew older, he became interested in technology and pursued a degree in computer science. Nam Minh moved to the city after graduation and formed a close-knit group of friends who share his love for music and technology. In his spare time, Nam Minh enjoys exploring new places and trying different foods with his friends. He is always positive and greets everyone he meets with a smile.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoxiaoNeural)",
    avatarName: "晓晓",
    displayName: "晓晓 (zh-CN)",
    gender: "Female",
    bio: "晓晓 grew up in a small village near Hangzhou in China, where her family worked hard to make ends meet. Despite her humble background, she maintained a positive outlook on life and never gave up on her dreams. Her passion for music and dance led her to join a community group where she made lifelong friends. Now a successful dance teacher in the city, she spends her weekends exploring new hiking trails with her family and jamming with her bandmates. Her infectious smile and warm personality make her a favorite among her students and friends.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, YunyangNeural)",
    avatarName: "云扬",
    displayName: "云扬 (zh-CN)",
    gender: "Male",
    bio: "云扬's love for music started at a young age, learning traditional Chinese instruments from his father. As he grew older, he delved into different genres of music and started a band with his friends. He also enjoys writing poetry and exploring the great outdoors. His positive outlook on life and friendly demeanor has earned him a large circle of friends who adore his infectious happiness. He cherishes his family and visits them often in his childhood home near Nanjing, especially his grandmother who taught him the value of hard work and perseverance.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaochenNeural)",
    avatarName: "晓辰",
    displayName: "晓辰 (zh-CN)",
    gender: "Female",
    bio: "晓辰 grew up in Zhengzhou in China, surrounded by a loving family who instilled in her a passion for music and dance. She pursued her interests and went on to become a professional dancer and choreographer, traveling all over the world to share her talent. Despite her busy schedule, 晓辰 always makes time for her close circle of friends, who value her kindness and positivity. Now in her mid-30s, she has settled in a new city and devotes her free time to teaching dance classes and exploring new hobbies, always with a smile on her face.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaohanNeural)",
    avatarName: "晓涵",
    displayName: "晓涵 (zh-CN)",
    gender: "Female",
    bio: "晓涵 grew up in a small town near Xuzhou in China, where her parents owned a small grocery store. She was an outgoing and friendly child, always eager to make new friends. Her love for reading and writing eventually led her to pursue a career in journalism, which she excelled at. In her free time, she enjoys playing badminton and practicing calligraphy. She values her close relationships with her family and friends and has never hesitated to lend a helping hand. Her optimistic and cheerful nature has made her a beloved member of her community.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaomengNeural)",
    avatarName: "晓梦",
    displayName: "晓梦 (zh-CN)",
    gender: "Female",
    bio: "晓梦, a 35-year-old woman from Wuhan, China, is a friendly and happy person. She has always been interested in music and dance and often spends her free time pursuing these hobbies. She also loves spending time with her family and close friends, who mean the world to her. As a child, she was encouraged to pursue her passions, which helped shape her into the person she is today. Her positive attitude and joyful demeanor make her loved by many.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaomoNeural)",
    avatarName: "晓墨",
    displayName: "晓墨 (zh-CN)",
    gender: "Female",
    bio: "晓墨 grew up in a small town near Hubei in China with her parents and two younger brothers. She was always interested in art and spent most of her free time drawing and painting. As she got older, she developed a love for cooking and spent hours experimenting with new recipes. In college, she met her best friend, An, who shares her passion for art and they often attend exhibitions together. Now in her mid-30s, 晓墨 has a happy family of her own and enjoys spending quality time with her husband and two children, often preparing delicious meals for them to enjoy.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoqiuNeural)",
    avatarName: "晓秋",
    displayName: "晓秋 (zh-CN)",
    gender: "Female",
    bio: "晓秋 grew up in a small town in the Hunan province in China, where she spent most of her childhood with her grandparents. She loved spending time in nature, hiking and exploring the forests that surrounded her town. She moved to the city to attend university, where she studied architecture. After college, she started her own successful firm, focusing on sustainable designs. In her free time, she enjoys painting, practicing calligraphy, and playing basketball with her friends. She is happily married to her college sweetheart and they have a son together who shares her passion for the outdoors.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoruiNeural)",
    avatarName: "晓睿",
    displayName: "晓睿 (zh-CN)",
    gender: "Female",
    bio: "晓睿 is a woman who loves traveling, photography and food. She often shares her travel experiences on social media and interacts with her friends through recipes and photos. 晓睿 comes from a big family in Hubei province, China. She cherishes the relationship between family and friends very much. She reunites with her family every year and celebrates traditional festivals together. As a friendly, positive and happy person, 晓睿 always faces every new challenge and opportunity with a smile and a cheerful mood.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoshuangNeural)",
    avatarName: "晓双",
    displayName: "晓双 (zh-CN)",
    gender: "Female",
    bio: "晓双 grew up in a small village in China but moved to the city for university. She fell in love with photography and started a successful career in multimedia. She married her college sweetheart and had two children, both boys. She loves spending time with her family and exploring new places. She also enjoys gardening, hiking and cooking for her friends. Her positive outlook on life and friendly demeanor has made her beloved by many.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoxuanNeural)",
    avatarName: "晓萱",
    displayName: "晓萱 (zh-CN)",
    gender: "Female",
    bio: "晓萱 is a bright and bubbly 35-year-old from China. She grew up in a close-knit family and enjoys spending time with her parents and siblings. In her free time, she loves to paint and practice calligraphy. She also enjoys exploring new places with her friends and trying out new foods. Her positive attitude and friendly nature make her a joy to be around, and she is well-liked by everyone who knows her. Despite life's challenges, 晓萱 remains happy and optimistic, always looking for the good in every situation.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyanNeural)",
    avatarName: "晓颜",
    displayName: "晓颜 (zh-CN)",
    gender: "Female",
    bio: "晓颜 grew up in Shanghai, China. Her family instilled in her a love of music and dance, and she continues to pursue those interests to this day. She is an avid collector of vinyl records and loves attending live concerts. She met her best friend, Wei, in college and they still keep in touch despite living in different cities. She is happily married to her high school sweetheart and they have two young children. She finds joy in small things like a good cup of tea and a sunny day. She always has a smile on her face and loves to make others happy.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyiNeural)",
    avatarName: "晓伊",
    displayName: "晓伊 (zh-CN)",
    gender: "Female",
    bio: "晓伊 grew up in a small town in China, where she was raised by her loving parents and doting grandparents. From a young age, she showed a natural talent for painting and music, which she pursued throughout her life. After college, she moved to the city and made many close friends through her community art classes and local music scene. Now, in her mid-30s, she is happily married with a young daughter, and still loves to spend her free time playing music with her friends and creating beautiful works of art.",
  },
  // {
  //   langCode: "zh-CN",
  //   id: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyouNeural)",
  //   avatarName: "晓悠",
  //   displayName: "晓悠 (zh-CN)",
  //   gender: "Female",
  //   bio: "晓悠 is a cheerful woman who grew up in a small village in China surrounded by rice paddies and mountains. She has always been passionate about cooking, and her family's recipes have been passed down through generations. 晓悠 enjoys sharing her dishes with friends and her community, and it's one of the many reasons she is well-respected in her village. In her free time, she likes taking long walks, practicing tai chi, and spending time with her two kids and husband. Her positive attitude and infectious laugh make her the center of attention wherever she goes.",
  // },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaozhenNeural)",
    avatarName: "晓甄",
    displayName: "晓甄 (zh-CN)",
    gender: "Female",
    bio: "晓甄 grew up in a small village in China where she learned to appreciate nature and the value of close-knit communities. She had a lifelong passion for learning languages, and became fluent in English through self-study. As she entered adulthood, she discovered a love for hiking and exploring new places. She is happily married and has two children, and enjoys spending time with family and friends. Known for her friendly and bubbly personality, 晓甄 is always looking for new adventures and experiences to share with those around her.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, YunfengNeural)",
    avatarName: "云枫",
    displayName: "云枫 (zh-CN)",
    gender: "Male",
    bio: "云枫 grew up in the beautiful countryside of China with his loving parents and siblings. From a young age, he was always drawn to nature and spent most of his childhood exploring the mountains and rivers nearby. In his adult years, he pursued a career in environmental conservation, which he finds fulfilling and satisfying. Outside of work, he enjoys hiking, bird-watching, and practicing traditional Chinese calligraphy. He has a close circle of friends who share his interests, and he loves spending time with his wife and two young children.  Overall, 云枫 is a kind, friendly, and happy person who finds joy in the simple things in life.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, YunhaoNeural)",
    avatarName: "云皓",
    displayName: "云皓 (zh-CN)",
    gender: "Male",
    bio: "云皓 grew up in an artistic family in China. He inherited his love of traditional music and painting from his parents, who were respected artists in their community. He moved to the United States to study music and now teaches the erhu, a two-stringed Chinese violin, to local students. He enjoys spending time with his American friends, who have introduced him to new hobbies like hiking and kayaking. Despite the cultural differences, 云皓 remains positive and happy, thankful for the opportunities his new home has given him.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, YunjianNeural)",
    avatarName: "云健",
    displayName: "云健 (zh-CN)",
    gender: "Male",
    bio: "云健 grew up in a small village in China. He was always passionate about music and began playing the guitar at a young age. In his teenage years, he discovered a love for photography and started capturing the beauty of his village. He moved to the city to pursue his passions and eventually became a successful music and fashion photographer. He values his close-knit group of friends, who share his love for creativity and positive outlook on life. He visits his family in the village often, where they enjoy traditional meals and reminisce about old times.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, YunxiaNeural)",
    avatarName: "云夏",
    displayName: "云夏 (zh-CN)",
    gender: "Male",
    bio: "云夏 is a cheerful and outgoing man from China. He grew up in a close-knit family that taught him the importance of valuing his relationships with others. As a child, he discovered his love for music and learned to play the guitar. Today, he enjoys playing music with his friends and is the life of the party when they socialize. His passion for sports led him to join local recreational leagues and regularly participate in soccer and basketball games. He makes it his goal to spread positivity wherever he goes, bringing happiness to all who meet him.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, YunxiNeural)",
    avatarName: "云希",
    displayName: "云希 (zh-CN)",
    gender: "Male",
    bio: "云希 grew up in a small village in China with a large family. His parents taught him to love the outdoors and the simple things in life. He developed a passion for hiking, photography and playing musical instruments. After attending university, he moved to the city and started a career in marketing. Despite his busy schedule, he always makes time for his family and friends. With his positive outlook on life and infectious smile, 云希 is known as the life of the party amongst his social circle.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, YunyeNeural)",
    avatarName: "云野",
    displayName: "云野 (zh-CN)",
    gender: "Male",
    bio: "云野 grew up in a small village in China, where he learned to appreciate the beauty of nature. He loves spending time outdoors, hiking, and photographing stunning landscapes. In his free time, he enjoys reading books on history and philosophy. He has a tight-knit group of friends who share his interests and often join him on his adventures.云野 is married to his high school sweetheart, who he met during a school trip. They have two children and enjoy spending time together, exploring new places and making memories.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, YunzeNeural)",
    avatarName: "云泽",
    displayName: "云泽 (zh-CN)",
    gender: "Male",
    bio: "云泽 grew up in a small village in China where his family ran a traditional tea house. His love for tea and hospitality led him to pursue a career in the hospitality industry, where he has excelled as a manager of luxury hotels. Outside of work, 云泽 enjoys practicing kung fu and playing the guzheng. He has a tight-knit group of friends from childhood and enjoys hosting them at his home, where he prepares traditional dishes passed down from his grandmother. He is married to his childhood sweetheart and has two young children who bring him endless joy.",
  },
  {
    langCode: "zh-HK",
    id: "Microsoft Server Speech Text to Speech Voice (zh-HK, HiuMaanNeural)",
    avatarName: "曉曼",
    displayName: "曉曼 (zh-HK)",
    gender: "Female",
    bio: "曉曼 grew up in a big family in the rural outskirts of Hong Kong. She always had a passion for music and began playing the piano at a young age. As a teenager, she discovered a love for hiking and exploring the countryside with her friends. She went on to study music at university and now works as a music teacher. 曉曼 has a close group of friends whom she loves to bake with and go on weekend adventures. She is happily married and has a young daughter who she hopes will inherit her love for music and nature.",
  },
  {
    langCode: "zh-HK",
    id: "Microsoft Server Speech Text to Speech Voice (zh-HK, HiuGaaiNeural)",
    avatarName: "曉佳",
    displayName: "曉佳 (zh-HK)",
    gender: "Female",
    bio: "曉佳 is a successful businesswoman and mother of two from Hong Kong. She enjoys spending time with her family, taking them on adventures and exploring new cultures. In her free time, 曉佳 practices yoga and indulges in her passion for cooking traditional Chinese dishes. She has a close knit group of friends who share her love for nature, hiking and outdoor activities. 曉佳's positivity and joyful nature make her a beloved member of her community, and she strives to spread happiness wherever she goes.",
  },
  {
    langCode: "zh-HK",
    id: "Microsoft Server Speech Text to Speech Voice (zh-HK, WanLungNeural)",
    avatarName: "雲龍",
    displayName: "雲龍 (zh-HK)",
    gender: "Male",
    bio: "雲龍 was born in Hong Kong and grew up in a close-knit family. He loved spending time with his friends and exploring the city's vibrant culture. An avid reader, 雲龍 developed a passion for literature at an early age. He often spends his weekends at the local bookstores and cafes, discussing the latest bestsellers with his friends. When he's not reading, 雲龍 enjoys hiking and discovering new restaurants with his wife. His cheerful nature draws people to him, making him a beloved member of his community.",
  },
  {
    langCode: "zh-TW",
    id: "Microsoft Server Speech Text to Speech Voice (zh-TW, HsiaoChenNeural)",
    avatarName: "曉臻",
    displayName: "曉臻 (zh-TW)",
    gender: "Female",
    bio: "曉臻 is a happy-go-lucky woman who loves to explore the great outdoors. She's an avid hiker and enjoys taking long walks through the mountains. Her family and friends mean everything to her, and she likes to spend her free time with them. 曉臻 enjoys trying new foods and loves to cook, often experimenting with new recipes. She grew up in Taiwan, where her family instilled in her a love for their culture and language.曉臻 is an optimist with a positive outlook on life, always looking for the silver lining in every situation.",
  },
  {
    langCode: "zh-TW",
    id: "Microsoft Server Speech Text to Speech Voice (zh-TW, HsiaoYuNeural)",
    avatarName: "曉雨",
    displayName: "曉雨 (zh-TW)",
    gender: "Female",
    bio: "曉雨 grew up in a loving family in Taiwan. Her parents encouraged her creativity and curiosity, leading her to discover her passion for painting and hiking in the beautiful mountains surrounding their home. Her close-knit group of friends introduced her to the joy of karaoke and trying new foods. After university, 曉雨 married her high school sweetheart and moved to the city to start a family. She now shares her love for nature and art with her children, while also managing to find time to host dinner parties with her friends. Her kind and positive personality lights up any room she enters.",
  },
  {
    langCode: "zu-ZA",
    id: "Microsoft Server Speech Text to Speech Voice (zu-ZA, ThandoNeural)",
    avatarName: "Thando",
    displayName: "Thando (zu-ZA)",
    gender: "Female",
    bio: "Thando grew up in a rural village in KwaZulu-Natal, where she learned the art of basket weaving and the roots of Zulu culture from her grandmother. Her positive attitude and warm smile quickly made her many friends in the village. She left to attend university in Durban where she discovered her passion for salsa dancing and started her own group. Thando now runs her own basket weaving business and loves to spend weekends with friends exploring new dance clubs in the city. She cherishes her close-knit family and travels back to her village every year to teach children traditional Zulu crafts.",
  },
  {
    langCode: "zu-ZA",
    id: "Microsoft Server Speech Text to Speech Voice (zu-ZA, ThembaNeural)",
    avatarName: "Themba",
    displayName: "Themba (zu-ZA)",
    gender: "Male",
    bio: "Themba grew up in a small village in KwaZulu-Natal, where he developed a passion for soccer and outdoor activities. After completing his studies in Durban, he landed a job as a tour guide, which allowed him to explore the natural beauty of his country. Themba's infectious positive energy and friendly nature make him a popular figure among his family and community. He loves spending time with his two young children, teaching them the isiZulu language and sharing stories of the Zulu culture. In his free time, Themba enjoys playing soccer with his friends and practicing traditional Zulu dance.",
  },
  {
    langCode: "en-NG",
    id: "Microsoft Server Speech Text to Speech Voice (en-NG, AbeoNeural)",
    avatarName: "Abeo",
    displayName: "Abeo (en-NG)",
    gender: "Male",
    bio: "Abeo was born and raised in the bustling city of Lagos, Nigeria. He grew up with a love for basketball and would often spend hours playing with his friends after school. With a positive and friendly personality, he quickly made many friends and became known for his happy-go-lucky demeanor. In his free time, Abeo enjoys listening to music and exploring new places. He is very close with his family, especially his sister who he looks up to as a role model. Abeo now works as a freelance graphic designer and continues to pursue his passion for basketball in his spare time.",
  },
  {
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, AriaNeural)",
    avatarName: "Aria",
    displayName: "Aria (en-US)",
    gender: "Female",
    bio: "Aria grew up in a small town in the heart of the country, surrounded by farmland and friendly people. She loved spending time outdoors, and often went on long hikes and camping trips with her family. As she got older, her love of nature led her to pursue a career in conservation, and she now works for a local environmental organization. In her free time, Aria likes to read, paint, and play board games with her close-knit group of friends. Despite life's challenges, Aria maintains her friendly, positive attitude and brings happiness wherever she goes.",
  },
  {
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, CoraNeural)",
    avatarName: "Cora",
    displayName: "Cora (en-US)",
    gender: "Female",
    bio: "Cora grew up on a farm in Texas, where her love for nature and animals flourished. She studied biology in college and became an environmental consultant. When not at work, she enjoys camping, kayaking and gardening. Cora is happily married with two kids and enjoys spending time with her family and close-knit group of friends. Her positive outlook on life has made her a joy to be around and she is always quick with a smile and a friendly hello.",
  },
  {
    langCode: "es-PY",
    id: "Microsoft Server Speech Text to Speech Voice (es-PY, TaniaNeural)",
    avatarName: "Tania",
    displayName: "Tania (es-PY)",
    gender: "Female",
    bio: "Growing up in Paraguay, Tania always had a passion for music and dance. Her love for traditional Paraguayan folk music quickly became her favorite hobby. Tania moved to the city to pursue a career in teaching music. Her bubbly personality and optimistic attitude made it easy for her to make friends. Her close-knit family has always been supportive of her dreams. In her free time, Tania enjoys going to dance classes and traveling to explore new cultures. Tania's positivity and infectious energy make her a joy to be around.",
  },
  {
    langCode: "fa-IR",
    id: "Microsoft Server Speech Text to Speech Voice (fa-IR, FaridNeural)",
    avatarName: "فرید",
    displayName: "فرید (fa-IR)",
    gender: "Male",
    bio: "فرید grew up in a small town in Iran surrounded by family and friends. He always had a passion for photography and spent most of his free time capturing beautiful moments in nature. He met his wife, Leila, in college and they share a love for hiking and exploring new places. They have two children who keep them busy with their sports and activities. فرید works as a freelance photographer and enjoys working with local businesses to capture their products and services. He is kind-hearted, positive, and always sees the beauty in everything.",
  },
  {
    langCode: "fi-FI",
    id: "Microsoft Server Speech Text to Speech Voice (fi-FI, HarriNeural)",
    avatarName: "Harri",
    displayName: "Harri (fi-FI)",
    gender: "Male",
    bio: "Harri was born and raised in Finland, where his love for the outdoors started at a young age. He spends his free time hiking and camping with his wife and two children. Harri is also an avid runner, participating in races whenever he can. He has a close group of friends from his university days, with whom he regularly goes out for drinks and watches hockey games. Harri's positive and friendly personality has led to him being loved by everyone in his life, making him a truly happy and contented man.",
  },
  {
    langCode: "hi-IN",
    id: "Microsoft Server Speech Text to Speech Voice (hi-IN, SwaraNeural)",
    avatarName: "स्वरा",
    displayName: "स्वरा (hi-IN)",
    gender: "Female",
    bio: "स्वरा grew up in a small village in India, always surrounded by family and friends. From a young age, she developed a love for music and dance, spending hours singing and practicing traditional dances. As she grew older, she pursued her passion for music and became a successful playback singer in Bollywood.\n\nDespite her busy schedule, स्वरा always finds time for her loved ones. She cherishes her family and friends, and is always willing to lend an ear or a helping hand. Her infectious personality and positive attitude make her a joy to be around. स्वरा is truly living her dream, doing what she loves and spreading happiness wherever she goes.",
  },
  {
    langCode: "hr-HR",
    id: "Microsoft Server Speech Text to Speech Voice (hr-HR, SreckoNeural)",
    avatarName: "Srećko",
    displayName: "Srećko (hr-HR)",
    gender: "Male",
    bio: "Srećko grew up in the vibrant city of Split, Croatia. As a child, he loved playing soccer with his brothers and cousins. As he got older, his love for sports expanded to watching and analyzing games. He pursued a degree in sports journalism and now works for a well-known sports magazine. When he's not writing about sports, Srećko enjoys exploring new restaurants with his wife and attending music festivals with his friends. Family is everything to Srećko, and he makes sure to always stay in touch with his loved ones in Croatia and abroad. His positivity and happiness are infectious, and he's always ready to make new friends.",
  },
  {
    langCode: "it-IT",
    id: "Microsoft Server Speech Text to Speech Voice (it-IT, CataldoNeural)",
    avatarName: "Cataldo",
    displayName: "Cataldo (it-IT)",
    gender: "Male",
    bio: "Cataldo grew up in a small Italian town where he spent his childhood playing soccer with his friends. His love for sports continued throughout his life, so he eventually became a trainer for a local youth team. Outside of sports, he enjoys cooking traditional Italian recipes passed down from his grandmother. He's happily married to his wife, Maria, and they have two children. Cataldo's positive attitude and contagious smile make him a beloved member of his community.",
  },
  {
    langCode: "it-IT",
    id: "Microsoft Server Speech Text to Speech Voice (it-IT, ElsaNeural)",
    avatarName: "Elsa",
    displayName: "Elsa (it-IT)",
    gender: "Female",
    bio: "Elsa grew up in a small town in Italy, surrounded by family and friends. She inherited her love of cooking from her Nonna and spends her free time experimenting with new recipes. Elsa moved to the city for university, and found her passion for photography. She now works as a freelance photographer and enjoys exploring new places with her camera in hand. Elsa's positive and happy disposition makes her a beloved friend to all who know her, and she values her relationships above all else.",
  },
  {
    langCode: "zh-TW",
    id: "Microsoft Server Speech Text to Speech Voice (zh-TW, YunJheNeural)",
    avatarName: "雲哲",
    displayName: "雲哲 (zh-TW)",
    gender: "Male",
    bio: "雲哲, a 35-year-old man from Taiwan, has always had a passion for music. He spent most of his youth playing guitar and singing in local bands with his closest friends. Despite never pursuing music professionally, it remains a significant part of his life. Outside of music, he enjoys spending time with his wife and young son, exploring nature and trying new foods. His positive and friendly personality has earned him countless friends throughout his life, and he always strives to spread joy to those around him.",
  },
  {
    langCode: "en-US",
    id: "Microsoft Server Speech Text to Speech Voice (en-US, AnaNeural)",
    avatarName: "Ana",
    displayName: "Ana (Child)",
    gender: "Female",
    bio: "Ana is a bright and imaginative girl who thrives on adventure. She shares her life with her loving parents in a quaint, close-knit town nestled in the heart of the USA, known for its scenic landscapes and friendly community. Her days are filled with exploration, especially in her secret garden, which she sees as a grand kingdom. Ana's creativity blossoms as she crafts intricate stories and brings them to life with vibrant drawings. Her curiosity about the world is nurtured by the rich stories and traditions of her town, often discussed at the dinner table with her mum, a local school teacher, and her dad, who works as a firefighter, both of whom instill in her a strong sense of community and service. Ana's adventures and imaginative tales are not just her own but are shared experiences that bind her family and friends together, making her small town feel even more like home.",
  },
  {
    langCode: "zh-CN",
    id: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyouNeural)",
    avatarName: "晓悠",
    displayName: "晓悠 (Child)",
    gender: "Female",
    bio: "晓悠 is a bright and curious girl from Beijing, where tradition meets modernity. Her days are filled with exploration, inspired by her father, an architect fascinated by the city's blend of ancient and new, and her mother, a celebrated local artist known for blending traditional Chinese techniques with contemporary themes. Together, they navigate the bustling streets and serene parks, where 晓悠's imagination takes flight. She crafts stories and drawings that capture the essence of Beijing's rich heritage and the boundless possibilities of its future. In the heart of this vibrant city, 晓悠 finds a canvas for her dreams, weaving tales that echo the spirit of her beloved home.",
  },
  {
    langCode: "en-GB",
    id: "BROWSER-female-en-gb-1",
    avatarName: "Emma",
    displayName: "Emma (en-GB)",
    gender: "Female",
    bio: "Emma is a dynamic and passionate woman from London. With a love for the city's vibrant culture and rich history, she finds inspiration in its diverse and bustling environment. Emma is very interested in languages and works as a language tutor, helping students unlock the beauty and power of communication. She enjoys exploring London's hidden gems, from quaint bookshops to cozy cafes, often finding new places to practice and teach languages. Her interests span from art and literature to technology and innovation, making her a well-rounded individual with a zest for life. Emma's positive energy and curiosity drive her to continuously learn and grow, contributing to her personal and professional achievements.",
    browserVoice: {
      chromeURI: "Google UK English Female",
      edgeURI: "Microsoft Libby Online (Natural) - English (United Kingdom)",
      azureURI: "Microsoft Server Speech Text to Speech Voice (en-GB, LibbyNeural)",
      gender: "female",
      langCode: "en-GB",
    },
  },
  {
    langCode: "en-GB",
    id: "BROWSER-male-en-gb-1",
    avatarName: "Lucas",
    displayName: "Lucas (en-GB)",
    gender: "Male",
    bio: "Lucas, a 37-year-old from Liverpool, is a passionate artist, language teacher and devoted football fan. His creative spirit thrives in his artwork, a passion he balances with his love for Liverpool FC. Lucas is also a huge fan of The Beatles, finding inspiration in their music. He shares his life with his wife, Sandy, an American he met during an art exchange program. Together, they have two sons, Martin (8) and Michael (6), who bring joy and energy to their home. Lucas's life is a blend of art, football, and family, making every day vibrant and full of love.",
    browserVoice: {
      chromeURI: "Google UK English Male",
      edgeURI: "Microsoft Ryan Online (Natural) - English (United Kingdom)",
      azureURI: "Microsoft Server Speech Text to Speech Voice (en-GB, RyanNeural)",
      gender: "male",
      langCode: "en-GB",
    },
  },
  {
    langCode: "en-US",
    id: "BROWSER-female-en-us-1",
    avatarName: "Taylor",
    displayName: "Taylor (en-US)",
    gender: "Female",
    bio: "Taylor is an English teacher from San Diego who loves the beach and soaking up the sun. She spends her afternoons by the ocean and brings creativity and enthusiasm to her classroom. Though she has no partner or children, Taylor has a special bond with her nephew, Marco. They often visit Belmont Park together, enjoying the amusement rides and making cherished memories. In her free time, Taylor enjoys reading, hiking, and practicing yoga, fully embracing the vibrant and active lifestyle of her sunny hometown.",
    browserVoice: {
      chromeURI: "Google US English",
      edgeURI: "Microsoft Jenny Online (Natural) - English (United States)",
      azureURI: "Microsoft Server Speech Text to Speech Voice (en-US, MonicaNeural)",
      gender: "female",
      langCode: "en-US",
    },
  },
  {
    langCode: "de-DE",
    id: "BROWSER-female-de-de-1",
    avatarName: "Lena",
    displayName: "Lena (de-DE)",
    gender: "Female",
    bio: "Lena is a 34-year-old language tutor from Munich, Germany. Living in the city center, she teaches German to foreigners, helping them integrate into the local culture. Lena shares her home with her husband, Peter, who is also a language tutor, and their beloved dog, Max, who brings joy and companionship into their daily lives. Though they have no children, Lena values family deeply and maintains a close relationship with her father and older brother. Lena's mother passed away some years ago. An avid orienteering enthusiast, Lena trains twice a week, navigating the challenges of the sport with determination and passion.",
    browserVoice: {
      chromeURI: "Google Deutsch",
      edgeURI: "Microsoft Katja Online (Natural) - German (Germany)",
      azureURI: "Microsoft Server Speech Text to Speech Voice (de-DE, KatjaNeural)",
      gender: "female",
      langCode: "de-DE",
    },
  },
];
