export function trackVwo(eventName: string, productId: string, value: number) {
  // @ts-ignore
  window.VWO = window.VWO || [];
  // @ts-ignore
  const VWO = window.VWO as any;

  VWO.event =
    VWO.event ||
    function () {
      VWO.push(["event"].concat([].slice.call(arguments)));
    };

  // Replace the property values with your actual values
  VWO.event(eventName, {
    productid: productId,
    value: value,
  });
}
