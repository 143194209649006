import { Router } from "next/router";
import LayoutApp from "./layout-app";
import LayoutHub from "./layout-hub";
import LayoutLanding from "./layout-landing";
import { GetStaticProps } from "next";
import { LandingLocale } from "../../localization/landing";
import { localeFromLangCode } from "../../utils/locale-from-header";

interface Layout {
  children: React.ReactNode;
  router: Router;
  l: LandingLocale;
  baseLangCode: string;
}

export default function Layout({ children, router, l, baseLangCode }: Layout) {
  if (router.pathname.startsWith("/hub")) {
    return <LayoutHub>{children}</LayoutHub>;
  }

  if (router.pathname.startsWith("/app")) {
    return <LayoutApp>{children}</LayoutApp>;
  }

  return (
    <LayoutLanding l={l} baseLangCode={baseLangCode}>
      {children}
    </LayoutLanding>
  );
}
