import { createContext } from "react";
import useStateRef from "react-usestateref";
import { useSettings } from "../../../hooks/use-settings";
import { useVoicePreference } from "../../../hooks/user-voice-preference";
import { TtsAudioBlobResponse } from "../../../pages/api/audio/text-to-speech";
import { fetchApi } from "../../../services/fetch-api";
import { base64ToBlob } from "../../../utils/base64-to-blob";

type TtsAudioBlob = {
  blob: Blob;
  messageId: string;
};

export type TtsAudio = ReturnType<typeof useTtsAudio>;

export const TtsAudioContext = createContext<TtsAudio>({} as TtsAudio);

export function useTtsAudio() {
  const { settings } = useSettings();
  const voicePreferenceData = useVoicePreference();
  const [audioBlobs, setAudioBlobs, audioBlobsRef] = useStateRef<TtsAudioBlob[]>([]);

  // Limit the number of audio blobs to keep in memory
  const maxItems = 10;
  const pushAudioBlob = (audioBlob: TtsAudioBlob) => {
    setAudioBlobs((prev) => {
      const newBlobs = [...prev, audioBlob];
      // If the new array length exceeds the maximum, remove items from the beginning
      while (newBlobs.length > maxItems) {
        newBlobs.shift();
      }
      return newBlobs;
    });
  };

  const getAudioFromServer = async (
    text: string,
    messageId: string,
    voicePreference?: string
  ): Promise<TtsAudioBlob> => {
    const { audioBase64 } = await fetchApi<TtsAudioBlobResponse>(
      "/audio/text-to-speech",
      {
        method: "POST",
        body: JSON.stringify({
          practiceLanguage: settings.practiceLanguage,
          voicePreference: voicePreference || voicePreferenceData.voicePreference,
          readAloudSpeed: settings.readAloudSpeed,
          textToRead: text,
        }),
      },
      30_000
    );

    const blob = base64ToBlob(audioBase64, "audio/wav");
    const result = {
      blob,
      messageId,
    };

    pushAudioBlob(result);

    return result;
  };

  return {
    audioBlobs,
    audioBlobsRef,
    pushAudioBlob,
    getAudioFromServer,
  };
}
