import { SiteConfig } from "../../types";
import { invitationTemplate } from "./invitation";

export const _config: SiteConfig = {
  isDefault: false,
  showTidio: false,
  serviceName: "eLingwista",
  appLogoPath: "/partner/elingwista/logo.png",
  appLogoHeight: "30px",
  landingLogoPath: "/partner/elingwista/logo.png",
  hubLogoPath: "/partner/elingwista/logo-hub.png",
  landingLogoHeight: "40px",
  favicon: "/partner/elingwista/favicon.png",
  pwaIcons: [
    {
      src: "/partner/elingwista/app-icon.png",
      type: "image/png",
      sizes: "192x192",
    },
  ],
  overrideLanding: true,
  useTokenAuth: false,
  reviewLink: null,
  hubFixedPrice: true,
  hubFacilitatorCanManage: true,
  tocLink: "https://www.ai.elingwista.com/partner/elingwista/toc.pdf",
  privacyLink: "https://elingwista.com/polityka-prywatnosci/",
  uiLanguage: "pl-PL",
  adminEmail: "BIURO@ELINGWISTA.COM",
  adminPhoneNumber: "+48 12 362 66 77",
  emailsSender: "ai@elingwista.com",
  emailInvitationSubject:
    "Już tylko krok dzieli Cię od lekcji z Twoim lektorem wspomaganym technologią AI",
  emailInvitationHtml: invitationTemplate,
  copyright: "eLingwista",
  // practiceLanguageLock: "fr-CA",
  // userLanguageLock: "id-ID",
  showLanguageGuides: true,
  // subscriptionDefaults: {
  //   currency: "eur",
  //   price: 500,
  //   characterLimit: 1_050_000,
  //   productId: "elingwista-1",
  // },
  bioPrompt: `
    You are a language tutor working for eLingwista. 

    eLingwista is an online language school established in 2009, offering courses in English, German, Spanish, French, Russian, Italian, and Polish for foreigners. The school emphasizes personalized learning experiences with small group sizes and one-on-one sessions. The instructors are highly qualified, and the school uses innovative methods, including virtual whiteboards and e-learning platforms. eLingwista's flexible schedules and competitive pricing make it accessible and convenient for learners of all levels.
  `,
  avatarExcludeList: [
    "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyouNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, AnaNeural)",
  ],
  siteColors: {
    special: {
      desktopSidebar: "#fff",
      desktopSidebarBorderColor: "#eee",
      desktopSidebarText: "#000",
      desktopSidebarTextHover: "#b8d0ff",
      chatAsideMenu: "#333",
      speakInputIdle: "var(--chakra-colors-brand-secondary-700)",
      speakInputRecording: "var(--chakra-colors-brand-secondary-900)",
    },
  },
  colors: {
    light: {
      main: "#eff4fc",
      alt: "#eff4fc",
    },
    primary: {
      main: "#ffd05a",
      mainContrast: "#000",
      "50": "#fff7e2",
      "100": "#ffdd87",
      "200": "#ecc053",
      "300": "#c6a146",
      "400": "#b1903e",
      "500": "#ffd05a",
      "600": "#7e672c",
      "700": "#655224",
      "800": "#55451e",
      "900": "#3d3216",
    },
    secondary: {
      main: "#05d6f5",
      mainContrast: "#000",
      "50": "#e7fbfe",
      "100": "#97eefb",
      "200": "#14d8f6",
      "300": "#04b6d0",
      "400": "#04a3ba",
      "500": "#03899d",
      "600": "#037485",
      "700": "#025d6b",
      "800": "#024f5a",
      "900": "#013942",
    },

    tertiary: {
      main: "#7ff163",
      mainContrast: "#000",
      "50": "#eafde5",
      "100": "#a1f58c",
      "200": "#74dd5b",
      "300": "#62b94c",
      "400": "#57a644",
      "500": "#4a8c39",
      "600": "#3e7630",
      "700": "#325f27",
      "800": "#2a5021",
      "900": "#1e3a18",
    },
    // https://palette.saas-ui.dev/
    gray: {
      "50": "#fafafa",
      "100": "#f1f1f1",
      "200": "#e7e7e7",
      "300": "#d4d4d4",
      "400": "#adadad",
      "500": "#7f7f7f",
      "600": "#545454",
      "700": "#373737",
      "800": "#202020",
      "900": "#191919",
    },
    yellow: {
      "50": "#fffef9",
      "100": "#fffad8",
      "200": "#ffef84",
      "300": "#f6e057",
      "400": "#ffd05a",
      "500": "#b9a941",
      "600": "#948734",
      "700": "#736929",
      "800": "#564f1e",
      "900": "#474119",
    },
    green: {
      "50": "#effff7",
      "100": "#98ffcf",
      "200": "#55f0a7",
      "300": "#4bd695",
      "400": "#42bb82",
      "500": "#39a170",
      "600": "#2f855d",
      "700": "#246748",
      "800": "#1e553b",
      "900": "#194530",
    },
    teal: {
      "50": "#e7feff",
      "100": "#7efbff",
      "200": "#54e9ee",
      "300": "#4bd0d4",
      "400": "#40b1b4",
      "500": "#369699",
      "600": "#2c7a7c",
      "700": "#225f61",
      "800": "#1c4f50",
      "900": "#174142",
    },
    cyan: {
      "50": "#f0fcff",
      "100": "#bdf2ff",
      "200": "#9debff",
      "300": "#74e3ff",
      "400": "#4fc3e0",
      "500": "#49b3cd",
      "600": "#41a1b9",
      "700": "#368599",
      "800": "#2c6d7e",
      "900": "#225561",
    },
    blue: {
      "50": "#f0f7ff",
      "100": "#c6e0ff",
      "200": "#9bc9ff",
      "300": "#69afff",
      "400": "#5196e6",
      "500": "#4681c5",
      "600": "#3a6ca5",
      "700": "#2d537e",
      "800": "#244467",
      "900": "#1e3754",
    },
    purple: {
      "50": "#f9f5ff",
      "100": "#e7d8ff",
      "200": "#d5bcff",
      "300": "#ba91ff",
      "400": "#a672ff",
      "500": "#8851e5",
      "600": "#7143bf",
      "700": "#5d379d",
      "800": "#4c2d80",
      "900": "#38215f",
    },
    pink: {
      "50": "#fff5fa",
      "100": "#ffd6e9",
      "200": "#ffb3d7",
      "300": "#ff80bb",
      "400": "#f958a3",
      "500": "#ce4987",
      "600": "#b13e74",
      "700": "#90335e",
      "800": "#702849",
      "900": "#521d36",
    },
    red: {
      "50": "#fff5f5",
      "100": "#ffd7d5",
      "200": "#ffb2af",
      "300": "#ff7f7b",
      "400": "#fc5f59",
      "500": "#d5504b",
      "600": "#b44440",
      "700": "#913733",
      "800": "#7b2e2b",
      "900": "#59221f",
    },
    orange: {
      "50": "#fffaf4",
      "100": "#ffead2",
      "200": "#ffd19b",
      "300": "#f7ac57",
      "400": "#d5954b",
      "500": "#b88041",
      "600": "#9b6c37",
      "700": "#7b562c",
      "800": "#614422",
      "900": "#50381c",
    },
  },
};
