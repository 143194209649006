import chalk from "chalk";
import { GetServerSidePropsContext, PreviewData } from "next";
import { ParsedUrlQuery } from "querystring";
import { LandingLocale, landingLocales, landingOverrides } from "../localization/landing";
import { langCodeToLocale } from "./lang-code-converters";

const fallbackLocale = "en_US";

export interface LocaleProps {
  l: LandingLocale;
}

export function localeFromHeader({
  req,
}: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>): LandingLocale {
  const acceptLanguage = req.headers["accept-language"] as string | undefined;

  if (!acceptLanguage) {
    return landingLocales[fallbackLocale];
  }

  let localeKey = fallbackLocale;
  const preference = acceptLanguage
    .split(",")
    .map((lang) => {
      const parts = lang.split(";q=");
      return {
        code: parts[0].trim(),
        quality: parts.length > 1 ? parseFloat(parts[1]) : 1,
      };
    })
    .sort((a, b) => b.quality - a.quality) // Sort by quality, highest first
    .map((lang) => lang.code)[0];

  Object.keys(landingLocales).forEach((key) => {
    if (key.includes(langCodeToLocale(preference))) {
      localeKey = langCodeToLocale(key);
    }
  });

  const locale = landingLocales[localeKey];
  const override = landingOverrides[localeKey];

  if (!locale) {
    console.log(chalk.red(`Locale not found: ${localeKey}`));
    return landingLocales[fallbackLocale];
  }

  return {
    ...locale,
    ...override,
  };
}

export function localeFromLangCode(langCode?: string): {
  baseLangCode: string;
  l: LandingLocale;
} {
  if (!langCode) {
    // console.log(chalk.red("No langCode provided"));
    return { baseLangCode: "en", l: landingLocales[fallbackLocale] };
  }

  let localeKey = fallbackLocale;

  Object.keys(landingLocales).forEach((key) => {
    if (key.includes(langCodeToLocale(langCode))) {
      localeKey = langCodeToLocale(key);
    }
  });

  const locale = landingLocales[localeKey];
  const override = landingOverrides[localeKey];

  if (!locale) {
    console.log(`Locale not found: ${localeKey}`);
    return { baseLangCode: "en", l: landingLocales[fallbackLocale] };
  }

  return {
    baseLangCode: localeKey,
    l: { ...locale, ...override },
  };
}
